import { Checkbox, Grid, Typography } from '@mui/material';
import React from 'react';

const TestCaseList = (props) => {
    const { testDataDetails, onChange, selectedItems } = props;
    return (
        <div style = {{marginTop: 10}}>
            {
                _.map(testDataDetails, data => (
                    <Grid sx = {{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <Checkbox onChange={(e) => onChange(e, data)} sx={{padding: 0, marginRight: 1}} size='small' checked = {_.includes(selectedItems, data?.testDataId)}/>
                        <Typography>{data.testDataNumber}</Typography>
                    </Grid>
                ))
            }
        </div>
    )

}

export default TestCaseList;