import { Box, Grid } from "@mui/material";
import React from "react";
import Dropdown from "../shared/components/Dropdown";
import { AuditLogs } from "../constants/constants";

const AuditlogHeader =(props)=>{
   const dropdown_data = AuditLogs;
    const { applicationNames, setApplicationNames } = props;
    return(
        <Box sx={{justifyContent: 'flex-end', display: 'flex'}}>
            <Grid item sx={{justifyContent: 'flex-end', display: 'flex',width:'200px'}}>
                <Dropdown
                    placeholder='Select Server'
                    margin="dense"
                    sx={{marginTop:'7px',padding:'10px'}}
                    className="user-dropdown"
                    style={{ height: '42px', width: '100%' }}
                    inputProps={{ 'aria-label': 'Without label' }}
                    options={dropdown_data}
                    onChange={(event)=>setApplicationNames(event)}
                    value = {applicationNames ? applicationNames : '' }
                    valueKey = 'shortName'
                    labelKey = 'shortName'
                    multiple = {false}
                    isClear={false}
                />
            </Grid>
            
        </Box>
    );
}

export default AuditlogHeader