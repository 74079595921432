import { yupResolver } from '@hookform/resolvers/yup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm, watch } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useAddUserMutation, useEditUserMutation, useFetchCustomerNameListMutation } from '../../redux/users/users.api';
import Dropdown from '../../shared/components/Dropdown';
import AddCheckbox from '../../shared/components/forms/AddCheckbox';
import MUIButton from '../../shared/components/MUIButton';
import PayaptTextField from '../../shared/components/TextField';
import { UserMsg } from '../../shared/constants/AlertMessages';
import { ALERT_SEVERITY } from '../../shared/constants/constants';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
import '../styles/users.css';
import TestCaseHeader from '../testCaseHeader';
import { userValidationSchema, userValidationSchemaWithCustomer } from './utils';
import { useSelector } from 'react-redux';
import { Roles } from '../../constants/constants';
import PayaptLoader from '../../shared/components/Spinner';

const styles = {
    menuProps: {
      sx: {
          '.MuiPaper-root': {
              maxHeight: '40%',
          },
      },
  },
  }
const AddEditUser = (props) => {
    const { selectedRecord, setModalVisibility = () => { }, setIsUserList = () => { }, roleList, customersList } = props;
    const { name: loggedInRole } = useSelector((state) => state?.common?.userDetails?.userRole)
    const resolver = _.isEqual(_.toUpper(Roles.SUPER_ADMIN.name), _.toUpper(loggedInRole)) ? yupResolver(userValidationSchemaWithCustomer) : yupResolver(userValidationSchema);
    const Snackbar = useContext(SnackbarContext);
    const { customerId } = useSelector((state) => state?.common);
    const [selectedRole, setSelectedRole] = useState(roleList?.find((item) => item.user_role_name == selectedRecord?.role) ? roleList?.find((item) => item.user_role_name == selectedRecord?.role)?.user_role_id : "")
   
    const [disabledOptions, setDisabledOptions] = useState([]);
    const [headerDetails, setHeaderDetails] = React.useState({
        title: "",
        subTitle: ""
    })

    
const [addUser,{isLoading:adduserLoading}] = useAddUserMutation();
const [updateUser,{isLoading}] = useEditUserMutation();
    const {
        control,
        watch,
        register,
        setValue,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver,
        defaultValues: {
            firstName: "",
            lastName: "",
            email: "",
            role: "",
            mobile: "",
            status: 0,
            isAccount: 0,
            // address: "",
            customerId: customerId,
            // altaddress: ""
        }

    });
    console.log('control', control);
    const setRole = (r) => {
        setSelectedRole(r);


        setValue('role', r)
        if (Roles.TESER.id === r) {
            setValue('isAccount', 0)
        }
    }
    const defaultValues = {
        firstName: selectedRecord.firstName,
        lastName: selectedRecord.lastName,
        email: selectedRecord.email,
        role: roleList?.find((item) => item.user_role_name == selectedRecord?.role) ? roleList?.find((item) => item.user_role_name == selectedRecord?.role)?.user_role_id : "",
        mobile: selectedRecord.mobile,
        status: selectedRecord.status === "Active" ? true : false,
        isAccount: true,
        // isAccount: selectedRecord.privateAccount,
        customerId: selectedRecord.customerId

    }
    const handleValidate = (value) => {
        const isValid = isValidPhoneNumber(value);
        return isValid;
    }

    React.useEffect(() => {
        if (selectedRecord && selectedRecord?.userId) {
            setHeaderDetails({
                title: "Edit User",
                subTitle: "You can update the detail of the user here"
            })
            reset({
                ...defaultValues,
            })
            return;
        }
        setHeaderDetails({
            title: "Add User",
            subTitle: "You can add new user by filling this form"
        });

    }, [selectedRecord]);
    const handleSaveNavigation = async (xRes) => {
        if (xRes.data === null) {
            Snackbar.displayMsg(UserMsg.ADD_USER_SUCCESS, ALERT_SEVERITY.SUCCESS);
            setIsUserList(true);
            setModalVisibility(false);
        } else {
            Snackbar.displayMsg(`${xRes?.error?.data?.message}`, ALERT_SEVERITY.ERROR);
        }
    }
    const handleEditNavigation = async (xRes) => {
        if (xRes.data === null) {
            Snackbar.displayMsg(UserMsg.UPDATE_USER_SUCCESS, ALERT_SEVERITY.SUCCESS);
            setIsUserList(true);
            setModalVisibility(false);
        } else {
            Snackbar.displayMsg(`${xRes?.error?.data?.message}`, ALERT_SEVERITY.ERROR);
        }
    }

    const onSubmit = async (data) => {
        console.log('data', data);
        data.status = data.status === true ? 1 : 0;
        data.isAccount = data.isAccount === true ? 1 : 0;
        data.customerId = Number(data.customerId);
        data.role = +data.role;
        if (selectedRecord.userId) {
            data.userId = selectedRecord.userId ? selectedRecord.userId : "";
            const updateResult = await updateUser(data);
            handleEditNavigation(updateResult);
        } else {
            const saveResult = await addUser(data);
            handleSaveNavigation(saveResult);
        }
    }




   



    const selectedCustomer = watch('customerId');

    useEffect(() => {

        const disabledOptions = determineDisabledOptions(selectedCustomer);
        setDisabledOptions(disabledOptions);

        // console.log('Selected customer:', selectedCustomer1);
    }, [selectedCustomer]);




    const determineDisabledOptions = (selectedCustomer) => {



        if (selectedCustomer !== -1) {
            return [3];
        }

        return []; // No disabled options by default
    };



    return (
        <>
            <TestCaseHeader
                title={headerDetails.title}
                subtitle={headerDetails.subTitle}
            />
            <Box px={2.5} sx={{ marginTop: 2 }}>
            { isLoading &&  <PayaptLoader sx={{ position:"absolute",left:"50%" }} /> }
{ adduserLoading &&  <PayaptLoader sx={{ position:"absolute",left:"50%" }} /> }
                <Grid container rowSpacing={2} columnSpacing={4} >
                    {
                        _.isEqual(_.toUpper(Roles.SUPER_ADMIN.name), _.toUpper(loggedInRole)) &&
                        <React.Fragment>
                            <Grid item xs={6}>
                                <span style={{ textAlign: 'left' }}>Customer Name <font color='red'>*</font></span>
                                <br />
                                <Controller
                                    control={control}
                                    name={"customerId"}
                                    render={({ field }) => (
                                        <Dropdown
                                            inputRef={register('customerId', { required: true })}
                                            {...field}
                                            placeholder='Select Customer'
                                            margin="dense"
                                            className="user-dropdown"
                                            MenuProps={styles.menuProps}
                                            style={{ width: '100%', height: '56px' }}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            options={customersList}
                                            error={!!errors['customerId']}
                                            helperText={errors['customerId']?.message}
                                            valueKey='customerId'
                                            labelKey='customerName'
                                            disabled={!!selectedRecord?.userId || watch('role') === 3}
                                        />
                                    )}
                                />
                                {errors.customerId && (
                                    <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem' }}>Customer is required</div>
                                )}
                            </Grid>
                            <Grid xs={6}></Grid>
                        </React.Fragment>
                    }
                    <Grid item xs>
                        <span style={{ textAlign: 'left' }}>First Name <font color='red'>*</font></span>
                        <br />
                        <Controller
                            control={control}
                            name={"firstName"}
                            render={({ field }) => (
                                <PayaptTextField
                                    {...register("firstName")}
                                    {...field}
                                    name="firstName"
                                    placeholder='Enter First Name'
                                    margin="dense"
                                    className="user-text-box"
                                    error={!!errors['firstName']}
                                    helperText={errors['firstName']?.message}

                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <span style={{ textAlign: 'left' }}>Last Name<font color='red'>*</font></span>
                        <br />
                        <Controller
                            control={control}
                            name={"lastName"}
                            render={({ field }) => (
                                <PayaptTextField
                                    {...register("lastName")}
                                    name="lastName"
                                    {...field}
                                    placeholder='Enter Last Name'
                                    margin="dense"
                                    className="user-text-box"
                                    error={!!errors['lastName']}
                                    helperText={errors['lastName']?.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <span style={{ textAlign: 'left' }}>Email <font color='red'>*</font></span>
                        <br />
                        <Controller
                            control={control}
                            name={"email"}
                            render={({ field }) => (
                                <PayaptTextField
                                    {...register("email")}
                                    {...field}
                                    name="email"
                                    placeholder='Enter Email address'
                                    margin="dense"
                                    className="user-text-box"
                                    error={!!errors['email']}
                                    helperText={errors['email']?.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <span style={{ textAlign: 'left' }}>Mobile <font color='red'>*</font> </span>
                        <br />
                        <Controller
                            name="mobile"
                            control={control}
                            rules={{
                                validate: (value) => handleValidate(value)
                            }}
                            render={({ field: { onChange, value } }) => (
                                <PhoneInput
                                    value={value}
                                    onChange={onChange}
                                    id="mobile"
                                    name="mobile"
                                    placeholder='Enter Mobile Number'
                                    margin="dense"
                                    addInternationalOption={true}
                                    international={true}
                                    style={errors.mobile && {
                                        border: '1px solid red'
                                    }}
                                    inputProps={{
                                        // className: "input-phone-number",
                                        // error: !!errors['mobile'],
                                        // helperText: errors['mobile']?.message,
                                        // fullWidth: true,

                                        inputRef: register('mobile'),
                                    }}
                                />
                            )}
                        />
                        {errors["mobile"] && (
                            <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem' }}>Mobile Number is required</div>
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        <span style={{ textAlign: 'left' }}>Role <font color='red'>*</font></span>
                        <br />

                        <Controller
                            control={control}
                            name={"role"}
                            render={({ field }) => (
                                <Dropdown
                                    inputRef={register('role', { required: true })}
                                    {...field}
                                    placeholder='Select Role'
                                    margin="dense"
                                    className="user-dropdown"
                                    style={{ width: '100%', height: '56px' }}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    options={roleList}
                                    error={!!errors['role']}
                                    // helperText={errors['role']?.message}
                                    valueKey='user_role_id'
                                    labelKey='user_role_name'
                                    onChange={setRole}
                                    //    value={selectedRole}
                                    // disabledOptions={disabledOptions}
                                />
                            )}
                        />
                        {errors.role && (
                            <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem' }}>Role is required</div>
                        )}
                    </Grid>
                    <Grid item xs={6} style={{ marginTop: 25, padding: 20 }}>
                        {/* <AddCheckbox
                            control={control}
                            register={register}
                            setValue={setValue}
                            errors={errors}
                            name={"isAccount"}
                            label={"Primary Account"}
                            disabled={_.isEqual(Roles.TESER.id, selectedRole)}
                        /> */}
                        <AddCheckbox
                            control={control}
                            register={register}
                            setValue={setValue}
                            errors={errors}
                            name={"status"}
                            label={"Active"}
                            disabled={_.isEqual(_.toUpper(Roles.SUPER_ADMIN.name), _.toUpper(selectedRecord?.role))}
                        />
                    </Grid>

                </Grid>

                <Grid item xs>
                    <br />
                    <MUIButton
                        style={{ marginLeft: '10px' }}
                        startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
                        title="Save Changes"
                        onClick={handleSubmit(onSubmit)}
                        type="submit" />
                </Grid>
            </Box>
        </>
    )
}

export default AddEditUser; 