import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { reviewDataRows } from './constants';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MUIButton from '../../shared/components/MUIButton';
import { InfoCircleFilled, LeftCircleFilled } from '@ant-design/icons';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {  useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentStep } from '../../actions/customerOnboarding';
import CustomTable from './CustomTable';

const CustomerOnbardingReview = () => {
    const [data, setData] = useState(reviewDataRows);
    const [expanded, setExpanded] = useState('panel0');
    const dispatch = useDispatch();
    const [tableData, setTableData] = React.useState();
    const { currentStep } = useSelector((state) => state.customerOnBoarding);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
      };

      const navigate = useNavigate();
      const onConfirm = () => {
        // dispatch(updateCurrentStep(currentStep + 1))
        navigate('/onboardprocessdone')
      }
      const onBack = () => {
        dispatch(updateCurrentStep(currentStep - 1));
      }
      const defaultExpanded = {};
      data.forEach((item, index) => {
     defaultExpanded[`panel${index}`] = true;
  });
  const mergedExpanded = { ...defaultExpanded, ...expanded };
  return (
    <Box sx={{ alignItems: "flex-start" }}mt={1}>
      <Box  sx={{ minHeight: '65vh' }}>
        <h4 className="title">Customer Onbarding Review&nbsp;&nbsp;<InfoCircleFilled className='step-info-icon' /></h4>
        <p className='title-content'>
          About Setup test Data for Simulator for Desired Results goes here
        </p>
        <Box>
            {/* {data?.map((xEl,xIdx) => ( */}
                <Accordion 
                // expanded={mergedExpanded["panel"] 
                defaultExpanded
                // onChange={handleChange(`panel${xIdx}`)}
                >
                    <AccordionSummary
                     sx={{backgroundColor:'#F2F2F2',borderBottom: '2px solid #ACACAC'}}
                     expandIcon={<ExpandMoreIcon />}
                     aria-controls="panel1a-content"
                     id="panel1a-header">
                      <Typography>Selected Plan</Typography>  
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography sx={{display:"flex"}}
                      >Subscription Plan A</Typography>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion 
                    defaultExpanded
                    // expanded={mergedExpanded["panel"]}
                    >
                    <AccordionSummary
                     sx={{backgroundColor:'#F2F2F2',borderBottom: '2px solid #ACACAC'}}
                     expandIcon={<ExpandMoreIcon />}
                     aria-controls="panel1a-content"
                     id="panel1a-header">
                      <Typography>Selected Participation Type</Typography>  
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography sx={{display:"flex"}}
                      >Credit Transfer Receive Only</Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion 
                    defaultExpanded
                    // expanded={mergedExpanded["panel"]}
                    >
                    <AccordionSummary
                     sx={{backgroundColor:'#F2F2F2',borderBottom: '2px solid #ACACAC'}}
                     expandIcon={<ExpandMoreIcon />}
                     aria-controls="panel1a-content"
                     id="panel1a-header">
                      <Typography>Selected Participation Type</Typography>  
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography sx={{display:"flex"}}
                      ><CustomTable
                       tableData={tableData}
                       setTableData={setTableData}
                      /></Typography>
                    </AccordionDetails>
                </Accordion>
            {/* ))}   */}
        </Box>
      </Box>
      <Grid container justifyContent="flex-start" sx={{ py: 2 }}>
        <MUIButton
          startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
          title="Back"
          sx={{ backgroundColor: '#F0F2F5', color: '#808080', mx: 2 }}
          color="inherit"
          onClick={onBack}
        />
        <MUIButton
          startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
          title="Confirm"
          onClick={onConfirm}
        />
      </Grid>
    </Box>
  )
}

export default CustomerOnbardingReview;
