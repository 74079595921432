
export const getRowClassName = (index, rec, selectedTestScenario) => {
    let className = "";
    if (
        rec.customerTestScenarioId ===
        selectedTestScenario?.customerTestScenarioId
    ) {
        className = "payapt-expanded-row";
    }
    return index % 2 === 0
        ? `${className} table-row-light`
        : `${className} table-row-dark`;
};

export const getSelectedRowKeys = (selectedTestScenarios) => {
    const selectedKeys = _.filter(
        Object.keys(selectedTestScenarios),
        (k) => selectedTestScenarios[k].isAllTestcasesSelected
    );
    return selectedKeys;
};

export const getSelectedTestCasesOfScenario = (scenarioId, selectedTestScenarios) => {
    const selectedKeys = _.get(
        selectedTestScenarios[scenarioId],
        "selectedKeys",
        []
    );
    return _.map(selectedKeys, (k) => k.customerTestCaseId);
};