import { createSlice } from '@reduxjs/toolkit';

export const registrationSlice = createSlice({
    name: 'registration',
    initialState: { },
    reducers: {
         // actionsGoesHere: (state, actions) => {
        //     state.customState = actions.payload;
        // },
    },
});

// export const { actionsGoesHere } = testCasesSlice.actions;
