import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TestCaseHeader from '../testCaseHeader';
import { user_activityLogs_column } from '../../constants/constants';
import useModal from '../../shared/hooks/useModal.hook';
import AntModal from '../../shared/components/Modal/AntModal';
import Filter from '../../shared/components/Filter.popup';
import CancelIcon from '@mui/icons-material/Cancel';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PayaptTable from '../../shared/components/Table';
import { useFetchCustomerNameListMutation } from '../../redux/users/users.api';
import { FORM_TYPES } from '../../shared/constants/formTypes';
import { getActivityLogFilterOptions, activityLogInitialFilters } from '../../components/homeScreens/TestRun/constants';
import { greyBorderColor, primaryColor } from '../../shared/constants/constants';
import { getSortDirection } from '../../utils/sorterUtils';
import { convertToLocalTime } from '../../shared/utils';
import PayaptTooltip from '../../shared/components/Tooltip';
import { EyeIcon } from '../../constants/menuIcons';
import { Modal } from 'antd';
import { useGetUserActivityLogDataMutation, useGetActivityTypeQuery } from '../../redux/activityLogs/activityLogs.api';
import WrapperComponent from '../../shared/components/wrapperComponent';
import ViewActivityLog from './viewActivityLog';
import moment from "moment";
import MUIButton from '../../shared/components/MUIButton';

const ActivityLogs = () => {
  const columns = user_activityLogs_column;
  const pageSize = useSelector((state) => state?.common?.pageSize);
  const { open, openModal, closeModal } = useModal();
  const [filterOptions, setFilterOptions] = useState(getActivityLogFilterOptions([]));
  const [filters, setFilters] = React.useState(activityLogInitialFilters || {});
  // console.log("filtersAC", activityLogInitialFilters);
  const [isModalVisible, setModalVisibility] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [selectedRecord, setSelectedRecord] = useState({});

  const initialPageOptions = {
    search: null,
    page: 0,
    size: pageSize,
    filters: {
      fromDate: null,
      toDate: null,
      customerId: 0,
      activityTypeId: null
    },
  }

  const [pageOptions, setPageOptions] = useState({ ...initialPageOptions });
  const [getUserActivityLogData, { data, isLoading, isSuccess, isError }] = useGetUserActivityLogDataMutation();
  // console.log("data===========>", data);
  const [fetchCustomerNameList, { data: customersList }] = useFetchCustomerNameListMutation();
  const { data: userActivityTypeData } = useGetActivityTypeQuery();
  useEffect(() => {
    fetchCustomerNameList();
    setSearchedText('');
    setPageOptions(initialPageOptions);
    const xPageOptions = {
      ...initialPageOptions
    }
    setPageOptions(xPageOptions);
  }, []);

  async function refetchUserLogsQuery(xOptions) {
    setPageOptions(xOptions);
    await getUserActivityLogData({ ...xOptions });
  }

  React.useEffect(() => {
    setPageOptions((prevSt) => ({ ...prevSt, search: searchedText }));
    if (searchedText === "") {
      setPageOptions(initialPageOptions)
    }
    getUserActivityLogData(pageOptions);
  }, [searchedText]);

  const updatePageAndGetData = (yPageOptions) => {
    const xPayload = {
      ...pageOptions,
      ...yPageOptions
    }
    setPageOptions(xPayload);
    getUserActivityLogData(xPayload);

  };

  const onSearchChange = (value) => {
    setSearchedText(value);
    setPageOptions({ ...pageOptions, search: value });
    refetchUserLogsQuery({ ...pageOptions, search: value });
  }

  const isFilterAdded = (category) => {
    const op = _.findIndex(filterOptions, f => f.categoryName === category);
    return op >= 0;
  }

  useEffect(() => {
    if (!_.isEmpty(customersList) && !isFilterAdded('customerId')) {
      filterOptions.unshift({
        id: '0',
        categoryLabel: "Customer",
        categoryName: "customerId",
        subOptions: [
          {
            id: '0',
            filterName: 'customerId',
            filterLabel: 'Select Customer',
            filterType: FORM_TYPES.ADDED_DROP_DOWNN,
            filterOptions: _.map(customersList, (c, index) => ({
              id: index,
              value: c.customerId,
              label: c.customerName
            }))
          }
        ]
      })
      setFilterOptions(filterOptions);
    }
  }, [customersList]);

  useEffect(() => {
    if (!_.isEmpty(userActivityTypeData) && !isFilterAdded('activityTypeId')) {
      filterOptions.unshift({
        id: '1',
        categoryLabel: "Activity Type",
        categoryName: "activityTypeId",
        subOptions: [
          {
            id: '1',
            filterName: 'activityTypeId',
            filterLabel: 'Select Activity Type',
            filterType: FORM_TYPES.ADD_DROP_DOWN,
            filterOptions: _.map(userActivityTypeData, (c, index) => ({
              id: index,
              value: c.activityTypeId,
              label: c.activityTypeName
            }))
          }
        ]
      })
      setFilterOptions(filterOptions);
    }
  }, [userActivityTypeData]);

  const setIsModalVisible = (isVisible, rec, entity) => {
    if (isVisible) {
      setSelectedRecord(rec);
    } else {
      setSelectedRecord({})
    }
    setModalVisibility(isVisible)
  }

  columns[0].render = (data) =>
  (
    <>
      <span style={{ textTransform: 'lowercase' }}>{convertToLocalTime(data)}</span>
    </>
  )
  columns[4].render = (data, rec) => (
    <PayaptTooltip title={((rec?.activityTypeName === 'Login') || (rec?.activityTypeName === 'Logout')) ? '' : 'Activity Log'}>
      <span
        onClick={() => ((rec?.activityTypeName === 'Login') || (rec?.activityTypeName === 'Logout')) ?
          null : setIsModalVisible(true, rec, 'viewData')}
        style={{
          justifyContent: 'center', display: 'flex',
          cursor: ((rec?.activityTypeName === 'Login') || (rec?.activityTypeName === 'Logout')) ? 'not-allowed' : 'pointer'
        }}>
        <EyeIcon />
      </span>
    </PayaptTooltip>
  )
  const [dateForDashboard, setDateForDashboard] = useState(moment());
  const handleSaveFilter = async () => {
    const options = {
      ...pageOptions,
      filters: {
        ...pageOptions.filters,
        fromDate: filters?.selectedDate?.selectedDate[0] ? moment(new Date(filters?.selectedDate?.selectedDate[0].$d)).format() : null,
        toDate: filters?.selectedDate?.selectedDate[0] ? moment(new Date(filters?.selectedDate?.selectedDate[1].$d)).format() : null,

        // fromDate: filters?.selectedDate?.selectedDate[0] ? moment.utc(new Date(filters?.selectedDate?.selectedDate[0].$d)).format() : null,
        // toDate: filters?.selectedDate?.selectedDate[0] ? moment.utc(new Date(filters?.selectedDate?.selectedDate[1].$d)).format() : null,
        customerId: filters?.customerId?.customerId || 0,
        activityTypeId: filters?.activityTypeId?.activityTypeId || null
      }
    };
    setPageOptions(options);
    await getUserActivityLogData({
      ...options
    });

    // setFilters({ ...activityLogInitialFilters });
    closeModal();
  }

  const handleCancelFilter = () => {
    // setFilters({ ...activityLogInitialFilters });
    closeModal();
  };

  const onClear = async () => {
    setFilters({ ...activityLogInitialFilters });
    setPageOptions(initialPageOptions);
    await getUserActivityLogData(initialPageOptions);
  };
  return (
    <Box sx={{ overflowY: 'auto', height: `calc(100vh - 100px)`, paddingBottom: "30px" }}>
      <TestCaseHeader
        title="User Activity Logs"
        subtitle="You can manage all the User Activity Logs here"

        showSearch={{
          show: true,
          value: searchedText,
          onChange: onSearchChange
        }}

        showFilter={{
          show: true,
          onClick: openModal
        }}
        displayInLine={true}
      />
      <Box my={2} style={{ textTransform: 'none' }}>
        <WrapperComponent isSuccess={isSuccess} isFetching={isLoading} isError={isError}>
          <PayaptTable
            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
            size="small"
            className="standalone_table"
            scroll={{ y: "60vh" }}
            columns={columns}
            data={!_.isEmpty(data) ? data?.list : []}
            loading={isLoading}
            rowKey={(rec) => rec?.activityLogId}
            onChange={(pagination, filters, sorter) =>
              updatePageAndGetData({
                page: pagination.current - 1,
                search: null,
                sortBy: sorter?.field || pageOptions.sortBy,
                sortDir: getSortDirection(sorter.order) || pageOptions.sortDir,
                pageSize
              })
            }
            pagination={{
              pageSize,
              current: !_.isEmpty(data) ? data.page + 1 : 1,
              total: !_.isEmpty(data) ? data.totalElements : 0,
              showPrevNextJumpers: true,
              showTitle: true,
              hideOnSinglePage: true,
              size: "small",
            }}

          />
        </WrapperComponent>
      </Box>

      <Modal
        onCancel={() => setIsModalVisible(false)}
        open={isModalVisible} footer={[]}
        width={'70%'}
        closeIcon={<CancelOutlinedIcon style={{ color: "#001A11", backgroundColor: "white" }} />}
        destroyOnClose
      >
        <ViewActivityLog item={selectedRecord}></ViewActivityLog>
      </Modal>

      {open && <AntModal
        title="Filter Results for User Activity Logs"
        open={open}
        width={'550px'}
        style={{ top: '21%', left: '29%' }}
        mask={false}
        okText="Apply Filter"
        cancelText="Clear"
        onOk={handleSaveFilter}
        footer={[]}
        okButtonProps={{
          style: {
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: primaryColor
          },
          icon: <CheckCircleIcon sx={{ fontSize: '18px' }} />
        }}
        cancelButtonProps={{
          style: {
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#F0F2F5',
            color: greyBorderColor
          },
          icon: <CancelIcon sx={{ fontSize: '18px' }} />
        }}
        onCancel={handleCancelFilter}
        closeIcon={<CancelOutlinedIcon sx={{ color: "#001A11", backgroundColor: "white" }} />}
      >
        <Filter filters={filters} setFilters={setFilters} options={filterOptions} />

        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }} >

          <>
            <MUIButton
              startIcon={<CancelIcon style={{ fontSize: 18, color: greyBorderColor }} />}
              title="Clear"
              sx={{ backgroundColor: '#F0F2F5', color: '#808080' }}
              color="inherit"
              onClick={onClear}
            />
            <MUIButton
              startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
              title="Apply Filter"
              onClick={handleSaveFilter}
              sx={{ mx: 2 }}
            />
          </>


        </Box>
      </AntModal>}

    </Box>
  )
}

export default ActivityLogs;
