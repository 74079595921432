import * as React from 'react';
import {Popover} from '@mui/material';
import Menu from './menu';
import MenuItem from './menuItem';

const PayaptPopoverMenu = (props) => {
    const { item, menuTitleKey, childrenKey,submenuTitleKey, isSelected, collapse, onMenuClick, selectedMenuKeys } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const onMenuItemClick = (e, item) => {
      handleClose();
      onMenuClick(e, item);
    }
    const open = anchorEl;
  
  return (
    <div>
      <MenuItem 
        item = {item} 
        isSelected = {isSelected} 
        onClick={handleClick}
        menuTitleKey = {menuTitleKey}
        collapse = {collapse}
        />
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
        }}
    >
        <Menu
            items = {item[childrenKey]}
            menuTitleKey = {submenuTitleKey}
            onClick = {onMenuItemClick}
            selectedKeys = {selectedMenuKeys}
        />
    </Popover>
    </div>
  );
}

export default PayaptPopoverMenu;
