import { Box } from '@mui/material';
import React from 'react';

import XSwitch from './XSwitch';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    active: {
        fontWeight: "600",
        opacity: 1,
        color:'#009966'
    },
    inactive: {
        opacity: 0.6,
    },
};

function XTwoWayLabelSwitch(props) {
    const { primary, secondary, ...restProps } = props;
    return (
        <Box sx={styles.container}>
            <Box display="flex" sx={props?.checked ? styles.inactive : styles.active}>{primary}</Box>
            <XSwitch containerStyles={{ mx: 1 }} {...restProps} />
            <Box display="flex" sx={props?.checked ? styles.active : styles.inactive}>{secondary}</Box>
        </Box>
    );
}

export default XTwoWayLabelSwitch;
