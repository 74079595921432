const Alert = {
    signIn: {
        SIGN_IN_SUCCESS: "Logged in Successfully",
        SIGN_IN_FAIL: "Login is Failed",
    },
    signUp: {
        SIGN_UP_SUCCESS: "Signup is Successfully",
        SIGN_UP_FAIL: "Signup is Failed",
    },
    testCases: {
        CLONE_TEST_DATA_SUCCESS: "Successfully cloned Test Data",
        CLONE_TEST_DATA_FAIL: "Failed cloning Test Data",
        DELETE_TEST_DATA_SUCCESS: 'Successfully deleted test data',
        DELETE_TEST_DATA_FAIL: 'Failed to delete test data'
    },
    batchFiles: {
        CREATE_BATCH_FILE_SUCCESS: 'Batch File created successfully',
        CREATE_BATCH_FAIL: 'Failed to create Batch File',
        UPDATE_BATCH_FILE_SUCCESS: 'Batch File updated successfully',
        UPDATE_BATCH_FAIL: 'Failed to update Batch File',
        ADD_TESTCASE_SUCCESS: 'Testcase added successfully',
        ADD_TESTCASE_FAIL: 'Failed to add Testcase',
        DELETE_TESTCASE_SUCCESS: 'Testcase deleted successfully',
        DELETE_TESTCASE_FAIL: 'Failed to delete testcase',
        EXECUTE_BATCH_SUCCESS: 'Batch executed successfully',
        EXECUTE_BATCH_FAIL: 'Failed to execute Batch'
    },
    batchTestRuns: {
        KILL_TEST_RUNS_SUCCESS: 'Test Runs killed successfully',
        KILL_TEST_RUNS_FAIL: 'Failed to kill Test Runs'
    },
    addusers: {
        ADD_USER_SUCCESS: "User added successfully",
        ADD_USER_FAIL: "Failed to add User",
        UPDATE_USER_SUCCESS: "User updated successfully",
        UPDATE_USER_FAIL: "Failed to update User",
        CHANGE_USER_PASSWORD_SUCCESS: "User password changed successfully"
    },
    addCustomers: {
        ADD_CUSTOMER_SUCCESS: "Customer added successfully",
        ADD_CUSTOMER_FAIL: "Failed to add Customer",
        UPDATE_CUSTOMER_SUCCESS: "Customer update successfully",
        UPDATE_CUSTOMER_FAIL: "Failed to update Customer"
    },
    addTestData: {
        ADD_TESTDATA_SUCCESS: 'Test Data successfully',
        ADD_TESTDATA_FAIL:' Failed Test Data'
    },
    addSimulatorData: {
        ADD_SIMDATA_SUCCESS: 'Customer Test Data Approved Successfully',
        ADD_SIMDATA_FAIL: 'Failed to approve Customer Test Data'
    },
    updateSimulatorTest: {
        ADD_UPDATE_SUCCESS:"Updated Customer Sim Test Data Details",
        ADD_UPDATE_FAIL:"Failed to Update Customer Sim Test Data",
    }
}
 

export const SignInMsg = Alert.signIn;
export const SignUpMsg = Alert.signUp;
export const TestCasesMsg = Alert.testCases;
export const BatchFileMsg = Alert.batchFiles;
export const BatchTestRunsMsg = Alert.batchTestRuns;
export const UserMsg = Alert.addusers;
export const CustomerMsg = Alert.addCustomers;
export const AddTestDataMsg = Alert.addTestData;
export const SimulatorData = Alert.addSimulatorData;
export const UpdateSimulator = Alert.updateSimulatorTest;
