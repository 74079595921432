import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

import MUIButton from "../../shared/components/MUIButton";
import './testCase.css'

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';

import PayaptTextField from "../../shared/components/TextField";
import { batchNameApiKey, systemBatchFileValidationSchema } from "../BatchFiles/utils";
import { generateCurrentEpochTimeValue } from "../../shared/utils";

const ModalComponent = (props) => {
    const { title, ButtonLabel, visible, createBatch, onClose, isLoading } = props;
    const resolver = yupResolver(systemBatchFileValidationSchema);

    const {
        control,
        register,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver
    });

    React.useEffect(() => {
        const uniqueCurrentTimeValue = generateCurrentEpochTimeValue();
        reset({
            [batchNameApiKey]: uniqueCurrentTimeValue
        });
        return () => {
            reset()
        }
    }, [])

    const handleOnClose = () => {
        onClose();
        reset()
    }

    const onSubmit = async (data) => {
        createBatch(data);
    }

    return (
        <Dialog open={visible} p={3}>
            <DialogTitle className="dialog-title" sx={{ mb: 2 }}>
                {title}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={handleOnClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
            <DialogContent mt={2} px={2}>
                  {isLoading}
                <Controller
                    control={control}
                    name={batchNameApiKey}
                    render={({ field }) => (
                        <PayaptTextField
                            {...register(`${batchNameApiKey}`)}
                            {...field}
                            name={batchNameApiKey}
                            label="Batch File Name"
                            placeholder='Enter Batch File Name'
                            margin='dense'
                            required
                            error={!!errors[batchNameApiKey]}
                            helperText={errors[batchNameApiKey]?.message}
                            containerSx={{ mb: 0 }}
                        />
                    )}
                />
            </DialogContent>
            <DialogActions sx={{
                display: "flex",
                justifyContent: "flex-start",
                m: 2
            }}>
                <MUIButton style={{ marginTop: '30px' }}
                    startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
                    title={ButtonLabel}
                    onClick={handleSubmit(onSubmit)}
                />
            </DialogActions>
        </Dialog>
    )
}

export default ModalComponent;