import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  useRadioGroup,
} from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import React, { useState } from "react";
import "../styles/message.css";
import { green, grey } from "@mui/material/colors";
import TestData from "./testData";
import MasterXml from "./masterXml";
import TestCaseHeader from "../testCaseHeader";
import TestcaseDetailsHeader from "../testcaseDetailsHeader";

const ReviewTestCases = (props) => {
  const [xmlData, setXmlData] = useState();
  const [isMasterXml, setIsMasterXml] = useState(false);
  const { testCaseScenarioId, onClose, selectedTestScenario, showSingleTestcase, testcaseDetails,masterTestCase,messageTypePayload  } = props;

  const onSelectionChange = (val) => {
    setIsMasterXml(val === 'masterXml')
  }
  return (
    <div>
      <div style={{ marginLeft: 35, fontFamily: "Inter" }}>
        {" "}
        {isMasterXml ? <MasterXml selectedTestScenario={selectedTestScenario} onSelectionChange={onSelectionChange} messageTypePayload={messageTypePayload} />
          : <TestData 
              testCaseScenarioId={testCaseScenarioId} 
              onClose={onClose} 
              testcaseDetails={testcaseDetails} 
              showSingleTestcase={showSingleTestcase}
              masterTestCase={masterTestCase}
              onSelectionChange={onSelectionChange}
              selectedTestScenario = {selectedTestScenario}
              />}
      </div>
    </div>
  );
};

export default ReviewTestCases;
