import React from "react";
import _ from "lodash";

const getButtonClass = (validationResponse) => {
  if(_.isEmpty(validationResponse)){
    return 'accordion-button'
  }if(validationResponse.status === 200){
    return 'accordion-button accordion-success'
  }
  return 'accordion-button accordion-error'
}
const ValidationResponse = (props) => {
  const { validationResponse, responseKey } = props;
  return (
    <div className="schema-validation accordion" style={{ padding: 10 }} id={`${responseKey}-parent`}>
       <div class="accordion-item">
        <h2 class="accordion-header" id={`${responseKey}-header`}>
          <button className={getButtonClass(validationResponse)} type="button" data-bs-toggle="collapse" data-bs-target={`#${responseKey}`} aria-controls={`${responseKey}`} aria-expanded="true" >
          {responseKey === "schemaValidationResponse"
            ? "Schema Validation"
            : "Business Validation"}
          </button>
        </h2>
        <div id={responseKey} class="accordion-collapse collapse show" aria-labelledby={`${responseKey}-header`} data-bs-parent={`#${responseKey}-parent`}>
          <div class="accordion-body" style = {{flexWrap: 'wrap'}}>
              {!_.isEmpty(validationResponse) &&
            !_.isEmpty(validationResponse.message) ? (
              <div style = {{flexWrap: 'break-word', textAlign: 'left'}}>{_.map(validationResponse.message, (msg, index) => (
                <React.Fragment>
                  {msg}
                  <br/>
                  {validationResponse.status !== 200 && !_.isEmpty(validationResponse.path)&& <span style = {{wordBreak: 'break-all'}}><b>Path:</b> {validationResponse.path[index]}</span>}
                  <br/><br/>
                </React.Fragment>
              ))}</div>
            ) : 'No validation done yet'
            }
          </div>
        </div>
  </div>
      {/* <div
        style={{
          display: "flex",
          margin: "1%",
          justifyContent: "space-between"
        }}
      >
        <div style={{ fontWeight: 600 }}>
          {responseKey === "schemaValidationResponse"
            ? "Schema Validation"
            : "Business Validation"}
        </div>
        {!_.isEmpty(validationResponse) &&
          !_.isEmpty(validationResponse[responseKey]) && (
            <div>
              {validationResponse[responseKey].status == 200 ? (
                <FaRegCheckCircle
                  style={{ fontSize: 24, color: "green", float: "right" }}
                />
              ) : (
                <BsXCircle style={{ fontSize: 24, color: "red" }} />
              )}
            </div>
          )}
      </div>
      <hr />
      {!_.isEmpty(validationResponse) &&
        !_.isEmpty(validationResponse[responseKey]) && (
          <div>{validationResponse[responseKey].message}</div>
        )} */}
    </div>
  );
};

export default ValidationResponse;
