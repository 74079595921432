import React from 'react'
import Dropdown from '../shared/components/Dropdown';
import { convertToLocalTime } from '../shared/utils';
import { useState } from 'react';

const styles = {
    menuProps: {
        sx: {
            '.MuiPaper-root': {
                maxHeight: '40%',
                maxWidth: '30%',
                overflowX: 'auto',
            },
        },
    },
}
const MessageIdDropdown = (props) => {
    const { value, onChange, data, testData } = props;
    console.log("MessageIdDropdown-value", value);
    const [ selectedValue , setSelectedValue] = useState()
    const dpOptions = [];
    
    // const filteredData = data.filter(
    //     (item) => item.customerTestDataId === customerTestDataId
    //   );
    // data?.customerTestDataInTestCase?.map((xEl) => {
        // let a=xEl?.msgDetails;
        // console.log("xEl", xEl)
        testData?.map((item, index) => {
            const label = `PrereqTestCase:${item.prereqTestCase} MsgId:${item.msgId} CreationDateTime:${convertToLocalTime(item.createdDate)} Amount:${item.amount} `
            dpOptions.push({
                id: item.msgId,
                label: label,
                value: item.msgId,
               
            })
        })
        // console.log("dpOptions", dpOptions);
    // });
    const onDropdownChange=(e)=>{
        setSelectedValue(e);
        onChange(e)
    }
    return (
        <>
            {dpOptions?.length >= 1 &&

                <Dropdown
                    placeholder=' Select Message Id'
                    className="user-dropdown"
                    value={value}
                    onChange={onDropdownChange}
                    options={dpOptions}
                    inputProps={{ 'aria-label': 'Without label' }}
                    // defaultValue={!_.isEmpty(dpOptions) ? dpOptions[0] : []}
                    sx={{ width: '100%', height: '80%', }}
                    MenuProps={styles.menuProps}
                />
            }
        </>
    )
}

export default MessageIdDropdown;


