import React, { useState, useMemo } from 'react';
import { SnackbarContext } from './SnackbarContext.Context';

let timer;
export default function SnackbarContextProvider(props) {
  const [msg, setMsg] = useState('');
  const [isDisplayed, setIsDisplayed] = useState(false);
  const [severity, setSeverity] = useState('');

  const displayHandler = (elMsg, elSeverity) => {
    if (elSeverity) {
      setSeverity(elSeverity);
    }
    setMsg(elMsg);
    setIsDisplayed(true);
    timer = setTimeout(() => {
      closeHandler();
    }, 10000); // close snackbar after 3 seconds
  };
  const closeHandler = () => {
    clearTimeout(timer);
    setIsDisplayed(false);
  };

  const valueUseMemo = useMemo(
    () => ({
      msg,
      isDisplayed,
      severity,
      displayMsg: displayHandler,
      onClose: closeHandler,
    }),
    [msg, isDisplayed]
  );

  return <SnackbarContext.Provider value={valueUseMemo}>{props.children}</SnackbarContext.Provider>;
}
