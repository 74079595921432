import { backendApi } from "../base.config";

export const customerAccountInfoApi = backendApi.injectEndpoints({
    endpoints: (builder) => ({
        getCustomerAccountList: builder.query({
            query: () => ({
                method: "GET",
                url: `customers/getCustomerAccountInformation`,
            }),
            providesTags: [],
        }),
        updateCustomerAccountDetails: builder.mutation({
            query: (data) => ({
                method: "POST",
                url: `customers/modifiedCustomerAccountInformation`,
                body: data,
            }),
        }),
        getCustomerTypeList: builder.query({
            query: () => ({
                method: "GET",
                url: `public/getCustomerType`,
            }),
            providesTags: [],
        }),
    }),
});

export const {useGetCustomerAccountListQuery,useUpdateCustomerAccountDetailsMutation, useGetCustomerTypeListQuery
} = customerAccountInfoApi;
