import { backendApi } from '../base.config';

export const testScenariosApi = backendApi.injectEndpoints({
    endpoints: (builder) => ({
        getTestScenarios: builder.query({
            query: (xargs) => {
                const { searchBoxInput, ...args } = xargs;
                if (!searchBoxInput) {
                    return {
                        url: `customerTcscenario/getCusTcScenario`,
                        params: { searchBoxInput,
                            ...args },
                        refetchOnMountOrArgChange: true
                    }
                } else {
                    return {
                        url: `customerTcscenario/getCusTcScenario`,
                        params: { 
                            searchBoxInput,
                            ...args
                         },
                        refetchOnMountOrArgChange: true
                    }
                }
            },
        }),
        //
        //TODO: for swathi this getXmlData query needs to be moved to respective <fileName>.api.js file
        getXmlData: builder.query({
            query: (args) => ({
                url: `customerTcscenario/getXmlData`,
                params: { ...args },
                refetchOnMountOrArgChange: true,
                responseHandler: (response) => response.text(),
            }),
        }),
        updateActiveStatus: builder.mutation({
            query:(payload) =>({
                url:`customerTcscenario/updateTestCaseLibraryStatus`,
                method: "POST",
                body:payload
            })
        }),
        getXmlDataLatest: builder.mutation({
            query: (payload) => ({
                url:`customerTcscenario/getXmlDataLatest`,
                method:"POST",
                body:payload
            })
        }),
    }),
});

export const {
    useGetTestScenariosQuery,
    useGetXmlDataQuery,
    useUpdateActiveStatusMutation,
    useGetXmlDataLatestMutation,
} = testScenariosApi;
