import { Box, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ProgressCircleContainer from "../DashboardPage/ProgressCircleContainer";
import MessageFlows from "../DashboardPage/Tabs/MessageFlows";
import NfrTestcases from "../DashboardPage/Tabs/NfrTestcases";
import StandaloneMessage from "../DashboardPage/Tabs/StandaloneMessage";
import ChartContainer from "./chartContainer";
import ChartHeader from "./chartHeader";
import './dashBoard.css'
import SetupStatus from "./setupStatus";
import TestDataReview from "./TestDataReview";
import TestCaseHeader from "../testCaseHeader";
import moment from "moment";
import LastExecutedDate from "./LastExecutedDate";
import TestDataResponseMsg from "./TestDataResponseMsg";
import { useGetBatchNameDropDownMutation } from "../../redux/dashboard/dashboard.api";
import { DropdownValueContext } from "../../shared/context/DropdownProvider";
const DashBoardContainer = () => {
  const [dateForDashboard, setDateForDashboard] = useState(moment());
  const { customer } = useContext(DropdownValueContext);
  // const [customer, selectedCustomer] = useState();
  const [messageType, selectedMessgaeType] = useState('Message Type');
  const [messageName, setSelectedMessageName] = useState();
  const currentDate = moment().format('MM-DD-YYYY');
  const onCustomerSelection = (event) => {
    selectedCustomer(event);
  }

  const onDateSelection = (event) => {
    // console.log(moment(event))
    // setDateForDashboard(event)
  }
  const onMessageSelection = (event, messageName) => {
    selectedMessgaeType(event);
    setSelectedMessageName(messageName)
  }
  const onChangeDateFrom = (event) => {
    setDateForDashboard(event)
  }
  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };
  
  return (
    <Box sx={{ overflowY: 'auto', height: `calc(100vh - 100px)`, paddingBottom: "20px" }}>
      <TestCaseHeader
        title='Dashboard'
        showDatepicker={{ show: false, onChange: onDateSelection, value: dateForDashboard }}
        displayInLine={true}
        showCustomer={{ show: true, onChange: onCustomerSelection, value: customer, }}
        showMessageType={{ show: true, onChange: onMessageSelection, value: messageType, isClear: false }}
        showDateFromToPicker={{ show: true, onChange: onChangeDateFrom, value: dateForDashboard, currentDate: currentDate, disabledDate: disabledDate }}
      />
      <Box sx={{ overflowY: 'auto', maxHeight: '76vh', marginTop: 2, marginLeft: '2%', marginRight: '2%', width: '96%' }} >
        {/* <Box> */}
        <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
          <Box sx={{ width: '100%' }}>
            <Box
              className="progress-circle-component"
            >
              <StandaloneMessage dateForDashboard={dateForDashboard} customer={customer} messageType={messageName} />
            </Box>
            <Box className="progress-circle-component-mf">
              <NfrTestcases dateForDashboard={dateForDashboard} customer={customer} messageType={messageName} />
            </Box>
            <Box className="chart-background">
              <ChartHeader customer={customer} messageName={messageName} />
            </Box>
          </Box>

          {/* <Box className="progress-circle-component-nfr">

            <Box className="setup-status-component">
              <SetupStatus dateForDashboard={dateForDashboard} customer={customer} />
            </Box>
            <Box sx={{ marginTop: '20px' }} className="setup-status-component">
              <LastExecutedDate dateForDashboard={dateForDashboard} customer={customer} />
            </Box>
            <Box className="card-background">
              <TestDataReview customer={customer} />
            </Box>
            <Box className="card-background">
              <TestDataResponseMsg customer={customer} />
            </Box>

          </Box> */}
        </Box>

        {/* </Box> */}

        <Box>
        </Box>

      </Box>
    </Box>
  )
}
export default DashBoardContainer



// return (
//   <Box sx={{overflowY: 'auto',height: `calc(100vh - 100px)`, paddingBottom: "20px" }}>
//     <TestCaseHeader
//   title='Dashboard'
//   showDatepicker={{ show: false, onChange: onDateSelection, value: dateForDashboard }}
//   displayInLine={true}
//   showCustomer={{ show: true, onChange: onCustomerSelection, value: customer, }}
//   showMessageType={{ show: true, onChange: onMessageSelection, value: messageType, isClear: false }}
//   showDateFromToPicker={{ show: true, onChange: onChangeDateFrom, value: dateForDashboard, currentDate: currentDate }}
// />
// <Box sx={{ overflowY: 'auto', maxHeight: '76vh', marginTop: 2, marginLeft: '2%', marginRight: '2%', width: '96%' }} display={"flex"} >
//   <Box sx={{ width: '100%'}}>
//     <Box display={"flex"} sx={{ justifyContent: 'space-between' }}>
//       <Box
//         className="progress-circle-component"
//       >
//         <StandaloneMessage dateForDashboard={dateForDashboard} customer={customer} messageType={messageName} />
//       </Box>
{/* <Box className="progress-circle-component-mf">
                  <MessageFlows dateForDashboard={dateForDashboard} customer = {customer} messageType={messageName}/>
               </Box> */}
{/* <Box className="progress-circle-component-mf">
              <NfrTestcases dateForDashboard={dateForDashboard} customer={customer} messageType={messageName} /> */}
{/* <Box className="setup-status-component">
                     <SetupStatus dateForDashboard={dateForDashboard} customer = {customer}/>
                  </Box> */}
{/* <Box className="setup-status-component">
                     <LastExecutedDate dateForDashboard={dateForDashboard} customer={customer}/>
                  </Box> */}
{/* <Box className="card-background">
                 <TestDataReview customer = {customer}/>
               </Box> */}
{/* </Box>
            <Box className="progress-circle-component-nfr"> */}

{/* <Box className="setup-status-component"> */ }
{/* <SetupStatus dateForDashboard={dateForDashboard} customer={customer} /> */ }
{/* </Box> */ }
{/* <Box className="setup-status-component"> */ }
{/* <LastExecutedDate dateForDashboard={dateForDashboard} customer={customer} />
              </Box>
              <Box className="card-background">
                <TestDataReview customer={customer} />
              </Box>
              <Box className="card-background">
                <TestDataResponseMsg customer={customer} />
              </Box>

            </Box>
          </Box>
          <Box className="chart-background">
            <ChartHeader customer={customer} messageName={messageName} />
          </Box>
        </Box>

        <Box>
        </Box>

      </Box>
    </Box>
  )
}
export default DashBoardContainer */}