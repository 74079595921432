import React from 'react';

const PageHeader = (props) => {
    const { pageTitle } = props;
    return (
        <span className='page-header'>{pageTitle}</span>
    )
}

export default PageHeader;
