import React from 'react';
import { useState } from 'react';

const getStyles = (maxWidth, showEllipsis) => {
    if(showEllipsis){
        return {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        }
    }
    return {
        whiteSpace: 'normal'
    }
}
const ToggleEllipsis = (props) => {
    const { text, maxWidth } = props;

    const [showEllipsis, setShowEllipsis ] = useState(true);

    const toggleEllipsis = (e) => {
        setShowEllipsis(!showEllipsis);
        e.stopPropagation();
    }

    return (
        <p onClick={toggleEllipsis} style = {getStyles(maxWidth, showEllipsis)}>{text}</p>
    )
}

export default ToggleEllipsis;