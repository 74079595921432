import React, { useContext, useEffect, useState } from 'react'
import TestCaseHeader from '../testCaseHeader';
import { Box, Grid, Typography } from '@mui/material';
import OrganizationDropdown from './OrganizationDropdown';
import PayaptTextField from '../../shared/components/TextField';
import ConfigPartiesDropdown from './ConfigPartiesDropdown';
import { useGetConfigurePaymentGatewayDropdownQuery, useGetConfigureSettlementSimulatorDropdownQuery, useGetCustomerDeatilsInOnboardingMutation, useUpdateCustomerOrganizationDeatilsMutation } from '../../redux/settings/settings.api';
import { useSelector } from 'react-redux';
import { LeftCircleFilled } from '@ant-design/icons';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MUIButton from '../../shared/components/MUIButton';
import { useNavigate } from 'react-router';
import { ALERT_SEVERITY, APP_ROUTE_KEYS } from '../../shared/constants/constants';
import ConfigurePayGateway from './ConfigurePayGateway';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
import Dropdown from '../../shared/components/Dropdown';
import { useGetCustomerTypeListQuery } from '../../redux/customers/customer.api';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { organizationValidationSchema } from './utils';

const OrganizationDetails = () => {

    const Snackbar = useContext(SnackbarContext);
    const navigate = useNavigate();
    const resolver = yupResolver(organizationValidationSchema);

    const [organizationType, setOrganizationType] = useState();
    const [configSettlement, setConfigSettlement] = useState();
    const [configPayment, setConfigPayment] = useState();
    const [customerTypeId, setCustomerTypeId] = useState("");
    // const [organizationName,setOrganizationName] = useState("");
    const [contactPerson, setContactPerson] = useState("");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [getCustomerDeatilsInOnboarding, { data }] = useGetCustomerDeatilsInOnboardingMutation();
    const [updateCustomerDeatilsInOnboarding, { data: updateList }] = useUpdateCustomerOrganizationDeatilsMutation();
    const customerId = useSelector((state) => state?.common?.customerId);
    const { data: customerTypeList } = useGetCustomerTypeListQuery();
    const { data: configPaymentList } = useGetConfigurePaymentGatewayDropdownQuery();
    const { data: configSettlementList } = useGetConfigureSettlementSimulatorDropdownQuery();

    const { userRole } = useSelector((state) => state?.common?.userDetails);

    const {
        control,
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        resolver,
        defaultValues: {}
    });

    var savePayload = {
        customerId: customerId,
        customerTypeId: organizationType,
        configurePaymentGateway: configPayment,
        configureSettlementSimulator: configSettlement,
    }
    var payload = customerId
    useEffect(() => {
        getCustomerDeatilsInOnboarding(payload)

    }, [])
    useEffect(() => {
        if (data) {
            setOrganizationType(data[0]?.customerTypeId === null ? '' : data[0]?.customerTypeId)
            setConfigSettlement(data[0]?.configureSettlementSimulator === null ? '' : data[0]?.configureSettlementSimulator)
            setConfigPayment(data[0]?.configurePaymentGateway === null ? '' : data[0]?.configurePaymentGateway)

            setValue("organizationType", data[0]?.customerTypeId === null ? '' : data[0]?.customerTypeId)
            setValue("configSettlement", data[0]?.configureSettlementSimulator === null ? '' : data[0]?.configureSettlementSimulator)
            setValue("configPayment", data[0]?.configurePaymentGateway === null ? '' : data[0]?.configurePaymentGateway)

            // setOrgValue(data[0]?.customerTypeId===null?'':data[0]?.customerTypeId)

        }
    }, [data])

    const [errorOrganizationType, setErrorOrganizationType] = useState('');
    const [errorConfigSettlement, setErrorConfigSettlement] = useState('');
    const [errorConfigPayment, setErrorConfigPayment] = useState('');

    // const handleOrganizationChange = (data) => {
    //     setOrganizationType(data)
    // }

    const handleConfigSettlement = (e) => {
        const value = e;
        setConfigSettlement(value)
        if (e != '') {
            setErrorConfigSettlement('')

        }
    }
    const handleConfigPayment = (event) => {
        // setConfigPayment(xEl)
        const value = event;

        setConfigPayment(value);
        if (event != '') {
            setErrorConfigPayment('')
        }

    }
    const handleOrganisationChange = (event) => {
        if (event != '') {
            setErrorOrganizationType('')
        }
        setOrganizationType(event)

    }

    const onBack = () => {
        // dispatch(updateCurrentStep(currentStep - 1));
        userRole?.name == 'Super Admin' ?
            navigate(`${APP_ROUTE_KEYS.CUSTOMERS.MASTER_SETTINGS}`)
            :
            navigate(`${APP_ROUTE_KEYS.SETTINGS.MASTER}`)
    }
    const onSave = () => {
        if (configPayment.trim() !== '' && configSettlement.trim() !== '' && organizationType !== '') {
            updateCustomerDeatilsInOnboarding(savePayload).then(res => {
                if (res?.data?.message === 'data updated successfully') {
                    Snackbar.displayMsg('Data Updated Successfully', ALERT_SEVERITY.SUCCESS)
                } else {
                    Snackbar.displayMsg('Data Failed to Update', ALERT_SEVERITY.ERROR)
                }
            })


        } else {
            if (organizationType === '') {
                setErrorOrganizationType("Organization Type is required")
            }
            if (configSettlement.trim() === '') {
                setErrorConfigSettlement("Simulator Type is required")
            }
            if (configPayment.trim() === '') {
                setErrorConfigPayment("Payment Gateway Type is required")
            }
        }


    }


    // const handleOrganization = async () => {
    //     const updateOrganizeResults = await updateCustomerDeatilsInOnboarding(savePayload);
    //     onSave(updateOrganizeResults)
    // }
    return (
        <Box sx={{ overflowY: 'auto', height: `calc(100vh - 100px)`, paddingBottom: "30px" }}>
            <TestCaseHeader
                title="Organization Configuration"
            />
            <Box sx={{ textAlign: "left" }} px={3}>
                <Box display={"flex"} mt={2}>
                    <Grid container>
                        <Grid item mt={1} width={'40%'}>
                            <Typography sx={{ fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 100, fontSize: "16px", }}>
                                Select Organization Type
                            </Typography>
                        </Grid>
                        <Grid item width={200} mt={2.5} >
                            {/* <Controller
                                control={control}
                                name={'organizationType'}
                                render={({ field }) => (
                                    <Dropdown
                                        {...field}
                                        inputRef={register('organizationType', { required: true })}
                                        placeholder='Select Organization Type'
                                        margin="dense"
                                        className="user-dropdown"
                                        style={{ width: '180%', height: '42px', marginTop: '-20px' }}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        options={customerTypeList}
                                        valueKey='customerTypeId'
                                        labelKey='customerTypeName'
                                        onChange={handleOrganisationChange}
                                        value={organizationType}
                                        error={!!errors['organizationType']}
                                        helperText={errors['organizationType']?.message}
                                    />
                                )}
                            /> */}
                            <Dropdown
                                placeholder='Select Organization Type'
                                margin="dense"
                                className="user-dropdown"
                                style={{ width: '180%', height: '42px', marginTop: '-20px' }}
                                inputProps={{ 'aria-label': 'Without label' }}
                                options={customerTypeList}
                                valueKey='customerTypeId'
                                labelKey='customerTypeName'
                                onChange={handleOrganisationChange}
                                value={organizationType}

                            />
                            {errorOrganizationType && (
                                <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem' }}>{errorOrganizationType}</div>
                            )}
                            {/* <Dropdown
                                placeholder='Select Organization Type'
                                margin="dense"
                                className="user-dropdown"
                                style={{ width: '180%', height: '42px', marginTop: '-20px' }}
                                inputProps={{ 'aria-label': 'Without label' }}
                                options={customerTypeList}
                                valueKey='customerTypeId'
                                labelKey='customerTypeName'
                                onChange={handleOrganisationChange}
                                value={organizationType}
                            // MenuProps={styles.menuProps}s
                            // isClear={isClear}
                            // disabled = {!!selectedRecord?.userId}
                            /> */}
                            {/* <Controller
                                control={control}
                                name={'organizationType'}
                                render={({ field }) => (
                                    <OrganizationDropdown
                                        {...field}
                                        isClear={false}
                                        value={organizationType}
                                        onChangeOrganization={handleOrganisationChange} />
                                )}
                            /> */}

                        </Grid>
                    </Grid>
                </Box>

                <Box display={"flex"} mt={2}>
                    <Grid container>
                        <Grid item mt={1} width={'40%'}>
                            <Typography sx={{ fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 100, fontSize: "16px", }}>
                                {/* Configure Connecting Parties */}
                                Configure Settlement Simulator
                            </Typography>
                        </Grid>
                        <Grid item width={200} mt={2.5} >
                            {/* <Controller
                                control={control}
                                name={'configSettlement'}
                                render={({ field }) => (
                                    <Dropdown
                                        {...field}
                                        inputRef={register('configSettlement', { required: false })}
                                        placeholder='Select Settlement Simulator Type'
                                        margin="dense"
                                        className="user-dropdown"
                                        style={{ width: '180%', height: '42px', marginTop: '-20px' }}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        options={configSettlementList}
                                        valueKey='value'
                                        labelKey='value'
                                        onChange={handleConfigSettlement}
                                        value={configSettlement}
                                        error={!!errors['configSettlement']}
                                        helperText={errors['configSettlement']?.message}
                                    />
                                )}
                            /> */}
                            <Dropdown
                                placeholder='Select Settlement Simulator Type'
                                margin="dense"
                                className="user-dropdown"
                                style={{ width: '180%', height: '42px', marginTop: '-20px' }}
                                inputProps={{ 'aria-label': 'Without label' }}
                                options={configSettlementList}
                                valueKey='value'
                                labelKey='value'
                                onChange={handleConfigSettlement}
                                value={configSettlement}

                            />
                            {errorConfigSettlement && (
                                <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem' }}>{errorConfigSettlement}</div>
                            )}
                            {/* <ConfigPartiesDropdown value={configSettlement} onChangeParties={handleConfigChange} /> */}
                        </Grid>
                    </Grid>
                </Box>

                <Box display={"flex"} mt={2}>
                    <Grid container>
                        <Grid item mt={1} width={'40%'}>
                            <Typography sx={{ fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 100, fontSize: "16px", }}>
                                Configure Payment Gateway
                            </Typography>
                        </Grid>
                        <Grid item width={200} mt={2.5} >
                            {/* <Controller
                                control={control}
                                name={'configPayment'}
                                render={({ field }) => (
                                    <Dropdown
                                        {...field}
                                        inputRef={register('configPayment', { required: false })}
                                        placeholder='Select Payment Gateway Type'
                                        margin="dense"
                                        className="user-dropdown"
                                        style={{ width: '180%', height: '42px', marginTop: '-20px' }}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        options={configPaymentList}
                                        valueKey='value'
                                        labelKey='value'
                                        onChange={handleConfigPayment}
                                        value={configPayment}
                                        error={!!errors['configPayment']}
                                        helperText={errors['configPayment']?.message}
                                    />
                                )}
                            /> */}
                            <Dropdown

                                placeholder='Select Payment Gateway Type'
                                margin="dense"
                                className="user-dropdown"
                                style={{ width: '180%', height: '42px', marginTop: '-20px' }}
                                inputProps={{ 'aria-label': 'Without label' }}
                                options={configPaymentList}
                                valueKey='value'
                                labelKey='value'
                                onChange={handleConfigPayment}
                                value={configPayment}

                            />
                            {/* {errors.configPayment && (
                                <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem' }}>Payment Gateway Type is required</div>
                            )} */}

                            {errorConfigPayment && (
                                <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem' }}>{errorConfigPayment}</div>
                            )}
                            {/* <ConfigurePayGateway value={configPayment} onChangePaymentGateway={handleConfigPayment} /> */}
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{ textAlign: 'left', marginTop: '2%' }}>
                    {(userRole?.name === 'Admin' || userRole?.name === 'Tester') ?
                        <MUIButton
                            startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
                            sx={{ backgroundColor: '#F0F2F5', color: '#808080' }}
                            color="inherit"
                            title="Back"
                            onClick={onBack}
                        />
                        :
                        <>
                            <MUIButton
                                startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
                                sx={{ backgroundColor: '#F0F2F5', color: '#808080' }}
                                color="inherit"
                                title="Back"
                                onClick={onBack}
                            />
                            <MUIButton
                                startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
                                title="Save"
                                sx={{ mx: 2 }}
                                onClick={onSave}
                            />

                        </>
                    }

                </Box>
            </Box>
        </Box>
    )
}

export default OrganizationDetails;
