import { Alert, AlertTitle } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types'

const PayaptAlert = (props) => {
    const { type, message, title } = props;
    return (
        <Alert  severity={type}>
            {title && <AlertTitle>{title}</AlertTitle>}
            {message}
        </Alert>
    )
}
PayaptAlert.defaultProps = {
    type: 'error',
    message: 'Something went wrong',
    title: null,
}
PayaptAlert.propTypes = {
    type: PropTypes.string,
    message: PropTypes.string,
    title: PropTypes.string,
}
export default PayaptAlert;
