import { LeftCircleFilled } from '@ant-design/icons';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from '@mui/icons-material/Info';
import { Box, Grid, Typography } from '@mui/material';
import { Input, Modal } from "antd";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TextAreaWithLineNumber from 'text-area-with-line-number';
import { useGetXmlDataQuery } from "../../redux/testScenarios/testScenarios.api";
import MUIButton from '../../shared/components/MUIButton';
import TestCaseHeader from '../testCaseHeader';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DragDropFile from '../../shared/components/DragDropFile';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    textArea: {
      marginBottom:'25px',
      marginLeft:'25px',
      marginRight:'25px',
  },
  muiBotton: {
    textAlign:'left',
    marginBottom:'35px',
    marginLeft:'25px',
    marginRight:'25px',
  },
  draggerContainer: {
    minHeight: '35vh',
    maxHeight: '50vh',
    minWidth: "60%",
    borderRadius: '20px'
  },

  muibackBotton:{
    backgroundColor:'#F0F2F5',
    color:'#808080',
    marginLeft:"25px",
    marginRight:"25px"
},
muisaveBotton:{
  margin:"0px 10px"
},
  
};


const CreateIncomingContainer = () => {
  const navigate = useNavigate();
    const { TextArea } = Input;
    const masterXMLPath = 'pacs_008_OB.xml';
    
    const { data, isFetching, isSuccess, isError, error } = useGetXmlDataQuery({ fileName: masterXMLPath },{
      skip: masterXMLPath === "" // this will skip the fetchCall when qpTestType value is empty
  }); 
  const [errorMsg, setErrorMsg ] = useState([]);
  const [text, setText] = useState(data);
  const [errorInfo, setErrorInfo] = useState();
  const [errorLine, setErrorLine] = useState();
  const [errorColumn, setErrorColumn] = useState();
  const [uploadFile, setUploadFile] = useState();
  const [isDragdropModalVisible, setIsDragdropModalVisible] = React.useState(false);

  const openFileUpload = async () => {
    setIsDragdropModalVisible(true)
  }

  const handleOnChangeDragdrop = (xFilesInfo) => {
    const { fileList, file } = xFilesInfo;
    setUploadFile(fileList);
  }

  const onCancel = () => {
    setIsDragdropModalVisible(false)
  }

  const onUploadFile = (e) => {
    if(uploadFile?.length > 0){
      let file = uploadFile[0];
      const blob = new Blob([file])
      const reader = new FileReader()
      reader.onload = async (e) => { 
        const text = (e.target.result)
        onTextChange(text);
      };
      reader.readAsDataURL(blob);
      // reader.readAsText(blob)
    }
  }

  const validateText = (text, setMsg=true) => {
    var domParser = new DOMParser();
    var dom = domParser.parseFromString(text, 'text/xml');
    if(dom.getElementsByTagName("parsererror").length<=0 && !_.isEmpty(errorMsg)){
        setErrorMsg([])
    }
    if(setMsg){
        dom.getElementsByTagName("parsererror").length>0   ? setErrorMsg(_.map(dom.getElementsByTagName("parsererror"), e => e.innerText.split('\n')[0])) : setErrorMsg(null)
    }
    return !dom.getElementsByTagName("parsererror").length>0;
  }

  const onTextChange = (text) => {
    setText(text);
    const res= validateText(text);
  }

    const onBack = () =>{
      navigate(-1)
    }
  
    const onSave = () =>{
      if(!errorMsg?.length){
        navigate('/message/xmlList')
      }
    }

    useEffect(()=>{
      if(errorMsg?.length){
        var str = errorMsg[0];
        str=str.toString();
        let matches = str.match(/\d+/g);
        const errorText = str.split(":");
        setErrorInfo(errorText[errorText.length-1]);
        var i=0
        setErrorLine(matches[0]);
        setErrorColumn(matches[1]);
      }else {
        setErrorLine();
        setErrorColumn();
      }
    },[errorMsg])

  return (
    <Box sx={styles.container}>
      <Grid container>
        <Grid item xs={6} md={8}>
          <TestCaseHeader
            title={'Create Incoming PACS.009.STP'}
            subtitle={'Some details related to XML Message List goes here'}
            showFileUpload={{
              show: true,
              onClick: openFileUpload
            }}
          >
          </TestCaseHeader>
          <Box  sx={styles.textArea}>
            <div className='editor' onBlur={() => validateText(text)}>
            <TextAreaWithLineNumber contentEditable = {true}  
                onChange={(e) => onTextChange(e.target.value)} 
                border = '0px'
                height = '60vh'
                // scrollbarWidth = '7px'
                value = {text ? text : ""}
                >
                  
            </TextAreaWithLineNumber>
            </div>
          </Box>
          <Box sx={styles.muiBotton}>
            <MUIButton 
              startIcon={<LeftCircleFilled style={{ fontSize: 18}}/>}
              title="Back"
              sx={styles.muibackBotton}
              color="inherit"
              onClick={onBack}
            >
            </MUIButton>
            <MUIButton
            startIcon={<CheckCircleIcon style={{ fontSize: 18,}}/>}
            title="Save"
            sx={styles.muisaveBotton}
              onClick={onSave}
            />
          </Box>
        </Grid>
        <Grid item xs={6} md={4} className="Validation-detail-container">
          <Box className="Validation-detail-header">
            <TestCaseHeader
            title={'Validation Report'}
            subtitle={'Details about validation report'}
            >
            </TestCaseHeader>
          </Box>
          <Box mt={2}>
            <h4 className="schemaValidationHeader">{<CheckCircleIcon  sx={{mb:0.7,color:'green'}}/>} Schema Validation</h4>
            <Box className="schemaValidationSucces">
            <Typography><span style={{fontWeight:700,fontSize:'18px'}}>Info:</span> <span style={{fontWeight:600,fontSize:'16px'}}>Schema validation passed without error</span></Typography>
            <Typography><span style={{fontWeight:700,fontSize:'18px'}}>Line:</span><span style={{fontWeight:600,fontSize:'16px'}}>1</span></Typography>
            </Box>
          </Box>

          <Box mt={2}>
            <h4 className="schemaValidationHeader">{<InfoIcon  sx={{mb:0.7,color:'rgb(221, 59, 59)',transform: 'rotate(180deg)'}}/>} Usage Rule Validation</h4>
            <Box className="schemaValidationError">
            <Typography><span style={{fontWeight:700,fontSize:'18px'}}>Error:</span> <span style={{fontWeight:600,fontSize:'16px'}}>{errorInfo}</span></Typography>
            <Typography><span style={{fontWeight:700,fontSize:'18px'}}>Line:</span> <span style={{fontWeight:600,fontSize:'16px'}}>{errorLine}</span></Typography>
            <Typography><span style={{fontWeight:700,fontSize:'18px'}}>XPATH:</span> <span style={{fontWeight:600,fontSize:'16px'}}>{'a/b/c'}</span></Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Modal
                onCancel={() => setIsDragdropModalVisible(false)}
                open={isDragdropModalVisible}
                footer={[]}
                centered
                closeIcon={<CancelOutlinedIcon sx={{ color: "#001A11", backgroundColor: "white" }} />}
                style={styles.draggerContainer}
            >
                <DragDropFile
                    title={"Upload XML File to Create Message"}
                    subTitle={"About uploading XML file instruction goes here"}
                    sampleFileUrl={""}
                    onChange={handleOnChangeDragdrop}
                    onClick={onCancel}
                    onUploadFile={onUploadFile}
                />
          </Modal>
    </Box>
  )
}

export default CreateIncomingContainer;
