import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react'
import TestCaseHeader from '../testCaseHeader';
import PayaptTable from '../../shared/components/Table';
import { respondentBank_columns } from '../../constants/constants';
import { EyeIcon } from '../../constants/menuIcons';
import { useNavigate } from 'react-router-dom';
import MUIButton from '../../shared/components/MUIButton';
import { useDispatch, useSelector } from 'react-redux';
import { LeftCircleFilled } from '@ant-design/icons';
import { APP_ROUTE_KEYS } from '../../shared/constants/constants';
import { useGetRespondentBankDetailsMutation } from '../../redux/customers/customer.api';
import _ from 'lodash';
import { updateTestScenarioName } from '../../redux/common/common.slice';

const RespondentBank = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { userRole } = useSelector((state) => state?.common?.userDetails);
    const pageSize = useSelector((state) => state?.common?.pageSize);
    const { customerId } = useSelector((state) => state?.common);
    const [searchTerm, setSearchTerm] = useState("");

    const columns = respondentBank_columns;

    const initialPageOptions = {
        "correspondentBankId": customerId,
        "page": 0,
        "size": pageSize,
        "search": null
    }

    const [pageOptions, setPageOptions] = useState({ ...initialPageOptions });

    const [getRespondentBankList, { data, isLoading }] = useGetRespondentBankDetailsMutation();

    const handleViewRTN = () => {
        userRole?.name == 'Super Admin' ? navigate(`${APP_ROUTE_KEYS.CUSTOMERS.RESPONDENT_RTN}`)
            : navigate(`${APP_ROUTE_KEYS.SETTINGS.RESPONDENT_RTN_SETTINGS}`)
    }

    columns[1].render = (data, rec) => (
        <>
            {rec?.address1}
            <br />
            {rec?.address2}
            <br />
            {rec?.city},{rec?.country}
        </>
    )

    columns[3].render = (data, rec) => (       
        <span onClick={(e) => {
            e.stopPropagation();
            handleViewRTN();
            dispatch(updateTestScenarioName(rec?.customerId));
        }}>{<EyeIcon />}</span>

    )
    const onPageChange = (yPageOptions) => {
        // console.log("options",options);
        const xPayload = {
            // "customerId": customerId,
            // "search": null,
            // "page": options.page,
            // "size": pageSize,
            // "fromDate": null,
            // "toDate": null,
            // "debitOrCredit": null
            ...pageOptions,
            ...yPageOptions
        }
        // console.log("cPage", xPayload);
        setPageOptions(xPayload);
        getRespondentBankList(xPayload)
    }
    useEffect(() => {
        setSearchTerm("");
        setPageOptions(initialPageOptions);
        const xPageOptions = {
            ...initialPageOptions
        }
        setPageOptions(xPageOptions)
    }, [])
    React.useEffect(() => {
        setPageOptions((prevSt) => ({ ...prevSt, search: searchTerm }));
        if (searchTerm === "") {
            setPageOptions(initialPageOptions)
        }
        getRespondentBankList(pageOptions);
    }, [searchTerm]);
    // useEffect(() => {
    //     getRespondentBankList(initialPageOptions);
    // }, [])

    const handleSearchOnChange = (xValue) => {
        setSearchTerm(xValue);
        setPageOptions({ ...pageOptions, search: xValue });
        // refetchTestRunsQuery({ ...pageOptions, search: xValue });
    };

    return (
        <Box sx={{ overflowY: 'auto', height: `calc(100vh - 100px)`, paddingBottom: "30px" }}>
            <TestCaseHeader
                title="Respondent Bank Management"

                showSearch={{
                    show: true,
                    value: searchTerm,
                    onChange: handleSearchOnChange
                }}
            />

            <Box my={2} style={{ textTransform: 'none' }} width={"100%"} px={"0%"}>
                <PayaptTable
                    size={"small"}
                    rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
                    className={"standalone_table"}
                    columns={columns}
                    data={!_.isEmpty(data) ? data?.list : []}
                    loading={isLoading}
                    onChange={(pagination) =>
                        onPageChange(
                            {
                                page: pagination.current - 1,
                                search: "",
                                pageSize
                            })
                    }
                    pagination={{
                        pageSize,
                        current: !_.isEmpty(data) ? data.page + 1 : 1,
                        total: !_.isEmpty(data) ? data.count : 0,
                        size: 'small',
                        hideOnSinglePage: true,
                        showPrevNextJumpers: true,
                        showTitle: true,
                    }}
                />
            </Box>
            <Box display={"flex"}>
                <MUIButton
                    startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
                    sx={{ backgroundColor: '#F0F2F5', color: '#808080', mx: 3 }}
                    color="inherit"
                    title="Back"
                    onClick={() => userRole?.name == 'Super Admin' ? navigate(`${APP_ROUTE_KEYS.CUSTOMERS.MASTER_SETTINGS}`) : navigate(`${APP_ROUTE_KEYS.SETTINGS.MASTER}`)}
                />
            </Box>
        </Box>
    )
}

export default RespondentBank;
