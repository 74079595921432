import { Button } from '@mui/material';
import React from 'react';
import Welcome from '../../assets/images/welcome.png'
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/emailStyle.module.css';
import MUIButton from '../../shared/components/MUIButton';
import { APP_ROUTE_KEYS } from '../../shared/constants/constants';
import { useDispatch } from 'react-redux';
import { updateProfileCompletion } from '../../redux/common/common.slice';
const AccountActivation = (props) => {
    const { isUserAuthenticated, onlogout } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
const onComplete=()=>{
    console.log('clicked')
    dispatch(updateProfileCompletion({
        isUserProfileCompleted: true
    }))
    // navigate('/updateprofile')
    if(Number(window.REACT_APP_ONBOARDING_REQUIRED) == 1) {
        navigate(`/customer/onboarding`);
    } else {
        navigate(`${APP_ROUTE_KEYS.TEST_CASES.MASTER}/SA`);
    }
}
    return(
        <div>
                <h1 className={styles.accountActivationHeader} style={{fontSize:'30px',}}>Account Activated</h1>
                <p className={styles.accountActivationBody}>Your account got activated, now start your onboarding process</p><br/>
                <img className={styles.AccountActivationImg}  src={Welcome} /><br/><br/><br/>
                
             <MUIButton
                 title="Get Started"
                 endIcon={<ArrowCircleRightRoundedIcon style={{fontSize: 18}}/>}
                 onClick={onComplete}
                />
            </div>
            )
    }

export default AccountActivation;