import { backendApi } from "../base.config";

export const batchSummaryApi = backendApi.injectEndpoints({
    endpoints: (builder) => ({
        getBatchExecutionSummary: builder.mutation({
            query: (payload) => ({
                method: 'POST',
                url:`customerTestcaseBatch/getBatchExecutionSummary`,
                body:payload
            }),
        }),
        getBatchSummaryBasedOnBatchRun: builder.mutation({
            query: (args) => ({
                method: 'POST',
                url:`customerTestcaseBatch/getBatchSummaryBasedOnBatchRun`,
                params: { ...args }
            }),
        }),


    }),
});

export const {   
 useGetBatchExecutionSummaryMutation,
 useGetBatchSummaryBasedOnBatchRunMutation

} = batchSummaryApi;