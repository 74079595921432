import { isRejectedWithValue } from '@reduxjs/toolkit';
import { updateUserAuth } from './auth/auth.slice';
import { onLogout } from './common/common.slice';

export class AuthorizationError extends Error {
  constructor(message) {
    super(message);
    this.name = 'AuthorizationError';
  }
}

export const authenticationMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (action.payload.status === 401) {
      // dispatch(updateUserAuth({ token: null }));
      dispatch(updateUserAuth(null));
      dispatch(onLogout());
    }
  }
  return next(action);
};
