import * as React from 'react';
import { Box, Checkbox, Typography } from '@mui/material';
import Switch from '@mui/material/Switch';

export function MUISwitch(props) {
  const {
    type = 'bordered',
    containerStyles = {},
    label,
    labelPosition = 'Right',
    checkbox = false,
    ...restProps
  } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: labelPosition === 'Left' ? 'row-reverse' : 'row',
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      {checkbox ? (
        <Checkbox sx={containerStyles} disableRipple {...restProps} />
      ) : (
        <Switch sx={containerStyles} disableRipple {...restProps} />
      )}

      {label && (
        <Typography sx={{ opacity: restProps.value || restProps.checked ? 1 : 0.5 }} ml={1}>
          {label}
        </Typography>
      )}
    </Box>
  );
}
