// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-title{
font-family: 'Inter';
font-style: normal;
font-weight: 600 !important;
font-size: 16px;
line-height: 23px;
color: #001A11;
}
.dialog-subtitle{
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
text-transform: capitalize;
color: #001A11;
}
.dialog-textbox{
    box-sizing: border-box;
width: 360px;
height: 40px;

background: #FFFFFF;
border: 1px solid #ACACAC;
border-radius: 5px;
}
.dialog-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 13px 10px 10px;
    gap: 8px;
    width: 106px;
    height: 40px;
    align-self: flex-start;
    background: #009966;
    border-radius: 5px;   
    margin: 40px;
}`, "",{"version":3,"sources":["webpack://./src/containers/TestCases/testCase.css"],"names":[],"mappings":"AAAA;AACA,oBAAoB;AACpB,kBAAkB;AAClB,2BAA2B;AAC3B,eAAe;AACf,iBAAiB;AACjB,cAAc;AACd;AACA;AACA,oBAAoB;AACpB,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB,0BAA0B;AAC1B,cAAc;AACd;AACA;IACI,sBAAsB;AAC1B,YAAY;AACZ,YAAY;;AAEZ,mBAAmB;AACnB,yBAAyB;AACzB,kBAAkB;AAClB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,uBAAuB;IACvB,QAAQ;IACR,YAAY;IACZ,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;AAChB","sourcesContent":[".dialog-title{\nfont-family: 'Inter';\nfont-style: normal;\nfont-weight: 600 !important;\nfont-size: 16px;\nline-height: 23px;\ncolor: #001A11;\n}\n.dialog-subtitle{\nfont-family: 'Inter';\nfont-style: normal;\nfont-weight: 400;\nfont-size: 16px;\nline-height: 19px;\ntext-transform: capitalize;\ncolor: #001A11;\n}\n.dialog-textbox{\n    box-sizing: border-box;\nwidth: 360px;\nheight: 40px;\n\nbackground: #FFFFFF;\nborder: 1px solid #ACACAC;\nborder-radius: 5px;\n}\n.dialog-button{\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    padding: 13px 10px 10px;\n    gap: 8px;\n    width: 106px;\n    height: 40px;\n    align-self: flex-start;\n    background: #009966;\n    border-radius: 5px;   \n    margin: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
