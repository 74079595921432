import { Box ,Grid,Snackbar,Typography} from "@mui/material";
import TestCaseHeader from '../testCaseHeader';
import DateFromToPicker from "../Dashboard/DateRangePicker";
import { useContext, useState } from "react";
import ClearDataMessageTypeDropdown from "./ClearDataMessageTypeDropdown";
import UserDropdown from "./UserDropdown";
import { useClearDataMutation } from "../../redux/users/users.api";
import MUIButton from "../../shared/components/MUIButton";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import moment from "moment";
import { useSelector } from "react-redux";
import { DropdownValueContext } from "../../shared/context/DropdownProvider";
import { ALERT_SEVERITY } from "../../shared/constants/constants";
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';


const ClearData = () => {
    const { userRole } = useSelector((state) => state?.common?.userDetails);
    const [valMsg, setValMsg] = useState(null);
    const [valUser, setValUser] = useState(null);
    const [date, setDate] = useState(moment(null));
    const Snackbar = useContext(SnackbarContext);
    const { customerId } = useSelector((state) => state?.common);
    const { customer, value } = useContext(DropdownValueContext);
    const [clearData, { data }] = useClearDataMutation();
    const onMessageSelect = (event) => {
        setValMsg(event);
    }
    const onUserSelect = (event) => {
        setValUser(event);
    }
    const onChangeDateFrom = (event) => {
        // console.log("EV-DDD", event);
        setDate(event)
    };

    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    };
    
    const handleClearData = async(res) => {
        if (res?.data?.status === 'success') {
         Snackbar.displayMsg(`${res?.data?.message}`, ALERT_SEVERITY.SUCCESS)
       } else {
         Snackbar.displayMsg(`${res?.data?.message}`,ALERT_SEVERITY.ERROR)
       }
     }
    const onClearData = async() => {
            const paylod = {
                "msgType": valMsg==''?null:valMsg,
                "frDate": date[0] ? moment(new Date(date[0])).format("YYYY-MM-DD") : null,
                "toDate": date[1] ? moment(new Date(date[1])).format("YYYY-MM-DD") : null,
                "userId": valUser==''?null:valUser,
                "customerId": (userRole?.name === 'Admin' || userRole?.name === 'Tester') ? customerId : customer,
                "paymentServiceId": value
            }
            const connectivityResults = await  clearData(paylod);
            handleClearData(connectivityResults);
            // console.log(paylod,connectivityResults);
    }

    return( 
    <Box sx={{ overflowY: 'auto', height: `calc(100vh - 100px)`, paddingBottom: "30px" }}>
        <TestCaseHeader
            title="Clear Data"
            showTestCustomer={{
                show: true,
            }}
        />
        <Box sx={{ px: '2.1%' }}>
                <Box>
                    <Grid container>
                        <Grid item width={"40%"} mt={1}>
                            <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "17px" }}>
                                Select Message Type
                            </Typography>
                        </Grid>
                        <Grid item mt={3}>
                            <ClearDataMessageTypeDropdown onChange={onMessageSelect} value={valMsg}   />
                        </Grid>
                    </Grid>
                </Box>

            </Box>
                <Box mt={"2%"} sx={{ px: '2.1%' }}>
                    <Box>
                        <Grid container>
                            <Grid item width={"40%"} mt={1}>
                                <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "17px" }}>
                                    Select Date
                                </Typography>
                            </Grid>
                            <Grid item>
                                <DateFromToPicker onChangeDateFrom={onChangeDateFrom} value={date} disabledDate={disabledDate} />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box mt={"2%"}>
                        <Box>
                            <Grid container>
                                <Grid item width={"40%"} mt={1}>
                                    <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "17px" }}>
                                        Select User
                                    </Typography>
                                </Grid>
                                <Grid item mt={3}>
                                    <UserDropdown  onChange={onUserSelect} user={valUser}/>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
                <Box mt={"20%"} sx={{ display: "flex", px: '2.1%' }}>
                <MUIButton
                    startIcon={<CheckCircleIcon style={{ fontSize: 18, }} />}
                    title="Clear Data"
                    onClick={onClearData}
                />
            </Box>
    </Box>

    );
}
export default ClearData;