import React, { useEffect } from 'react';
import Dropdown from '../../shared/components/Dropdown';
import _ from 'lodash';
import { useGetConfigurePaymentGatewayDropdownQuery } from '../../redux/settings/settings.api';


const styles = {
    menuProps: {
        sx: {
            '.MuiPaper-root': {
                maxHeight: '40%',
            },
        },
    },
}

const data = [
    {
        paymentGatewayId: '1',
        paymentGatewayName:'Payapt Payment Gateway Simulator',
    },
    {
        paymentGatewayId: '2',
        paymentGatewayName:'Other Payment Gateway',
    }
]

const ConfigurePayGateway = (props) => {
    const {onChangePaymentGateway, value } = props;
    const {data:configPayment} = useGetConfigurePaymentGatewayDropdownQuery();

    useEffect(() => {
        if (!_.isEmpty(configPayment)) {
            onChangePaymentGateway(configPayment?.id, configPayment?.value)
        }
    }, [configPayment])

    const onChange = (xEl) => {
        // console.log('xEl-onChange',xEl);
        if(xEl){
          const obj = _.find(configPayment, m => m?.id === xEl);
          onChangePaymentGateway(xEl, obj?.value)
        } else {
          onChangePaymentGateway(xEl, xEl)
        }
        
      }

    return (
        <>
            <Dropdown
                placeholder='Select Payment Gateway Type'
                margin="dense"
                className="user-dropdown"
                style={{ width: '180%', height: '42px', marginTop: '-20px' }}
                inputProps={{ 'aria-label': 'Without label' }}
                options={configPayment}
                valueKey='value'
                labelKey='value'
                onChange={(e) => onChange(e)}
                value={value}
                MenuProps={styles.menuProps}
                // isClear={isClear}
            // disabled = {!!selectedRecord?.userId}
            />
        </>
    )
}

export default ConfigurePayGateway;
