import React, { useState } from 'react';
import { generatePasc008 } from '../actions/schemaValidator';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import _ from 'lodash';
import TextAreaWithLineNumber from 'text-area-with-line-number';

// import { bindActionCreators } from 'redux'
// import { connect } from 'react-redux';


const SchemaEditor = (props) => {

    const [text, setText] = useState('');
    const [errorMsg, setErrorMsg ] = useState([]);

    const { validateBusiness, sendToMock, isSendToMockEnabled, enableSendToMock, isXmlValidated, validateXML, setXmlValidated } = props;

    const openFile = async (e) => {
        e.preventDefault()
        const reader = new FileReader()
        reader.onload = async (e) => { 
          const text = (e.target.result)
          onTextChange(text)
        };
        reader.readAsText(e.target.files[0])
      }
    
      const validateText = (text, setMsg=true) => {
        var domParser = new DOMParser();
        var dom = domParser.parseFromString(text, 'text/xml');
        if(dom.getElementsByTagName("parsererror").length<=0 && !_.isEmpty(errorMsg)){
            setErrorMsg([])
        }
        if(setMsg){
            dom.getElementsByTagName("parsererror").length>0   ? setErrorMsg(_.map(dom.getElementsByTagName("parsererror"), e => e.innerText.split('\n')[0])) : setErrorMsg(null)
        }
        return !dom.getElementsByTagName("parsererror").length>0;
    }

    const onTextChange = (text) => {
        setText(text);
        enableSendToMock(false);
        // if(isXmlValidated)
        // setXmlValidated(false)
        const res= validateText(text);
        if(res){
            validateXML(text)
        }
        
        // validateText(text);
    }

    const generate = async () => {
        const data = await generatePasc008();
        setText(data);
    }

    return (
        <div className='schema-editor'>
            <h5 style = {{textAlign: 'left'}}>Input</h5>
            <div className='download-btn'>
                <label htmlFor="file-upload" className='validation-btns'>Upload File</label>
                <input type="file"  onChange={(e) => openFile(e)} name = "Download File" accept="xml/json" 
                style= {{'display':'none'}} id="file-upload"/>
            </div>
            <div className='editor' onBlur={() => validateText(text)}>
            <TextAreaWithLineNumber contentEditable = {true}  
            onChange={(e) => onTextChange(e.target.value)} 
            border = '0px'
            height = '60vh'
            value = {text}>

            </TextAreaWithLineNumber>
            </div>
            {
                !_.isEmpty(errorMsg) && <div class="alert alert-danger" role="alert">
                {_.map(errorMsg, msg => <React.Fragment>{msg}<br/></React.Fragment>)}
              </div>
            }
            <div className='footer-buttons'>
                <div className='validate-btn' style={{float:'left'}}>
                    <Button className='validation-btns' onClick={() => generate()}>Generate Pasc008</Button>
                </div>
                <div className='validate-btn'>
                    <Button className='validation-btns' onClick={() => validateXML(text)} disabled = {!text || !validateText(text, false)}>Validate Schema</Button>
                </div>
                <div className='validate-btn'>
                    <Button className='validation-btns' onClick={() => validateBusiness(text)} disabled = {!isXmlValidated}>Validate Business</Button>
                </div>
                <div className='validate-btn'>
                    <Button className='validation-btns' onClick={() => sendToMock(text)} disabled = {!isSendToMockEnabled}>DUT</Button>
                </div>
            </div>
            
        </div>
    )
}

// const mapDispatchToProps = (dispatch) => bindActionCreators(
//     {
//         actions: {
//             generatePasc008,
//         }
//     }
// )
export default SchemaEditor;
// export default connect(null, mapDispatchToProps)(SchemaEditor);