import { Box, Typography } from '@mui/material';
import React from 'react';

const styles = {
    titleContainer: {
        display: "flex",
        alignItems: "flex-start",
        ml: 2,
        py: 1.5
    },
    titleStyle: {
        fontSize: "1rem", fontWeight: "600"
    }
}

function BatchBoxTitle(props) {
    const { title } = props;
    return (
        <Box sx={styles.titleContainer}>
            <Typography sx={styles.titleStyle}>{title}</Typography>
        </Box>
    );
}

export default BatchBoxTitle;