import React, { useEffect } from 'react';
import Dropdown from '../../shared/components/Dropdown';
import _ from 'lodash';
import { useGetCustomerTypeListQuery } from '../../redux/customers/customer.api';


const styles = {
    menuProps: {
      sx: {
          '.MuiPaper-root': {
              maxHeight: '40%',
          },
        },
    },
}

const data = [
    {
        organizationId:'1',
        organizationName: 'Payment ',
    },
    {
        organizationId:'2',
        organizationName: 'Payment Gateway'
    },
    {
        organizationId:'3',
        organizationName: 'Bank'
    }
]
const OrganizationDropdown = (props) => {

    const { onChangeOrganization, value, isClear,error } = props;
    const { data: customerTypeList } = useGetCustomerTypeListQuery();

    useEffect(() => {
        if (!_.isEmpty(customerTypeList)) {
            onChangeOrganization(customerTypeList?.customerTypeId, customerTypeList?.customerTypeName)
        }
    }, [customerTypeList])

    const onChange = (value) => {
        // console.log('value-onChange',value);
        if(value){
          const obj = _.find(customerTypeList, m => m?.customerTypeId === value);
          onChangeOrganization(value, obj?.customerTypeName)
        } else {
          onChangeOrganization(value, value)
        }
        
      }
    return (
        <>
            <Dropdown
                placeholder='Select Organization Type'
                margin="dense"
                className="user-dropdown"
                style={{ width: '180%', height: '42px', marginTop: '-20px' }}
                inputProps={{ 'aria-label': 'Without label' }}
                options={customerTypeList}
                valueKey='customerTypeId'
                labelKey='customerTypeName'
                onChange={(e) => onChange(e)}
                value={value}
                MenuProps={styles.menuProps}
                isClear={isClear}
                error={error}
            // disabled = {!!selectedRecord?.userId}
            />
        </>
    )
}

export default OrganizationDropdown;
