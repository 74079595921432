import { Box, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import TestCaseHeader from '../testCaseHeader';
import moment from 'moment';
import MUIButton from '../../shared/components/MUIButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';
import { ALERT_SEVERITY, APP_ROUTE_KEYS } from '../../shared/constants/constants';
import ValueDropdown from './ValueDropdown';
import MessageDropdown from './MessageDropdown';
import { useSelector } from 'react-redux';
import { useContext } from 'react';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';

const Admi006 = () => {
    const navigate = useNavigate();
    const Snackbar = useContext(SnackbarContext);
    const { customerId } = useSelector((state) => state?.common);
    const pageSize = useSelector((state) => state?.common?.pageSize);
    const [date, setDate] = useState(moment());
    const [value, setValue] = useState();
    const [valMsg, setValMsg] = useState();
    const [customer, selectedCustomer] = useState();
    const currentDate = moment().format('MM/DD/YYYY');
    const onDateSelection = (event) => {
        setDate(event)
    };

    const onValueSelection = (event) => {
        setValue(event)
    };

    const handleViewMessage = () => {
        if (value === undefined || valMsg === undefined) {
            Snackbar.displayMsg("Please Select the Values", ALERT_SEVERITY.ERROR)
        } else {
            const paylod = {
                "messageName": valMsg,
                "fromDate": date[0] ? moment(new Date(date[0])).format() : null,
                "toDate": date[1] ? moment(new Date(date[1])).format() : null,
                "search": null,
                "page": 0,
                "size": pageSize,
                "cusId":customer ? customer : customerId
            }
            navigate(`${APP_ROUTE_KEYS.REPORTS.MESSAGE_ADMI006}`, { state: { paylod } })
        }

    }
    const onMessageSelect = (event) => {
        setValMsg(event);
    }
    const disabledDate = (current) => {
        //   return current && current > moment().endOf('day');
        const today = moment().endOf('day');
        const sevenDaysAgo = today.clone().subtract(8, 'days');
        return current && (current > today || current < sevenDaysAgo);
    };

    const onTestCaseCustomerSelection = (event) => {
        selectedCustomer(event);
    }
    return (
        <Box sx={{ overflowY: 'auto', height: `calc(100vh - 100px)`, paddingBottom: "30px" }}>
            <TestCaseHeader
                title="Admi.006-Retrieval Request Report"
                displayInLine={true}
                showCustomer={{
                    show: true,
                    onChange: onTestCaseCustomerSelection,
                    value: customer
                }}
                // showDatepicker={{
                //     show: false,
                //     onChange: onDateSelection,
                //     maxDate: new Date(),
                //     // currentDate: currentDate,
                //     value: date
                // }}
                showDateFromToPicker={{
                    show: true,
                    disabledDate: disabledDate,
                    onChange: onDateSelection,
                    value: date
                }}
            />

            {/* <Box mt={"2%"} sx={{ px: '2.1%' }}>
                <Box>
                    <Grid container>
                        <Grid item width={"40%"} mt={1}>
                            <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "17px" }}>
                                Select Routing Number
                            </Typography>
                        </Grid>
                        <Grid item mt={3} width={"25%"}>
                            <RoutingDropdown/>
                        </Grid>
                    </Grid>
                </Box>
            </Box> */}
            <Box mt={"2%"} sx={{ px: '2.1%' }}>
                <Box>
                    <Grid container>
                        <Grid item width={"40%"} mt={1}>
                            <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "17px" }}>
                                Select Message Type
                            </Typography>
                        </Grid>
                        <Grid item mt={3} width={"25%"}>
                            <ValueDropdown onChangeValue={onValueSelection} value={value} />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box mt={"2%"} sx={{ px: '2.1%' }}>
                <Box>
                    <Grid container>
                        <Grid item width={"40%"} mt={1}>
                            <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "17px" }}>
                                Select Messages
                            </Typography>
                        </Grid>
                        <Grid item mt={3} width={"25%"}>
                            <MessageDropdown messageType={value} onChange={onMessageSelect} value={valMsg} />
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <Box mt={"20%"} sx={{ display: "flex", px: '2.1%' }}>
                <MUIButton
                    startIcon={<CheckCircleIcon style={{ fontSize: 18, }} />}
                    title="View Message"
                    onClick={handleViewMessage}
                />
            </Box>
        </Box>
    )
}

export default Admi006;
