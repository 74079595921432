import React, { useContext, useState } from "react";
import "../../components/styles/loginStyles.css";
import "../../components/styles/forgotStyles.css";
import CustomerOnboardingTitle from "../customerOnboardingTitle";
import OTPInput  from "otp-input-react";
import { Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useLocation, useNavigate } from "react-router-dom";
import MUIButton from "../../shared/components/MUIButton";
import { useValidateOtpMutation } from "../../redux/registration/registration.api";
import { SnackbarContext } from "../../shared/context/SnackbarContext.Context";
import { ALERT_SEVERITY } from "../../shared/constants/constants";
import { useRequestOtpMutation } from "../../redux/registration/registration.api";

const RequestOtp = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState();
  const snackbar = useContext(SnackbarContext);
  const location = useLocation();
  const [validate, { isSuccess, isError, data, error} ] = useValidateOtpMutation();
  const [requestOtp] = useRequestOtpMutation();

  const handleChange = (e) => {
    setOtp(e);
  };

  const handleCreateOTPNavigation = async (xRes) => {
    if(xRes?.data.message == "OTP sent to email"){
          snackbar.displayMsg(xRes?.data?.message, ALERT_SEVERITY.SUCCESS);
        } else if(xRes?.error){
          snackbar.displayMsg(xRes?.error?.data?.message, ALERT_SEVERITY.ERROR)
        }
  }

  const generateOtp = async (e) => {
    if(location?.state?.email){
      const payload ={
         email:location?.state?.email
       }
       const resOTP = await requestOtp(payload);
       handleCreateOTPNavigation(resOTP);
     }
  };

  const validateOtp = async () => {
    validate({email: location?.state?.email, otp:otp})
      .unwrap()
      .then((payload) => {
        snackbar.displayMsg(payload?.message, ALERT_SEVERITY.SUCCESS)
        if(location?.state?.fromPath === '/forgotemail'){
        navigate('/resetpassword', {state: { email: location?.state?.email}});
        }else{
          navigate('/completeprofile')
        }
        
      })
      .catch((payload) => {
        snackbar.displayMsg(payload?.data?.message, ALERT_SEVERITY.ERROR)
      })

  }
    
  return (
    <div className="container">
      <CustomerOnboardingTitle />
      <div style={{justifyContent:'center'}}>
      <br/>
        <h6 className="id-forgot">Verification Code</h6>
        <p className="otp-content">
          We've sent an verification code on your given <br/> email
          <font color="#009966"> {location?.state?.email}</font>
        </p>
        <br/>
        <OTPInput  inputStyles={{border: '1px solid #ACACAC',borderRadius: '5px',width:'54px',height:'54px'}} OTPLength={6} otpType="number" disabled={false} value={otp} onChange={handleChange} />
        <p className="didnt-receive">Didn't receive code?<a color="#009966" onClick={generateOtp}> Request again</a></p>
        <br/>
      <MUIButton
       startIcon={<CheckCircleIcon style={{ fontSize: 18 }}/>}
       title="Verify"
       onClick={validateOtp}
      />
      </div>
    </div>
  );
};

export default RequestOtp;