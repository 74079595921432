import React from 'react';
import '../../components/styles/datepickerStyles.css';
// import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import PropTypes from 'prop-types';
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import '../../components/styles/datepickerStyles.css';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { LocalizationProvider } from '@mui/x-date-pickers-pro';
// import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro';
import { DatePicker } from 'antd';
import moment from 'moment';
import './dashBoard.css';


const DateFromToPicker = (props) => {
    const { onChangeDateFrom , currentDate,disabledDate} = props;
    const {  RangePicker } = DatePicker;

    // const disabledDate = (current) => {
    //   return current && current > moment().endOf('day');
    // };
   
    return (
    <RangePicker  size='large' 
     format="MM-DD-YYYY"
     placeholder={currentDate&& [currentDate, currentDate] }
     className={true? 'createDateRangePicker' : ''}
     onChange={(e) => onChangeDateFrom(e)}
     disabledDate={disabledDate}
    //  style={{border:'2px solid #006644'}}
    />
  )
}


export default DateFromToPicker;
