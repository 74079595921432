export const UPDATE_CURRENT_STEP = 'UPDATE_CURRENT_STEP';
export const UPDATE_SUBSCRIPTION_PLAN = 'UPDATE_SUBSCRIPTION_PLAN';
export const UPDATE_PARTICIPATION_TYPE = 'UPDATE_PARTICIPATION_TYPE';

export const updateCurrentStep = (currentStep) => ({
    type: UPDATE_CURRENT_STEP,
    currentStep
});

export const updateSubscriptionPlan = (subscriptionPlan) =>({
    type: UPDATE_SUBSCRIPTION_PLAN,
    selectedPlan: subscriptionPlan
});

export const updateParticipationType = (participationType) =>({
    type: UPDATE_PARTICIPATION_TYPE,
    participationType
});
  