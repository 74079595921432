export const customerData = [
    {
        customerID: "1001",
        customerInfo: "CTS",
        customerType: "Financial Institution",
        participationType: "Receive",
        address1: "6/170",
        address2: "Thuraiyur",
        city: "Trichy",
        country: "India",
        postalCode: "621002",
        mobile: "987654321",
        email: "swathi@gmail.com",
        routingNumber:"One",
        registeredDate:"17-04-2023",
        restApiConnectivity: "Yes",
        mqSetup: "Yes",
        testDataReview: "25%",
        certificationReadiness: "4%",
        totalTestDataReview: "50%",
        subscriptionPlan: "Sales",
        validityDate: "2023-04-18",
        status: "Active",

    },
    {
        customerID: "1002",
        customerInfo: "TCS",
        customerType: "Bank",
        participationType: "Send",
        address1: "6/170",
        address2: "Thuraiyur",
        city: "Trichy",
        country: "India",
        postalCode: "621002",
        mobile: "987654321",
        email: "moorthy@gmail.com",
        routingNumber:"One",
        registeredDate:"17-04-2023",
        restApiConnectivity: "Yes",
        mqSetup: "Yes",
        testDataReview: "25%",
        certificationReadiness: "4%",
        totalTestDataReview: "50%",
        subscriptionPlan: "Basic",
        validityDate: "2023-04-18",
        status: "Pending",

    },
];