import { createSlice } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { authApi } from './auth.api';

const token = localStorage.getItem('token');

function storeToken(value) {
  // const navigate = useNavigate();
  if (value && value.length > 0) {
    localStorage.setItem('jwttoken', value);
  } else {
    localStorage.removeItem('jwttoken');;
    // navigate('/signin');
  }
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: { token },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
      if (payload.isAuthSuccessful) {
        state.token = payload.token;
        storeToken(payload.token);
      }
    });
  },
  reducers: {
    updateUserAuth: (state, { payload }) => {
      state.token = payload?.token;
      storeToken(payload?.token);
    },
  },
});

export const { updateUserAuth } = authSlice.actions;
export const getToken = (state) => state.auth?.token;
