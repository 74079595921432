import { backendApi } from '../base.config';

export const testCasesApi = backendApi.injectEndpoints({
    endpoints: (builder) => ({
        getAllTestCasesByScenarioId: builder.query({
            query: (args) => ({
                url: `customerTestcase/getAllCusTestCase`,
                params: { ...args },
                refetchOnMountOrArgChange: true
            }),
        }),
        getTestCaseDetails: builder.mutation({
            query: (args) => ({
                method: "POST",
                url: `customertcversion/getAllCustomertcVersion`,
                params: { ...args },
            }),
        }),
        getAllTestCaseDetailsByScenarioId: builder.query({
            query: (args) => ({
                url: `customertcversion/getCustomerTestCaseVersion`,
                params: { ...args },
                refetchOnMountOrArgChange: true
            }),
        }),
        getXpathAndValueDetailsOfTestcase: builder.query({
            query: (args) => ({
                url: `customertestdata/getCustomerXpathAndValue`,
                params: { ...args },
                refetchOnMountOrArgChange: true
            }),
        }),
        reviewTestcases: builder.mutation({
            query: (args) => ({
                method: "POST",
                url: `customertcversion/updateCustomerTestCaseVersion`,
                body: { ...args },
            }),
        }),
        
        // cloning and getting back testData, xPathPath, Values (total 3 apis)
        cloneTestData: builder.mutation({
            query: (args) => ({
                method: "POST",
                url: `customertestdata/cloneInsertion`,
                body: { ...args },
            }),
        }),
        getTestDataSbyVersionId: builder.query({
            query: (args) => ({
                url: `customertestdata/getTestDataUsingVersionId`,
                method: "POST", //TODO: Ask BE team to make this req as GET
                body: { ...args },
                refetchOnMountOrArgChange: true
            }),
        }),
        getTestDataDetailsByTestDataId: builder.query({
            query: (args) => ({
                url: `customertestdata/getTestDataDetailsByTestDataId`,
                method: "POST", //TODO: Ask BE team to make this req as GET
                body: { ...args },
                refetchOnMountOrArgChange: true
            }),
        }),
        updateXpathAndValue: builder.mutation({
            query: (args) => ({
                url: `customertestdata/updateXpathaandValue`,
                method: "POST", 
                body: { ...args },
            }),
        }),
        deleteXpathAndValue: builder.mutation({
            query: (args) => ({
                url: `customertestdata/deleteXpathandValue`,
                method: "POST", 
                body: { ...args },
            }),
        }),
        deleteTestData: builder.mutation({
            query: (args) => ({
                method: "POST",
                url: `customertestdata/deleteClonedTestData`,
                body: { ...args },
            }),
        }),
        getTestRunDetailsWithXpathandValue: builder.mutation({
            query: (args) => ({
                method: "POST",
                url: `customerTestRun/getTestRunDataUsingTestRunId`,
                body: { ...args },
            }),
        }),
        getTestRunDataUsingTestRunIdMaster: builder.mutation({
            query: (args) => ({
                method:"POST",
                url:`customerTestRun/getTestRunDataUsingTestRunIdonmaster`,
                body: {...args}
            })
        }),
        getAllMasterTcVersionMaster: builder.mutation({
            query: (args) => ({
                method:"POST",
                url:`customertcversion/getAllMasterTcVersionMaster`,
                params: {...args}
            })
        }),
        addedTestDataDetails: builder.mutation({
            query: (args) => ({
                url:`customertestdata/addAddiionalDataDetails`,
                method:"POST",
                body: {...args},
            })
        }),
        selectAPICall: builder.mutation({
            query: (payload) => ({
                method: "POST",
                url:`TestCaseUpload/selectAPICall`,
                body:payload
            })
        }),
        updateXpathAndValueMaster: builder.mutation({
            query: (args) => ({
                url: `customertestdata/updateXpathaandValueonMaster`,
                method: "POST", 
                body: { ...args },
            }), 
        }),
        updateTestCaseStatus: builder.mutation({
            query: (payload) =>({
                url:`customerTestcase/updateTestCaseStatus`,
                method: "POST",
                body:payload
            })
        }),
    }),
});

export const {
    useGetAllTestCasesByScenarioIdQuery,
    useGetTestCaseDetailsMutation,
    useGetAllTestCaseDetailsByScenarioIdQuery,
    useGetXpathAndValueDetailsOfTestcaseQuery,
    useReviewTestcasesMutation,
    useCloneTestDataMutation,
    useLazyGetTestDataSbyVersionIdQuery,
    useLazyGetTestDataDetailsByTestDataIdQuery,
    useUpdateXpathAndValueMutation,
    useDeleteXpathAndValueMutation,
    useDeleteTestDataMutation,
    useGetTestRunDetailsWithXpathandValueMutation,
    useGetTestRunDataUsingTestRunIdMasterMutation,
    useGetAllMasterTcVersionMasterMutation,
    useAddedTestDataDetailsMutation,
    useSelectAPICallMutation,
    useUpdateXpathAndValueMasterMutation,
    useUpdateTestCaseStatusMutation,
} = testCasesApi;
