import { Select, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { base64Data, testcaseFiles } from '../constants/constants';
import * as XLSX from "xlsx";
import { testcase1 } from '../assets/base64/testcases';


const { Option } = Select;
const Testcases = () => {
    const [tableColumns, setTableColumns] = useState([]);
    const [dataSource, setDataSource] = useState([])

    const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
      
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);
      
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
      
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
      
        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
      }

    const readFile = async(filename) => {
        const blob = b64toBlob(base64Data[filename], 'application/vnd.ms-excel');
        const wb = XLSX.read(await blob.arrayBuffer(), { type: "array" });
        const sheets = wb.SheetNames;
        console.log(sheets)
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        const json = XLSX.utils.sheet_to_json(ws, {header:1});
        console.log(json)
        
        const keys = Object.keys(json);
        const data = _.slice(keys, 1,_.size(keys)-1 );
        let loDataSource = [];
        _.map(data, (c) => {
            const obj = {}
           _.map(json[c], (d,i) => {
            obj[i] = d;
           })
           loDataSource = _.concat(loDataSource, {...obj})
        });
        
        // const arr = [...loDataSource];
        setDataSource(loDataSource);
        const columns = _.map(json[0], (c, index) => ({
            title: c,
            dataIndex: index,
            key:c,
            width: 300,
            ellipsis: {
                showTitle: true,
              },
        }));
        columns[0].fixed = 'left';
        console.log(columns)
        setTableColumns(columns);
    }

    console.log("columns")
    console.log(tableColumns)
    console.log("data")
    console.log(dataSource)
    return (
        <div>
        <Select 
        placeholder = "Select File"
        style = {{ width: '30%', textAlign: 'left'}}
        onChange={readFile}
        >
            {
                    _.map(testcaseFiles, file => (
                        <Option key = {file.value}>{file.name}</Option>
                    ))
            }
        </Select>
        <Table 
            dataSource={dataSource} 
            columns={tableColumns} 
            scroll={{ x: 1500 }}
            bordered
            style = {{ marginTop: 20}}
            ></Table>
        </div>
    )
}

export default Testcases;