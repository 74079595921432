import moment from "moment";
// import 'moment-timezone';


export const appLogger = (xLogName = "No-Screen", xLogDt = "") => {
    console.log(`appLogger ~ xLogName: [${xLogName}]:`, { xLogDt })
}

export const xDownloadFile = (xFileUrl) => {
    var link = document.createElement("a");
    link.href = xFileUrl;
    link.download = xFileUrl.substr(xFileUrl.lastIndexOf("/") + 1);
    document.body.appendChild(link);
    window.open(xFileUrl);
    document.body.removeChild(link);
};

export const generateCurrentEpochTimeValue = () => {
    return `${new Date().valueOf()}`;
}

export const convertAndFormatToUtcDate = (xDt, xFormat = "MM-DD-YYYY HH:mm:ss") => {
    const sdDt = xDt ? moment.utc(xDt).format(xFormat) : "NA";
    return sdDt;
}
export function toLocalDate(date, format = 'DD/MM/YYYY') {
    return moment(date).format(format);
}

export const convertToLocalTime = (datetime, format='MM-DD-YYYY HH:mm:ss') => {
    // console.log("datetime", datetime)
    const utcDate = moment.utc(datetime).format(format)
    // console.log("utcDate", utcDate)
    const utc = moment.utc(utcDate).toDate();
    // console.log("utc", utc)
    const date = moment.utc(utc).local().format(format)
    // console.log("date", date)
    // console.log(datetime)
    // return moment(datetime).format(format);
    return date;
}

// export const convertToEST = (datetime, format = 'YYYY-MM-DD HH:mm:ss') => {
//     const utcDate = moment.utc(datetime).format(format);
//     const estDate = moment.utc(utcDate).tz('America/New_York').format(format);
//     return estDate;
// }