import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTestDataReviewMutation } from "../../redux/dashboard/dashboard.api";
import './dashBoard.css'
import ProgressLine from "./progressLine";
import TestDataReviewMF from "./TestDataReviewMF";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export const DATA =
    {
        header : 'Standalone Messages',
        directionIn : 'Inbound',
        directionOut : 'Outbound',
        total : 1500 ,
        received : 400,
        inbound : 30,
        outbound : 40
    }

    // {
    //   "testDataReview": "Test Data Review",
    //   "data": {
    //     "inboundReviewed": 11,
    //     "inbound": 35,
    //     "outbound": 31,
    //     "outboundReviewed": 11,
    //     "outboundPercentage": 35,
    //     "inboundPercentage": 31
    //   },
    // }
    


const TestDataReview =(props)=>{
      const { customer } = props;
     const [ testData , { data }] = useTestDataReviewMutation();
     const saData = data?.data1;
    //  const mfData = data?.messageFlow;
     useEffect(()=>{
        testData({ customerId: customer});
     },[customer])
    return(
        <Box>
            <Box display={'flex'} 
             sx={{justifyContent:'space-between',alignItems:'center'}}
             >
                <Typography className="test-data-review-dashboard" sx={{textAlign:'left',fontWeight:'600'}}>Test Data Review</Typography>
                {/* <ChevronRightIcon sx={{marginRight:'15px'}}/> */}
              </Box>
            {/* <Typography className="test-data-review-dashboard" sx={{textAlign:'left',fontWeight:'600'}}>Test Data Review</Typography> */}
            <Box sx={{flexDirection:'column',paddingBottom:'15px'}}>
          <ProgressLine data={saData}/>
          </Box>
          {/* <Box>
          <TestDataReviewMF data={mfData}/>
          </Box> */}
        </Box>
    )
}
export default TestDataReview