import React from 'react';
import { Box, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import ConditionalIcon from '../../assets/images/conditional.png';
import prohibitedIcon from '../../assets/images/prohibited.png';
import { sampleDataRowsConfigure } from './constants';
function CustomTable(props) {
const {tableData,setTableData} = props;
  const sendCheckboxClick = (zRow) => {
    // handle checkbox click event for the 'send' column
    setTableData(tableData?.filter(x =>
    x.rows.filter(y => y.name === zRow.name ?
    y.sendStatus = !y.sendStatus : '')))
  };

  const receiveCheckboxClick = (zRow) => {
    // handle checkbox click event for the 'receive' column
     setTableData(tableData?.filter(x =>
    x.rows.filter(y => y.name === zRow.name ?
    y.receiveStatus = !y.receiveStatus : '')))
  };
  React.useEffect(() => {
    if (sampleDataRowsConfigure)
      setTableData(sampleDataRowsConfigure);
  })

  return (
    <>
    <TableContainer style={{ marginTop: '20px', maxHeight: '60vh', overflowY: 'auto' }} sx={{ py: 2 }} component={Paper}>
      {tableData?.map((xEl) => (
        <Table key={xEl.id} size='small' sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {xEl?.headCells?.map((yEl) => (
                <TableCell key={yEl} style={{ fontWeight: 'bold' }}>{yEl}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {xEl?.rows.map((zRow) => (
              <TableRow key={zRow.name}>
                <TableCell sx={{ width: '50%' }} component="th" scope="row">
                  {zRow.name}
                </TableCell>
                <TableCell>{zRow.transfer}</TableCell>
                <TableCell sx={{ width: '30%' }}>
                  {zRow.sendStatus === true || zRow.sendStatus === false ? (
                    <Checkbox
                      sx={{
                        height: 20,
                        width: 20,
                      }}
                      onClick={(event) => sendCheckboxClick(zRow)}
                      checked={zRow.sendStatus}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  ) : zRow.sendStatus === 'prohibited' ? (
                    <Box
                      component="img"
                      sx={{
                        height: 20,
                        width: 20,
                      }}
                      src={prohibitedIcon}
                    />
                  ) : zRow.sendStatus === 'conditional' ? (
                    <Box
                      component="img"
                      sx={{
                        height: 20,
                        width: 20,
                      }}
                      src={ConditionalIcon}
                    />
                  ) : (
                    <p>N/A</p>
                  )}
                </TableCell>
                <TableCell>
                  {zRow.receiveStatus === true || zRow.receiveStatus === false ? (
                    <Checkbox
                      sx={{
                        height: 20,
                        width: 20,
                      }}
                      onClick={(e) => receiveCheckboxClick(zRow)}
                      checked={zRow.receiveStatus}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  ) : zRow.receiveStatus === 'prohibited' ? (
                    <Box
                      component="img"
                      sx={{
                        height: 20,
                        width: 20,
                      }}
                      src={prohibitedIcon}
                    />
                  ) : zRow.receiveStatus === 'conditional' ? (
                    <Box
                      component="img"
                      sx={{
                        height: 20,
                        width: 20,
                      }}
                      src={ConditionalIcon}
                    />
                  ) : (
                    <p>N/A</p>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ))}
    </TableContainer>
    </>
  );
}

export default CustomTable;
