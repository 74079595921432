import React from 'react';
import { Button } from '@mui/material';

function MUIButton(props) {
    const {variant= 'contained', title,sx,...restProps} = props;
  return (
      <Button  variant={variant} 
            {...restProps}
            sx={[{ textTransform: 'none', },{...sx}]}>
         {title} 
      </Button>
  )
};

export default MUIButton;
