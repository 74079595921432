export const StandaloneData = [
    {
       id:1,
       category:"Standalone Messages" ,
       total:'450',
       standalone:{
        inbound:
            {
            executed:'15',
            passed:'10',
            failed:'05'
            },
        
        outbound:{
            executed:'20',
            passed:'15',
            failed:'05',
        }
       }
    }
]

export const MessageFlowsData =[
    {
        id:1,
        category:"Message Flows" ,
        total:'460',
        message:{
         inbound:
             {
             executed:'15',
             passed:'10',
             failed:'05'
             },
         
         outbound:{
             executed:'30',
             passed:'15',
             failed:'15',
         }
        }
     } 
]

export const NfrData =[
    {
        id:1,
        category:"NFR" ,
        total:'470',
        testCases:{
             executed:'15',
             passed:'10',
             failed:'05'
        }
     } 
]

