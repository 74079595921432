// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.add-title {
  width: 137px;
  height: 24px;
  line-height: 24px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-transform: capitalize;
  color: #001a11;
}
.add-subtitle {
  width: 316px;
  height: 17px;
  font-family: "Inter";
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 17px;
  color: #000000;
}
.add-header-content {
  width: 45px;
  height: 17px;
  font-family: "Inter";
  font-style: normal;
  font-size: 16px;
  font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/components/onboarding/UploadandAdd testData/styles.css"],"names":[],"mappings":";AACA;EACE,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,0BAA0B;EAC1B,cAAc;AAChB;AACA;EACE,YAAY;EACZ,YAAY;EACZ,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;AAChB;AACA;EACE,WAAW;EACX,YAAY;EACZ,oBAAoB;EACpB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;AAClB","sourcesContent":["\n.add-title {\n  width: 137px;\n  height: 24px;\n  line-height: 24px;\n  font-family: \"Inter\";\n  font-style: normal;\n  font-weight: 600;\n  font-size: 20px;\n  text-transform: capitalize;\n  color: #001a11;\n}\n.add-subtitle {\n  width: 316px;\n  height: 17px;\n  font-family: \"Inter\";\n  font-size: 14px;\n  font-style: italic;\n  font-weight: 400;\n  line-height: 17px;\n  color: #000000;\n}\n.add-header-content {\n  width: 45px;\n  height: 17px;\n  font-family: \"Inter\";\n  font-style: normal;\n  font-size: 16px;\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
