import { backendApi } from "../base.config";

export const batchTestRunsApi = backendApi.injectEndpoints({
    tagTypes: ['BATCH_TEST_RUN_LIST'],
    endpoints: (builder) => ({
        getTestBatchExecutions: builder.mutation({
            query: (args) => ({
                url:`customerTestcaseBatch/getAllBatchOnly`,
                method: 'POST',
                body: {...args},
            }),
            providesTags: ['BATCH_TEST_RUN_LIST']
        }),
        killTestRuns: builder.mutation({
            query: (args) => ({
                url:`customerTestRun/killTestRun`,
                method: 'POST',
                body: [...args],
            }),
            invalidatesTags: ['BATCH_TEST_RUN_LIST']
        }),
        getAllBatchWithTestCase: builder.mutation({
            query: (args) => ({
                url:`customerTestcaseBatch/getAllBatchWithTestCase`,
                method: 'POST',
                body: {...args},
            }),
            providesTags: ['BATCH_TEST_RUN_LIST']
        }),
    }),
});

export const {
    useGetTestBatchExecutionsMutation,
    useKillTestRunsMutation,
    useGetAllBatchWithTestCase
} = batchTestRunsApi;