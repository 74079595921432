import { backendApi } from "../base.config";

export const reportsApi = backendApi.injectEndpoints({
    endpoints: (builder) => ({
        reportRequestGeneration: builder.mutation({
            query: (data) => ({
                url: "customerTestRun/accountReportRequestGeneration",
                method:"POST",
                body: data,
            }),
        }),
        accountReportResponse: builder.mutation({
            query: (args) => ({
                url:`customerTestRun/accountReportResponse`,
                method:"POST",
                params: {...args}
            }),       
        }),
        getMessageIdByTestRunId: builder.mutation({
            query: (args) => ({
                url:`customerTestcaseBatch/getMessageIdByTestRunId`,
                method:'POST',
                params:{...args}
            }),
        }),
        getAdmi006TransactionList: builder.mutation({
            query:(data) => ({
                method:"POST",
                url:`customerTestRun/getAdmi006TransactionList`,
                body:data
            }),
        }),
        SaveMessageIdAdmi006: builder.mutation({
            query: (data) => ({
                url:`customerTestRun/SaveMessageId`,
                method:"POST",
                body:data
            })
        }),
        getRetreivalRequestResponse: builder.mutation({
            query: (args) => ({
                url:`customerTestRun/getRetreivalRequestResponse`,
                method:"POST",
                params:{...args}
            }),
        }),
        getAdmi004MessageTypes: builder.query({
            query: () => ({
                method:"GET",
                url:`public/getAdmi004MessageTypes`,
            }),
        }),
        getAdmi004BoardCastMessage: builder.query({
            query: (args) => ({
                method:'GET',
                url:`public/getAdmi004BoardCastMessageTypes`,
                params:{...args}
            }),
            providesTags: [],
        }),
        getAllRoutingNumbers: builder.query({
            query: (args) => ({
                method:"GET",
                url:`customerTestRun/getAllRoutingNumbers`,
                params:{...args}
            }),
            providesTags:[],
        }),
        systemMessageRequest: builder.mutation({
            query: (data) => ({
                method:"POST",
                url:`customerTestRun/systemMessageRequestGeneration`,
                body: data,
            }),
        }),
        systemMessageResponse: builder.mutation({
            query: (data) => ({
                method:"POST",
                url:`customerTestRun/systemMessageResponse`,
                body: data,
            }),
        }),
    }),
});

export const {
 useReportRequestGenerationMutation,
 useAccountReportResponseMutation,
 useGetMessageIdByTestRunIdMutation,
 useGetAdmi006TransactionListMutation,
 useSaveMessageIdAdmi006Mutation,
 useGetRetreivalRequestResponseMutation,
 useGetAdmi004MessageTypesQuery,
 useGetAdmi004BoardCastMessageQuery,
 useGetAllRoutingNumbersQuery,
 useSystemMessageRequestMutation,
 useSystemMessageResponseMutation,
} = reportsApi