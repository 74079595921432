import { Box, Grid, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import PayaptLoader from '../../shared/components/Spinner';
import TestCaseHeader from '../../components/testCaseHeader';
import XTwoWayLabelSwitch from '../../shared/components/XTwoWayLabelSwitch';
// import BatchFiles from '../BatchFiles/BatchFiles';
import { useCreateBatchFileMutation, useCreateSystemBatchFileMutation, useExecuteBatchMutation, useUpdateBatchFileMutation } from '../../redux/batchFiles/batchFile.api';
import { BatchFileMsg } from '../../shared/constants/AlertMessages';
import { ALERT_SEVERITY } from '../../shared/constants/constants';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
// import CreateBatchFile from '../BatchFiles/create/CreateBatchFile.testScenarios';
import { useDispatch, useSelector } from 'react-redux';
import { collapseSideMenu, updateCustomerTestRunId } from '../../redux/common/common.slice';
// import ViewTestCaseDetails from '../../components/messages/testcaseDetails';
import TestCaseMessages from '../TestCases/TestCaseMessages';
import { useParams } from 'react-router-dom';
import BatchFile from './BatchFile';
import ViewTestCaseDetails from '../../components/messages/testcaseDetails';
import useModal from '../../shared/hooks/useModal.hook';
import CreateBatchFiles from './CreateBatchFile';
import { DropdownValueContext } from '../../shared/context/DropdownProvider';


const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        height: `calc(100vh - 100px)`,
    }
};

const TestCaseBatch = (props) => {
    const { title, subtitle, messageTypePayload } = props;
    // console.log("messageTypePayload", messageTypePayload);
    const Snackbar = useContext(SnackbarContext);
    const { customer } = useContext(DropdownValueContext);
    const dispatch = useDispatch();
    const { testType: qpTestType } = useParams();
    const { customerId } = useSelector((state) => state?.common);
    const { open: createBatchFileEl, openModal: createBatchFileOpenModal, closeModal: createBatchFileCloseModal } = useModal();
    const [isBatchMode, setIsBatchMode] = useState(true);
    const [batchContextToRun, setBatchContextToRun] = useState([]);
    const [selectedTestScenarios, setSelectedScenarios] = useState({});
    const [displayAllBatches, setDisplayAllBatches] = useState(false);
    const [selectedBatch, setSelectedBatch] = useState({})
    // const [customer, selectedCustomer] = useState();
    const [batchContext, setBatchContext] = useState({
        batchId: null,
        batchType: 'C', //S for system generated,
        open: false,
        isUpdate: false
    });
    const [isTestcaseDetailsVisible, setTestcaseDetailsVisibility] = useState(false);
    const [selectedTestcase, setSelectedTestcase] = useState({});

    const [executeBatch, { isLoading: isexecuteBatchLoading }] = useExecuteBatchMutation();
    const [createCustomerBatchFile] = useCreateBatchFileMutation();
    const [updateBatchFile] = useUpdateBatchFileMutation();
    const [createSystemBatchFile] = useCreateSystemBatchFileMutation();

    const condGridValue = (isTestcaseDetailsVisible || (batchContext.open && batchContext.batchType === 'C')) ? 7 : 12;
    const condGridDirection = (isTestcaseDetailsVisible || (batchContext.open && batchContext.batchType === 'C')) ? "row" : "column";
    const getTotalTestCasesCount = () => {
        let size = 0;
        _.forEach(selectedTestScenarios, (val, key) => {
            if (!val?.isAllTestcasesSelected) {
                size = size + _.size(val?.selectedKeys)
            } else {
                size = size + val?.totalTescaseCount
            }
        })
        testCaseSize = size;
        return size;

    }
    const setBatchselection = (batch) => {
        setSelectedBatch(batch);
        setBatchContext({
            ...batchContext,
            batchType: 'C',
            open: false,
            selectedBatch: {},
            isUpdate: false
        })
    }
    const createSystemBatch = async (batchFileDetails) => {
        batchFileDetails.batchType = 'C';
        const payload = getBatchPayload(batchFileDetails);
        try {
            const res = await createSystemBatchFile({ ...payload });
            Snackbar.displayMsg(BatchFileMsg.CREATE_BATCH_FILE_SUCCESS, ALERT_SEVERITY.SUCCESS);
            setBatchContext({
                ...batchContext,
                open: false
            })
            setSelectedScenarios({});
            setSelectedTestcase({})
        } catch (error) {
            Snackbar.displayMsg(BatchFileMsg.CREATE_BATCH_FAIL, ALERT_SEVERITY.ERROR);
        }
    };

    const handleModalClose = () => {
        setBatchContext({
            ...batchContext,
            batchType: 'C',
            open: false
        })
    }
    const closeBatchModal = () => {
        setBatchContext({
            ...batchContext,
            open: false,
            selectedBatch: null
        })
        dispatch(collapseSideMenu(false))
    }

    const executeSelectedBatch = async () => {
        try {
            const res = await executeBatch({ batchIds: batchContextToRun });
            // console.log("RESS",res);
            if (res?.error?.status === 400) {
                Snackbar.displayMsg(res?.error?.data?.message, ALERT_SEVERITY.ERROR)
            } else {
                Snackbar.displayMsg(BatchFileMsg.EXECUTE_BATCH_SUCCESS, ALERT_SEVERITY.SUCCESS); 
            }  
        } catch (error) {
            Snackbar.displayMsg(BatchFileMsg.EXECUTE_BATCH_FAIL, ALERT_SEVERITY.ERROR);
        }
    }
    const runTestCases = async () => {

        if (isBatchMode) {
            await executeSelectedBatch();
            setBatchContextToRun([]);
            setSelectedBatch({});
            setSelectedScenarios({});
            setSelectedTestcase({});
        } else {
            setBatchContext({
                ...batchContext,
                batchType: 'S',
                open: true
            })
        }
    }
    const onBatchTestDataSelectionChange = (e, testdata, testcase, batch) => {
        const updatedBatches = [...batchContextToRun];
        let batchIndex = _.findIndex(updatedBatches, b => b.batchId === batch?.customerBatchId);
        if (batchIndex < 0) {
            updatedBatches.push({
                batchId: batch?.customerBatchId,
                caseAndDatas: null,
            })
            batchIndex = _.size(updatedBatches) - 1;
        }
        const caseAndDatas = updatedBatches[batchIndex]?.caseAndDatas || [];
        const testcaseIndex = _.findIndex(caseAndDatas, b => b.testCases === testcase?.testCaseId);
        if (e.target.checked) {
            if (testcaseIndex >= 0) {
                caseAndDatas[testcaseIndex]?.testDatas.push(testdata?.testDataId)
            } else {
                caseAndDatas.push({
                    testCases: testcase?.testCaseId,
                    testDatas: [testdata?.testDataId],
                })
            }

        } else {
            if (testcaseIndex >= 0) {
                _.remove(caseAndDatas[testcaseIndex]?.testDatas, b => b === testdata?.testDataId);
            }
        }
        updatedBatches[batchIndex].caseAndDatas = caseAndDatas;
        setBatchContextToRun(updatedBatches)
    }

    const setTestcaseDetailsVisible = (visible) => {
        dispatch(collapseSideMenu(visible))
        setTestcaseDetailsVisibility(visible);
        createBatchFileCloseModal();
        if (isBatchMode) {
            setSelectedTestcase({})
        }
    };

    const onBatchSelectionChange = (checked, batch) => {
        const updatedBatches = [...batchContextToRun];
        if (checked) {
            updatedBatches.push({
                batchId: batch?.customerBatchId,
                caseAndDatas: null,
            })
        } else {
            _.remove(updatedBatches, b => b.batchId === batch?.customerBatchId);
        }
        setBatchContextToRun(updatedBatches);
    }
    const createCustomerBatch = async (batchFileDetails) => {
        // console.log("batchFileDetails", batchFileDetails);
        batchFileDetails.batchType = 'C';
        const payload = getBatchPayload(batchFileDetails);
        try {
            const res = await createCustomerBatchFile({ ...payload });
            Snackbar.displayMsg(BatchFileMsg.CREATE_BATCH_FILE_SUCCESS, ALERT_SEVERITY.SUCCESS);
            setBatchContext({
                ...batchContext,
                open: false,
                batchId: res?.data?.customerbatchId
            })
            setSelectedScenarios({});
            dispatch(collapseSideMenu(false))
        } catch (error) {
            Snackbar.displayMsg(BatchFileMsg.CREATE_BATCH_FAIL, ALERT_SEVERITY.ERROR);
        }
    };
    const updateBatchFileDetails = async (batchFileDetails) => {
        try {
            const res = await updateBatchFile({ ...batchFileDetails, batchId: batchContext?.selectedBatch?.customerBatchId });
            Snackbar.displayMsg(BatchFileMsg.UPDATE_BATCH_FILE_SUCCESS, ALERT_SEVERITY.SUCCESS);
            setBatchContext({
                ...batchContext,
                open: false,
            })
            dispatch(collapseSideMenu(false))
        } catch (error) {
            Snackbar.displayMsg(BatchFileMsg.UPDATE_BATCH_FAIL, ALERT_SEVERITY.ERROR);
        }
    };
    const handleBatchPreviewClick = (previewEl, batch) => {
        setTestcaseDetailsVisibility(false);
        dispatch(collapseSideMenu(false));
        setBatchContext({
            ...batchContext,
            batchType: 'C',
            open: previewEl,
            selectedBatch: batch,
            isUpdate: true
        })
        // createBatchFileOpenModal();
    };
    const openBatchTestcaseDetails = (e, testcase) => {
        // console.log("TestBatch", testcase);
        setBatchContext({
            ...batchContext,
            open: false
        })
        dispatch(collapseSideMenu(true));
        dispatch(updateCustomerTestRunId(testcase.customerTestRunId))
        setSelectedTestcase({ ...testcase, customerTestCaseId: testcase.testCaseId });
        setTestcaseDetailsVisibility(true);
    }
    const handleSwitchToggle = (xEvent) => {
        setIsBatchMode(xEvent.target.checked);
        setSelectedBatch({});
        setSelectedScenarios({});
    };
    const onTestCaseCustomerSelection = (event) => {
        selectedCustomer(event);
    }
    return (
        <Box sx={styles.container}>
            {/* { isLoading &&  <PayaptLoader sx={{ position:"absolute",left:"55%" }} /> } */}
            {isexecuteBatchLoading && <PayaptLoader sx={{ position: "absolute", left: "55%" }} />}
            <Grid container direction={condGridDirection}>
                <Grid item xs={condGridValue} md={condGridValue}>
                    <TestCaseHeader
                        title={title}
                        subtitle={subtitle}
                        showRun={{
                            show: true,
                            disabled: isBatchMode ? _.isEmpty(batchContextToRun) : _.isEmpty(selectedTestScenarios) || !getTotalTestCasesCount(),
                            onClick: runTestCases
                        }}
                        // showCreate={{
                        //     show: !isBatchMode,
                        //     onClick: handleCreateClick,
                        //     disabled: _.isEmpty(selectedTestScenarios) && !getTotalTestCasesCount()
                        // }}
                        // showSearch={{
                        //     show: !isBatchMode,
                        //     value: searchTerm,
                        //     onChange: handleSearchOnChange
                        // }}
                        showTestCustomer={{
                            show: true,
                            // onChange: onTestCaseCustomerSelection,
                            // value: customer
                        }}
                    >
                        <Box sx={{ display: "flex", }}>
                            {/* <XTwoWayLabelSwitch
                        primary={"Test Cases"} secondary={"Batch Files"} checked={isBatchMode} onChange={handleSwitchToggle}
                    />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                            {isBatchMode &&
                                <XTwoWayLabelSwitch
                                    primary={"My Batches"} secondary={"All Batches"} checked={displayAllBatches} onChange={(e) => {
                                        setDisplayAllBatches(e.target.checked);
                                        setBatchContextToRun([]);
                                    }}
                                />
                            }
                        </Box>
                    </TestCaseHeader>
                    {/* <Typography>Hello</Typography> */}
                    {!isBatchMode &&
                        <Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', }} className='standalone_table'>
                                <Typography sx={{ textAlign: 'left', fontSize: 14 }}>Total test cases selected: <b>{getTotalTestCasesCount()}</b></Typography>
                            </Box>
                            <TestCaseMessages
                                selectedTestScenarios={selectedTestScenarios}
                                setSelectedScenarios={setSelectedScenarios}
                                isTestcaseDetailsVisible={isTestcaseDetailsVisible}
                                setTestcaseDetailsVisibility={setTestcaseDetailsVisibility}
                                selectedTestcase={selectedTestcase}
                                setSelectedTestcase={setSelectedTestcase}
                                // searchTerm={searchTerm}
                                customer={customer ? customer : customerId}
                                messageTypePayload={messageTypePayload}
                            />
                        </Box>
                    }
                    {isBatchMode &&
                        <Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', }} className='standalone_table'>
                                <Typography sx={{ textAlign: 'left', fontSize: 14 }}>Selected Batch Files: <b>{_.size(batchContextToRun)}</b></Typography>
                            </Box>

                            <BatchFile
                                selectedBatch={selectedBatch}
                                setSelectedBatch={setBatchselection}
                                onPreview={handleBatchPreviewClick}
                                openBatchTestcaseDetails={openBatchTestcaseDetails}
                                type={qpTestType}
                                onBatchSelectionChange={onBatchSelectionChange}
                                onBatchTestDataSelectionChange={onBatchTestDataSelectionChange}
                                batchContextToRun={batchContextToRun}
                                displayAllBatches={displayAllBatches}
                                customer={customer ? customer : customerId}
                            />
                        </Box>
                    }
                </Grid>
                {batchContext.batchType === 'C' && batchContext.open && <Grid item xs={5} sx={{ borderLeft: "1px solid #808080" }}>
                    <CreateBatchFiles
                        onCancel={closeBatchModal}
                        selectedTestScenarios={selectedTestScenarios}
                        createBatch={isBatchMode ? updateBatchFileDetails : createCustomerBatch}
                        selectedBatch={batchContext?.selectedBatch}
                        isUpdateScenario={batchContext?.isUpdate}
                    // batchName={batchName}
                    />
                </Grid>}
                {isTestcaseDetailsVisible && (
                    <Grid item xs={5}>
                        <ViewTestCaseDetails
                            testcase={selectedTestcase}
                            onClose={setTestcaseDetailsVisible}
                            isBatchMode={isBatchMode}
                        />
                    </Grid>
                )}
            </Grid>
            {batchContext.open && batchContext.batchType === 'S' && <ModalComponent
                visible={batchContext.open && batchContext.batchType === 'S'}
                title={`Multiple Test Cases Were Selected \n
                    Batch File created, you can rename it below`}
                ButtonLabel="Submit"
                createBatch={createSystemBatch}
                onClose={handleModalClose}
            />}
        </Box>
    )
}

export default TestCaseBatch;
