import React, { useState } from 'react';
import SchemaEditor from './schemaEditor';
import ValidationResponse from './validationResponse';
import './styles/editorStyles.css'
import OutputFiles from './outputFiles';
import { getFiles, sendToMock1, validateXML as validatexml, validateBusiness as validatebusiness } from '../actions/schemaValidator';
import _, { delay } from 'lodash'
import { SUCCESS_MSG } from '../constants/constants';
import PageHeader from './pageHeader';

const SchemaValidator = () => {
    const [validationResponse, setValidationResponse ] = useState({})
    // const [sendToMock, setSendToMock] = useState(false);
    const [isSendToMockEnabled, enableSendToMock ] = useState(false);
    const [files, setFiles] = useState([]);
    const [filesLoading, setFilesLoading] = useState(false);
    const [isXmlValidated, setXmlValidated ] = useState(false);

    const validateXML = async (text) => {
        console.log(text);
        const res = await validatexml(text);
        setValidationResponse({
            ...validationResponse,
            schemaValidationResponse: {
                status: res.isvalid ? 200 : 400,
                message: [res.isvalid ? 'Schema validated successfully.': res.message],
            }
        })
        if(isXmlValidated !== res.isValid){
            setXmlValidated(res.isvalid)
        }
        
    }

    const validateBusiness = async (text) => {
        console.log(text);
        const res = await validatebusiness(text);
        const isValid = res && _.size(res) >= 1 && _.isEqual(_.toLower(res[0].Status), SUCCESS_MSG);
        setValidationResponse({
            ...validationResponse,
            businessValidationResponse: {
                status: isValid? 200 : 400,
                message: _.map(res, r => r.Message),
                path: _.map(res, r => r.path),
            }
        })
        enableSendToMock(isValid)
    }

    const getFileList = async () => {
        const fileList = await getFiles();
        setFiles([...fileList])
        setFilesLoading(false);
    }
    const sendToMock = async (text) => {
        await sendToMock1(text);
        setFilesLoading(true);
        delay(() => getFileList(), 20000);
    }
    
    return (
        <div className='schema-validator-container'>
            <PageHeader pageTitle='Customer Credit Transfer Outbound Flow'/>
            {/* <hr className='line-breaks'/> */}
            <div className='schema-editor-container'>
                <SchemaEditor 
                    validateXML = {validateXML} 
                    sendToMock={sendToMock} 
                    isSendToMockEnabled={isSendToMockEnabled} 
                    enableSendToMock={enableSendToMock}
                    isXmlValidated = {isXmlValidated}
                    validateBusiness = {validateBusiness}
                    setXmlValidated = {setXmlValidated}
                />
            </div>
            <div className='validation-container'>
                    <ValidationResponse validationResponse={validationResponse.schemaValidationResponse} responseKey="schemaValidationResponse"/>
                    <ValidationResponse validationResponse={validationResponse.businessValidationResponse } responseKey="businessValidationResponse"/>
                    {
                        sendToMock && <OutputFiles files={files} filesLoading={filesLoading}/>
                    }
                    
            </div>
        </div>
    )
}

export default SchemaValidator;