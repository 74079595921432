import { backendApi } from "../base.config";

export const uploadTestCase = backendApi.injectEndpoints({
    // tagTypes: ['BATCH_TEST_RUN_LIST'],
    endpoints: (builder) => ({
        uploadTestCaseData: builder.mutation({
            query: (formData) => ({
                url:`Simulator/uploadTestCaseData`,
                method: 'POST',
                body: formData,
            }),
            // providesTags: ['BATCH_TEST_RUN_LIST']
        }),
        getSelectDirectionList: builder.query({
            query: () => ({
                method:"GET",
                url:`TestCaseUpload/selectDirection`,
            })
        }),
        getMasterTestCaseList: builder.query({
            query:(args)=>({
                // method:"GET",optional
                url:`customerTestcase/getAllCusTestCasebasedonMaster`,
                params: {...args},
                refetchOnMountOrArgChange: true
            })
        }),
        getMessageListForMaster: builder.mutation({
            query: (payload) => ({
                method:"POST",
                url:`customerTestcase/getAllCTCbasedonMasterdropdown`,
                body:payload
            })
        }),
        uploadMessageType: builder.query({
            query: () => ({
                method:"GET",
                url:`TestCaseUpload/getAllMessagesForUploadScreen`
            })
        }),
        saveTestcaseforCustomer: builder.mutation({
            query: (payload) => ({
                method:"POST",
                url:`Simulator/SaveTestcaseforCustomer`,
                body:payload
            })
        }),
        masterTestCaseList: builder.mutation({
            query: (payload) => ({
                method:"POST",
                url:`customerTestcase/getAllCusTestCasebasedonMaster`,
                params:{...payload},
                
            })
        }),
        selectAPICallForMaster: builder.mutation({
            query:(payload) => ({
                url:`TestCaseUpload/selectAPICallForMaster`,
                method:"POST",
                body:payload
            })
        })
    }),
});

export const {
   useUploadTestCaseDataMutation,
   useGetSelectDirectionListQuery,
   useGetMasterTestCaseListQuery,
   useGetMessageListForMasterMutation,
   useUploadMessageTypeQuery,
   useSaveTestcaseforCustomerMutation,
   useMasterTestCaseListMutation,
   useSelectAPICallForMasterMutation,
} = uploadTestCase;