import { Box, Grid, TextField, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import TestCaseHeader from '../testCaseHeader';
import moment from 'moment';
import ReportDropdown from './ReportDropdown';
import DateFromToPicker from '../Dashboard/DateRangePicker';
import MUIButton from '../../shared/components/MUIButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Input, Modal, Pagination } from 'antd';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {
	useAccountReportResponseMutation,
	useReportRequestGenerationMutation,
} from '../../redux/Reports/reports.api';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
import { ALERT_SEVERITY } from '../../shared/constants/constants';
import { useEffect } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useSelector } from 'react-redux';
import { DropdownValueContext } from '../../shared/context/DropdownProvider';
import RtnDropdown from './RtnDropdown';
import PayaptLoader from '../../shared/components/Spinner';
import * as yup from 'yup';
import FlowTypeDropdown from './FlowTypeDropdown';

const validationSchema = yup.object({
	from: yup
		.number()
		.typeError('Must be a number')
		.min(1, 'Minimum value is 000001')
		.max(999999, 'Maximum value is 999999')
		.required('From is required'),
	to: yup
		.number()
		.typeError('Must be a number')
		.min(1, 'Minimum value is 000001')
		.max(999999, 'Maximum value is 999999')
		.required('To is required'),
});

const Reports = () => {
	const { TextArea } = Input;
	const Snackbar = useContext(SnackbarContext);
	const { customerId } = useSelector((state) => state?.common);
	const { userRole } = useSelector((state) => state?.common?.userDetails);
	const { customer, value } = useContext(DropdownValueContext);

	const [dateForReport, setDateForReport] = useState(moment());
	const [isDateSelected, setDateSelected] = useState(false);
	const onChangeDateFrom = (event) => {
		setDateForReport(event);
		if (event && event[0] && event[1]) {
			setDateSelected(true);
		} else {
			setDateSelected(false);
		}
	};
	const [reportType, setReportType] = useState('');
	const [reportDrop, setReportDrop] = useState(null);
	const [accReportRequestId, setAccReportRequestId] = useState();
	const [valuee, setValue] = useState([]);
	const [rtnNum, setRtnNum] = useState('');
	const [isModalVisible, setModalVisibility] = useState(false);
	const [reportRequestGeneration] = useReportRequestGenerationMutation();
	const [reportResponse, { isLoading, isSuccess }] = useAccountReportResponseMutation();
	const [isLoadingg, setIsLoading] = useState(false);
	const [formData, setFormData] = useState({ from: '', to: '' });
	const [errors, setErrors] = useState({ from: '', to: '' });
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [flowMsg, setFlowMsg] = useState();

	const validateForm = async () => {
		try {
			await validationSchema.validate(formData, { abortEarly: false });
			setErrors({ from: '', to: '' });
			return true; // Validation successful
		} catch (err) {
			const errorObj = err.inner?.reduce((acc, currErr) => {
				acc[currErr.path] = currErr.message;
				return acc;
			}, {});
			setErrors(errorObj);
			return false; // Validation failed
		}
	};

	// const handleChange = (e) => {
	// 	const { name, value } = e.target;
	// 	setFormData((prevData) => ({
	// 		...prevData,
	// 		[name]: value,
	// 	}));
	// };

	const handleChange = (e) => {
		const { name, value } = e.target;

		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));

		validationSchema
			.validateAt(name, { [name]: value })
			.then(() => {
				setErrors((prevErrors) => ({
					...prevErrors,
					[name]: '', // Clear the specific field's error
				}));
			})
			.catch(() => { });
	};

	const handleReportChange = (event) => {
		setReportType(event.target.value);
	};
	const onReportSelection = (event) => {
		setReportDrop(event);
		// setDateSelected(false);
	};

	const reportPayload = {
		fromDate:
			'AADR' === reportDrop ||
				'AATR' === reportDrop ||
				'CADR' === reportDrop ||
				'CATR' === reportDrop
				? dateForReport && dateForReport[0]
					? moment(new Date(dateForReport[0])).format()
					: null
				: null,
		toDate:
			'AADR' === reportDrop ||
				'AATR' === reportDrop ||
				'CADR' === reportDrop ||
				'CATR' === reportDrop
				? dateForReport && dateForReport[1]
					? moment(new Date(dateForReport[1])).format()
					: null
				: null,
		reportType: reportDrop,
		customerId: userRole?.name === 'Admin' || userRole?.name === 'Tester' ? customerId : customer,
		routingNumber:
			'AADR' === reportDrop ||
				'AATR' === reportDrop ||
				'CADR' === reportDrop ||
				'CATR' === reportDrop ||
				3 === value
				? rtnNum
				: null,
		paymentServiceId: value,
		fromSequenceNumber: 'DTLS' === reportDrop || 'DTLR' === reportDrop ? formData?.from : null,
		toSequenceNumber: 'DTLS' === reportDrop || 'DTLR' === reportDrop ? formData?.to : null,
		"flowType": flowMsg || null,
	};
	const responsePayload = {
		customerAccReportRequestId: accReportRequestId,
	};

	useEffect(() => {
		if (isModalVisible !== false && accReportRequestId !== 'undefined') {
			reportResponse(responsePayload).then((res) => {
				setValue(res?.data?.data);
			});
		}
	}, [accReportRequestId, isModalVisible]);

	useEffect(() => {
		if (valuee?.length > 0) {
			setIsLoading(false);
		} else {
			setIsLoading(true);
		}
	}, [valuee]);

	const onFlowSelect = (event) => {
        setFlowMsg(event);
    };

	// const handleGenerate = async (isVisible) => {
	// 	if (reportDrop === undefined) {
	// 		Snackbar.displayMsg('Please Select Report Type', ALERT_SEVERITY.ERROR);
	// 		return; // Stop further execution
	// 	}
	// 	if (
	// 		('AADR' === reportDrop ||
	// 			'AATR' === reportDrop ||
	// 			'CADR' === reportDrop ||
	// 			'CATR' === reportDrop) &&
	// 		!isDateSelected
	// 	) {
	// 		Snackbar.displayMsg('Please Select Start & End date', ALERT_SEVERITY.ERROR);
	// 		return;
	// 	} else {
	// 		await reportRequestGeneration(reportPayload).then((res) => {
	// 			// console.log("Res", res);
	// 			if (res?.data?.status === 'success') {
	// 				Snackbar.displayMsg(
	// 					'Account Report Request Generated Successfully',
	// 					ALERT_SEVERITY.SUCCESS
	// 				);
	// 				setAccReportRequestId(res?.data?.customerAccReportRequestId);
	// 				setTimeout(() => {
	// 					setModalVisibility(isVisible);
	// 				}, 5000);
	// 				//    setTimeout(()=>setModalVisibility(!isVisible),2500);
	// 				//    setModalVisibility(isVisible);
	// 			} else {
	// 				Snackbar.displayMsg('Failed to generated account report request', ALERT_SEVERITY.ERROR);
	// 			}
	// 		});
	// 	}
	// };

	const handleGenerate = async (isVisible) => {
		setIsSubmitted(true); // Mark as submitted to show errors

		if (reportDrop === undefined) {
			Snackbar.displayMsg('Please Select Report Type', ALERT_SEVERITY.ERROR);
			return;
		}

		if (['AADR', 'AATR', 'CADR', 'CATR'].includes(reportDrop) && !isDateSelected) {
			Snackbar.displayMsg('Please Select Start & End date', ALERT_SEVERITY.ERROR);
			return;
		}

		const isValid = await validateForm();
		if ((reportDrop === 'DTLR' || reportDrop === 'DTLS') && !isValid) {
			return; // Stop further execution if validation fails
		}

		// Proceed with report generation
		try {
			const res = await reportRequestGeneration(reportPayload);
			if (res?.data?.status === 'success') {
				Snackbar.displayMsg(
					'Account Report Request Generated Successfully',
					ALERT_SEVERITY.SUCCESS
				);
				setAccReportRequestId(res?.data?.customerAccReportRequestId);
				setTimeout(() => {
					setModalVisibility(isVisible);
					setFormData({ from: '', to: '' });
				}, 5000);
				setIsSubmitted(false);
			} else {
				Snackbar.displayMsg('Failed to generate account report request', ALERT_SEVERITY.ERROR);
			}
		} catch (error) {
			Snackbar.displayMsg('An error occurred. Please try again.', ALERT_SEVERITY.ERROR);
			console.error('API Error:', error);
		}
	};

	// const disabledDate = (current) => {
	//     //   return current && current > moment().endOf('day');
	//     const today = moment().endOf('day');
	//     const sevenDaysAgo = today.clone().subtract(91, 'days');
	//     return current && (current > today || current < sevenDaysAgo);
	// };

	const disabledDate = (current) => {
		const today = moment().startOf('day'); // Use startOf('day') to avoid including today
		const sevenDaysAgo = today.clone().subtract(90, 'days');
		return current && (current >= today || current < sevenDaysAgo);
	};

	const [currentPage, setCurrentPage] = useState(1); // Track the current page
	const itemsPerPage = 1; // Define the number of items per page
	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;
	const changePage = (page) => {
		setCurrentPage(page);
	};
	const xmlData = valuee[startIndex]?.response;

	const onCancel = () => {
		setModalVisibility(false);
		setReportDrop();
		setDateSelected(false);
		setValue([]);
	};

	const downloadFile = () => {
		if (valuee?.length > 0) {
			const element = document.createElement('a');
			const file = new Blob([xmlData], { type: 'text/plain' });
			element.href = URL.createObjectURL(file);
			element.download = `${reportDrop}`;
			document.body.appendChild(element);
			element.click();
			setModalVisibility(true);
		} else {
			Snackbar.displayMsg('No data to download', ALERT_SEVERITY.ERROR);
		}
	};

	const handleRefreshButton = () => {
		reportResponse(responsePayload).then((res) => {
			setValue(res?.data?.data);
			// setModalVisibility(true);
		});
	};

	const onRTNSelection = (e) => {
		setRtnNum(e);
	};

	return (
		<Box sx={{ overflowY: 'auto', height: `calc(100vh - 100px)`, paddingBottom: '30px' }}>
			<TestCaseHeader
				title="Account Reporting Request Camt.060"
				showTestCustomer={{
					show: true,
				}}
				showCustomer={{ show: false }}
			/>

			{/* <Box mt={"2%"} sx={{ px: '2.1%' }}>
                <Box>
                    <Grid container>
                        <Grid item width={"40%"} mt={1}>
                            <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "17px" }}>
                                Select Report Type
                            </Typography>
                        </Grid>
                        <Grid item>
                            <FormControl component="fieldset">
                                <RadioGroup row aria-label="payment-method" name="payment-method" value={reportType} onChange={handleReportChange}>
                                    <FormControlLabel value="EOD Report" control={<Radio />} label="EOD Report" />
                                    <FormControlLabel value="Account report request" control={<Radio />} label="Account report request" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </Box> */}

			{/* {"Account report request" === reportType && */}
			<Box sx={{ px: '2.1%' }}>
				<Box>
					<Grid container>
						<Grid item width={'40%'} mt={1}>
							<Typography
								sx={{
									display: 'flex',
									fontFamily: 'Inter',
									fontStyle: 'normal',
									fontWeight: 'bold',
									fontSize: '17px',
								}}
							>
								Select Report Type
							</Typography>
						</Grid>
						<Grid item mt={3}>
							<ReportDropdown
								onChangeReport={onReportSelection}
								value={reportDrop}
								paymentServiceId={value}
							/>
						</Grid>
					</Grid>
				</Box>
			</Box>
			{/* // } */}

			{('AADR' === reportDrop ||
				'AATR' === reportDrop ||
				'CADR' === reportDrop ||
				'CATR' === reportDrop) && (
					<Box mt={'2%'} sx={{ px: '2.1%' }}>
						<Box>
							<Grid container>
								<Grid item width={'40%'} mt={1}>
									<Typography
										sx={{
											display: 'flex',
											fontFamily: 'Inter',
											fontStyle: 'normal',
											fontWeight: 'bold',
											fontSize: '17px',
										}}
									>
										Select Date
									</Typography>
								</Grid>
								<Grid item>
									<DateFromToPicker
										onChangeDateFrom={onChangeDateFrom}
										value={dateForReport}
										disabledDate={disabledDate}
									/>
								</Grid>
							</Grid>
						</Box>

						<Box mt={'2%'}>
							<Box>
								<Grid container>
									<Grid item width={'40%'} mt={1}>
										<Typography
											sx={{
												display: 'flex',
												fontFamily: 'Inter',
												fontStyle: 'normal',
												fontWeight: 'bold',
												fontSize: '17px',
											}}
										>
											Select Routing Number
										</Typography>
									</Grid>
									<Grid item mt={3}>
										<RtnDropdown
											onChangeRtn={onRTNSelection}
											value={rtnNum}
											customer={
												userRole?.name === 'Admin' || userRole?.name === 'Tester'
													? customerId
													: customer
											}
											paymentValue={value}
											reportName={reportDrop === 'CADR' || reportDrop === 'CATR' ? reportDrop : null}
										/>
									</Grid>
								</Grid>
							</Box>
						</Box>
					</Box>
				)}

			{('DTLS' === reportDrop || 'DTLR' === reportDrop) && (
				<Box mt={'2%'} sx={{ px: '2.1%' }}>
					<Box>
						<Grid container>
							<Grid item width={'40%'} mt={1}>
								<Typography
									sx={{
										display: 'flex',
										fontFamily: 'Inter',
										fontStyle: 'normal',
										fontWeight: 'bold',
										fontSize: '17px',
									}}
								>
									Enter Sequence Number
								</Typography>
							</Grid>
							<Grid item xs={5} sm={4} sx={{ display: 'flex', gap: '20px' }}>
								<TextField
									fullWidth
									id="from"
									name="from"
									label="From"
									autoComplete="off"
									value={formData.from}
									onChange={handleChange}
									error={isSubmitted && Boolean(errors.from)}
									helperText={isSubmitted ? errors.from : ''}
								/>
								<TextField
									fullWidth
									id="to"
									name="to"
									label="To"
									autoComplete="off"
									value={formData.to}
									onChange={handleChange}
									error={isSubmitted && Boolean(errors.to)}
									helperText={isSubmitted ? errors.to : ''}
								/>
							</Grid>
						</Grid>
					</Box>
				</Box>
			)}
			{3 === value && (
				<>
				   {'ABAR' === reportDrop && (
					<Box mt={"2%"} sx={{ px: '2.1%' }}>
						<Box>
							<Grid container>
								<Grid item width={"40%"} mt={1}>
									<Typography
										sx={{
											display: "flex",
											fontFamily: 'Inter',
											fontStyle: 'normal',
											fontWeight: "bold",
											fontSize: "17px",
										}}
									>
										Select Flow Type
									</Typography>
								</Grid>
								<Grid item mt={3} width={"25%"}>
									<FlowTypeDropdown flowType={flowMsg} onChangeFlow={onFlowSelect} />
								</Grid>
							</Grid>
						</Box>
					</Box>
					)}
					<Box mt={'2%'} sx={{ px: '2.1%' }}>
						<Box>
							<Grid container>
								<Grid item width={'40%'} mt={1}>
									<Typography
										sx={{
											display: 'flex',
											fontFamily: 'Inter',
											fontStyle: 'normal',
											fontWeight: 'bold',
											fontSize: '17px',
										}}
									>
										Select Routing Number
									</Typography>
								</Grid>
								<Grid item mt={3}>
									<RtnDropdown
										onChangeRtn={onRTNSelection}
										value={rtnNum}
										customer={
											userRole?.name === 'Admin' || userRole?.name === 'Tester'
												? customerId
												: customer
										}
										paymentValue={value}
										reportName={reportDrop}
									/>
								</Grid>
							</Grid>
						</Box>
					</Box>
				</>
			)}

			{/* <Box sx={{ px: '2.1%' }}>
                <Box>
                    <Grid container>
                        <Grid item width={"40%"} mt={1}>
                            <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "17px" }}>
                                Select Routing Number
                            </Typography>
                        </Grid>
                        <Grid item mt={3}>
                            <ReportDropdown onChangeReport={onReportSelection} value={reportDrop} />
                        </Grid>
                    </Grid>
                </Box>

            </Box> */}
			<Box mt={'23%'} sx={{ display: 'flex', px: '2.1%' }}>
				<MUIButton
					startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
					title="Generate"
					onClick={handleGenerate}
				/>
			</Box>

			<Modal
				onCancel={onCancel}
				open={isModalVisible}
				footer={[]}
				closeIcon={<CancelOutlinedIcon style={{ color: '#001A11', backgroundColor: 'white' }} />}
				destroyOnClose
				width={'70%'}
				maskClosable={false}
			>
				<Box>
					<TestCaseHeader
						title={`Camt.052-${reportDrop}`}
						showFileUpload={{
							show: true,
							customIcon: <FileDownloadIcon />,
							onClick: () => downloadFile(),
						}}
						showRefresh={{
							show: false,
							onClick: handleRefreshButton,
						}}
					/>
					{/* <WrapperComponent isSuccess={isSuccess} isFetching={isLoading}> */}
					<div style={{ marginTop: 1 }}>
						{isLoadingg && <PayaptLoader />}
						<TextArea
							className="xml-txt-area"
							rows={15}
							readOnly
							style={{ height: '100%' }}
							value={valuee?.length > 0 ? valuee[startIndex]?.response : 'NO DATA'} // Display the response based on the current page
						/>

						<div
							style={{
								marginTop: 4,
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'flex-end',
							}}
						>
							{/* {("AADR" === reportDrop || "AATR" === reportDrop || "CADR" === reportDrop || "CATR" === reportDrop || "IATR" === reportDrop) && ( */}
							<Pagination
								current={currentPage}
								pageSize={itemsPerPage}
								total={valuee?.length}
								onChange={changePage}
								hideOnSinglePage={true}
							/>
							{/* )} */}
						</div>
					</div>
					{/* </WrapperComponent> */}
				</Box>
			</Modal>
		</Box>
	);
};

export default Reports;
