import React, { useState } from 'react';
import { ReactNestedMenu } from 'react-nested-menu';
import { onboardingMenus } from '../constants/constants';
import { Link } from 'react-router-dom'
import { Menu, ConfigProvider } from 'antd';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import './styles/menu.css'


const SideMenu = () => {
    const navigate = useNavigate();
    const [selectedKeys, setSelectedKeys] = useState(['simulator'])
    const onClick = (key, url) => {
        // return navigate(url)
        setSelectedKeys([key]);
    };
    const getMenuItem = (menu) => {
        if (_.isEmpty(menu.children)) {
            return <Menu.Item onClick={() => onClick(menu.url)}>{menu.title}</Menu.Item>
        }
        return (
            <Menu.SubMenu title={menu.title} style = {{ backgroundColor: '#ffffff'}}>
                {
                    _.map(menu.children, submenu => (
                        <Menu.Item>{submenu.title}</Menu.Item>
                    ))
                }
            </Menu.SubMenu>
        )
    }
    return (
        <ConfigProvider
            theme={{
                // token: {
                //     colorFillContent: '#009966'
                //     // '#00b96b',
                // },
                components: {
                    Menu: {
                        colorItemBgHover: '#00b96b',
                        colorItemBgSelected: '#009966',
                        colorItemTextSelected: '#ffffff',
                        colorItemTextHover: '#ffffff'
                    },
                }
            }}
        >
            <Menu
                onClick={onClick}
                style={{ width: 256 }}
                defaultSelectedKeys={['1']}
                // defaultOpenKeys={['sub1']}
                mode="inline"
            //   items={items}
            >
                {
                    _.map(onboardingMenus, menu => getMenuItem(menu))
                }
            </Menu>
        </ConfigProvider>
    )
}

export default SideMenu;
