import { createTheme } from '@mui/material';
import { colors } from './colors';

export const theme = createTheme({
    palette: colors,
    components: {
        MuiSwitch: {
            styleOverrides: {
                root: {
                    width: 35,
                    height: 19,
                    padding: 0,
                },
                switchBase: {
                    padding: 0,
                    margin: 2,
                    '&.Mui-checked': {
                        transform: 'translateX(16px)',
                        '& + .MuiSwitch-track': {
                            opacity: 1,
                            border: 0,
                        },
                    },
                },
                thumb: {
                    boxSizing: 'border-box',
                    width: 15,
                    height: 15,
                    backgroundColor: '#fff',
                },
                track: {
                    borderRadius: 19 / 2,
                    border: 0,
                    opacity: 0.15,
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                asterisk: {
                    color: "#d32f2f"
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: 16,
                    fontFamily: "Inter" ,
                }
            }
        }
    },
});
