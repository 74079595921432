import { LeftCircleFilled, LoginOutlined } from '@ant-design/icons';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { messageTemplateList } from '../../components/messages/constants';
import MUIButton from '../../shared/components/MUIButton';
import TestCaseHeader from '../testCaseHeader';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    textAlign: "left",
    margin: '0px 20px'
  },
  card: {
    marginBottom: '35px',
    marginLeft: '25px',
    marginRight: '25px',
  },
  cardHeader: {
    backgroundColor: '#F0F2F5',
    margin: 0,
    paddingLeft: 0,
    borderBottom: '2px solid #006600'
  },
  muibackBotton: {
    backgroundColor: '#F0F2F5',
    color: '#808080',
    marginLeft: "25px",
    marginRight: "25px"
  },
  moreVertIcon: {
    backgroundColor: 'white',
    padding: '6px',
    border: "1px solid #C6CBD1"
  }
};



const MessageTemplates = () => {
  const templateData = messageTemplateList;
  const navigate = useNavigate();

  const onNext = () => {
    navigate('/message/incomingTemplate')
  }

  const onBack = () => {
    navigate(-1)
  }

  return (

    <Box sx={styles.container}>
      <TestCaseHeader
        title={'Messages - Choose Template'}
        subtitle={'Some details related to XML Message List goes here'}
        showSearch={{
          show: true,
        }}
      >
      </TestCaseHeader>
      {
        templateData?.map((value, index) => (
          <>
            <Box sx={styles.card} key={index} >
              <Card>
                <CardHeader sx={styles.cardHeader}
                  title={<><strong className='cardTitle1'>{value.title}</strong></>}
                  action={
                    <>
                    <div onClick={onNext} className='headerAction'>
                    <span >
                    <IconButton aria-label="settings" sx={{transform: 'rotate(130deg)'}}>
                        <LoginOutlined  sx={{fontSize:"15px"}}/>
                    </IconButton>
                    </span>
                    &nbsp; &nbsp;
                    <span >
                    <strong sx={{fontSize:"15px"}}>Incoming</strong>
                    </span>
                    &nbsp; &nbsp;
                    <span >
                    <IconButton aria-label="settings" sx={styles.moreVertIcon}>
                        <MoreVertIcon sx={{fontSize:"15px"}}/>
                    </IconButton>
                    </span>
                    </div>
                    </>
                }
                />
                < CardContent >
                  <Typography>
                    <strong>Template</strong>
                    {
                      value.templates?.map((data, index) => (
                        <>
                          <span key={index}>
                            <span style={{ paddingLeft: "10px" }} >{data?.template}</span>
                          </span>

                        </>
                      ))
                    }

                  </Typography>
                  <Typography mt={2}>
                    <strong>Creates</strong><span style={{ paddingLeft: "20px" }} >{value.create}</span>
                  </Typography>
                  </CardContent>
              </Card >
            </Box >
          </>
        ))
      }
      <Box>
        <MUIButton
          startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
          title="Back"
          sx={styles.muibackBotton}
          color="inherit"
          onClick={onBack}
        >
        </MUIButton>
      </Box>
    </Box >
  )
}

export default MessageTemplates;
