import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import TestCaseHeader from '../testCaseHeader';

const EODReports = () => {
    return (
        <Box sx={{ overflowY: 'auto', height: `calc(100vh - 100px)`, paddingBottom: "30px" }}>
            <TestCaseHeader
                title="End of the day Reports"
            />

            <Box mt={"2%"} sx={{ px: '2.1%' }}>
                <Box>
                    <Grid container>
                        <Grid item width={"40%"} mt={1}>
                            <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "17px" }}>
                                Select Report Type
                            </Typography>
                        </Grid>
                        <Grid item>
                            {/* <FormControl component="fieldset">
                                <RadioGroup row aria-label="payment-method" name="payment-method" value={reportType} onChange={handleReportChange}>
                                    <FormControlLabel value="EOD Report" control={<Radio />} label="EOD Report" />
                                    <FormControlLabel value="Account report request" control={<Radio />} label="Account report request" />
                                </RadioGroup>
                            </FormControl> */}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}

export default EODReports;
