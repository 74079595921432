import { Box } from '@mui/material';
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import React from 'react';
import AccountInfoData from '../onboarding/accountInfoData';
import RoutingNumber from './RoutingNumber';

const AccountInfoContainer = () => {
    const [value, setValue] = React.useState("1");
    const handleChange = (event, newValue) => { setValue(newValue); };
    return (
        <Box sx={{ overflowY: 'auto', height: `calc(100vh - 100px)`, }}>
            <TabContext value={value}>
                <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                // sx={{ borderBottom: "1px solid #DBE5CE" }}
                >
                    <Tab
                        label="Organization Details"
                        value="1"
                        sx={{
                            backgroundColor: "#F5F5F5",
                            borderRadius: "5px 5px 0px 0px",
                            borderTop: "1px solid #DBE5CE",
                            borderRight: "1px solid #DBE5CE",
                            borderLeft: "1px solid #DBE5CE",
                            textTransform:"none",
                            color:"black",
                            m: 1,
                            mb: 0,
                        }}
                        // onClick={() => { GetAccountData("accountHeadType=Mature") }}
                    />
                    <Tab
                        label="Routing Number"
                        value="2"
                        sx={{
                            backgroundColor: "#F5F5F5",
                            borderRadius: "5px 5px 0px 0px",
                            borderTop: "1px solid #DBE5CE",
                            borderRight: "1px solid #DBE5CE",
                            borderLeft: "1px solid #DBE5CE",
                            textTransform:"none",
                            color:"black",
                            m: 1,
                            mb: 0,
                        }}
                        // onClick={() => { GetAccountData("accountHeadType=Mature") }}
                    />
                </TabList>
                <TabPanel value="1">{<AccountInfoData/>}</TabPanel>
                <TabPanel value="2">{<RoutingNumber/>}</TabPanel>
            </TabContext>
        </Box>
    )
}

export default AccountInfoContainer
