import React, { useState } from 'react'
import { Box, Typography } from '@mui/material';
import PayaptTable from '../../shared/components/Table';
import TestCaseHeader from "../testCaseHeader";
import WrapperComponent from '../../shared/components/wrapperComponent';
import _ from 'lodash';
import { Table } from 'antd';
import { EyeIcon, EyeIconReviewed } from "../../constants/menuIcons";
import { simulatorTestData_columns } from '../../constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useGetSimulatorTestDataListMutation } from '../../redux/settings/settings.api';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTE_KEYS } from '../../shared/constants/constants';
import { updateSimulatorData } from '../../redux/common/common.slice';
import { convertToLocalTime } from '../../shared/utils';
import MUIButton from '../../shared/components/MUIButton';
import { LeftCircleFilled } from '@ant-design/icons';


const SimulatorTestDataNewTable = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userRole } = useSelector((state) => state?.common?.userDetails);

  const [inboundOrOutboundValue, setInboundOrOutboundValue] = useState("IB");

  const pageSize = useSelector((state) => state?.common?.pageSize);

  const { customerId } = useSelector((state) => state?.common);

  const [simulatorTestDataList, { data }] = useGetSimulatorTestDataListMutation();

  const columns = simulatorTestData_columns;

  var initialData = {
    customerId: customerId,
    directionStatus: inboundOrOutboundValue,
    page: 0,
    size: pageSize
  }

  // const [pageOptions, setPageOptions] = useState({ ...initialData });


  React.useEffect(() => {
    simulatorTestDataList(initialData)

    // console.log("simulator data", data);
  }, []);


  const updatePageAndGetData = (options) => {
    // console.log("optionss", options);
    var pagechangedData = {
      customerId: customerId,
      directionStatus: inboundOrOutboundValue,
      page: options.page,
      size: pageSize
    }

    simulatorTestDataList(pagechangedData)
    // setPageOptions(pagechangedData)
  };

  const handleEyeIconReviewd = (rec) => {
    dispatch(updateSimulatorData(rec?.customerSimTestDataId))
    userRole?.name == 'Super Admin' ?
    navigate(`${APP_ROUTE_KEYS.CUSTOMERS.SIM_DATA_SETTINGS}`,{state: { messageFlow: rec?.messageFlow}})
    :
    navigate(`${APP_ROUTE_KEYS.SETTINGS.SIM_DATA}`,{state: { messageFlow: rec?.messageFlow}})
  }
  // navigate(`/customers/settings`,{state: { customerName: rec.customerOrganizationName}});
  // console.log("cusid", customerId);

  columns[1].render = (data) =>
  (
    <>
      <span style={{ textTransform: 'lowercase' }}>{data!=null?convertToLocalTime(data):data}</span>
    </>
  )

  columns[3].render = (data, rec) => {

    // console.log("iopipoipoipoipoipoi",rec); // Logging the 'data' parameter

    return (
      <div onClick={() => handleEyeIconReviewd(rec)}>
        <span>{rec.actions === "Click to Review" ? <EyeIcon /> : <EyeIconReviewed />}</span>
        <span className={rec.actions === "Click to Review" ? "review-column" : "review-column-checked"}>
          {rec.actions === "Click to Review" ? "Click to Review" : "Reviewed"}
        </span>
      </div>
    );
  };


  var subtitle = "The following data will be used by Receiver agent to respond to a request sent by the Sender agent. Please review the data,make modifications if required, and upload."


  const onInboundOrOutboundChange = (event) => {


    // console.log("selected value", event);

    setInboundOrOutboundValue(event);

    var changedData = {
      customerId: customerId,
      directionStatus: event,
      page: 0,
      size: pageSize
    }


    // console.log("summaaaa",{...pageOptions,directionStatus:event});

    simulatorTestDataList(changedData)
  }

  return (
    <Box sx={{ overflowY: 'auto', height: `calc(100vh - 100px)`, paddingBottom: "30px" }}>
      <TestCaseHeader
        // title="Simulator Test Data"
        title={"Test Data for Response Messages"}
        subtitle={subtitle}

        showInboundOutbound={{
          show: true,
          onChange: onInboundOrOutboundChange,
          value: inboundOrOutboundValue
        }}
        displayInLine={true}
      />

      <Box my={2} style={{ textTransform: 'none' }}>

        <PayaptTable
          rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
          size="small"
          className="standalone_table"
          scroll={{ y: "50vh" }}
          columns={columns}
          data={data?.list}
          // data={!_.isEmpty(data)?data: []}
          //   loading={isLoading}
          //   rowKey={(rec) => rec?.userId}
          onChange={(pagination, filters, sorter) =>
            updatePageAndGetData({
              page: pagination.current - 1,

            })
          }
          pagination={{
            pageSize,
            current: !_.isEmpty(data) ? data.page + 1 : 1,
            total: !_.isEmpty(data) ? data.count : 0,
            showPrevNextJumpers: true,
            showTitle: true,
            hideOnSinglePage: true,
            size: "small",
          }}

        />

      </Box>
      <Box display={"flex"}>
        <MUIButton
          startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
          sx={{ backgroundColor: '#F0F2F5', color: '#808080', mx: 3 }}
          color="inherit"
          title="Back"
          onClick={() => userRole?.name == 'Super Admin' ? navigate(`${APP_ROUTE_KEYS.CUSTOMERS.MASTER_SETTINGS}`) : navigate(`${APP_ROUTE_KEYS.SETTINGS.MASTER}`)}
        />
      </Box>
    </Box>
  )
}

export default SimulatorTestDataNewTable