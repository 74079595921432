import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Welcome from '../../assets/images/onboardProcessDone.png'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import { updateAuthCheckParams } from '../../redux/common/common.slice';
// import { updateUserAuth } from '../../redux/auth/auth.slice';
// import { updateCustomerOnboarding } from '../../redux/customerOnBoarding/customerOnBoarding.slice';
// import { updateSelectedMenuKeys } from '../../redux/common/common.slice';
import MUIButton from '../../shared/components/MUIButton';

// const OnBoardingProcessDone = (props) => {

const OnBoardingProcessDone = () => {
   const stateData= useSelector(state=>state?.common)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onLogin = async () => {
        const xPayload = {
            isUserAuthenticated: true,
            isDashboard: true,
            // enableCustomerOnboarding: true, // should make true on profile completed submit button
            selectedMenuKey: 'standaloneMsg',
            selectedSubMenuKey: 'testcases',
            isUserOnBoardingCompleted: true
        }
        await dispatch(updateAuthCheckParams(xPayload));
        navigate('/testCases/SA')
        
    };

    return (
        <div>
            <h1>Onboarding Process Done <br />Successfully</h1>
            <p>There is lot more to moving money. Our products help you get products out to market quiclly with<br />FedNow simulators and certifications.we help you integrate with the latest payment protocols<br />quiclly without transforming our technical stack.</p><br />
            <br />
            <img src={Welcome} className='onboarding-img' /><br /><br /><br />
            {/* <Button
                onClick={onLogin}
                variant="contained" color="success" style={{ textTransform: 'none', fontFamily: 'Inter', fontSize: 16, marginTop: '30px' }}>
                <CheckCircleIcon style={{ height: '16.67px', width: '16.67px', color: '#f6f6f6', margin: '5px' }} />
                Confirm
            </Button> */}
            <MUIButton
              endIcon={<ArrowCircleRightRoundedIcon style={{ fontSize: 20}} />}
              title="Goto Dashboard"
              onClick={onLogin}
            />
        </div>
    )
}


export default OnBoardingProcessDone;
