import React from 'react';
import { Circle } from 'rc-progress';
import { Box, Typography } from '@mui/material';

const CircularProgress = (props) => {
    const { size, strokeWidth=1, strokeColor, trailColor, trailWidth=1, value} = props;
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <Circle 
            percent={value} 
            strokeWidth={strokeWidth}
            strokeColor={strokeColor} 
            trailColor = {trailColor}
            trailWidth={trailWidth}
            style = {{ width: size, height: size}}
        /> 
        <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        > {value}%</Typography>
      </Box>
        </Box>
    )
}

export default CircularProgress;
