import { CircularProgress, LinearProgress } from '@mui/material';
import React from'react';
import PropTypes from 'prop-types'

const PayaptLoader = (props) => {
    const { type, color, size, sx, thickness, value, variant} = props;
    if(type === 'circular'){
        return <CircularProgress 
                color={color} 
                size={size} 
                sx={sx} 
                thickness={thickness}
                value = {value}
                variant = {variant}
                />
    } if(type === 'linear'){
        return <LinearProgress 
                color={color} 
                size={size} 
                sx={sx} 
                thickness={thickness}
                value = {value}
                variant = {variant}
                />
    }
}

PayaptLoader.defaultProps = {
    color: 'primary', //'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit' | colorcode
    type: 'circular',
    size: 20,
    sx: {},
    thickness: 3.6,
    value: 0,
    variant: 'indeterminate'//'determinate' | 'indeterminate'
}
PayaptLoader.propTypes = {
    color: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.number,
    sx: PropTypes.instanceOf(Object),
    thickness: PropTypes.number,
    value: PropTypes.number,
    variant: PropTypes.string,
}

export default PayaptLoader;
