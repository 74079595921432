import { backendApi } from "../base.config";

export const dashboardApi = backendApi.injectEndpoints({
    overrideExisting : true,
    endpoints : (builder) =>({
        testDataReview : builder.mutation({
            query : (payload) =>({
                method:'POST',
                url : `dashboard/dashboardTestDataReviewCount?customerId=${payload?.customerId}`,
            }),
        }),
        getSAMsgCount: builder.mutation({
          query: (payload) => ({
            url:'dashboard/getSAMsgCount',
            method:'POST',
            body: payload,
          }),
        }),
        getMFMsgCount: builder.mutation({
            query: (payload) => ({
               url:'dashboard/getMFMsgCount' ,
               method:'POST',
               body: payload,
            }),
        }),
        getNFRDetails: builder.mutation({
            query: (payload) => ({
                url:'dashboard/getNFRDetails',
                method:'POST',
                body: payload,
            }),
        }),
        getSetupStatus: builder.mutation({
            query: (payload) => ({
                url:'dashboard/setUpStatus',
                method:'POST',
                body: payload,
            })
        }),
        getTestExecutionTrend: builder.mutation({
            query: (payload) => ({
                url:'dashboard/testExecutionTrend',
                method:'POST',
                body: payload,
            }),
        }),
        getDistinctMsgCount: builder.mutation({
            query: (payload) => ({
                method:'POST',
                url:`dashboard/getDistinctMsgCount`,
                body: payload,
            }),
        }),
        getDistinctTcCountFromBatch: builder.mutation({
            query: (payload) => ({
                method:'POST',
                url:`dashboard/getDistinctTcCountFromBatch`,
                body: payload,
            }),
        }),
        getBatchNameDropDown: builder.mutation({
            query: (payload) => ({
                method:'POST',
                url:`dashboard/getBatchNameDropDown`,
                body: payload,
            }),
        }),
        menusBasedOnPayment: builder.mutation({
            query: (args) => ({
                method:"POST",
                url:`customers/menusBasedOnPaymentService`,
                params: {...args}
            })
        }),
    })
})

export const { 
    useTestDataReviewMutation,
    useGetSAMsgCountMutation,
    useGetMFMsgCountMutation,
    useGetNFRDetailsMutation,
    useGetSetupStatusMutation,  
    useGetTestExecutionTrendMutation,
    useGetDistinctMsgCountMutation,
    useGetDistinctTcCountFromBatchMutation,
    useGetBatchNameDropDownMutation,
    useMenusBasedOnPaymentMutation,
} = dashboardApi