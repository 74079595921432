// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  /* width: 266px; */
  /* height: 24px; */
  line-height: 35px;
  text-transform: capitalize;
  color: #001a11;
  top: 235px;
}
.upload-subtitle {
  width: 316px;
  height: 17px;
  line-height: 15px;
  top: 265px;
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
}
.upload-file-content {
  /* width: 324px; */
  height: 27px;
  /* left: 697px; */
  /* top: 303px; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  /* text-align: right; */

  color: #001a11;
}
`, "",{"version":3,"sources":["webpack://./src/shared/components/styles.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;EACjB,0BAA0B;EAC1B,cAAc;EACd,UAAU;AACZ;AACA;EACE,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,UAAU;EACV,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;EAChB,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,uBAAuB;;EAEvB,cAAc;AAChB","sourcesContent":[".upload-title {\n  font-family: \"Inter\";\n  font-style: normal;\n  font-weight: 600;\n  font-size: 20px;\n  /* width: 266px; */\n  /* height: 24px; */\n  line-height: 35px;\n  text-transform: capitalize;\n  color: #001a11;\n  top: 235px;\n}\n.upload-subtitle {\n  width: 316px;\n  height: 17px;\n  line-height: 15px;\n  top: 265px;\n  font-family: \"Inter\";\n  font-style: italic;\n  font-weight: 400;\n  font-size: 14px;\n}\n.upload-file-content {\n  /* width: 324px; */\n  height: 27px;\n  /* left: 697px; */\n  /* top: 303px; */\n  font-family: \"Inter\";\n  font-style: normal;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 27px;\n  /* text-align: right; */\n\n  color: #001a11;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
