import { Grid } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Roles } from "../../constants/constants";
import { useFetchCustomerNameListMutation } from "../../redux/users/users.api";
import Dropdown from "../../shared/components/Dropdown";
import _ from "lodash";
import { DropdownValueContext } from "../../shared/context/DropdownProvider";

const styles = {
    menuProps: {
        sx: {
            '.MuiPaper-root': {
                maxHeight: '40%',
            },
        },
    },
}

const CustomerDropdown = (props) => {
    //    const { onChangeCustomer, value , isClear } = props

    const { customer, changeValue } = useContext(DropdownValueContext);
    const [fetchCustomerNameList , {data:customersList}] =useFetchCustomerNameListMutation()

    useEffect(()=>{
          fetchCustomerNameList();
      },[])

    // useEffect(() => {
    //     if(!_.isEmpty(customersList)){
    //         onChangeCustomer(customersList[0]?.customerId)
    //     }
    // }, [customersList])

    const onChangeCustomer = (event) => {
        changeValue(event)
        // setFedTch(event)
    };

    return (
        <>
            <Dropdown
                placeholder='Select Customer'
                margin="dense"
                className="user-dropdown"
                style={{ width: '100%', height: '42px', marginTop: '-20px' }}
                inputProps={{ 'aria-label': 'Without label' }}
                options={customersList}
                valueKey='customerId'
                labelKey='customerName'
                onChange={(e) => onChangeCustomer(e)}
                value={customer}
                MenuProps={styles.menuProps}
                // isClear={isClear}
            // disabled = {!!selectedRecord?.userId}
            />
        </>
    )
}
export default CustomerDropdown