import React from 'react';
import PayaptAlert from './Alert';
import PayaptLoader from './Spinner';


const WrapperComponent = (props) => {
    // console.log("---props----", props);
    const { isFetching, isSuccess, isError, message, children } = props;
    if(isFetching){
        return <div className='wrapper-container'>
            <PayaptLoader />
        </div>
    }
    if(isError){
        return <div className='wrapper-container'>
            <PayaptAlert type='error' message={message}/>
        </div>
    }
    if(isSuccess){
        return children
    }
}

export default WrapperComponent;
