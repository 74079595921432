import { createSlice } from '@reduxjs/toolkit';

export const userProfileSlice = createSlice({
    name: 'userProfile',
    initialState: { isUserProfileCompleted: false },
    reducers: {
        updateUserProfile: (state, { payload }) => {
            state.isUserProfileCompleted = payload;
        },
    },
});

export const { updateUserProfile } = userProfileSlice.actions;