import React from 'react';
import { CaretDownOutlined } from '@ant-design/icons';
import { Box } from '@mui/material';
import { Collapse } from 'antd';
import TestCaseSubDetails from './testcaseSubDetails';

const { Panel } = Collapse;


const ExecutionStep = (props) => {
    const { testCaseStepData } = props;
    const [activeKey, setActiveKey] = React.useState([]);

    return (
        <Box>
            <Collapse
                bordered={false}
                accordion
                expandIcon={({ isActive }) => <CaretDownOutlined rotate={isActive ? 180 : 0} style={{ color: '#ACACAC' }} />}
                expandIconPosition='end'
            >
                {testCaseStepData?.length > 0 && testCaseStepData?.map((xItem, xIndex) => (
                    <Panel
                        isActive={activeKey}
                        onItemClick={() => { setActiveKey((prev) => !prev.activeKey) }}
                        header={`Step ${xIndex + 1}`}
                        key={String(xIndex + 1)}
                        className='panel-header'>
                        <div className='panel-content-container'>
                            <div className='panel-content'>
                                <TestCaseSubDetails
                                    title='Execution Instruction'
                                    titleValue={xItem?.executionInstruction || "NA"}
                                />
                                <TestCaseSubDetails
                                    title='Expected Results for Instruction'
                                    titleValue={xItem?.expectedResult || "NA"}
                                />
                                <TestCaseSubDetails
                                    title='Expected Final Result'
                                    titleValue={xItem?.expectedFinalResult || "NA"}
                                />
                            </div>
                        </div>
                    </Panel>
                ))}
            </Collapse>
        </Box>

    )
}

export default ExecutionStep;