import React, { useState } from 'react'
import styles from  '../styles/emailStyle.module.css';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { useNavigate,useLocation } from 'react-router-dom';
import CustomerOnboardingTitle from '../customerOnboardingTitle';
import PayaptTextField from '../../shared/components/TextField';
import MUIButton from '../../shared/components/MUIButton';
import { Controller, useForm } from 'react-hook-form';
import { resetPasswordValidationSchema } from '../Profile/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { useResetPasswordMutation } from '../../redux/registration/registration.api';
import { SnackbarContext } from "../../shared/context/SnackbarContext.Context";
import { useContext } from "react";
import { ALERT_SEVERITY } from "../../shared/constants/constants";
import { Visibility, VisibilityOff } from '@mui/icons-material';

const ResetPass = () => {
    const navigate = useNavigate();
    const snackbar = useContext(SnackbarContext);
    const location = useLocation();
    const resolver = yupResolver(resetPasswordValidationSchema);
    const [ resetPassword , { data }] = useResetPasswordMutation();
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const {
      control,
      register,
      handleSubmit,
      reset,
      formState: { errors },
  } = useForm({
      resolver,
      defaultValues: {
        email: "",
        newPassword: "",
        confirmPassword: "",
        
    }
  });
  const defaultValues = {
    email: location?.state?.email,
}

React.useEffect(() => {
        reset({
            ...defaultValues,
        })
        return;
}, []);

const handleResetPasswordNavigation = async (xRes) => {
  if(xRes?.error?.data == "Passsword Changed Successfully"){
        snackbar.displayMsg(xRes?.error.data, ALERT_SEVERITY.SUCCESS);
        navigate('/signin');
      } else{
        snackbar.displayMsg(xRes?.error?.data?.message, ALERT_SEVERITY.ERROR)
      }
}

  const onSubmit = async (data) => {
    const resetPasswordResult = await resetPassword(data);
    handleResetPasswordNavigation(resetPasswordResult);
};
const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
const handleMouseDownPassword1 = (event) => {
  event.preventDefault();
};
const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
const handleMouseDownPassword2 = (event) => {
  event.preventDefault();
};


  return (
    <div className={styles.forgotcontent}>
        <CustomerOnboardingTitle />
        <div>
        <br />
        <h6 className={styles.resheader} style ={{ textAlign: 'left'}}>Reset Password</h6>
        <br />
        <Grid  style ={{ textAlign: 'left'}}>
       <Grid
          lg={24}
          md={24}
          sm={24}
          xs={24}
          item
          className={styles.logingriditem}
        >
          <span style={{ textAlign: "left",lineHeight:2.5 }}>Email address</span>
          <br />
          <Controller
                                control={control}
                                name={"email"}
                                render={({ field }) => (
                                    <PayaptTextField
                                        {...register("email")}
                                        {...field}
                                        placeholder='Enter your email address'
                                        id="email"
                                        className='signin-text-box'
                                        type='text'
                                        error={!!errors['email']}
                                        helperText={errors['email']?.message}
                                        inputProps={{ className: 'signin-input-box' }}
                                    />
                                )}
                            />
        </Grid>
        <Grid lg={24} md={24} sm={24} xs={24} item className={styles.logingriditem}>
          <span style={{ textAlign: "left",lineHeight:2.5 }}>New Password</span>
            <br/>
            <Controller
                                control={control}
                                name={"newPassword"}
                                render={({ field }) => (
                                    <PayaptTextField
                                        {...register("newPassword")}
                                        {...field}
                                        placeholder='Enter your New Password'
                                        id="newPassword"
                                        className='signin-text-box'
                                        type={showPassword1 ? 'text' : 'password'}
                                        error={!!errors['newPassword']}
                                        helperText={errors['newPassword']?.message}
                                        InputProps={{
                                          endAdornment: (
                                              <InputAdornment position="end">
                                                  <IconButton
                                                      aria-label="toggle password visibility"
                                                      onClick={handleClickShowPassword1}
                                                      onMouseDown={handleMouseDownPassword1}
                                                  >
                                                      {showPassword1 ? <Visibility /> : <VisibilityOff />}
                                                  </IconButton>
                                              </InputAdornment>
                                          )
                                      }}
                                    />
                                )}
                            />
        </Grid>
        <Grid lg={24} md={24} sm={24} xs={24} item className={styles.logingriditem}>
          <span style={{ textAlign: "left",lineHeight:2.5 }}> Confrim Password</span>
            <br/>
            <Controller
                                control={control}
                                name={"confirmPassword"}
                                render={({ field }) => (
                                    <PayaptTextField
                                        {...register("confirmPassword")}
                                        {...field}
                                        placeholder='Enter your Password Again'
                                        id="confirmPassword"
                                        className='signin-text-box'
                                        type={showPassword2 ? 'text' : 'password'}
                                        error={!!errors['confirmPassword']}
                                        helperText={errors['confirmPassword']?.message}
                                        InputProps={{
                                          endAdornment: (
                                              <InputAdornment position="end">
                                                  <IconButton
                                                      aria-label="toggle password visibility"
                                                      onClick={handleClickShowPassword2}
                                                      onMouseDown={handleMouseDownPassword2}
                                                  >
                                                      {showPassword2 ? <Visibility /> : <VisibilityOff />}
                                                  </IconButton>
                                              </InputAdornment>
                                          )
                                      }}
                                    />
                                )}
                            />
                            <br/>
        </Grid>
        </Grid>
        <div style={{textAlign:'right',width:'370px'}}>
       <MUIButton
        startIcon={<CheckCircleIcon style={{ fontSize: 18 }}/>}
        title="Reset Password"
        onClick={handleSubmit(onSubmit)}
        type="submit"
       />
      </div>
      </div>
    </div>
    
  )
}

export default ResetPass