import React from 'react';
import Dropdown from '../../shared/components/Dropdown';
import { useGetValueMessageQuery } from '../../redux/SystemMessages/systemMessages.api';
import { useEffect } from 'react';
import _ from 'lodash';

const styles = {
    menuProps: {
        sx: {
            '.MuiPaper-root': {
                maxHeight: '40%',
            },
        },
    },
}
// const valueOptions = [
//     {
//         id:1,
//         value:"Value"
//     },
//     {
//         id:2,
//         value:"Non Value"
//     }
// ]
const ValueDropdown = (props) => {
    const { value, onChangeValue } = props;
    const {data:valueOptions} = useGetValueMessageQuery();
    
    useEffect(() => {
        if (!_.isEmpty(valueOptions)) {
            onChangeValue(valueOptions?.id, valueOptions?.value)
        }
    }, [valueOptions])

    const onChange = (value) => {
        if (value) {
            const obj = _.find(valueOptions, m => m?.id === value);
            onChangeValue(value, obj?.value)
        } else {
            onChangeValue(value, value)
        }
    }
    return (
        <>
            <Dropdown
                placeholder=' Select Message Type'
                margin="dense"
                className="user-dropdown"
                style={{ width: '100%', height: '42px', marginTop: '-20px' }}
                inputProps={{ 'aria-label': 'Without label' }}
                options={valueOptions}
                valueKey='value'
                labelKey='value'
                onChange={(e) => onChange(e)}
                value={value}
                MenuProps={styles.menuProps}
                defaultValue={!_.isEmpty(valueOptions) ? valueOptions[0] : ""}
            //    isClear={isClear}
            />
        </>
    )
}

export default ValueDropdown;
