import _ from 'lodash';
import React, { useState } from 'react';
import Dropdown from '../shared/components/Dropdown';
import PayaptTooltip from '../shared/components/Tooltip';
import { useSelector } from 'react-redux';

const VersionSelection = (props) => {
    const { data, onChange, value } = props;
    const {selectedTestCasePayload} = useSelector((state) => state?.common)
// console.log('value',value);
    const [isDropdownOpened, setDropdownOpened] = useState(false)
    const dpOptions = data?.customerTestDataInTestCase?.map((xEl) => ({
        id: xEl?.customerTestDataId,
        label: xEl?.testDataNumber,
        value: xEl?.testDataNumber,
        isClonedCopy: xEl?.isClonedCopy
      }));

    const getTooltipTitle = () => {
      const labels = [];
      _.forEach(value, v => {
        const obj = _.find(dpOptions, op => op.id === v);
        if(!_.isEmpty(obj)){
          labels.push(obj.label)
        }
      })
      return _.join(labels, ',')
    }
// console.log('dpOptions',dpOptions, data
// // selectedTestCasePayload?.filter(item => {
// //   data?.map(testData => item.customerTestCaseId === testData?.customerTestCaseId)
// //   })
//   );
    const onOpen = () => {
      setDropdownOpened(true)
    }
    const onClose = () => {
      setDropdownOpened(false)
    }
    return (
        <PayaptTooltip title = {isDropdownOpened ? '' : getTooltipTitle()}>
            <Dropdown
            // multiple
            value={value}
            onChange={onChange}
            options={dpOptions}
            defaultValue={value? value : !_.isEmpty(dpOptions) ? dpOptions[0] : ""}
            sx = {{ width: '100%', height: '80%'}}
            autoWidth = {false}
            onOpen = {onOpen}
            onClose = {onClose}
            />
          </PayaptTooltip>
    )
}

export default VersionSelection;
