import { date } from 'yup';
import { backendApi } from '../base.config';

export const usersApi = backendApi.injectEndpoints({
    endpoints: (builder) => ({

        getUserData: builder.mutation({
            query: (xargs) => {
                const { searchBoxInput, ...args } = xargs;
                    return {
                        url: `User/userList`,
                        method: 'POST',
                        body: { ...args },
                        refetchOnMountOrArgChange: true
                    }
            },

        }),
        addUser: builder.mutation({
            query: (data) => ({
                url: 'customers/addUsers',
                method: 'POST',
                body: data,
            }),
        }),
        editUser: builder.mutation({
            query: (data) => ({
                url: 'User/editUser',
                method: 'POST',
                body: data,
            }),
        }),
        getRoleList: builder.query({
            query: (data) => ({
                url: 'User/fetchUserRolesList',
                method: 'GET',
            }),
        }),
        fetchCustomerNameList: builder.mutation({
            query: () => ({
                url: 'customers/fetchCustomerNameList',
            }),
        }),
        getCustomerLoginBaseInfo: builder.mutation({
            query: (args) => ({
                url: 'customers/customerLoginBaseInfo',
                method: 'POST',
                body: args,
            }),
        }),
        customerLoginBaseUpdate: builder.mutation({
            query: (data) => ({
                url: 'customers/customerLoginBaseUpdate',
                method: 'PUT',
                body: data,
            }),
        }),
        changeUserPassword: builder.mutation({
            query: (data) => ({
                url: 'customers/changePassword',
                method: 'POST',
                body: data,
            }),
        }),
        onUserLogOut: builder.mutation({
            query: () => ({
              url: 'User/logoutUser'
            }),
        }),
        getConnectivityOption: builder.mutation({
            query:(payload)=>({
                url:`Simulator/getConnectivityOption`,
                method:'POST',
                body: payload,
            })
        }),
        saveCertificate: builder.mutation({
            query: (savePayload) => ({
                method : "POST",
                url:"customers/saveCertificate",
                body: savePayload,
            })
        }),
        fetchUserNameList: builder.mutation({
            query: (args) => ({
                url: 'User/userListByCustomerId',
                method: 'POST',
                params:{...args}
            }),
        }),
        clearData: builder.mutation({
            query: (body) => ({
                url: 'customers/clearData',
                method: 'POST',
                body: body,
            }),
        })
    }),   
});


export const { 
    useGetUserDataMutation, 
    useAddUserMutation, 
    useEditUserMutation, 
    useGetRoleListQuery, 
    useFetchCustomerNameListMutation,
    useCustomerLoginBaseUpdateMutation,
    useChangeUserPasswordMutation ,
    useGetCustomerLoginBaseInfoMutation,
    useOnUserLogOutMutation,
    useGetConnectivityOptionMutation,
    useSaveCertificateMutation,
    useFetchUserNameListMutation,
    useClearDataMutation,
} = usersApi;
