import { backendApi } from '../base.config';

export const testRunsApi = backendApi.injectEndpoints({
    tagTypes: ['BATCH_LIST', 'TEST_CASE_LIST'],
    endpoints: (builder) => ({
        createBatchFile: builder.mutation({
            query: (args) => ({
                method: "POST",
                url: `customerTestcaseBatch/customerTestBatchCreate`,
                body: { 
                    ...args,
                    batchType: "C"
                },
            }),
            invalidatesTags: ['BATCH_LIST']
        }),
        createSystemBatchFile: builder.mutation({
            query: (args) => ({
                method: "POST",
                url: `customerTestcaseBatch/systemTestRunBatchCreate`,
                body: { 
                    ...args,
                    batchType: "S"
                },
            }),
            invalidatesTags: ['BATCH_LIST']
        }),
        getAllBatchFiles: builder.query({
            query: (args) => ({
                url: `customerTestcaseBatch/getAllBatch`,
                params: { ...args },
                refetchOnMountOrArgChange: true
            }),
            providesTags: ['BATCH_LIST']
        }),
        getAllTestcasesOfBatch: builder.query({
            query: (args) => ({
                url: `customerTestcaseBatch/getAllTestCaseWithBatch`,
                params: { ...args },
                refetchOnMountOrArgChange: true
            }),
            providesTags: ['TEST_CASE_LIST']
        }),
        getAllNonTestcasesOfBatch: builder.query({
            query: (args) => ({
                url: `customerTestcaseBatch/getAllNoNTestCaseWithBatch`,
                params: { ...args },
                refetchOnMountOrArgChange: true
            }),
            providesTags: ['NON_TEST_CASE_LIST']
        }),
        executeBatch: builder.mutation({
            query: (args) => ({
                method: "POST",
                url: `customerTestcaseBatch/executeBatchRunTestcases`,
                body: { ...args },
            }),
        }),
        updateBatchFile: builder.mutation({
            query: (args) => ({
                method: "PUT",
                url: `customerTestcaseBatch/updateBatchFile`,
                body: { 
                    ...args,
                },
            }),
            invalidatesTags: ['BATCH_LIST']
            // async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
            //     try {
            //       const { data: updatedPost } = await queryFulfilled
            //       const patchResult = dispatch(
            //         backendApi.util.updateQueryData('getAllBatchFiles', {testType: 'SA'}, (draft, b) => {
            //             console.log("===================")
            //             console.log(draft)
            //             console.log(b)
            //             //Object.assign(draft, updatedPost)
            //         })
            //       )
            //     } catch {}
            //   },
        
        }),
        deleteTestcaseFromBatch: builder.mutation({
            query: (args) => ({
                method: "DELETE",
                url: `customerTestcaseBatch/deleteTestCaseName`,
                params: { ...args },
            }),
            invalidatesTags: ['NON_TEST_CASE_LIST']
        }),
        downloadBatchRunData: builder.mutation({
            query: (args) => ({
                responseType : 'blob',
                url: `customerTestcaseBatch/downloadBatchRunDatas`,
                params: { ...args },
            }),
        }),
        addTestCaseToBatch: builder.mutation({
            query: (args) => ({
                method: "POST",
                url: `customerTestcaseBatch/addTestCaseToBatch`,
                params: { 
                    ...args,
                },
            }),
            invalidatesTags: ['TEST_CASE_LIST', 'NON_TEST_CASE_LIST']
        }),
        listofcustomertestbatch: builder.mutation({
            query: (args) => ({
                method: "POST",
                url: `customerTestcaseBatch/listofcustomertestbatch`,
                params: { ...args },
            }),
        }),
        ExistingcustomerTestBatchCreate: builder.mutation({
            query: (args) => ({
                method: "POST",
                url: `customerTestcaseBatch/ExistingcustomerTestBatchCreate`,
                body: {
                    ...args,
                },
            }),
        }),
    }),

});

export const {
    useCreateBatchFileMutation,
    useCreateSystemBatchFileMutation,
    useGetAllBatchFilesQuery,
    useGetAllTestcasesOfBatchQuery,
    useGetAllNonTestcasesOfBatchQuery,
    useExecuteBatchMutation,
    useUpdateBatchFileMutation,
    useDeleteTestcaseFromBatchMutation,
    useDownloadBatchRunDataMutation,
    useAddTestCaseToBatchMutation,
    useListofcustomertestbatchMutation,
    useExistingcustomerTestBatchCreateMutation,
} = testRunsApi;
