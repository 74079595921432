// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.users-title{
    /* position: absolute; */
    /* width: 178px; */
    height: 24px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    margin-top: 20px;
    margin-left: 20px;
    color: #001A11;
}
.users-subtitle{
    display: flex;
    margin-left: 20px;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    font-style: italic;
    color: #000000;
}

.ant-table-cell {
    text-transform: initial !important;
}

.scrollable-content {
    max-height: 60vh;
    overflow-y: auto;
    /* background-color: #f53939; */
    width: 90%;
  }

`, "",{"version":3,"sources":["webpack://./src/components/Users/userStyles.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,kBAAkB;IAClB,YAAY;IACZ,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,aAAa;IACb,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;AAClB;AACA;IACI,aAAa;IACb,iBAAiB;IACjB,oBAAoB;IACpB,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,+BAA+B;IAC/B,UAAU;EACZ","sourcesContent":[".users-title{\n    /* position: absolute; */\n    /* width: 178px; */\n    height: 24px;\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 20px;\n    line-height: 24px;\n    display: flex;\n    margin-top: 20px;\n    margin-left: 20px;\n    color: #001A11;\n}\n.users-subtitle{\n    display: flex;\n    margin-left: 20px;\n    font-family: 'Inter';\n    font-weight: 400;\n    font-size: 14px;\n    font-style: italic;\n    color: #000000;\n}\n\n.ant-table-cell {\n    text-transform: initial !important;\n}\n\n.scrollable-content {\n    max-height: 60vh;\n    overflow-y: auto;\n    /* background-color: #f53939; */\n    width: 90%;\n  }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
