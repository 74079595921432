import { Box, Typography } from '@mui/material';
import React from 'react';

function PageEmpty() {
    return (
        <Box m={2} p={4}>
            <Typography>No Data</Typography>
        </Box>
    );
}

export default PageEmpty;
