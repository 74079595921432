import React from 'react';

import TestRuns from '../components/homeScreens/testRuns';

const testRunsPage = (props) => {
    return (
        <TestRuns title='Test Runs' subtitle='Some details related to running Standalone Messages goes here' />
    )
}

export default testRunsPage;
