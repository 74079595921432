import React from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { onboardingMenus, onboardingScreen } from '../../constants/constants';
import ChooseSubscriptionPlan from './ChooseSubscriptionPlan';
import ChooseParticipationType from './ChooseParticipationType';
import SimulatorTestData from './simulatorTestData';
import ConfigureParticipationType from './configureParticipationType';
import ConfigureTestData from './ConfigureTestData';
import { updateCurrentStep } from '../../redux/customerOnBoarding/customerOnBoarding.slice';

import check from '../../assets/images/check.png';
import '../styles/onboardingStyles.css'
import CustomerOnbardingReview from './CustomerOnbardingReview';
import { useLocation, useNavigate } from 'react-router-dom';

const OnboardingMenu = () => {
    const navigate = useNavigate();
    const { customerOnBoarding } = useSelector((state) => state.common)
    const isStepCompleted = (key) => {
        switch (screen) {
            case onboardingScreen.CHOOSE_SUBSCRIPTION:
                return !!customerOnBoarding?.subscriptionPlan?.subscriptionPlanId
            case onboardingScreen.SELECT_PARTICIPATION_TYPE:
                return !!customerOnBoarding?.participationType?.participationTypeId
            case onboardingScreen.CONFIGURE_PARTICIPATION_TYPE:
                return customerOnBoarding?.participationType?.isConfigured
            default:
                return false;
        }
    }
    const getMenuItem = (item, step) => {
        const completed = isStepCompleted(item?.key);
        return (
            <div key={step} className={completed ? 'onboarding-menu-item' : 'onboarding-menu-item'} onClick={() => navigate(`/customer/onboarding?screen=${item?.key}`)}>
                <div className={completed ? 'onboarding-menu-index-disabled' : 'onboarding-menu-index'}>
                    {/* <img src={rectangle}/> */}
                    {!completed && <img src={check} className='completed-step-img' />}
                    <span className={completed ? 'onboarding-menu-index-text-disabled' : 'onboarding-menu-index-text'}>{step}</span>
                </div>
                <div className='onboarding-menu-title-desc'>
                    <span className={completed ? 'onboarding-menu-title-disabled' : 'onboarding-menu-title'}>{item.title}</span>
                    <span className={completed ? 'onboarding-menu-description-disabled' : 'onboarding-menu-description'}>{item.description}</span>
                </div>
            </div>
        )
    }

    const getCurrentStepContent = () => {
        const location = useLocation();
        const searchParams = new URLSearchParams(location?.search);
        const screen = searchParams.get('screen')
        const type = searchParams.get('type')
        switch (screen) {
            case onboardingScreen.CHOOSE_SUBSCRIPTION:
                return <ChooseSubscriptionPlan />
            case onboardingScreen.SELECT_PARTICIPATION_TYPE:
                return <ChooseParticipationType />
            case onboardingScreen.CONFIGURE_PARTICIPATION_TYPE:
                return <ConfigureParticipationType screenType = {type === 'review'}/>
            default:
                return <ChooseSubscriptionPlan />;
        }
    }
    return (
        <div className='onborading-container'>
            <div className='onboarding-side-menu'>
                <span className='onboarding-title header1'>Onboarding</span>
                <div className='menu-items'>
                    {
                        _.map(onboardingMenus, (m, index) => getMenuItem(m, index + 1))
                    }
                </div>
            </div>
            <div className='onboarding-content'>
                {getCurrentStepContent()}
            </div>
        </div>
    )
}

export default OnboardingMenu;
