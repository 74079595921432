import React from 'react';

function NotFoundPage() {
    return (
        <div style={{
            height: '100vh',
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <h2>Page not found / Un-Authenticated</h2>
        </div>
    );
}

export default NotFoundPage;
