import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Box, Grid, InputLabel, TextField, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';

import InfoIcon from '@mui/icons-material/Info';

import ChildHeader from '../../../shared/components/ChildHeader';
import PayaptTextField from '../../../shared/components/TextField';
import { batchNameApiKey, batchDescriptionApiKey, createBatchFileValidationSchema } from '../utils';
import MUIButton from '../../../shared/components/MUIButton';
import { SnackbarContext } from '../../../shared/context/SnackbarContext.Context';
import { convertAndFormatToUtcDate } from '../../../shared/utils';
import XTwoWayLabelSwitch from '../../../shared/components/XTwoWayLabelSwitch';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useListofcustomertestbatchMutation } from '../../../redux/batchFiles/batchFile.api';
import { useSelector } from 'react-redux';
const styles = {
    titleStyle: {
        fontSize: "1rem",
        fontWeight: "500"
    },
    subTitleStyle: {
        fontSize: "0.85rem",
        fontWeight: "300",
        color: "rgba(0,0,0,0.85)"
    }
}


function CreateBatchFile(props) {
    const { onCancel, selectedTestScenarios, createBatch, selectedBatch, isUpdateScenario = false } = props;
    const Snackbar = useContext(SnackbarContext);
    const [existingBatchFileData, { data }] = useListofcustomertestbatchMutation();

    console.log("🚀 ~ file: CreateBatchFile.testScenarios.js:28 ~ CreateBatchFile ~ selectedBatch:", { selectedBatch });

    const selTestCasesLength = 0;

    const selBatchFileName = selectedBatch?.batchName || "";
    const modalHeaderTitle = !isUpdateScenario ? "Create Batch File" : "View & Edit Batch File";
    const modalHeaderSubTitle = !isUpdateScenario ? `Selected test cases - ${selTestCasesLength}` : `Selected ${selBatchFileName}`;
    const [isExisting, setIsExisting] = useState(false)
    const [inputValue, setInputValue] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedExistingBatch, setSelectedExistingBatch] = useState({})
    const [error, setError] = useState('');
    const customerId = useSelector((state) => state?.common?.customerId);

    const resolver = yupResolver(createBatchFileValidationSchema);
    var dropDownPayload = {
        searchbytestbatchname: "",
        customerId: customerId,
        status : 'all'

    }

    const {
        control,
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
        clearErrors,
        setFocus,
    } = useForm({
        resolver,
        defaultValues: {
            [batchNameApiKey]: selectedBatch?.batchName,
            [batchDescriptionApiKey]: selectedBatch?.batchDescription
        }

    });

    React.useEffect(() => {
        reset({
            [batchNameApiKey]: selectedBatch?.batchName,
            [batchDescriptionApiKey]: selectedBatch?.batchDescription
        })
    }, [selectedBatch])



    useEffect(()=>{
        setFocus(batchNameApiKey);
    },[setFocus])

    const onSubmit = async (data) => {

        console.log("data",data);

        if (isExisting) {
            if (!selectedOption) {
                setError('Please select an option from the dropdown.');
            } else {
                setError('');
                createBatch(data, selectedExistingBatch?.customerBatchId);

            }
        }
        else {
            createBatch(data, selectedExistingBatch?.customerBatchId);

        }

    }



    const handleSwitchToggle = (event) => {


        setIsExisting(event.target.checked)
        if (event.target.checked === true) {
            existingBatchFileData(dropDownPayload)

        }
        reset()
        setError('');
        setSelectedOption(null)
    }



    const handleSearchDropdownChange = (event) => {
        dropDownPayload = {
            searchbytestbatchname: event,
            customerId: customerId,
            status : 'all'

        }
        setInputValue(event)
        existingBatchFileData(dropDownPayload)


    }

    const handleDropdownChange = (event) => {
        if (event) {
            setError('');

        }
        setSelectedOption(event)
        setSelectedExistingBatch(event)
        setValue(batchNameApiKey, event?.testRunBatchName)
        setValue(batchDescriptionApiKey, event?.testRunBatchDescription)
       
        existingBatchFileData(dropDownPayload)

        clearErrors()
    }


    const handleButtonClick = () => {
        // Call the onSubmit function from handleSubmit
        handleSubmit(onSubmit)();
        if (!selectedOption) {
            setError('Please select an option from the dropdown.');
        } else {
            setError('');
            

        }
        
      };

    return (
        <Box>
            <ChildHeader
                title={modalHeaderTitle}
                // subTitle={modalHeaderSubTitle}
                // IconComponent={InfoIcon}
                onCancel={onCancel} />

            <Box p={2}>
                {!isUpdateScenario ? (<Box display={"flex"} alignItems={"flex-start"}>
                    {/* <Typography>{"Create New Batch File"}</Typography> */}
                    <XTwoWayLabelSwitch
                        primary={"Create New Batch File"} secondary={"Add Existing Batch Files"} checked={isExisting} onChange={handleSwitchToggle}
                    />
                </Box>) : (
                    <Grid container my={1.5} justifyContent="space-between">
                        <Grid item xs={6} display="flex" alignItems="flex-start" flexDirection={"column"}>
                            <Typography sx={styles.titleStyle}>Created Date</Typography>
                            <Typography sx={styles.subTitleStyle}>{convertAndFormatToUtcDate(selectedBatch?.createdDate)}</Typography>
                        </Grid>
                        <Grid item xs={6} display="flex" alignItems="flex-start" flexDirection={"column"}>
                            <Typography sx={styles.titleStyle}>Last Modified Date</Typography>
                            <Typography sx={styles.subTitleStyle}>{convertAndFormatToUtcDate(selectedBatch?.modifiedDate)}</Typography>
                        </Grid>
                    </Grid>
                )}
                {isExisting && <Box sx={{ marginTop: "20px", marginBottom: "10px" }}>
                    <InputLabel sx={{ textAlign: "left" }}>Existing Batch File Name <span style={{color:"#d32f2f"}}>*</span></InputLabel>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={data?.length > 0 ? data : []}
                        getOptionLabel={(option) => option.testRunBatchName}
                        value={selectedOption}
                        sx={{ marginTop: "8px" }}
                        onChange={(event, newValue) => handleDropdownChange(newValue)}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => handleSearchDropdownChange(newInputValue)}

                        renderInput={(params) => <TextField
                            placeholder='Select/Search Batch Name'
                            error={error}
                            InputProps={{
                                ...params.InputProps,
                              
                            }}
                            {...params} />}
                            

                    />
                    {error && <div style={{ color: "#DD2025", textAlign: "left" }}>{error}</div>}

                </Box>
                }

                {!isExisting && <Box my={2}>
                    <Controller
                        control={control}
                        name={batchNameApiKey}
                        render={({ field }) => (
                            <PayaptTextField
                                {...register(`${batchNameApiKey}`)}
                                {...field}
                                name={batchNameApiKey}
                                label="Batch File Name"
                                placeholder='Enter Batch File Name'
                                margin='dense'
                                required
                                error={!!errors[batchNameApiKey]}
                                helperText={errors[batchNameApiKey]?.message}
                            />
                        )}
                    />
                </Box>}
                <Box my={2}>
                    <Controller
                        control={control}
                        name={batchDescriptionApiKey}
                        render={({ field }) => (
                            <PayaptTextField
                                {...register(`${batchDescriptionApiKey}`)}
                                {...field}
                                name={batchDescriptionApiKey}
                                label="Batch File Description"
                                placeholder='Enter Batch File Description'
                                margin='dense'
                                required
                                multiline
                                maxRows={3}
                                error={!!errors[batchDescriptionApiKey]}
                                helperText={errors[batchDescriptionApiKey]?.message}
                            />
                        )}
                    />
                </Box>
            </Box>
            <Box m={2}>
                <MUIButton
                    title="Submit"
                    fullWidth
                    // onClick={handleSubmit(onSubmit)}
                    onClick={handleButtonClick}
                // disabled = {selectedBatch?.batchName}
                />
            </Box>
        </Box>
    );
}

export default CreateBatchFile;