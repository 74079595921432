// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.id-forgot {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  text-transform: capitalize;
  color: #001A11;
  /* left: 786px;
  top: 179px; */
}

.para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  width: 360px;
  height: 49px;
  color: #001A11;
  /* left: 786px;
  top: 232px;
  right: 300px; */
}
.otp-content{
  margin-bottom: 30px;
  width: 420px;
height: 49px;
  font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 27px;
/* or 169% */

text-align: center;

color: #001A11;
}
.otp-input{
  /* box-sizing: border-box; */
width: 54px;
height: 54px;
padding-top: 30px;

background: #FFFFFF;
border: 1px solid #ACACAC;
border-radius: 5px;
}
.didnt-receive{
  padding-top: 20px;
  font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 27px;
/* identical to box height, or 169% */

text-align: center;

color: #001A11;
}`, "",{"version":3,"sources":["webpack://./src/components/styles/forgotStyles.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,0BAA0B;EAC1B,cAAc;EACd;eACa;AACf;;AAEA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,cAAc;EACd;;iBAEe;AACjB;AACA;EACE,mBAAmB;EACnB,YAAY;AACd,YAAY;EACV,oBAAoB;AACtB,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB,YAAY;;AAEZ,kBAAkB;;AAElB,cAAc;AACd;AACA;EACE,4BAA4B;AAC9B,WAAW;AACX,YAAY;AACZ,iBAAiB;;AAEjB,mBAAmB;AACnB,yBAAyB;AACzB,kBAAkB;AAClB;AACA;EACE,iBAAiB;EACjB,oBAAoB;AACtB,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB,qCAAqC;;AAErC,kBAAkB;;AAElB,cAAc;AACd","sourcesContent":[".id-forgot {\n  font-family: \"Inter\";\n  font-style: normal;\n  font-weight: 600;\n  font-size: 30px;\n  line-height: 36px;\n  text-transform: capitalize;\n  color: #001A11;\n  /* left: 786px;\n  top: 179px; */\n}\n\n.para {\n  font-family: \"Inter\";\n  font-style: normal;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 27px;\n  text-align: center;\n  width: 360px;\n  height: 49px;\n  color: #001A11;\n  /* left: 786px;\n  top: 232px;\n  right: 300px; */\n}\n.otp-content{\n  margin-bottom: 30px;\n  width: 420px;\nheight: 49px;\n  font-family: 'Inter';\nfont-style: normal;\nfont-weight: 400;\nfont-size: 16px;\nline-height: 27px;\n/* or 169% */\n\ntext-align: center;\n\ncolor: #001A11;\n}\n.otp-input{\n  /* box-sizing: border-box; */\nwidth: 54px;\nheight: 54px;\npadding-top: 30px;\n\nbackground: #FFFFFF;\nborder: 1px solid #ACACAC;\nborder-radius: 5px;\n}\n.didnt-receive{\n  padding-top: 20px;\n  font-family: 'Inter';\nfont-style: normal;\nfont-weight: 400;\nfont-size: 16px;\nline-height: 27px;\n/* identical to box height, or 169% */\n\ntext-align: center;\n\ncolor: #001A11;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
