import { yupResolver } from '@hookform/resolvers/yup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Grid, IconButton, InputAdornment } from '@mui/material';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import MUIButton from '../../shared/components/MUIButton';
import PayaptTextField from '../../shared/components/TextField';
import TestCaseHeader from '../testCaseHeader';
import { newPasswordValidationSchema } from './utils';
import { useChangeUserPasswordMutation } from '../../redux/users/users.api';
import { UserMsg } from '../../shared/constants/AlertMessages';
import { ALERT_SEVERITY } from '../../shared/constants/constants';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const ChangePassword = () => {
    const Snackbar = useContext(SnackbarContext);
    const resolver = yupResolver(newPasswordValidationSchema);
    const [changeUserPassword] = useChangeUserPasswordMutation();
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [showPassword3, setShowPassword3] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
    const handleMouseDownPassword2 = (event) => {
        event.preventDefault();
    };
    const handleClickShowPassword3 = () => setShowPassword3((show) => !show);
    const handleMouseDownPassword3 = (event) => {
        event.preventDefault();
    };
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver
    });

    const handleSaveNavigation = async (xRes) => {
        if (xRes?.error?.data == 'Passsword Changed Successfully') {
            Snackbar.displayMsg(UserMsg.CHANGE_USER_PASSWORD_SUCCESS, ALERT_SEVERITY.SUCCESS);
        } else {
            Snackbar.displayMsg(`${xRes?.error?.data?.message}`, ALERT_SEVERITY.ERROR);
        }
    }

    const onSubmit = async (data) => {
        const updateResult = await changeUserPassword(data);
        handleSaveNavigation(updateResult);
    }

    return (
        <>
            <Box >
                <TestCaseHeader
                    title="Change Password"
                    subtitle="You can change Password here"

                />
                <Grid spacing={3} container >
                    <Grid item xs={0} > </Grid>
                    <Grid item xs={6} sx={{ textAlign: 'left', marginTop: 2 }}>
                        <Box >
                            <Grid>
                                <span style={{ textAlign: 'left', lineHeight: 2 }}>Current Password <font color='red'>*</font></span>
                                <br />
                                <Controller
                                    control={control}
                                    name={"oldPassword"}
                                    render={({ field }) => (
                                        <PayaptTextField
                                            {...register("oldPassword")}
                                            {...field}
                                            placeholder='Enter your Current Password'
                                            id="oldPassword"
                                            className='signin-text-box'
                                            // type='password'
                                            type={showPassword ? 'text' : 'password'}
                                            error={!!errors['oldPassword']}
                                            helperText={errors['oldPassword']?.message}
                                            InputProps={{
                                                className: 'signin-input-box',
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <br />
                            <Grid>
                                <span style={{ textAlign: 'left', lineHeight: 2 }}>New Password <font color='red'>*</font></span>
                                <br />
                                <Controller
                                    control={control}
                                    name={"newPassword"}
                                    render={({ field }) => (
                                        <PayaptTextField
                                            {...register("newPassword")}
                                            {...field}
                                            placeholder='Enter your New Password'
                                            id="newPassword"
                                            className='signin-text-box'
                                            // type='password'
                                            type={showPassword2 ? 'text' : 'password'}
                                            error={!!errors['newPassword']}
                                            // helperText={errors['newPassword']?.message}
                                            inputProps={{
                                                className: 'signin-input-box',
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword2}
                                                            onMouseDown={handleMouseDownPassword2}
                                                        >
                                                            {showPassword2 ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    )}
                                />
                                {errors["newPassword"] && (
                                        <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem'}}>{errors.newPassword?.message}</div>
                                    )}
                            </Grid>
                            <br />
                            <Grid >
                                <span style={{ textAlign: 'left', lineHeight: 2 }}>Confirm Password <font color='red'>*</font></span>
                                <br />
                                <Controller
                                    control={control}
                                    name={"confirmPassword"}
                                    render={({ field }) => (
                                        <PayaptTextField
                                            {...register("confirmPassword")}
                                            {...field}
                                            placeholder='Enter your Password Again'
                                            id="confirmPassword"
                                            className='signin-text-box'
                                            // type='password'
                                            type={showPassword3 ? 'text' : 'password'}
                                            error={!!errors['confirmPassword']}
                                            helperText={errors['confirmPassword']?.message}
                                            inputProps={{
                                                className: 'signin-input-box',
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword3}
                                                            onMouseDown={handleMouseDownPassword3}
                                                        >
                                                            {showPassword3 ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <br />
                            <Box my={2} sx={{ display: 'flex', justifyContent: 'flex-start', }} >
                                <MUIButton
                                    startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
                                    title="Change Password"
                                    onClick={handleSubmit(onSubmit)}
                                    type="submit"
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={2}></Grid>
                </Grid>

            </Box>

        </>
    )
}

export default ChangePassword;
