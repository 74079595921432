import React from 'react';
import { xml2json, json2xml } from "xml-js";

const XmlEditor = (props) => {
    // const { xmlText } = props;
    const xmlText = `<?xml version="1.0"?>
    <x:books xmlns:x="urn:books">
       <book id="bk001">
          <author>Writer</author>
          <title>The First Book</title>
          <genre>Fiction</genre>
          <price>44.95</price>
          <pub_date>2000-10-01</pub_date>
          <review>An amazing story of nothing.</review>
       </book>
    
       <book id="bk002">
          <author>Poet</author>
          <title>The Poet's First Poem</title>
          <genre>Poem</genre>
          <price>24.95</price>
          <review>Least poetic poems.</review>
       </book>
    </x:books>`
    const xsdText = `<xsd:schema xmlns:xsd="http://www.w3.org/2001/XMLSchema"
    targetNamespace="urn:books"
    xmlns:bks="urn:books">

<xsd:element name="books" type="bks:BooksForm"/>

<xsd:complexType name="BooksForm">
<xsd:sequence>
<xsd:element name="book" 
          type="bks:BookForm" 
          minOccurs="0" 
          maxOccurs="unbounded"/>
</xsd:sequence>
</xsd:complexType>

<xsd:complexType name="BookForm">
<xsd:sequence>
<xsd:element name="author"   type="xsd:string"/>
<xsd:element name="title"    type="xsd:string"/>
<xsd:element name="genre"    type="xsd:string"/>
<xsd:element name="price"    type="xsd:float" />
<xsd:element name="pub_date" type="xsd:date" />
<xsd:element name="review"   type="xsd:string"/>
</xsd:sequence>
<xsd:attribute name="id"   type="xsd:string"/>
</xsd:complexType>
</xsd:schema>`;

    const xmlJson = xml2json(xmlText, { compact: true, spaces: 4 });
    const xsdJson = xml2json(xsdText, { compact: true, spaces: 4 });

    console.log(xmlJson);
    console.log(xsdJson);

    return (
        <div>
            <div>
                {xmlJson}
            </div>
        </div>
    )

}

export default XmlEditor;