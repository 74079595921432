import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SettingsIcon from '@mui/icons-material/Settings';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React,{ useEffect } from 'react';
import { ParticipationIcon } from '../../constants/menuIcons';
import { useGetSetupStatusMutation } from '../../redux/dashboard/dashboard.api';
import '../Dashboard/setupStatus.css';
import moment from 'moment';

const styles = {
    statusHeading :{
        width: '100%',
        height: '22px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '17px',
        lineHeight: '30px',
        textTransform: 'capitalize',
        color: '#001A11',
    },
    completeGroup:{
        display: 'flex',
        flexDirection:'row',
        alignItems:'center',
        gap:'6px',
        width: '123px',
        height: '30px',
        padding: '10px',
        background: '#E5FFF7',
        borderRadius: '5px 0px 0px 5px',
        marginLeft:'70px !important'
    },
    headingIcon:{
        width: '17px',
        height: '17px', 
        color:'#006644'
    },
    setupStatusBox:{
        display: 'flex',
        textAlign:'left',
        flexWrap: 'wrap',
        '& > :not(style)': {
            m: 1,
            mt:0.2,
            width: '100%',
            height: '270px',
            background: '#FFFFFF',
            boxShadow: '0px 0px 4px rgba(240, 242, 245, 0.8)',
            borderRadius: '10px'
        },
    },
}

const SetupStatus = (props) => {
    const { dateForDashboard, customer } = props
    const [getSetupStatus,{data}] = useGetSetupStatusMutation();
    var payload = {
        date :dateForDashboard[0]? moment.utc( new Date(dateForDashboard[0])).format() : null,
        toDate :dateForDashboard[1]? moment.utc( new Date(dateForDashboard[1])).format() : null,
        customerId: customer? customer : null,
    }
    useEffect(() => {
        if (dateForDashboard && customer) {
            getSetupStatus(payload);
        }
        
    }, [dateForDashboard, customer]);
    

  return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    '& > :not(style)': {
                        width: '100%',
                        height:"340px",
                        borderRadius:'5px',
                    },
                }}
            >
                <Paper  style={{ backgroundColor: "#F4F8F9", borderBottom: '3px solid #009966' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            '& > :not(style)': {
                                m: 2,
                            },
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Box>
                            <Typography sx={styles.statusHeading}>Client Configuration Status</Typography>
                        </Box>
                        {/* <Box sx={styles.completeGroup}>
                            <CheckCircleRoundedIcon sx={styles.headingIcon} />
                            <Typography className='completeStatus'>Completed</Typography>
                        </Box> */}
                    </Box>
                    <Box sx={styles.setupStatusBox}>
                        <Paper elevation={0} style={{borderRadius:4}}>
                            <Box sx={{

                                display: 'flex',
                                m: 2,
                               
                            }}>
                                <Box className="setupIconGroup">
                                        <ParticipationIcon className="partIcon"/>
                                </Box>
                                <Box sx={{ marginLeft:'10px'}}>
                                    <Typography className="participationType">Participation Type</Typography>
                                    <Typography className="participationValue">{data? data.participationType :''}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{

                                display: 'flex',
                                m: 2,
                                mt:3
                            }}>
                                 <Box className="setupIconGroup">
                                        <SettingsIcon className="settingsIcon"/>
                                </Box>
                                <Box sx={{ marginLeft:'10px', width: '100%'}}>
                                    <Typography className="participationType">Environment Setup</Typography>
                                    <Box sx={{ display: 'flex',flexDirection:'row',mt: 0.5, flexWrap: 'wrap', justifyContent: 'space-between'}}>
                                        <Typography className="EnvironmentType">RestAPI Connectivity</Typography>
                                        {data?.restApiConnectivity === 'Yes'? 
                                        <CheckCircleOutlineIcon className="environmentIcon"/> : <CancelOutlinedIcon className="cancelEnvironmentIcon"/>
                                        }
                                    </Box>
                                    <Box sx={{ display: 'flex',flexDirection:'row',mt: 0.5, flexWrap: 'wrap', justifyContent: 'space-between'}}>
                                        <Typography className="EnvironmentType">MQ Setup </Typography>
                                        {data?.mqSetup === 'Yes'? 
                                        <CheckCircleOutlineIcon className="environmentIcon"/> : <CancelOutlinedIcon className="cancelEnvironmentIcon"/>
                                        }
                                    </Box>
                                    <Box sx={{ display: 'flex',flexDirection:'row',mt: 0.5, flexWrap: 'wrap', justifyContent: 'space-between'}}>
                                        <Typography className="EnvironmentType">Test Data Review </Typography>
                                        <Typography className="environmentReviewValue">{data? (data.testDataReview + '%') :''} </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            {/* <Box sx={{

                                display: 'flex',
                                m: 2,
                                mt:3
                            }}>

                                <Box className="setupIconGroup">
                                        <DateRangeIcon className="rangIcon"/>
                                </Box>
                                <Box sx={{ marginLeft:'10px'}}>
                                    <Typography className="participationType">Last Executed Date</Typography>
                                    <Typography sx={{ pt: 1}} className="EnvironmentType">{data? data.lastExecutedDate :''}</Typography>
                                </Box>
                            </Box> */}
                            <Box sx={{

                                display: 'flex',
                                m: 2,
                                mt:3
                            }}>

                                <Box className="setupIconGroup">
                                        <VerifiedUserIcon className="userIcon"/>
                                </Box>
                                <Box sx={{ marginLeft:'10px'}}>
                                    <Typography className="participationType">Certification Readiness</Typography>
                                    <Typography sx={{ pt: 1}} className="EnvironmentType">{data? (data.certificationReadiness +'%') :''}</Typography>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Paper>
            </Box>
        </div>
    )
}

export default SetupStatus;
