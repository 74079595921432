import { Grid } from '@mui/material';
import React from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import BatchFileSelect from './BatchFileSelect';
import TestCaseSelection from './TestCasesSelection';
import TestCaseAddition from './TestCasesAddition';


const styles = {
    container: {
        p: 2,
        border: '1px solid #FAFAFA',
        borderRadius: '4px',
        minHeight: '60vh',
        width: "100%",
    }
}

function BatchFile(props) {
    const { selectedBatch, setSelectedBatch, onPreview, openBatchTestcaseDetails, type, onBatchSelectionChange,
         batchContextToRun, onBatchTestDataSelectionChange, displayAllBatches,customer } = props;
    return (
        <DndProvider backend={HTML5Backend}>
            <Grid container sx={styles.container}>
                <Grid item xs={4}>
                    <BatchFileSelect
                        setSelectedBatch={setSelectedBatch}
                        selectedBatch={selectedBatch}
                        onPreview={onPreview}
                        type={type}
                        onBatchSelectionChange = { onBatchSelectionChange }
                        batchContextToRun = { batchContextToRun }
                        displayAllBatches = {displayAllBatches}
                        customer={customer}
                    /></Grid>
                <Grid item xs={4}>
                    <TestCaseSelection 
                        selectedBatch={selectedBatch} 
                        openBatchTestcaseDetails={openBatchTestcaseDetails} 
                        onBatchTestDataSelectionChange = {onBatchTestDataSelectionChange}
                        customer={customer}
                        selectedTestcases = {_.find(batchContextToRun, b => b.batchId === selectedBatch?.customerBatchId)}
                    /></Grid>
                <Grid item xs={4} pl={2}><TestCaseAddition selectedBatch={selectedBatch} openBatchTestcaseDetails={openBatchTestcaseDetails} customer={customer}/></Grid>
            </Grid>
        </DndProvider>
    );
}

export default BatchFile;