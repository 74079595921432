import { Route, Routes } from "react-router-dom"
import { Layout } from 'antd';
import AppHeader from '../components/header';
import ProfileUpdate from '../components/welcome/ProfileUpdate';
import ThanksForCompleteProfile from '../components/welcome/ThanksForCompleteProfile';
import AccountActivation from '../components/welcome/AccountActivation';
import NotFoundPage from '../components/NotFoundPage';
import AppLoader from "../screens/appLoader";

const { Header, Content, Footer } = Layout;

const ProfileRoutes = () => {
  console.log('[ProfileRoutes]');
  return (
    <Layout style={{ height: '100vh' }}>
      <Header className='app-header'><AppHeader /></Header>
      <Content style={{ backgroundColor: '#ffffff', paddingTop: 0, paddingLeft: 0, marginTop: 0 }}>
        <Routes>
          {/* ProfileRoutes */}
          <Route path='/app/loader' element={<AppLoader />} />
          <Route path='/accountactivation' element={<AccountActivation />} />
          {/* <Route path='/updateCompleteProfile' element={<ProfileUpdate />} />
          <Route path='/thankscompleteprofile' element={<ThanksForCompleteProfile />} /> */}
          {/* unknown routes */}
          <Route path="*" element={<NotFoundPage />} />

        </Routes>
      </Content>
      <Footer className='app-signin-footer'>
        <div className='signin-footer'>
          <span>Payapt &#169; 2023</span>
          <div className='privacy-terms'>
            <span>Privacy</span>&nbsp;&nbsp;
            <span>Terms</span>
          </div>
        </div>
      </Footer>
    </Layout>
  )
}

export default ProfileRoutes;