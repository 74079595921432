// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-table-thead {
  background: #f2f2f2;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;
  color: var(--text-color);
}
.ant-table {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 19px !important;
  text-transform: capitalize;
  color: var(--text-color);
}
.ant-pagination {
  margin-top: 10px !important;
  margin-bottom: 5px !important;
}
.ant-table-row-selected > td {
  background: unset !important;
}
.ant-table-tbody tr:hover {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/styles/tableStyles.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,0BAA0B;EAC1B,wBAAwB;AAC1B;AACA;EACE,+BAA+B;EAC/B,6BAA6B;EAC7B,2BAA2B;EAC3B,0BAA0B;EAC1B,4BAA4B;EAC5B,0BAA0B;EAC1B,wBAAwB;AAC1B;AACA;EACE,2BAA2B;EAC3B,6BAA6B;AAC/B;AACA;EACE,4BAA4B;AAC9B;AACA;EACE,eAAe;AACjB","sourcesContent":[".ant-table-thead {\n  background: #f2f2f2;\n  font-family: \"Inter\";\n  font-style: normal;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 22px;\n  text-transform: capitalize;\n  color: var(--text-color);\n}\n.ant-table {\n  font-family: \"Inter\" !important;\n  font-style: normal !important;\n  font-weight: 400 !important;\n  font-size: 14px !important;\n  line-height: 19px !important;\n  text-transform: capitalize;\n  color: var(--text-color);\n}\n.ant-pagination {\n  margin-top: 10px !important;\n  margin-bottom: 5px !important;\n}\n.ant-table-row-selected > td {\n  background: unset !important;\n}\n.ant-table-tbody tr:hover {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
