import React from 'react';
import { Modal } from 'antd';
import '../../components/modalCss.css'
function AntModal(props) {
    const { children, ...restProps } = props;
    return (
        <Modal {...restProps}>
            {children}
        </Modal>
    );
}

export default AntModal;