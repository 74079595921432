import * as yup from 'yup';

export const accoutInfoValidationSchema = yup.object().shape({
    companyName: yup.string().required("Organization Name is required"),
    // adminName: yup.string().required("Admin Name is required"),
    email: yup.string().email().required("Email Address is required"),
    mobile: yup.string().required("Contact Number is required"),
    customerType: yup.string().required("OrganizationType is required"),
    address1: yup.string().required("Address1 is required"),
    address2: yup.string().required("Address2 is required"),
    city: yup.string().required("City is required"),
    country: yup.string().required("Country is required"),
    postalCode: yup.string().required("Postal Code is required"),
    primaryRtnNumber:yup.string().matches(/^[a-zA-Z0-9]{9}$/, 'Input must be 9 alphanumeric characters'),
    contactPerson: yup.string().required("Contact Person is required"),
    status: yup.number().required("Status is required"),
    validityStartDate:yup.string().required('Validity Start Date is required'),
    validityEndDate:yup.string().required('Validity End Date is required'),
});

export const customerInfoValidationSchema = yup.object().shape({
    organisationName: yup.string().required("Organization Name is required"),
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    email: yup.string().email().required("Email Address is required"),
    mobile: yup.string().required("Contact Number is required"),
    organisationType: yup.number().typeError("Organization Type is required").required(),
    address1: yup.string().required("Address1 is required"),
    address2: yup.string().required("Address2 is required"),
    city: yup.string().required("City is required"),
    country: yup.string().required("Country is required"),
    postalCode: yup.string().required("Postal Code is required"),
});