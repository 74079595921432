import { Box, InputAdornment, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import '../../components/homeScreens/homeScreen.css'
import PayaptTextField from '../../shared/components/TextField';
import { INPUT_HEIGHT } from '../../shared/dims';
import { filterByValue } from './utils';
import { ExternalLinkIcon } from '../../assets/base64/images';
import { DATA } from './BatchFiles';
import TestCasesSelection from './TestCasesSelection.second';
// import DATA from './BatchFiles'
import DraggableTestcase from './draggableTestcase';
import { useGetAllBatchFilesQuery } from '../../redux/batchFiles/batchFile.api';
import PayaptLoader from '../../shared/components/Spinner';
import BatchBoxTitle from './BatchBoxTitle';
import { useSelector } from 'react-redux';

const styles = {
    container: {
        display: "flex",
        flexDirection: "column"
    },
    boxContainer: {
        border: '1px solid rgba(0,0,0, 0.1)',
        p: 2,
        height: '55vh',
        overflowY: 'auto'
    },
    searchContainer: {
        width: "100%",
    },
    contentContainer: {
        // p: 2
        mt: 1,
        maxHeight: "50vh",
        overflowY: "auto"
    },
    cardStyle: {
        width: '100%',
        display: "flex",
        alignItems: "flex-start",
        px: 2,
        backgroundColor: "rgba(0,0,0, 0.05)",
        my: 2,
        py: 2,
        borderRadius: '4px'
    },
    inputSx: {
        '.MuiOutlinedInput-notchedOutline': {
            borderRadius: '4px'
        },
        '.MuiInputBase-root': {
            height: INPUT_HEIGHT
        }
    }
}

function BatchFileSelection(props) {
    const { setSelectedBatch, selectedBatch, onPreview, type = 'SA', 
    onBatchSelectionChange, batchContextToRun, displayAllBatches=false,customer } = props;
    const [searchTerm, setSearchTerm] = React.useState(null);
    const { userRole} = useSelector((state) => state?.common?.userDetails);
    // const { customerId } = useSelector((state) => state?.common);

    const getPayload = () => {
        const payload = {
            testType: type,
            customersId:userRole?.name == 'Super Admin'? customer : 0,
        }
        if (searchTerm) {
            payload.batchName = searchTerm
        }
        payload.status = displayAllBatches ? 'all' : 'individual'
        return payload;
    }

    
    const { data, isFetching, refetch } = useGetAllBatchFilesQuery({...getPayload()});

    useEffect(() => {
        refetch()
    }, [type, displayAllBatches,])

    const handleOnSearchChange = (xValue) => {
        setSearchTerm(xValue);
    }

    const isBatchSelected = (batchId) => {
        const batchIndex = _.findIndex(batchContextToRun, b => b.batchId === batchId)
        return batchIndex >=0;
    }
    return (
        <Box sx={styles.container}>
            <BatchBoxTitle title={`Batch Files (${_.size(data)})`} />
            <Box sx={styles.boxContainer}>
                <Box sx={styles.searchContainer}>
                    <PayaptTextField
                        fullWidth
                        sx={styles.inputSx}
                        placeholder="Search here ..."
                        value={searchTerm}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}
                        onChange={(event) => handleOnSearchChange(event.target.value)} />
                </Box>
                {data?.length !== 0 && data !== undefined ?
                (<Box sx={styles.contentContainer}>
                    {isFetching && <PayaptLoader />}
                    {data?.map((xRes, xIdx) => (
                        <DraggableTestcase
                            title={xRes?.batchName}
                            index={xIdx}
                            className={xRes?.customerTestRunBatchId === selectedBatch?.customerTestRunBatchId ? 'sharp-background arrow-right' : 'without-sharp'}
                            onClick={() => {
                                setSelectedBatch(xRes);
                            }}
                            item={xRes}
                            actions={[
                                {
                                    icon: <ExternalLinkIcon />,
                                    onClick: (e, data) => {
                                        e.stopPropagation();
                                        onPreview(true, xRes);
                                    }
                                }
                            ]}
                            showSelection = { true }
                            isSelected = { isBatchSelected(xRes?.customerTestRunBatchId) }
                            onSelectionChanged = {(e, item) => {
                                e.stopPropagation();
                                onBatchSelectionChange(e.target.checked, item)
                            }}
                        />
                    ))}
                </Box>) : 'NO DATA'
                 }
            </Box>
        </Box>
    );
}

export default BatchFileSelection;
