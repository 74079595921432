// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  display: flex;
  height: 24px;
  top: 123px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;

  color: #001a11;
}
.title-content {
  /* width: 350px; */
  height: 17px;
  left: 286px;
  top: 153px;

  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/components/styles/configStyles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,UAAU;;EAEV,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,0BAA0B;;EAE1B,cAAc;AAChB;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,UAAU;;EAEV,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".title {\n  display: flex;\n  height: 24px;\n  top: 123px;\n\n  font-family: \"Inter\";\n  font-style: normal;\n  font-weight: 600;\n  font-size: 20px;\n  line-height: 24px;\n  text-transform: capitalize;\n\n  color: #001a11;\n}\n.title-content {\n  /* width: 350px; */\n  height: 17px;\n  left: 286px;\n  top: 153px;\n\n  font-family: \"Inter\";\n  font-style: italic;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 17px;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
