import React from 'react';
import { Layout, Steps } from 'antd';
import _ from 'lodash';
import ChooseParticipationType from '../components/onboarding/ChooseParticipationType';
import SideMenu from '../components/sideMenu';

const { Sider, Content } = Layout;
const steps = [{
    title: 'Select',
    description: 'Participation Type'
},
{
    title: 'Configure',
    description: 'Participation Type'
},
{
    title: 'Configure',
    description: 'Test Data'
},
{
    title: 'Setup',
    description: 'Test Data'
}
]

const OnboardingStepper = (props) => {
    return (
        <Layout>
            <Sider theme='light' >
                <SideMenu />
            </Sider>
            <Content theme='light' style={{ backgroundColor: '#ffffff' }}>
                <div style={{ margin: 'auto', marginTop: 40, width: '80%', justifyContent: 'center' }}>
                    <Steps current={1} items={steps} />
                    <ChooseParticipationType />
                </div>
            </Content>
        </Layout>

    )
}

export default OnboardingStepper;