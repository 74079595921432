import React from "react";
import { useNavigate } from "react-router-dom";
import { messageTypes, standaloneMessageTypes, directionTagColor, statusTagColor } from "../../constants/constants";
import { Checkbox, Table, Tag } from "antd";
import '../styles/message.css'
import PageHeader from "../pageHeader";
import _ from "lodash";
import MessageListItem from "./messageListItem";
import {PlayCircleOutlined } from '@ant-design/icons'

const columns = [
    {
        title: 'Message Type',
        dataIndex: 'messageType',
        key: 'messageType'
    },
    {
        title: 'Message Description',
        dataIndex: 'messageDescription',
        key: 'messageDescription'
    },
    {
        title: 'Direction',
        dataIndex: 'direction',
        key: 'direction'
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status'
    },
    {
        title: 'Action',
        dataIndex: 'action',
        key: 'action'
    },
]
const MessageList = () => {
    const navigate = useNavigate();
    let keys = [];
    if(!_.isEmpty(standaloneMessageTypes)){
        keys = Object.keys(standaloneMessageTypes[0])
    }

    // columns[0].render = (data, record) => <div>
    //     <Checkbox/>&nbsp;&nbsp;&nbsp;&nbsp;{data}
    // </div>
    columns[4].render = () => <PlayCircleOutlined 
    style={{fontSize: '18px', alignSelf: 'center', justifySelf: 'center', fontWeight: 200}}
    onClick = {() => navigate('/message/testcases')}
    />
    columns[2].render = (data) => data ? <Tag color={directionTagColor[data]}>{data}</Tag> : null
    columns[3].render = (data) => data ? <Tag color={statusTagColor[data]}>{data}</Tag> : null
    return (
        <div>
            <PageHeader pageTitle = 'Message List'/>
            <Table 
                columns = {columns}
                dataSource = {standaloneMessageTypes}
                bordered = {false}
            />
            {/* <List
                header={<MessageListItem item={headers} keys={Object.keys(headers)} isTitle={true}/>}
                footer={null}
                dataSource={standaloneMessageTypes}
                bordered
                renderItem={(item) => (
                    <List.Item>
                        <MessageListItem item={item} keys={keys} isTitle={false}/>
                    </List.Item>
                )}
                /> */}
        </div>
    )
}

export default MessageList;