import * as yup from 'yup';

export const newRegistrationValidationSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
    // email: yup.string().email().required("Email is required"),
  contactNumber: yup.string().required("Mobile Number is required")

});

export const newPasswordValidationSchema = yup.object().shape({
  oldPassword: yup.string().required("Current Password is required"),
  newPassword: yup.string().required("New Password is required").min(8, 'Password is too short - should be 8 chars minimum')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
    confirmPassword: yup.string().when('newPassword', (newPassword, field) =>
    newPassword ? field.required("Confirm Password is required").oneOf([yup.ref('newPassword')], 'Passwords are not matching') : field
  ),

});

export const resetPasswordValidationSchema = yup.object().shape({
  email: yup.string().required("Email Address is Required"),
  newPassword: yup.string().required("New Password is required").min(8, 'Password is too short - should be 8 chars minimum')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
    confirmPassword: yup.string().when('newPassword', (newPassword, field) =>
    newPassword ? field.required("Confirm Password is required").oneOf([yup.ref('newPassword')], 'Passwords are not matching') : field
  ),

});