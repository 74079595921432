import * as yup from 'yup';

export const customerValidationSchema = yup.object().shape({
    customerName: yup.string().required("Organization Name is required"),
    customerType: yup.number().required("Organization Type is required"),
    correspondentCustomerId: yup.string(),
    // participationTypeId: yup.number().required("Participation Type is required"),
    address1: yup.string().required("Address1 is required"),
    address2:yup.string().required("Address2 is required"),
    city: yup.string().required("City is required"),
    country: yup.string().required("Country is required"),
    postalCode: yup.string().required("Postal Code is required"),
    contactNumber: yup.string().required("Contact Number is required"),
    contactPerson: yup.string().required("Contact Person is required"),
    email: yup.string().email().required("Email Address is required"),
    // subscriptionPlanId: yup.number().required("Subscription Plan is required"),
    // routingNumber: yup.string().matches(/^[a-zA-Z0-9]{9}$/, 'RTN should contains 9 alphanumeric characters'),
    // registeredDate: yup.string().required("Registered Date is required"),
    validityStartDate:yup.string().required('Validity Start Date is required'),
    validityEndDate:yup.string().required('Validity End Date is required'),
    accountStatus: yup.number().required("Status is required"),
    // routingNumber: yup.string().when('customerType', {
    //     is: 1,
    //     then: yup.string().matches(/^[a-zA-Z0-9]{9}$/, 'RTN should contain 9 alphanumeric characters'),
    //     otherwise: yup.string()
    // }),
    routingNumber:yup
    .string()
    .test('isRoutingNumberRequired','RTN should contain 9 alphanumeric characters', function(value) {
        const customerType = this.resolve(yup.ref('customerType'));
        return customerType === 1 ? /^[a-zA-Z0-9]{9}$/.test(value) : true;
    })
    
});

export const routingNumberValidation = yup.object().shape({
    routingNumber: yup.string().matches(/^[a-zA-Z0-9]{9}$/, 'RTN should contains 9 alphanumeric characters'),
});

