import { backendApi } from "../base.config";

export const settingsApi = backendApi.injectEndpoints({
    endpoints: (builder) => ({
        getSimulatorTestDataDetails: builder.mutation({
            query: (payload) => ({
                method: "POST",
                url: `Simulator/getSimulatorTestDataDetails`,
                body: payload,
            }),
        }),
        updateSimulatorTestData: builder.mutation({
            query: (payload) => ({
                method: "POST",
                url: `Simulator/updateSimulatorTestData`,
                body: payload,
            }),
        }),
        getSimulatorTestDataList: builder.mutation({
            query: (payload) => ({
                url: `Simulator/simulatorTestDataList?customerId=${payload.customerId}&directionStatus=${payload.directionStatus}&page=${payload.page}&size=${payload.size}`,
                method: 'POST',
                body: payload,
            }),
        }),
        approveCustomerData: builder.mutation({
            query: (payload) => ({
                method: "POST",
                url: `Simulator/ApproveCustomerTestData`,
                body: payload
            })
        }),
        downloadSimulatorTestDataDetails: builder.mutation({
            query: (payload) => ({
                url: `Simulator/downloadSimulatorTestDataDetails?customerSimTestDataId=${payload}`,
                // body:payload
            })
        }),
        getConfigrationParametersDetail: builder.mutation({
            query: (payload) => ({
                method: "POST",
                url: `Simulator/getConfigrationParametersDetail`,
                body: payload
            })
        }),
        UploadSimulatorTestDataDetails: builder.mutation({
            query: (payload) => ({
                method: "POST",
                url: `Simulator/UploadSimulatorTestDataDetails`,
                body: payload
            })
        }),
        updateConfigurationParametersDetails: builder.mutation({
            query: (payload) => ({
                method: "POST",
                url: `Simulator/updateConfigrationParametersDetail`,
                body: payload
            })
        }),
        testCountsBasedOnPartType: builder.mutation({
            query: (payload) => ({
                method: "POST",
                url: `Simulator/tcCountsBasedOnPartType`,
                body: payload
            })
        }),
        getCustomerDeatilsInOnboarding: builder.mutation({
            query: (payload) => ({
                method: "POST",
                url: `Simulator/getCustomerDeatilsInOnboarding`,
                body: payload
            })
        }),
        getConfigureSettlementSimulatorDropdown: builder.query({
            query: () => ({
                method: "GET",
                url: `Simulator/getconfigureSettlementSimulatorDropdown`,
            }),
            providesTags: [],
        }),
        getConfigurePaymentGatewayDropdown: builder.query({
            query: () => ({
                method: "GET",
                url: `Simulator/getconfigurePaymentGatewayDropdown`,
            }),
            providesTags: [],
        }),
        updateCustomerOrganizationDeatils: builder.mutation({
            query: (payload) => ({
                method: "POST",
                url: `Simulator/updateCustomerDeatilsInOnboarding`,
                body: payload
            })
        }),
        getSimulatorTestDataWithType: builder.mutation({
            query: (payload) => ({
                method: "POST",
                url: `Simulator/getSimulatorTestDataWithType`,
                body: payload
            })
        })
        ,
        simulatorList: builder.mutation({
            query: (payload) => ({
                method: "POST",
                url: `Simulator/simulatorList`,
                body: payload
            })
        }),
        cloneCustomerSimTestDataDetail: builder.mutation({
            query: (payload) => ({
                method: "POST",
                url: `Simulator/cloneCustomerSimTestDataDetail`,
                body: payload
            })
        }),
        updateCustomerSimulatorTestDataList: builder.mutation({
            query: (payload) => ({
                method: "POST",
                url: `Simulator/updateCustomerSimulatorTestDataList`,
                body: payload
            })
        }),
        listofsimtestdatatype: builder.query({
            query: () => ({
                method: "GET",
                url: `Simulator/listofsimtestdatatype`,
            })
        }),
        saveSimulatortestdata: builder.mutation({
            query: (payload) => ({
                method: "POST",
                url: `Simulator/saveSimulatortestdata`,
                body: payload
            })
        }),
        simulatorTypeDropdown: builder.mutation({
            query: (args) => ({
                method: "POST",
                url: `Simulator/simulatorTypeDropdown`,
                params: { ...args },
            })
        }),
    }),
})

export const {
    useGetSimulatorTestDataDetailsMutation,
    useUpdateSimulatorTestDataMutation,
    useGetSimulatorTestDataListMutation,
    useApproveCustomerDataMutation,
    useDownloadSimulatorTestDataDetailsMutation,
    useGetConfigrationParametersDetailMutation,
    useUploadSimulatorTestDataDetailsMutation,
    useUpdateConfigurationParametersDetailsMutation,
    useTestCountsBasedOnPartTypeMutation,
    useGetCustomerDeatilsInOnboardingMutation,
    useGetConfigureSettlementSimulatorDropdownQuery,
    useGetConfigurePaymentGatewayDropdownQuery,
    useUpdateCustomerOrganizationDeatilsMutation,
    useGetSimulatorTestDataWithTypeMutation,
    useSimulatorListMutation,
    useCloneCustomerSimTestDataDetailMutation,
    useUpdateCustomerSimulatorTestDataListMutation,
    useListofsimtestdatatypeQuery,
    useSaveSimulatortestdataMutation,
    useSimulatorTypeDropdownMutation,
} = settingsApi;