import { configureStore } from '@reduxjs/toolkit';
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { combineReducers } from "redux";

import { authenticationMiddleware } from './middleware';

import { authSlice } from './auth/auth.slice';
import { backendApi } from './base.config';
import { customerOnBoardingSlice } from './customerOnBoarding/customerOnBoarding.slice';
import { userProfileSlice } from './userProfile/userProfile.slice';
import { commonSlice } from './common/common.slice';
import { testCasesSlice } from './testCases/testCases.slice';
import { registrationSlice } from './registration/registration.slice';

const commonPersistConfig = {
  key: "common",
  version: 1,
  storage
};
const customerOnBoardPersistConfig = {
  key: "customerOnBoarding",
  version: 1,
  storage
};
const reducer = combineReducers({
  // Add the generated reducer as a specific top-level slice
  [backendApi.reducerPath]: backendApi.reducer,
  [authSlice.name]: authSlice.reducer,
  [customerOnBoardingSlice.name]: persistReducer(customerOnBoardPersistConfig, customerOnBoardingSlice.reducer),
  [userProfileSlice.name]: userProfileSlice.reducer,
  [commonSlice.name]: persistReducer(commonPersistConfig, commonSlice.reducer),
  [testCasesSlice.name]: testCasesSlice.reducer,
  [registrationSlice.name]: registrationSlice.reducer
});

export const store = configureStore({
  reducer: reducer,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(authenticationMiddleware, backendApi.middleware),
});

export const persistor = persistStore(store)