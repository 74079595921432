// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-heading-executed{
    color: #ACACAC;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
}

.direction-title{
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold; 
    font-size: 16px;
}
.progress-circle{
    
    width: 97%;
    height: 130px;
    
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(240, 242, 245, 0.8)!important;
    border-radius: 10px;
    box-shadow: 0px;
    }`, "",{"version":3,"sources":["webpack://./src/components/DashboardPage/progressCircle.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;AACnB;AACA;;IAEI,UAAU;IACV,aAAa;;IAEb,mBAAmB;IACnB,0DAA0D;IAC1D,mBAAmB;IACnB,eAAe;IACf","sourcesContent":[".content-heading-executed{\n    color: #ACACAC;\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 400;\n}\n\n.direction-title{\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: bold; \n    font-size: 16px;\n}\n.progress-circle{\n    \n    width: 97%;\n    height: 130px;\n    \n    background: #FFFFFF;\n    box-shadow: 0px 4px 4px rgba(240, 242, 245, 0.8)!important;\n    border-radius: 10px;\n    box-shadow: 0px;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
