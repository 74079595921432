import React from 'react';
import { Table, ConfigProvider } from 'antd';
import PropTypes from 'prop-types'
import { colors } from '../colors';
import { func } from 'prop-types';

const PayaptTable = (props) => {
    const { columns, data, loading, className, rowClassName, rowKey, onChange, pagination, size, scroll,
    onRow, expandable, primaryColor, ...restProps } = props;
    return (
        <ConfigProvider
            theme={{
              token: {
                colorPrimary: colors.primary.main,
              },
            }}
          >
            <Table
                {...restProps}
                columns={columns}
                dataSource = {data}
                loading = {loading}
                className = {className}
                rowClassName = {(record, index) => rowClassName(record, index)}
                rowKey = {(record, index) => rowKey(record, index)}
                onChange={(pagination, filters, sorter) => onChange(pagination, filters, sorter)}
                pagination = {{...pagination, showSizeChanger: false}}
                size={size}
                scroll = {scroll}
                onRow={(record, rowIndex) => onRow(record, rowIndex)}
                expandable = {expandable}
            />
        </ConfigProvider>
    )
}

PayaptTable.defaultProps = {
    columns: [],
    data: [],
    loading: false,
    className: '',
    rowClassName: () => '',
    rowKey: () => '',
    onChange: () => null,
    pagination: {
        hideOnSinglePage: true,
        size: 'small'
    },
    size:'small',
    scroll: { y: "50vh" },
    rowSelection: null,
    onRow: () => null,
    expandable: {},
    primaryColor: colors.primary.main,
}
PayaptTable.propTypes = {
    columns: PropTypes.arrayOf(Object),
    data: PropTypes.arrayOf(Object),
    loading: PropTypes.bool,
    className: PropTypes.string,
    rowClassName: PropTypes.instanceOf(func),
    rowKey: PropTypes.instanceOf(func),
    onChange: PropTypes.instanceOf(func),
    pagination: PropTypes.instanceOf(Object),
    size: PropTypes.string,
    scroll: PropTypes.instanceOf(Object),
    rowSelection: PropTypes.instanceOf(Object),
    onRow: PropTypes.instanceOf(func),
    expandable: PropTypes.instanceOf(Object),
    primaryColor: PropTypes.string,
}

export default PayaptTable;