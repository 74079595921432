import React, { useEffect } from "react";
import Dropdown from "../../shared/components/Dropdown";
import _ from "lodash";


const styles = {
    menuProps: {
        sx: {
            '.MuiPaper-root': {
                maxHeight: '40%',
            },
        },
    },
}




const data = [
    {
        inboundOrOutbound: "Inbound",
        inboundOrOutboundId: "IB"
    },
    {
        inboundOrOutbound: "Outbound",
        inboundOrOutboundId: "OB"
    },
]

const InboundOutboundDropdown = (props) => {



    const { onChangeInboundOrOutbound, value, isClear } = props

    useEffect(() => {
        if (!_.isEmpty(data)) {
            onChangeInboundOrOutbound(data[0]?.inboundOrOutboundId)
        }
    }, [data])

    return (
        <>
            <Dropdown
                placeholder='Select Inbound/Outbound'
                margin="dense"
                className="user-dropdown"
                style={{ width: '100%', height: '42px', marginTop: '-20px' }}
                inputProps={{ 'aria-label': 'Without label' }}
                options={data}
                valueKey='inboundOrOutboundId'
                labelKey='inboundOrOutbound'
                onChange={(e) => onChangeInboundOrOutbound(e)}
                value={value}
                MenuProps={styles.menuProps}
                isClear={isClear}
            // disabled = {!!selectedRecord?.userId}
            />
        </>
    )
}

export default InboundOutboundDropdown;