import { Route, Routes } from "react-router-dom"
import { Layout } from 'antd';

import AppHeader from '../components/header';
import OnboardingMenu from '../components/onboarding/menu';
import OnBoardingProcessDone from '../components/onboarding/onBoardingProcessDone';
import NotFoundPage from "../components/NotFoundPage";

const { Header, Content, Footer } = Layout;

const OnboardingRoutes = () => {
  console.log('[OnboardingRoutes]');

  return (
    <Layout style={{ height: '100vh' }}>
      <Header className='app-header'><AppHeader /></Header>
      <Content style={{ backgroundColor: '#ffffff', paddingTop: 0, paddingLeft: 0, marginTop: 0 }}>
        <Routes>
          {/* OnboardingRoutes */}
          <Route path='/customer/onboarding' element={<OnboardingMenu />} />
          <Route path='/onboardprocessdone' element={<OnBoardingProcessDone />} />
          {/* unknown routes */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Content>
      <Footer className='app-signin-footer'>
        <div className='signin-footer'>
          <span>Payapt &#169; 2023</span>
          <div className='privacy-terms'>
            <span>Privacy</span>&nbsp;&nbsp;
            <span>Terms</span>
          </div>
        </div>
      </Footer>
    </Layout>
  )
}

export default OnboardingRoutes;