import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InfoIcon from '@mui/icons-material/Info';
import ChildHeader from '../../shared/components/ChildHeader';
import PayaptTextField from '../../shared/components/TextField';
import { batchDescriptionApiKey, batchNameApiKey, createBatchFileValidationSchema } from '../BatchFiles/utils';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
import { convertAndFormatToUtcDate } from '../../shared/utils';
import MUIButton from '../../shared/components/MUIButton';

const styles = {
    titleStyle: {
        fontSize: "1rem",
        fontWeight: "500"
    },
    subTitleStyle: {
        fontSize: "0.85rem",
        fontWeight: "300",
        color: "rgba(0,0,0,0.85)"
    }
}

function CreateBatchFiles(props) {
    const { onCancel, selectedTestScenarios, createBatch, selectedBatch, isUpdateScenario = false } = props;
    const Snackbar = useContext(SnackbarContext);

    console.log("🚀 ~ file: CreateBatchFile.testScenarios.js:28 ~ CreateBatchFile ~ selectedBatch:", {selectedBatch});
    
    const selTestCasesLength = 0;

    const selBatchFileName = selectedBatch?.testRunBatchName || "";
    const modalHeaderTitle = !isUpdateScenario ? "Create Batch File" : "View & Edit Batch File";
    const modalHeaderSubTitle = !isUpdateScenario ? `Selected test cases - ${selTestCasesLength}` : `Selected ${selBatchFileName}`;

    const resolver = yupResolver(createBatchFileValidationSchema);

    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver,
        defaultValues: {
            [batchNameApiKey]: selectedBatch?.testRunBatchName,
            [batchDescriptionApiKey]: selectedBatch?.testRunBatchDescription
        }

    });

    React.useEffect(() => {
        reset({
            [batchNameApiKey]: selectedBatch?.testRunBatchName,
            [batchDescriptionApiKey]: selectedBatch?.testRunBatchDescription
        })
    }, [selectedBatch])

    const onSubmit = async (data) => {
        createBatch(data);
    }

    return (
        <Box>
            <ChildHeader
                title={modalHeaderTitle}
                // subTitle={modalHeaderSubTitle}
                // IconComponent={InfoIcon}
                onCancel={onCancel} />

            <Box p={2}>
                {!isUpdateScenario ? (<Box display={"flex"} alignItems={"flex-start"}>
                    <Typography>{"Create New Batch File"}</Typography>
                </Box>) : (
                    <Grid container my={1.5} justifyContent="space-between">
                        <Grid item xs={6} display="flex" alignItems="flex-start" flexDirection={"column"}>
                            <Typography sx={styles.titleStyle}>Created Date</Typography>
                            <Typography sx={styles.subTitleStyle}>{convertAndFormatToUtcDate(selectedBatch?.batchCreatedDate)}</Typography>
                        </Grid>
                        <Grid item xs={6} display="flex" alignItems="flex-start" flexDirection={"column"}>
                            <Typography sx={styles.titleStyle}>Last Modified Date</Typography>
                            <Typography sx={styles.subTitleStyle}>{convertAndFormatToUtcDate(selectedBatch?.modifiedDate)}</Typography>
                        </Grid>
                    </Grid>
                )}

                <Box my={2}>
                    <Controller
                        control={control}
                        name={batchNameApiKey}
                        render={({ field }) => (
                            <PayaptTextField
                                {...register(`${batchNameApiKey}`)}
                                {...field}
                                name={batchNameApiKey}
                                label="Batch File Name"
                                placeholder='Enter Batch File Name'
                                margin='dense'
                                required
                                error={!!errors[batchNameApiKey]}
                                helperText={errors[batchNameApiKey]?.message}
                            />
                        )}
                    />
                </Box>
                <Box my={2}>
                    <Controller
                        control={control}
                        name={batchDescriptionApiKey}
                        render={({ field }) => (
                            <PayaptTextField
                                {...register(`${batchDescriptionApiKey}`)}
                                {...field}
                                name={batchDescriptionApiKey}
                                label="Batch File Description"
                                placeholder='Enter Batch File Description'
                                margin='dense'
                                required
                                multiline
                                maxRows={3}
                                error={!!errors[batchDescriptionApiKey]}
                                helperText={errors[batchDescriptionApiKey]?.message}
                            />
                        )}
                    />
                </Box>
            </Box>
            <Box m={2}>
                <MUIButton
                    title="Submit"
                    fullWidth
                    onClick={handleSubmit(onSubmit)}
                // disabled = {selectedBatch?.batchName}
                />
            </Box>
        </Box>
    );
}

export default CreateBatchFiles;