import React, { useState, useEffect, useContext, useRef } from 'react';
import { testData } from '../../constants/constants';
import { Input, Row, Col, message } from 'antd';
import { SearchOutlined, LeftCircleFilled, CheckCircleFilled } from '@ant-design/icons'
import '../styles/customTestCaseScrollbar.css'
import { Box } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { bindActionCreators } from 'redux'
import { connect, useSelector } from 'react-redux';
import { getAllTestCasesOfTheScenario, getXpathAndValues, reviewTheXpathData } from '../../actions/testcases'
import CircularProgress from '@mui/material/CircularProgress';
import {
    useAddedTestDataDetailsMutation,
    useCloneTestDataMutation, useDeleteTestDataMutation, useDeleteXpathAndValueMutation, useGetAllTestCaseDetailsByScenarioIdQuery,
    useGetTestRunDataUsingTestRunIdMasterMutation,
    useGetTestRunDetailsWithXpathandValueMutation,
    useGetXpathAndValueDetailsOfTestcaseQuery, useLazyGetTestDataDetailsByTestDataIdQuery,
    useLazyGetTestDataSbyVersionIdQuery, useReviewTestcasesMutation, useUpdateXpathAndValueMasterMutation, useUpdateXpathAndValueMutation
} from '../../redux/testCases/testCases.api';
import Menu from '../../shared/components/Menu/menu';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ExpandIcon } from '../../constants/menuIcons';
import { ALERT_SEVERITY } from '../../shared/constants/constants';
import { TestCasesMsg } from '../../shared/constants/AlertMessages';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
import TestcaseDetailsHeader from '../testcaseDetailsHeader';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PayaptTooltip from '../../shared/components/Tooltip';
import MUIButton from '../../shared/components/MUIButton';
import _ from 'lodash';

export const TestData = (props) => {
    const Snackbar = useContext(SnackbarContext);
    const { userRole } = useSelector((state) => state?.common?.userDetails);

    const { testCaseScenarioId, showSingleTestcase, testcaseDetails,
        onSelectionChange, selectedTestScenario, masterTestCase } = props;

    const [selectedTestCase, setSelectedTestCase] = useState({})
    const [data, setData] = useState([]);
    const [isLastPage, setIsLastPage] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [currentXpathPage, setCurrentXPathPage] = useState(0);
    const [isXPathLastPage, setIsXPathLastPage] = useState(false);
    const [xpathValues, setXpathValues] = useState([]);
    const [pageOptions, setPageOptions] = useState({ pageNo: 0, pageSize: 100, customerTestScenarioId: null, searchByTestCaseName: '' });
    const [xpathPageOptions, setXPathPageOptions] = useState({ pageNo: 0, pageSize: 100, customerTestCaseVersionId: null });
    const [selectedItem, setSelectedItem] = useState({});

    useEffect(() => {
        if (_.isEmpty(data)) {
            getData({ pageNo: 0, selectDefaultTestCase: true });
        }
    }, [])
    useEffect(() => {
        if (!_.isEmpty(selectedItem) && !showSingleTestcase) {
            const xPayload = {
                customerTestCaseVersionId: selectedItem?.testCaseVersionId
            }
            fetchTestDataSbyVersionId(xPayload);
        }
    }, [selectedItem])

    useEffect(() => {
        if (!_.isEmpty(selectedTestCase) && !showSingleTestcase) {
            getXpathDetailsOfTestCase({ pageNo: 0, resetData: true, customerTestDataId: selectedTestCase?.customerTestCaseDataId, testDataId: selectedTestCase?.testDataId });
        }
    }, [selectedTestCase])

    const { data: testcasesList, isFetching } = useGetAllTestCaseDetailsByScenarioIdQuery({ ...pageOptions }, {
        skip: _.isEmpty(pageOptions.customerTestScenarioId) || showSingleTestcase // this will skip the fetchCall when qpTestType value is empty
    });
    const [getTestRunDetails] = useGetTestRunDetailsWithXpathandValueMutation();
    const [getTestRunMaster] = useGetTestRunDataUsingTestRunIdMasterMutation();
    // const { data: xpathAndValueList, isFetching: xpathValuesLoading } = useGetXpathAndValueDetailsOfTestcaseQuery({ ...xpathPageOptions }, {
    //     skip: _.isEmpty(xpathPageOptions.customerTestCaseVersionId) // this will skip the fetchCall when qpTestType value is empty
    // });
    const [xpathAndValueList, setXpathAndValueList] = useState([]);

    const [reviewXpathData, { data: reviewData }] = useReviewTestcasesMutation();
    // clone queries // mutations
    const [cloneTestData] = useCloneTestDataMutation()
    const [getTestDataSbyVersionId] = useLazyGetTestDataSbyVersionIdQuery();
    const [getTestDataDetailsByTestDataId, { isFetching: xpathValuesLoading, refetch }] = useLazyGetTestDataDetailsByTestDataIdQuery();
    const [updateXpathandValue] = useUpdateXpathAndValueMutation();
    const [deleteTestData, { data: deleteData }] = useDeleteTestDataMutation();
    const [addedXpathandValue] = useAddedTestDataDetailsMutation();
    const [updateXpathandValueMaster] = useUpdateXpathAndValueMasterMutation();


    useEffect(() => {
        if (_.isEmpty(testcasesList) && !isLastPage) {
            setIsLastPage(true)
        } else {
            setIsLastPage(false)
        }
        if (!showSingleTestcase) {
            const updatedData = (!!pageOptions.searchByTestCaseName) ? [] : [...data];
            _.map(testcasesList, d => updatedData.push(
                {
                    ...d,
                    extra: <PayaptTooltip title='Clone Test Data'>
                        <ContentCopyIcon fontSize='small' sx={{ color: '#676878' }} />
                    </PayaptTooltip>
                }));
            setData(updatedData)
            if (_.isEmpty(selectedTestCase) || !!pageOptions.searchByTestCaseName) {
                setSelectedItem(updatedData[0])
            }
            if (_.isEmpty(updatedData)) {
                setSelectedItem({})
                setSelectedTestCase({})
                setXpathAndValueList([])
            }
        }
    }, [testcasesList, pageOptions.pageNo])

    // useEffect(() => {
    //     if (_.isEmpty(xpathAndValueList) && !isXPathLastPage) {
    //         setIsXPathLastPage(true)
    //     } else {
    //         setIsXPathLastPage(false)
    //     }
    //     if (!_.isEmpty(xpathAndValueList)) {
    //         const updatedData = [...xpathAndValueList];
    //         _.map(xpathAndValueList, d => updatedData.push(d));
    //         setXpathAndValueList(updatedData)
    //     }
    // }, [xpathAndValueList, xpathPageOptions.pageNo])

    // useEffect(() => {
    //     if (!_.isEmpty(xpathAndValueList)) {
    //         setXpathValues(xpathAndValueList)
    //     }
    // }, [xpathAndValueList])

    useEffect(() => {
        if (!_.isEmpty(selectedTestScenario?.customerTestRunId)) {
            getTestRunDetailsWithXpathAndValue();
        }
    }, [selectedTestScenario?.customerTestRunId])
    const getTestRunDetailsWithXpathAndValue = async () => {
        try {
            const res = await getTestRunDetails({ customerTestRunId: selectedTestScenario?.customerTestRunId });
            if (!_.isEmpty(res.data)) {
                const testDataVersion = {
                    name: res.data?.customerTestDataNumber,
                    customerTestCaseDataId: res.data?.testDataId,
                    icon: (isActive) => <ExpandIcon isActive={isActive} />
                }
                const data = [{
                    testCaseName: res.data?.customerTestCaseName,
                    testCaseVersionId: res.data?.customerTestCaseName,
                    versions: [testDataVersion]
                }];
                const xpathData = res.data?.customerTestRunDataDTO;
                setData(data);
                setSelectedItem(!_.isEmpty(data) ? data[0] : {})
                setSelectedTestCase(testDataVersion)
                setXpathAndValueList(xpathData)
                // console.log("xpathData",xpathData);
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (!_.isEmpty(selectedTestScenario?.testCaseId)) {
            getTestRunMasterWithXpathAndValue();
        }
    }, [selectedTestScenario?.testCaseId])


    const getTestRunMasterWithXpathAndValue = async () => {
        try {
            const res = await getTestRunMaster({ testCaseId: selectedTestScenario?.testCaseId });
            if (!_.isEmpty(res.data)) {
                // console.log("res.DATA", res.data);
                const testDataId = res.data?.testDataId
                const testDataVersion = {
                    name: res.data?.testDataNumber,
                    customerTestCaseDataId: res.data?.testDataId,
                    icon: (isActive) => <ExpandIcon isActive={isActive} />
                }
                const data = [{
                    testCaseName: res.data?.testCaseName,
                    testCaseVersionId: res.data?.testCaseName,
                    versions: [testDataVersion]
                }];
                const xpathDataMaster = res.data?.testRunDataDTOs;
                setData(data);
                setSelectedItem(!_.isEmpty(data) ? data[0] : {})
                setSelectedTestCase(testDataVersion)
                setXpathAndValueList(xpathDataMaster)
            }
        } catch (error) {
            console.log("ERROR MASTER", error);
        }
    }
    const getXpathDetailsOfTestCase = async ({ pageNo, customerTestDataId, resetData }) => {
        const xPayload = {
            customerTestDataId
        }
        if (customerTestDataId && customerTestDataId !== "" && !showSingleTestcase) {
            getTestDataDetailsByTestDataId(xPayload)
                .unwrap()
                .then((xRes) => {
                    console.log("🚀 ~ file: testData.js:103 ~ getXpathDetailsOfTestCase ~ .then ~ xRes:", { xRes })
                    setXpathAndValueList(xRes);
                })
                .catch((xErr) => {
                    console.log("🚀 ~ file: testData.js:129 ~ getXpathDetailsOfTestCase ~ xErr:", { xErr })
                })
        }

        // if (!_.isEmpty(selectedTestCase)) {
        //     const pageOptions = {
        //         pageNo,
        //         pageSize: 100,
        //         customerTestCaseVersionId: selectedTestCase?.testCaseVersionId
        //     }
        //     if (resetData) {
        //         setXpathValues([])
        //     }
        //     setXPathPageOptions(pageOptions)
        // }
    }

    const deleteTestCaseData = async (e, item) => {
        if (item !== null) {
            try {
                if (!_.isEmpty(item?.customerTestCaseDataId)) {
                    await deleteTestData({ customerTestDataId: item?.customerTestCaseDataId }).
                        then((response) => {
                            if (response.data.Message === 'Cloned TestData Deleted') {
                                Snackbar.displayMsg(TestCasesMsg.DELETE_TEST_DATA_SUCCESS, ALERT_SEVERITY.SUCCESS);
                            }
                        })
                }
                if (!_.isEmpty(selectedItem)) {
                    const xPayload = {
                        customerTestCaseVersionId: selectedItem?.testCaseVersionId
                    }
                    fetchTestDataSbyVersionId(xPayload);
                }

            } catch (error) {
                console.log(error);
                Snackbar.displayMsg(TestCasesMsg.DELETE_TEST_DATA_FAIL, ALERT_SEVERITY.SUCCESS);

            }
            item = null
        }

    }
    const fetchTestDataSbyVersionId = async (xPayload) => {
        await getTestDataSbyVersionId({
            ...xPayload
        })
            .unwrap()
            .then((yRes) => {
                const yUpdatedArr = yRes?.map((zEl) => ({
                    customerTestCaseDataId: zEl?.customerTestDataId,
                    name: zEl?.testDataNumber,
                    isCloned: zEl?.isClonedCopy,
                    testCaseVersionId: xPayload?.customerTestCaseVersionId,
                    icon: (isActive) => <ExpandIcon isActive={isActive} />,
                    isDelete: zEl?.isDelete,
                    extra: zEl?.isDelete && <PayaptTooltip title='Delete Test Data'>
                        <DeleteOutlineIcon
                            fontSize='small'
                            sx={{ color: '#676878' }}
                        />
                    </PayaptTooltip>
                }));

                const index = _.findIndex(data, d => d.testCaseVersionId === xPayload?.customerTestCaseVersionId);
                const loData = [...data];
                if (index != -1) {
                    loData[index] = {
                        ...loData[index],
                        versions: yUpdatedArr
                    }
                }
                setData([...loData])
                if (!_.isEmpty(yUpdatedArr)) {
                    const firstData = yUpdatedArr[0];
                    setSelectedTestCase(selectedTestCase?.testCaseVersionId !== firstData?.testCaseVersionId ? firstData : selectedTestCase)
                    // getXpathDetailsOfTestCase({ pageNo: 0, resetData: true, customerTestDataId: firstData?.customerTestCaseDataId });
                }
            })
            .catch((yErr) => {
                console.log("🚀 ~ file: testData.js:155 ~ .then ~ yErr:", yErr)
            })
    }
    const getData = async ({ selectDefaultTestCase, pageNo }) => {
        if (showSingleTestcase) {
            data.push({
                ...testcaseDetails
            });
            setData(data);
            setSelectedTestCase(data[0])
        } else {
            const loPageOptions = {
                ...pageOptions,
                pageNo,
                pageSize: 50,
                customerTestScenarioId: testCaseScenarioId
            }
            setPageOptions({ ...loPageOptions })

        }
    }
    const fetchNextPage = () => {
        setCurrentPage(currentPage + 1);
        getData({ pageNo: currentPage + 1 })
    }
    const fetchNextPageXpathValues = () => {
        setCurrentXPathPage(currentXpathPage + 1);
        getXpathDetailsOfTestCase({ pageNo: currentXpathPage + 1, customerTestCaseDataId: selectedTestCase?.customerTestCaseDataId })
    }
    const submitData = () => {
        const versionIds = _.map(data, d => d.testCaseVersionId);
        const payload = {
            versionIds
        }
        reviewXpathData(payload).then((res) => {
            if (res.data.message == 'All Customer TestCase Version Data Updated Successfully') 
            // { message.success(res.data.message) }
              Snackbar.displayMsg('All Customer TestCase Version Data Updated Successfully',ALERT_SEVERITY.SUCCESS)
            else {
                // message.error('Failed to Update')
                Snackbar.displayMsg("Failed to Review and Approve",ALERT_SEVERITY.ERROR)
            }
        })
        // reviewTheXpathData(payload)
    }
    // console.log('reviewData', reviewData);
    const copyTestCase = async (e, item) => {
        // e.stopPropagation();
        const xPayload = {
            customerTestCaseVersionId: item?.testCaseVersionId
        }
        await cloneTestData(xPayload)
            .unwrap()
            .then(async (xRes) => {
                const xPayload = {
                    customerTestCaseVersionId: item?.testCaseVersionId
                }
                fetchTestDataSbyVersionId(xPayload);
                Snackbar.displayMsg(TestCasesMsg.CLONE_TEST_DATA_SUCCESS, ALERT_SEVERITY.SUCCESS);
            })
            .catch((xRrr) => {
                Snackbar.displayMsg(TestCasesMsg.CLONE_TEST_DATA_FAIL, ALERT_SEVERITY.ERROR);
            })

    }
    const updateValue = (key, val, index) => {
        const updatedXpathAndValues = [...xpathAndValueList];
        updatedXpathAndValues[index] = {
            ...updatedXpathAndValues[index],
            isUpdated: true,
            [key]: val
        }
        setXpathAndValueList(updatedXpathAndValues);
    }

    const getXPathValues = (canModifyTestData) => {
        return (
            <React.Fragment>
                <Row className='xpath-value-table-header'>
                    <Col span={8} className='table-col-header'><span>Key</span></Col>
                    <Col span={12} className='table-col-header'><span>Xpath Description</span></Col>
                    <Col  className='table-col-header'><span>Value</span></Col>
                    {/* <Col span={12} className='table-col-header'><span>customerTestDataDetailsId</span></Col> */}
                </Row>
                <div className={canModifyTestData ? 'xpath-value-content content-save-btn' : 'xpath-value-content content-no-save-btn'} style={{ height: canModifyTestData ? '50vh' : '55vh' }}>
                    {xpathValuesLoading && <CircularProgress className='data-loader' />}

                    <InfiniteScroll
                        dataLength={xpathAndValueList.length} //This is important field to render the next data
                        next={fetchNextPageXpathValues}
                        hasMore={!isLastPage}
                        refreshFunction={getXpathDetailsOfTestCase}
                        scrollableTarget='scrollable-value-target'
                    >
                        <div id='scrollable-value-target'>
                            {
                                _.map(xpathAndValueList, (ele1, index) => (
                                    <XpathValueRow
                                        key={index}
                                        ele1={ele1}
                                        index={index}
                                        updateValue={updateValue}
                                        setXpathAndValueList={setXpathAndValueList}
                                        xpathAndValueList={xpathAndValueList}
                                        selectedTestCase={selectedTestCase}
                                        deleteUnsavedXpathAndValue={deleteUnsavedXpathAndValue}
                                    />
                                ))
                            }
                        </div>
                    </InfiniteScroll>
                </div>
            </React.Fragment>
        );
    }
    const handleMenuItemsClick = (xItem, parent) => {
        if (xItem && xItem.customerTestCaseDataId) {
            setSelectedTestCase(xItem)
            setSelectedItem(parent)
        } else {
            setSelectedItem(xItem);
        }
    }

    const updateSelectedItem = (item) => {
        if (!selectedItem || (selectedItem.testCaseVersionId !== item.testCaseVersionId)) {
            setSelectedItem(item)
        }
    }

    const updatexpathData = async () => {
        const updatedValues = _.filter(xpathAndValueList, d => d.isUpdated);
        const addedValues = _.filter(xpathAndValueList, d => d.isNew);

             addedValues.map(item => {
            if (item.xpath === null) {
            //   item.xpath = 'your filled value for xpath';
              Snackbar.displayMsg("Fill the Key and Value", ALERT_SEVERITY.ERROR)
              // Fill in other missing data similarly
            }
            return item;
          });

        if (updatedValues.length > 0) {
            const payload = {
                customerTestDataId: selectedTestCase?.customerTestCaseDataId,
                requestTestDataDetailsDTOs: _.map(updatedValues, d => ({
                    customerTestDataDetailsId: d.customerTestDataDetailsId,
                    xpath: d.xpath,
                    value: d.value,
                    xpathDescription: d.xpathDescription
                }))
            }
            console.log("UPDATE", payload);
            updateXpathandValue(payload).then(res => {
                if (res?.data?.Message === "Cloned Inforamation Are Updated") {
                    Snackbar.displayMsg('Updated Successfully.', ALERT_SEVERITY.SUCCESS)
                } else {
                    Snackbar.displayMsg('Failed to Update', ALERT_SEVERITY.ERROR)
                }
            })

        }
        // if (addedValues.length > 0) {
        //     const xElpayload = {
        //         customerTestDataId: selectedTestCase?.customerTestCaseDataId,
        //         customerXpathValueDTOs: _.map(addedValues, d => ({
        //             // customerTestDataDetailsId: d.customerTestDataDetailsId,
        //             xpath: d.xpath,
        //             value: d.value
        //         }))
        //     }
        //     console.log("ADDEDPayload", xElpayload);

        //     addedXpathandValue(xElpayload).then(res => {
        //         if (res?.data?.status === "success") {
        //             Snackbar.displayMsg("Customer TestData Created Successfully", ALERT_SEVERITY.SUCCESS);
        //         } else {
        //             Snackbar.displayMsg("Customer TestData is Failed to Created", ALERT_SEVERITY.ERROR);
        //         }
        //     })
        // }

    }

    const updateMasterXpathKey = async () => {
        const updatedValues = _.filter(xpathAndValueList, d => d.isUpdated);
        console.log("updatedValuesSS", updatedValues);
        const payload = {
            testDataId: selectedTestCase?.customerTestCaseDataId,
            requestTestDataDetailsDTOsonMaster: _.map(updatedValues, d => ({
                customerTestDataDetailsId: d.customerTestDataDetailsId,
                xpath: d.xpath,
                value: d.value,
                xpathDescription:d.xpathDescription,

            }))
        }
        console.log("PAYLoad", payload);
        updateXpathandValueMaster(payload).then(res => {
            console.log("resSS", res);
            if (res?.data?.Message === "Cloned Inforamation Are Updated for superAdmin") {
                Snackbar.displayMsg("Test Data For Master Updated Successfully", ALERT_SEVERITY.SUCCESS);
            } else {
                Snackbar.displayMsg("Test Data For Master Failed toUpdated ", ALERT_SEVERITY.ERROR);
            }
        })
    }
    // const newRowRef = useRef(null);

    const onAddRowButtonClick = (xSelTC) => {
        const newObj = {
            // customerTestDataDetailsId: null,
            xpath: null,
            value: null,
            xpathDescription:null,
            isNew: true,
            // isUpdated: false
        }
        // const newRowWithOldValues = [...xpathAndValueList, newObj];
        const newRowWithOldValues = [newObj, ...xpathAndValueList];
        setXpathAndValueList(newRowWithOldValues);
        // console.log("newRowWithOldValues",newRowWithOldValues);
        // if (newRowRef.current) {
        //     newRowRef.current.scrollIntoView({ behavior: 'smooth' });
        //   }
        const addedValues = _.filter(newRowWithOldValues, d => d.isNew);
        if (addedValues.length > 0) {
            const xElpayload = {
                customerTestDataId: selectedTestCase?.customerTestCaseDataId,
                customerXpathValueDTOs: _.map(addedValues, d => ({
                    // customerTestDataDetailsId: d.customerTestDataDetailsId,
                    xpath: d.xpath,
                    value: d.value,
                    xpathDescription: d.xpathDescription
                }))
            }
            // console.log("ADDEDPayload", xElpayload);

            // addedXpathandValue(xElpayload).then(res => {
            //     if (res?.data?.status === "success") {
            //         Snackbar.displayMsg("Customer TestData Created Successfully", ALERT_SEVERITY.SUCCESS);
            //     } else {
            //         Snackbar.displayMsg("Customer TestData is Failed to Created", ALERT_SEVERITY.ERROR);
            //     }
            // })
        } 
    }

    const onSearchInputChange = (e) => {
        setPageOptions({
            ...pageOptions,
            searchByTestCaseName: e.target.value,
        })
    }

    const deleteUnsavedXpathAndValue = (index) => {
        const updatedData = [...xpathAndValueList];
        _.remove(updatedData, (d, i) => i === index);
        setXpathAndValueList(updatedData)
    }
    return (
        <div style={{ paddingTop: 10 }}>
            <TestcaseDetailsHeader
                value='testData'
                onSelectionChange={onSelectionChange}
                showReviewBtn={!showSingleTestcase}
                onReviewButtonClick={submitData}
                showAddRowBtn={{
                    show: true,
                    onClick: onAddRowButtonClick
                }}
                selectedTestCase={selectedTestCase}
                // {!showSingleTestcase && (_.isEqual(_.toUpper(selectedTestCase?.isCloned), 'YES'))}
                disableReviewButton={showSingleTestcase || isFetching || masterTestCase}
                disableAddRowButton={showSingleTestcase ||
                    ((userRole?.name === 'Admin' || userRole?.name === 'Tester') ?
                        ((selectedTestCase?.isDelete === false) &&
                            !(selectedTestCase?.isDelete === true)) :
                        (!(_.isEqual(_.toUpper(selectedTestCase?.isCloned), 'NO')) &&
                            !(selectedTestCase?.isDelete === true))
                    )
                    // || (userRole?.name === 'Admin' || userRole?.name === 'Tester')
                    // || !(_.isEqual(_.toUpper(selectedTestCase?.isCloned), 'NO')) 
                }
                selectedTestScenario={selectedTestScenario}
            />

            <Box display={"flex"} flexDirection="row">

                <Box mx={1} sx={{
                    flex: 0.3, border: '1px solid #f5f5f5',
                    maxHeight: '60vh',
                    // maxWidth:800,
                    // paddingBottom: "30px"
                }}>
                    <Input placeholder='Search Name here' prefix={<SearchOutlined className='search-icon' />} className='search-box' onChange={onSearchInputChange} value={pageOptions.searchByTestCaseName} />
                    <div className='testcases-menu'>
                        {isFetching && <CircularProgress className='data-loader' />}
                        <InfiniteScroll
                            dataLength={data.length} //This is important field to render the next data
                            next={fetchNextPage}
                            hasMore={!isLastPage}
                            refreshFunction={fetchNextPage}
                            scrollableTarget='scrollable-menu-target'
                        >
                            <div id='scrollable-menu-target' style={{ height: '70%' }}>
                                <Menu
                                    items={data}
                                    onClick={(e, item, parent) => handleMenuItemsClick(item, parent)}
                                    // selectedKeys={selectedMenuKeys}
                                    selectedKeys={[selectedTestCase?.customerTestCaseDataId]}
                                    collapse={false}
                                    openSubmenu={false}
                                    // openSubmenu={handleOpenSubMenu}
                                    showExpandIcon={false}
                                    selectionKey='testCaseVersionId'
                                    menuTitleKey='testCaseName'
                                    submenuTitleKey='name'
                                    childrenKey='versions'
                                    onSubmenuExtraClick={copyTestCase}
                                    onMenuExtraClick={deleteTestCaseData}
                                    width='100%'
                                    submenuSelectionKey='customerTestCaseDataId'
                                    // style={{ paddingRight: '10px' }}
                                    selectedSubmenuKeys={[selectedItem?.testCaseVersionId]}
                                    onSubmenuSelected={(e, item) => updateSelectedItem(item)}
                                // submenuContainerStyle = {{borderLeft: isSelected ? '4px solid #009966': 'none',
                                // backgroundColor: isSelected ? '#F0F2F5' : '#ffffff'}}
                                />

                                {/* {
                                    _.map(data, t => (
                                        <div className={selectedTestCase && selectedTestCase.testCaseId === t.testCaseId ? 'test-case-menu-item-active' : 'test-case-menu-item'} onClick={() => setSelectedTestCase(t)}>{t.testCaseName}</div>
                                    ))
                                } */}
                            </div>
                        </InfiniteScroll>
                    </div>
                </Box>
                <Box mx={1} sx={{ flex: 0.7, }} className='test-data-values-container'>
                    {(userRole?.name === 'Admin' || userRole?.name === 'Tester') ?
                        <>
                            {getXPathValues(_.isEqual(_.toUpper(selectedTestCase?.isCloned), 'YES') && !showSingleTestcase)}
                            {!_.isEmpty(selectedTestCase) && _.isEqual(_.toUpper(selectedTestCase?.isCloned), 'YES') && !showSingleTestcase &&
                                (selectedTestCase?.isDelete === true) &&
                                <div style={{ marginTop: "20px" }}>
                                    <MUIButton
                                        startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
                                        title="Save"
                                        onClick={updatexpathData}
                                    />
                                </div>
                            }
                        </>
                        :
                        <>
                            {getXPathValues(_.isEqual(_.toUpper(selectedTestCase?.isCloned), 'YES') && !showSingleTestcase)}
                            {!_.isEmpty(selectedTestCase) && _.isEqual(_.toUpper(selectedTestCase?.isCloned), 'YES') && !showSingleTestcase &&
                                (selectedTestCase?.isDelete === true) &&
                                <div style={{ marginTop: "20px" }}>
                                    <MUIButton
                                        startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
                                        title="Save"
                                        onClick={updatexpathData}
                                    />
                                </div>
                            }
                            {!_.isEmpty(selectedTestCase) && _.isEqual(_.toUpper(selectedTestCase?.isCloned), 'NO') && !showSingleTestcase &&
                                <div style={{ marginTop: "20px" }}>
                                    <MUIButton
                                        startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
                                        title="Save"
                                        onClick={updatexpathData}
                                    />
                                    {/* <Button 
                                size="small" 
                                startIcon={<CheckCircleIcon size="small"/>} 
                                onClick={upd
                                    atexpathData} 
                                sx = {{textTransform: 'none'}}
                                variant = 'contained'
                            >
                                Save
                            </Button> */}
                                </div>
                            }
                        </>
                    }
                    <Box >
                        {masterTestCase &&
                            <div >
                                <MUIButton
                                    startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
                                    title="Save"
                                    onClick={updateMasterXpathKey}
                                />
                            </div>}
                    </Box>

                </Box>
            </Box>
        </div>

    )
}

const XpathValueRow = (props) => {
    const Snackbar = useContext(SnackbarContext);
    const { userRole } = useSelector((state) => state?.common?.userDetails);
    const { ele1, index, setXpathAndValueList, selectedTestCase, xpathAndValueList, updateValue, deleteUnsavedXpathAndValue } = props;
    // console.log("Checking",ele1,xpathAndValueList);
    const [deleteXpathAndValue] = useDeleteXpathAndValueMutation();

    const handleDeleteXpathValue = async (xEl) => {
        if (!xEl?.customerTestDataDetailsId) {
            return deleteUnsavedXpathAndValue(index)
        }
        await deleteXpathAndValue({
            customerTestDataDetailsId: xEl?.customerTestDataDetailsId
        })
            .unwrap()
            .then((res) => {
                if (res?.Message === "Datas Are Deleted") {
                    const oldValues = [...xpathAndValueList];
                    const curIdx = oldValues.findIndex((el) => el?.customerTestDataDetailsId === xEl?.customerTestDataDetailsId);
                    if (curIdx !== -1) {
                        oldValues.splice(curIdx, 1);
                    }
                    setXpathAndValueList(oldValues);
                    Snackbar.displayMsg(res?.Message, ALERT_SEVERITY.SUCCESS);
                }
            })
            .catch((err) => {
                Snackbar.displayMsg(JSON.stringify(err?.message), ALERT_SEVERITY.ERROR);
            })
    }
    return (
        <Row className='test-data-value-row'>
            <Col span={8} className='table-col-header'>
                <Input className='test-data-xpath-value' value={ele1.xpath}
                    // readOnly={true}
                    onChange={(e) => updateValue('xpath', e.target.value, index)}
                />
            </Col>
            <Col span={8} className='table-col-header'>
                <Input className='test-data-xpath-value' value={ele1.xpathDescription}
                    // readOnly={true}
                    onChange={(e) => updateValue('xpathDescription', e.target.value, index)}
                />
            </Col>
            <Col span={8} className='table-col-header'>
                {(userRole?.name === 'Admin' || userRole?.name === 'Tester') ?
                    <>
                        <Input
                            className='test-data-xpath-value'
                            value={ele1.value}
                            readOnly={!_.isEqual(_.toUpper(selectedTestCase?.isCloned), 'YES')}
                            // disabled = {!_.isEqual(_.toUpper(selectedTestCase?.isCloned), 'YES')}
                            onChange={(e) => updateValue('value', e.target.value, index)} />&nbsp;&nbsp;
                        {/* {_.isEqual(_.toUpper(selectedTestCase?.isCloned), 'YES') && */}
                         {(selectedTestCase?.isDelete === true) 
                    && <DeleteOutlineIcon onClick={() => handleDeleteXpathValue(ele1)} size="small" sx={{ color: '#676878' }} />
                }
                    </>
                    :
                    <>
                        <Input
                            className='test-data-xpath-value'
                            value={ele1.value}
                            readOnly = {!_.isEqual(_.toUpper(selectedTestCase?.isCloned), 'YES')}
                            // disabled = {!_.isEqual(_.toUpper(selectedTestCase?.isCloned), 'YES')}
                            onChange={(e) => updateValue('value', e.target.value, index)} />&nbsp;&nbsp;
                        {
                        //    ( _.isEqual(_.toUpper(selectedTestCase?.isCloned), 'NO')) 
                        //     &&
                            (selectedTestCase?.isDelete === true)&&
                            <DeleteOutlineIcon onClick={() => handleDeleteXpathValue(ele1)} size="small" sx={{ color: '#676878' }} />
                        }
                        {
                           ( _.isEqual(_.toUpper(selectedTestCase?.isCloned), 'NO')) 
                            &&
                            // (selectedTestCase?.isDelete === true)&&
                            <DeleteOutlineIcon onClick={() => handleDeleteXpathValue(ele1)} size="small" sx={{ color: '#676878' }} />
                        }

                    </>
                }
                {/* <Input 
                    className='test-data-xpath-value' 
                    value={ele1.value} 
                    // readOnly = {!_.isEqual(_.toUpper(selectedTestCase?.isCloned), 'YES')}
                    // disabled = {!_.isEqual(_.toUpper(selectedTestCase?.isCloned), 'YES')}
                    onChange={(e) => updateValue('value', e.target.value, index)} />&nbsp;&nbsp; */}
                {/* {_.isEqual(_.toUpper(selectedTestCase?.isCloned), 'YES') && selectedTestCase?.isDelete
                    && <DeleteOutlineIcon onClick={() => handleDeleteXpathValue(ele1)} size="small" sx={{ color: '#676878' }} />
                } */}
            </Col>
            {/* <Col span={12} className='table-col-header'>
                <Input className='test-data-xpath-value' value={ele1.customerTestDataDetailsId} />
            </Col> */}
        </Row>

    )
}

export default TestData;