import React, { useEffect } from 'react';
import Dropdown from '../../shared/components/Dropdown';
import { useGetValueNonValueMessageQuery } from '../../redux/SystemMessages/systemMessages.api';
import _ from 'lodash';

const styles = {
    menuProps: {
        sx: {
            '.MuiPaper-root': {
                maxHeight: '40%',
            },
        },
    },
}
const MessageDropdown = (props) => {
    const {value,messageType,onChange} = props;
    const {data:messageOptions} = useGetValueNonValueMessageQuery({messageType:messageType});
    
    useEffect(() => {
        if(!_.isEmpty(messageOptions)) {
            onChange(messageType === "Value" ? (messageOptions?.valueDropDown?.id,messageOptions?.valueDropDown?.value) : (messageOptions?.nonValueDropDown?.id,messageOptions?.nonValueDropDown?.value) )
        }
    },[messageOptions])
    
    const handleDropdownChange = (selectedValues) => {
        onChange(selectedValues);
    };
    
    return (
        <>
            <Dropdown
                placeholder=' Select Message'
                multiple
                margin="dense"
                className="user-dropdown"
                style={{ width: '100%', height: '42px', marginTop: '-20px' }}
                inputProps={{ 'aria-label': 'Without label' }}
                options={messageType === "Value" ? messageOptions?.valueDropDown : messageOptions?.nonValueDropDown }
                valueKey='value'
                labelKey='value'
                onChange={(selectedValues) => handleDropdownChange(selectedValues)}
                value={value}
                MenuProps={styles.menuProps}
                renderValue={(selected) => (
                    selected.length === 0 ? 'Select Message Type' : selected.join(', ')
                )}
            />
        </>
    )
}

export default MessageDropdown;
