import { Box } from '@mui/material';
import React from 'react';

const TestCaseSubDetails = (props) => {
    const { title, titleValue, subtitle, subTitleValue, } = props;

    return (
        <Box my={1.5}>
            <span className='desc-title'>{title}</span>
            <div className='desc-content'>{titleValue}</div>
            {!_.isEmpty(subtitle) &&
                <Box mt={1}>
                    <span className='desc-content desc-subtitle'>{subtitle}:</span>
                    <div className='desc-content'>{subTitleValue}</div>
                </Box>
            }
        </Box>
    )
}

export default TestCaseSubDetails;