import { backendApi } from '../base.config';
// test
export const registrationApi = backendApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    createCustomer: builder.mutation({
      query: (data) => ({
        url: 'public/createCustomer',
        method: 'POST',
        body: data,
      }),
    }),
    requestOtp: builder.mutation({
      query: (payload) => ({
        url: 'public/user/requestOtp',
        method: 'POST',
        body: payload,
      }),
    }),
    validateOtp: builder.mutation({
      query: (payload) => ({
        url: 'public/user/validatingOtp',
        method: 'POST',
        body: payload,
      }),
    }),
    resetPassword: builder.mutation({
      query: (payload) => ({
        url: 'public/changePasswordWithEmail',
        method: 'POST',
        body: payload,
      }),
    }),
    getCustomerSignupInfoById: builder.mutation({
      query: (args) => ({
          url: `public/getCustomerSignupInfoById`,
          method: 'POST',
          params: { ...args },
      }),
    }),
    updateCustomerSignupInformation: builder.mutation({
      query: (args) => (
        {
          url: `public/updateCustomerSignupInformation`,
          method: 'POST',
          body: args ,
      }),
    }),
  }),
  
});

export const {  useCreateCustomerMutation , 
                useRequestOtpMutation, 
                useValidateOtpMutation , 
                useResetPasswordMutation,
                useGetCustomerSignupInfoByIdMutation ,
                useUpdateCustomerSignupInformationMutation ,
              } = registrationApi;
