import React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom"
import { Provider } from 'react-redux'
import { PersistGate } from "redux-persist/integration/react";

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { store, persistor } from './redux/store';
import { theme } from './shared/theme';
import SnackbarContextProvider from './shared/context/SnackbarContextProvider.Provider';
import { DropdownProvider } from './shared/context/DropdownProvider';
// import { init as initApm } from '@elastic/apm-rum'


// const apm = initApm({

//   // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
//   serviceName: 'portal-frontend',

//   // Set custom APM Server URL (default: http://localhost:8200)
//   serverUrl: `${window.ELASTIC_SERVER_URL}` ,

//   // Set service version (required for sourcemap feature)
//   // serviceVersion: ''
// });
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <PersistGate loading={null} persistor={persistor}>
          <SnackbarContextProvider>
            <CssBaseline />
            <DropdownProvider>
            <App />
            </DropdownProvider>
          </SnackbarContextProvider>
        </PersistGate>
      </ThemeProvider>
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
