import React, { useEffect, useState } from 'react';
import Dropdown from '../../shared/components/Dropdown';
import _ from 'lodash';

const styles = {
    menuProps: {
        sx: {
            '.MuiPaper-root': {
                maxHeight: '40%',
            },
        },
    },
}

const messageOptions = [
        {
            id:1,
            value:"Pacs.004"
        },
        {
            id:2,
            value:"Pacs.008"
        },
        {
            id:3,
            value:"Pacs.028"
        },
        {
            id:4,
            value:"Pain.013"
        },
        {
            id:5,
            value:"Camt.026"
        },
        {
            id:6,
            value:"Camt.055"
        },
        {
            id:7,
            value:"Camt.056"
        },
        {
            id:8,
            value:"Camt.110"
        }
    ]
const ClearDataMessageTypeDropdown = (props) => {
    const {value,onChange} = props;

    const handleDropdownChange = (selectedValues) => {
        onChange(selectedValues);
    };
    
    return (
        <>
            <Dropdown
                placeholder=' Select Message'
                margin="dense"
                className="user-dropdown"
                style={{ width: '100%', height: '42px', marginTop: '-20px' }}
                inputProps={{ 'aria-label': 'Without label' }}
                options={messageOptions}
                valueKey='value'
                labelKey='value'
                onChange={(selectedValues) => handleDropdownChange(selectedValues)}
                value={value}
                MenuProps={styles.menuProps}
            />
        </>
    )
}

export default ClearDataMessageTypeDropdown;
