import React, { createContext, useEffect, useState } from "react";
import { useFetchCustomerNameListMutation } from "../../redux/users/users.api";
import _ from 'lodash'; // Ensure lodash is imported if you're using it

export const DropdownValueContext = createContext();

export const DropdownProvider = ({ children }) => {
    const [fetchCustomerNameList, { data: customersList, isSuccess }] = useFetchCustomerNameListMutation();
    const [customer, setCustomer] = useState();

    useEffect(() => {
        fetchCustomerNameList(); // Fetch customer list on mount
    }, []); // Ensures this effect runs only once when the component mounts

    useEffect(() => {
        // Added isSuccess check to ensure data is set only when the fetch is successful
        if (isSuccess && !_.isEmpty(customersList)) {
            setCustomer(customersList[0]?.customerId);
        }
    }, [customersList, isSuccess]); // Listen for changes in customersList and the isSuccess flag

    const changeValue = (v) => {
        setCustomer(v);
    };

    return (
        <DropdownValueContext.Provider value={{ customer, changeValue }}>
            {children}
        </DropdownValueContext.Provider>
    );
};
