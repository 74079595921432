import { FormControlLabel } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { MUISwitch } from '../MUISwitch';

function AddCheckbox({ control, name, label, register, defaultValue = '', disabled = false }) {
    return (
        <FormControlLabel
            sx={{
                ml: 1,
                '&.MuiFormControlLabel-root': {
                    color: '#000',
                },
            }}
            control={
                <Controller
                    control={control}
                    name={name}
                    defaultValue={defaultValue}
                    render={({ field: { value, ...field } }) => (
                        <MUISwitch
                            checkbox
                            label={label}
                            disabled={disabled}
                            checked={!!value}
                            {...register(name)}
                            {...field}
                        />
                    )}
                />
            }
        />
    );
}

export default AddCheckbox;