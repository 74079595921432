import { createSlice } from '@reduxjs/toolkit';

export const customerOnBoardingSlice = createSlice({
    name: 'customerOnBoarding',
    initialState: {
        currentStep: 1,
        createdSubscriptionPlan: {},
        subscriptionPlan: {},
        participationType: {},
        checkingSubscriptionPlan: {},
    },
    reducers: {
        updateCurrentStep: (state, { payload }) => {
            state.currentStep = payload;
        },
        createSubscriptionPlan: (state, { payload }) => {
            state.createdSubscriptionPlan = {
                ...state.createdSubscriptionPlan,
                createdPlan: payload
            };
        },
        checkingSubscriptionPlan: (state, { payload }) => {
            state.checkingSubscriptionPlan = {
                ...state.checkingSubscriptionPlan,
                checkingPlan: payload
            };
            
        },
        updateSubscriptionPlan: (state, { payload }) => {
            state.subscriptionPlan = {
                ...state.subscriptionPlan,
                selectedSubcriptionPlan: payload
            };
        },
        updateParticipationType: (state, { payload }) => {
            state.participationType = {
                ...state.subscriptionPlan,
                selectedParticipationType: payload
            };
        },
    },
});

export const { updateCurrentStep, createSubscriptionPlan,updateSubscriptionPlan, updateParticipationType, checkingSubscriptionPlan } = customerOnBoardingSlice.actions;
