import * as yup from 'yup';

export const userValidationSchema = yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    email: yup.string().email().required("Email Address is required"),
    role: yup.string().required("Role is required"),
    mobile: yup.string().required("Mobile Number is required")
});
export const userValidationSchemaWithCustomer = yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    email: yup.string().email().required("Email Address is required"),
    role: yup.string().required("Role is required"),
    mobile: yup.string().required("Mobile Number is required"),
    customerId: yup.string().required("Customer is required")
});
export const validationSchema = yup.object().shape({
    certificate: yup.string().required('Certificate is required'),
    simulatorIpAddress: yup.string().required('Simulator IP Address is required'),
    paymentGatewayIpAddress: yup.string().required('Payment Gateway IP Address is required'),
  });