import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CircularProgressPercentage from '../CircularProgressPercentage';
import ProgressCircle from '../ProgressCircle';
import { MessageFlowsData } from './constants';
import '../../Dashboard/dashBoard.css';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useGetMFMsgCountMutation } from '../../../redux/dashboard/dashboard.api';
import CircularProgress from '../../../shared/components/CircularProgress';
import moment from 'moment';

// export const resultData = [
//     {
//         resultTitle: 'Executed', 
//         count: 10
//     },
//   {
//     resultTitle: 'Passed',
//     count: 20
//   },
//   {
//     resultTitle: 'Failed', 
//     count: 30
//    }
// ]
const MessageFlows = (props) => {
  const { dateForDashboard, customer, messageType } = props
  const [getMFMsgCount, {data}] = useGetMFMsgCountMutation();
  var payload = {
    date :dateForDashboard[0]? moment.utc( new Date(dateForDashboard[0])).format() : null,
    toDate :dateForDashboard[1]? moment.utc( new Date(dateForDashboard[1])).format() : null,
    customerId: customer? customer : null,
    messageTypeId : messageType? messageType : null
 }
  useEffect(() => {
    getMFMsgCount(payload)
  }, [dateForDashboard, customer, messageType]);

  const resultData = [{
    inbound:[
    {
        resultTitle: 'Executed', 
        count: data?.inboundExecuted
    },
  {
    resultTitle: 'Passed',
    count: data?.inboundPassed
  },
  {
    resultTitle: 'Failed', 
    count: data?.inboundFailed
   }
  ],
  outbound:[
    {
      resultTitle: 'Executed', 
      count: data?.outboundExecuted
  },
{
  resultTitle: 'Passed',
  count: data?.outboundPassed
},
{
  resultTitle: 'Failed', 
  count: data?.outboundFailed
 }
  ]
}
  
]
    // const data = MessageFlowsData;
  return (
    <div>
      
             <Box sx={{marginTop:'15px'}}
              >
              <Box display={'flex'} 
             sx={{justifyContent:'space-between'}}
             >
                <Typography className='test-data-review' sx={{fontWeight:600,textAlign:'left',paddingLeft:'15px'}}>Message Flows</Typography>
                {/* <ChevronRightIcon sx={{marginRight:'15px'}}/> */}
              </Box>
            
                <Box sx={{justifyContent:'center',alignItems:'center',padding:'10px'}}>
                 <ProgressCircle
                 iconType={<SouthIcon sx={{color:"#D65442",marginTop:'10px'}}/>}
                 circleIcon={
                  <CircularProgress 
                  value={data?.inboundPercentage} 
                  size={45} 
                  strokeColor = '#D65442'
                  strokeWidth = {8}
                  trailColor='#E9D4CF'
                  trailWidth = {6}        
                />
                 }
                 sx={{backgroundColor:"#FFF6F4",borderRadius:"5px"}}
                 total={data?.totalInboundTestCases}
                 directionTitle="Inbound"
                 testCases
                 title="Test Cases"
                 // resultTitle1="Executed"
                 // count1={xEl.standalone.inbound.executed}
                 // resultTitle2="Passed"
                 // count2={xEl.standalone.inbound.passed}
                 // resultTitle3="Failed"
                 // count3={xEl.standalone.inbound.failed}
                 resultData={resultData[0]?.inbound}
                />
                 
               
            {/* <br/> */}
            <Box sx={{marginTop:'10px'}}>
            <ProgressCircle
            iconType={<NorthIcon sx={{color:"#D65442",marginTop:'10px'}}/>}
            circleIcon={
              <CircularProgress 
                  value={data?.outboundPercentage} 
                  size={45} 
                  strokeColor = '#D65442'
                  strokeWidth = {8}
                  trailColor='#E9D4CF'
                  trailWidth = {6}        
                />
            }
            sx={{backgroundColor:"#FFF6F4",borderRadius:"5px"}}
            total={data?.totalOutboundTestCases}
            directionTitle="Outbound"
            testCases
            title="Test Cases"
         //    resultTitle1="Executed"
         //    count1={xEl.standalone.outbound.executed}
         //    resultTitle2="Passed"
         //    count2={xEl.standalone.outbound.passed}
         //    resultTitle3="Failed"
         //    count3={xEl.standalone.outbound.failed}
         resultData={resultData[0]?.outbound}
     
           />
           </Box>
            </Box>
           
           </Box>
     
      
    </div>
  )
}

export default MessageFlows