import * as yup from 'yup';

export const batchNameApiKey = "testRunBatchName";
export const batchDescriptionApiKey = "testRunBatchDescription";

export const createBatchFileValidationSchema = yup.object().shape({
    [batchNameApiKey]: yup.string().required("Batch File Name is required"),
    [batchDescriptionApiKey]: yup.string().required("Batch File Description is required"),
});

export const systemBatchFileValidationSchema = yup.object().shape({
    [batchNameApiKey]: yup.string().required("Batch File Name is required"),
});

export function filterByValue(array, value) {
    return array.filter((data) => JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1);
}