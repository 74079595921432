
import { APP_ROUTE_KEYS } from "../../shared/constants/constants";
import { EditIcon, ViewIcon } from "../../assets/base64/images";


 const CARD_DATA = [
  {
    title: "Account Info",
    linkText: "Click Here To View Info",
    urlPath: "",
    iconType: <EditIcon />,
  },
  {
    title: "Subscription Plan",
    subTitle: "",
    urlPath: `${APP_ROUTE_KEYS.SETTINGS.CHOOSE_PLAN}`,
    iconType: <ViewIcon />,
  },
  {
    title: "Participation Type",
    subTitle: "",
    urlPath: `${APP_ROUTE_KEYS.SETTINGS.CHOOSE_TYPE}`,
    iconType: <EditIcon />,
  },
  {
    title: "Participation Type Configuration",
    linkText: "Click Here To View Info",
    urlPath: `${APP_ROUTE_KEYS.SETTINGS.CONFIG_TYPE}`,
    iconType: <EditIcon />,
  },
];
