import { IconButton } from '@mui/material';
import { styled } from '@mui/system';

export const CustomIconButton = styled((props) => <IconButton {...props} />)(
    ({ color }) => ({
        width: 30,
        height: 30,
        "&:hover": {
            color: `${color}`,
        },
    }),
);