// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .ant-modal-content {
    width: 125%;
    left: 50%;
    top: 135px;
    } */`, "",{"version":3,"sources":["webpack://./src/shared/components/modalCss.css"],"names":[],"mappings":"AAAA;;;;OAIO","sourcesContent":["/* .ant-modal-content {\n    width: 125%;\n    left: 50%;\n    top: 135px;\n    } */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
