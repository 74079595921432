import CustomTable from "./CustomTable";

export const dataRows = [
    {
        id: '1',
        categoryName: "Financial Institutions",
        subCats: [
            {
                id: '1',
                name: "DBS Bank India Ltd",
                address: "1690 / HDFC Bank ,Spring, P.o.box 688, Buffalo, Ny",
                routingAddress: "877-817-6428",
                someMoreColumn: "877-817-6428",
                anotherColumnOne: "23456778",
                anotherColumnTwo: "Rodriguez LLC"
            },
            {
                id: '2',
                name: "DBS Bank India Ltd",
                address: "1690 / HDFC Bank ,Spring, P.o.box 688, Buffalo, Ny",
                routingAddress: "877-817-6428",
                someMoreColumn: "877-817-6428",
                anotherColumnOne: "23456778",
                anotherColumnTwo: "Rodriguez LLC"
            },
            {
                id: '3',
                name: "DBS Bank India Ltd",
                address: "1690 / HDFC Bank ,Spring, P.o.box 688, Buffalo, Ny",
                routingAddress: "877-817-6428",
                someMoreColumn: "877-817-6428",
                anotherColumnOne: "23456778",
                anotherColumnTwo: "Rodriguez LLC"
            },
            {
                id: '4',
                name: "DBS Bank India Ltd",
                address: "1690 / HDFC Bank ,Spring, P.o.box 688, Buffalo, Ny",
                routingAddress: "877-817-6428",
                someMoreColumn: "877-817-6428",
                anotherColumnOne: "23456778",
                anotherColumnTwo: "Rodriguez LLC"
            },
            {
                id: '5',
                name: "DBS Bank India Ltd",
                address: "1690 / HDFC Bank ,Spring, P.o.box 688, Buffalo, Ny",
                routingAddress: "877-817-6428",
                someMoreColumn: "877-817-6428",
                anotherColumnOne: "23456778",
                anotherColumnTwo: "Rodriguez LLC"
            },
            {
                id: '6',
                name: "DBS Bank India Ltd",
                address: "1690 / HDFC Bank ,Spring, P.o.box 688, Buffalo, Ny",
                routingAddress: "877-817-6428",
                someMoreColumn: "877-817-6428",
                anotherColumnOne: "23456778",
                anotherColumnTwo: "Rodriguez LLC"
            },
            {
                id: '7',
                name: "DBS Bank India Ltd",
                address: "1690 / HDFC Bank ,Spring, P.o.box 688, Buffalo, Ny",
                routingAddress: "877-817-6428",
                someMoreColumn: "877-817-6428",
                anotherColumnOne: "23456778",
                anotherColumnTwo: "Rodriguez LLC"
            },
        ]
    },
    {
        id: '2',
        categoryName: "Service Providers",
        subCats: [
            {
                id: '1',
                name: "DBS Bank India Ltd",
                address: "1690 / HDFC Bank ,Spring, P.o.box 688, Buffalo, Ny",
                routingAddress: "877-817-6428",
                someMoreColumn: "877-817-6428",
                anotherColumnOne: "23456778",
                anotherColumnTwo: "Rodriguez LLC"
            },
            {
                id: '2',
                name: "DBS Bank India Ltd",
                address: "1690 / HDFC Bank ,Spring, P.o.box 688, Buffalo, Ny",
                routingAddress: "877-817-6428",
                someMoreColumn: "877-817-6428",
                anotherColumnOne: "23456778",
                anotherColumnTwo: "Rodriguez LLC"
            },
        ]
    },
    {
        id: '3',
        categoryName: "Corporations",
        subCats: [
            {
                id: '1',
                name: "DBS Bank India Ltd",
                address: "1690 / HDFC Bank ,Spring, P.o.box 688, Buffalo, Ny",
                routingAddress: "877-817-6428",
                someMoreColumn: "877-817-6428",
                anotherColumnOne: "23456778",
                anotherColumnTwo: "Rodriguez LLC"
            },
            {
                id: '2',
                name: "DBS Bank India Ltd",
                address: "1690 / HDFC Bank ,Spring, P.o.box 688, Buffalo, Ny",
                routingAddress: "877-817-6428",
                someMoreColumn: "877-817-6428",
                anotherColumnOne: "23456778",
                anotherColumnTwo: "Rodriguez LLC"
            },
            {
                id: '3',
                name: "DBS Bank India Ltd",
                address: "1690 / HDFC Bank ,Spring, P.o.box 688, Buffalo, Ny",
                routingAddress: "877-817-6428",
                someMoreColumn: "877-817-6428",
                anotherColumnOne: "23456778",
                anotherColumnTwo: "Rodriguez LLC"
            },
            {
                id: '4',
                name: "DBS Bank India Ltd",
                address: "1690 / HDFC Bank ,Spring, P.o.box 688, Buffalo, Ny",
                routingAddress: "877-817-6428",
                someMoreColumn: "877-817-6428",
                anotherColumnOne: "23456778",
                anotherColumnTwo: "Rodriguez LLC"
            },
            {
                id: '5',
                name: "DBS Bank India Ltd",
                address: "1690 / HDFC Bank ,Spring, P.o.box 688, Buffalo, Ny",
                routingAddress: "877-817-6428",
                someMoreColumn: "877-817-6428",
                anotherColumnOne: "23456778",
                anotherColumnTwo: "Rodriguez LLC"
            },
        ]
    },
    {
        id: '4',
        categoryName: "Individuals",
        subCats: [
            {
                id: '1',
                name: "DBS Bank India Ltd",
                address: "1690 / HDFC Bank ,Spring, P.o.box 688, Buffalo, Ny",
                routingAddress: "877-817-6428",
                someMoreColumn: "877-817-6428",
                anotherColumnOne: "23456778",
                anotherColumnTwo: "Rodriguez LLC"
            },
            {
                id: '2',
                name: "DBS Bank India Ltd",
                address: "1690 / HDFC Bank ,Spring, P.o.box 688, Buffalo, Ny",
                routingAddress: "877-817-6428",
                someMoreColumn: "877-817-6428",
                anotherColumnOne: "23456778",
                anotherColumnTwo: "Rodriguez LLC"
            },
            {
                id: '3',
                name: "DBS Bank India Ltd",
                address: "1690 / HDFC Bank ,Spring, P.o.box 688, Buffalo, Ny",
                routingAddress: "877-817-6428",
                someMoreColumn: "877-817-6428",
                anotherColumnOne: "23456778",
                anotherColumnTwo: "Rodriguez LLC"
            },
        ]
    },
];

//
export const sampleDataRowsConfigure = [
    {
        id: '1',
        headCells: ['Consumer Credit Transfer', '', 'Send', 'Receive'],
        rows: [
            {
                id: '1',
                name: 'Consumer Credit transfer',
                sendStatus: "prohibited",
                receiveStatus: true,
                isModified : false
            },
            {
                id: '2',
                name: 'Payment Status Report',
                sendStatus: true,
                receiveStatus: true,
                isModified : false
            },
            {
                id: '3',
                name: 'Payment Status Request',
                sendStatus: 'conditional',
                receiveStatus: true,
                isModified : false
            },
        ]
    },
    {
        id: '2',
        headCells: ['Payment Returns', ,'', '', '',''],
        rows: [
            {
                id: '1',
                name: 'Return Request',
                sendStatus: true,
                receiveStatus: true,
                isModified : false
            },
            {
                id: '2',
                name: 'Return Request Response',
                sendStatus: true,
                receiveStatus: 'N/A',
                isModified : false
            },
            {
                id: '3',
                name: 'Payment Return',
                sendStatus: false,
                receiveStatus: true,
                isModified : false
            },
        ]
    },
    {
        id: '3',
        headCells: ['Request For Payment', '', '','',''],
        rows: [
            {
                id: '1',
                name: 'Request For Payment (RFP)',
                sendStatus: 'conditional',
                receiveStatus: 'prohibited',
                isModified : false
            },
            {
                id: '2',
                name: 'Request For Payment Response',
                sendStatus: true,
                receiveStatus: 'conditional',
                isModified : false
            },
            {
                id: '3',
                name: 'RFP Cancellation Request',
                sendStatus: 'conditional',
                receiveStatus: 'prohibited',
                isModified : false
            },
            {
                id: '4',
                name: 'RFP Cancellation Request Response',
                sendStatus: true,
                receiveStatus: 'conditional',
                isModified : false
            },
        ]
    },
    {
        id: '4',
        headCells: ['Information Request Message', '', '','',''],
        rows: [
            {
                id: '1',
                name: 'Information Request',
                sendStatus: false,
                receiveStatus: true,
                isModified : false
            },
            {
                id: '2',
                name: 'Information Request Response',
                sendStatus: true,
                receiveStatus: 'conditional',
                isModified : false
            },
            {
                id: '3',
                name: 'Additional Payment Information',
                sendStatus: false,
                receiveStatus: 'conditional',
                isModified : false
            },
        ]
    },
    {
        id: '5',
        headCells: ['Account Reporting Message', '', '','',''],
        rows: [
            {
                id: '1',
                name: 'Account Reporting Request',
                sendStatus: 'conditional',
                receiveStatus: 'N/A',
                isModified : false
            },
            {
                id: '2',
                name: 'Account Balance Report',
                sendStatus: 'N/A',
                receiveStatus: false,
                isModified : false
            },
            {
                id: '3',
                name: 'Account Activity Totals Report',
                sendStatus: 'N/A',
                receiveStatus: false,
                isModified : false
            },
            {
                id: '4',
                name: 'Account Activity Details Report',
                sendStatus: 'N/A',
                receiveStatus: false,
                isModified : false
            },
            {
                id: '5',
                name: 'Account Debit/Credit Notification',
                sendStatus: 'N/A',
                receiveStatus: false,
                isModified : false
            },
        ]
    },
    {
        id: '6',
        headCells: ['System Messages', '', '','',''],
        rows: [
            {
                id: '1',
                name: 'Message Reject',
                sendStatus: true,
                receiveStatus: true,
                isModified : false
            },
            {
                id: '2',
                name: 'FedNow Acknowledgement',
                sendStatus: 'N/A',
                receiveStatus: true,
                isModified : false
            },
            {
                id: '3',
                name: 'FedNow Broadcast',
                sendStatus: 'N/A',
                receiveStatus: true,
                isModified : false
            },
            {
                id: '4',
                name: 'Participant Broadcast',
                sendStatus: true,
                receiveStatus: false,
                isModified : false
            },
            {
                id: '5',
                name: 'FedNow System Response',
                sendStatus: 'N/A',
                receiveStatus: true,
                isModified : false
            },
            {
                id: '6',
                name: 'Retrieval Request',
                sendStatus: true,
                receiveStatus: false,
                isModified : false
            },
            {
                id: '7',
                name: 'FedNow Participant File',
                sendStatus: 'N/A',
                receiveStatus: true,
                isModified : false
            },
        ]
    },
]

export const reviewDataRows = [
    {
        id:'1',
        categoryName:"Selected Plan",
        selectType:"Subscription Plan A"
    },
    {
        id:'2',
        categoryName:"Selected Participation Type",
        selectType:"Credit Transfer Receive Only"
    },
    {
        id:'3',
        categoryName:"Selected Participation Type",
        selectType:"null",
    },
];
