import React from 'react'
import TestCaseHeader from '../testCaseHeader';
import { Box, Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { activityLog_view_columns } from '../../constants/constants';
import WrapperComponent from '../../shared/components/wrapperComponent';
import PayaptTable from '../../shared/components/Table';
import { getSortDirection } from '../../utils/sorterUtils';
import '../homeScreens/homeScreen.css'
import EastIcon from '@mui/icons-material/East';


const ViewActivityLog = (props) => {
    const { item: xSelRec } = props;
    const columns = activityLog_view_columns

    columns[1].render = (data, rec) => (
        <>
        <span>{rec.old}</span>
        <span><EastIcon sx={{marginLeft:'10px',marginRight:'10px',fontSize:20}}/></span>
        <span>{rec.new}</span>
        </>
      )
    

  return (
   <Box>
        <TestCaseHeader
            title={`User Activity log data - ${xSelRec.activityTypeName}`}
            subtitle={`${xSelRec.activityDescription}`}
            showInfoIcon={false}
        /> 
        <Box my={2} style={{ textTransform: 'none' }}>
          <PayaptTable
            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
            size="small"
            className="standalone_table"
            scroll={{ y: "50vh" }}
            columns={columns}
            data={!_.isEmpty(xSelRec) ? xSelRec?.changeFieldDatas : []}
            rowKey={(rec,index) => index}
            pagination={{
              pageSize:20,
              hideOnSinglePage: true,
              size: "small",
            }}
          />
        </Box>
       
   </Box>
  )
}

export default ViewActivityLog;
