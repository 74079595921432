import { Box, Typography } from "@mui/material";
import React from "react";
import ReactApexChart from "react-apexcharts";
import moment from 'moment'

const ChartContainer =(props)=>{
    const { data, dateRange } = props;
  
  const getDataArray = (type) => {
    const arr = [];
    const keys = _.sortBy(Object.keys(data), d => moment(d))
    _.forEach(keys, k => {
      arr.push(data[k][type])
    })
    return arr;
  }
   const series= [{
      name: 'Outbound',
      data: getDataArray('Outbound')
    }, {
      name: 'Inbound',
      data: getDataArray('Inbound')
    },
    
  ]

  const getCategories = () => {
    let dates = _.map(data, (val, k) => Number(dateRange) <= 31 ? moment.utc(k).format('DD MMM') : k)
    if(Number(dateRange) > 31){
      dates = _.sortBy(dates, d => moment(d,'MMM,YYYY'))
    } else {
      dates = _.sortBy(dates, d => moment(d,'DD MMM'))
    }
    return dates;
  }
   const options= {
      chart: {
        height: 350,
        type: 'area',
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        // type: 'datetime',
        categories: getCategories()
        // ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        },
      },
      legend: {
        show:true,
          position: 'bottom',
          floating: true,
          // verticalAlign: 'top',
          // align:'right',
          inverseOrder: true,
          // offsetX: 460,
          offsetY: 23,
      },
      chart: {
        toolbar: false
      }
    }
  
    return(
      <Box>
        
        <ReactApexChart options={options} series={series} type="area" height={300} width='100%'/>
      
      </Box>
    );
}

export default ChartContainer;