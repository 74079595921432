import { backendApi } from "../base.config";

export const batchTestRunsApi = backendApi.injectEndpoints({
    // tagTypes: ['BATCH_TEST_RUN_LIST'],
    endpoints: (builder) => ({
        getAllBatchWithTestCase: builder.mutation({
            query: (args) => ({
                url:`customerTestcaseBatch/getAllBatchWithTestCase`,
                method: 'POST',
                body: {...args},
            }),
            // providesTags: ['BATCH_TEST_RUN_LIST']
        }),
        listOfCustomerTestBatch: builder.mutation({
            query: (args) => ({
                method: "POST",
                url:`customerTestcaseBatch/listofcustomertestbatch`,
                params: {...args},
            })
        }),
        getAllNonTestcasesOfBatches: builder.query({
            query: (args) => ({
                // url: `customerTestcaseBatch/getAllNoNTestCaseWithBatch`,
                url:`customerTestcaseBatch/getAllNoNTestCaseWithBatchWithoutValidation`,
                params: { ...args },
                refetchOnMountOrArgChange: true
            }),
            providesTags: ['NON_TEST_CASE_LIST']
        }),
    }),
});

export const {
   useGetAllBatchWithTestCaseMutation,
   useListOfCustomerTestBatchMutation,
   useGetAllNonTestcasesOfBatchesQuery,
} = batchTestRunsApi;