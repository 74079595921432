import { LeftCircleFilled } from '@ant-design/icons';
import { Box, Button, Grid, TextField } from '@mui/material';
import React from 'react';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import  '../../../shared/components/styles.css';
import PayaptTextField from '../../../shared/components/TextField';
import MUIButton from '../../../shared/components/MUIButton';

const AddDataResults = () => {
  return (
    <Box>
        <h5 className='upload-title'>Add Test Data For Simulator For Desired Results</h5>
        <span className='upload-subtitle'>About uploading test data instruction goes here</span>
        <Box mt={1}>
        <br/><tr>
        <td className="add-header-content">
          <span>Creditor Agent RTN</span>
          <br />
          <PayaptTextField
            sx={{ width: "330px", height: "50px",border:" #C6CBD1" }}
            placeholder="Enter Creditor Agent RTN"
            margin="dense" 
          />
        </td>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <td style={{ textAlign: "left" }} className="add-header-content">
          <span>Account Id</span>
          <br />
          <PayaptTextField
            style={{ width: "330px",height:'50px' }}
            placeholder="Enter Account Id"
            margin="dense"
          />
        </td>
      </tr>
      <br/><tr>
        <td className="add-header-content">
          <span>Status Desired</span>
          <br />
          <PayaptTextField
            sx={{ width: "330px", height: "50px",border:" #C6CBD1" }}
            placeholder="Enter Status Desired"
            margin="dense" 
          />
        </td>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <td style={{ textAlign: "left" }} className="add-header-content">
          <span>Simulator</span>
          <br />
          <PayaptTextField
            style={{ width: "330px",height:'50px' }}
            placeholder="Enter Simulator"
            margin="dense"
          />
        </td>
      </tr>
        </Box>
        <Grid item xs={12} justifyContent="flex-start">
        <MUIButton
         startIcon={<LeftCircleFilled style={{ fontSize: 18}}/>}
         title="Reset"
         sx={{backgroundColor:'#F0F2F5',color:'#808080'}}
         color="inherit"
        />
        <MUIButton
         startIcon={<CheckCircleIcon style={{fontSize: 18,}}/>}
         title="Confrim"
         sx={{mx:2}}
        />
        </Grid>
    </Box>
    
  )
}

export default AddDataResults
