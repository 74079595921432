import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useCallback, useState } from 'react'

function Dropdown(props) {
    const {
        id,
        label,
        isClear,
        value = props?.multiple ? [] : '',
        onChange,
        options,
        placeholder = "",
        defaultValue = null,
        sx,
        valueKey = 'id',
        labelKey = 'label',
        disabled = false,
        disabledOptions = [],
        ...selectProps
    } = props;

    // const selectValue = value === "" ? null : value;
    const handleChange = useCallback((event) => {
        // const selectedOp = options.find((v) => v[valueKey] === event?.target.value);
        // console.log("🚀 ~ file: Dropdown.js:21 ~ handleChange ~ selectedOp:", { selectedOp, evVal: event?.target.value })
        // console.log('event',event?.target.value);
        onChange(event?.target.value);
        // event.stopPropagation();
    }, [options]
    );


    return (
        <FormControl variant='outlined' size='small' sx={{ width: '100%' }}>
            {label && (
                <InputLabel id={id} sx={{ color: '#000' }}>{label}</InputLabel>
            )}
            <Select
                id="demo-simple-select-autowidth"
                label={label}
                labelId="demo-simple-select-autowidth-label"
                defaultValue={defaultValue}
                value={value}
                onChange={handleChange}
                displayEmpty
                disabled={disabled}
                sx={[{
                    backgroundColor: '#FFF',
                    width: '100%',
                }, { ...sx }]}
                {...selectProps}>
                    
                {/* {isClear && (
                    <MenuItem key='clear' value={null}>Clear selection</MenuItem>
                )} */}
                {placeholder && (
                    <MenuItem value={value!=null?"":null} sx={{ color: '#ACACAC' }}>
                        {placeholder}
                    </MenuItem>
                )}
                
                {options?.map((v, k) => (
                    <MenuItem key={k} value={v[valueKey]} disabled={disabledOptions.includes(v[valueKey])}>
                        {v[labelKey]}
                    </MenuItem>
                ))}

            </Select>
        </FormControl>
    )
}

export default Dropdown;