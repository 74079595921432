import React from 'react';
import customerOnboarding from '../assets/base64/customerOnboarding';
import portalimage from '../assets/images/portal-image.png'

const CustomerOnboardingTitle = () => {
    return (
        <div>
            <div className='payapt-title-container'>
                <span className='onboard-title'>The faster way for companies and banks to launch payment solutions</span>
            </div>
            <div className='portal-image'><img src={portalimage} style={{height:'425px',width:'425px'}} className='onboarding-img'/></div>
        </div>
    )
}

export default CustomerOnboardingTitle;