import React, { useEffect } from "react";
import Dropdown from "../../shared/components/Dropdown";
import _ from "lodash";
import { useGetSimulatorTestDataWithTypeMutation } from "../../redux/settings/settings.api";
import { useSelector } from "react-redux";
import { useState } from "react";


const styles = {
    menuProps: {
        sx: {
            '.MuiPaper-root': {
                maxHeight: '40%',
            },
        },
    },
}






const CustomerBankDropdown = (props) => {
    const { onChangeCustomerBank, value, isClear } = props

    const [simulatorTestDataWithTypeList, { data: firstSimulatorTestData }] = useGetSimulatorTestDataWithTypeMutation();

    const customerId = useSelector((state) => state?.common?.customerId);

    var initialpayload = {
        "page": 0,
        "size": 10,
        "filter": null,
        "customerId": customerId
    }
    useEffect(() => {
        simulatorTestDataWithTypeList(initialpayload);


    }, [])

    useEffect(() => {
        if (!_.isEmpty(firstSimulatorTestData)) {
            onChangeCustomerBank(firstSimulatorTestData[0]?.type)
        }
    }, [firstSimulatorTestData])


    return (
        <>
            <Dropdown
                // placeholder=''
                margin="dense"
                className="user-dropdown"
                style={{ width: '100%', height: '42px', marginTop: '-20px' }}
                inputProps={{ 'aria-label': 'Without label' }}
                options={firstSimulatorTestData}
                valueKey='type'
                labelKey='type'
                onChange={(e) => onChangeCustomerBank(e)}
                value={value}
                MenuProps={styles.menuProps}
                isClear={isClear}
            // disabled = {!!selectedRecord?.userId}
            />
        </>
    )
}

export default CustomerBankDropdown