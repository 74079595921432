import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types'
import CancelOutlined from "@mui/icons-material/CancelOutlined";

const styles = {
    container: {
        p: 2,
        backgroundColor: "#F5F5F5"
    },
    titleContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start"
    },
    titleContent: {
        display: "flex",
        flexDirection: 'row',
        alignItems: "center"
    },
    title: {
        fontSize: '1.25rem', fontWeight: '600', pr: 1
    },
    infoIcon: {
        fontSize: '1.25rem'
    },
    closeContainer: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-end",
        cursor: "pointer"
    },
    closeIcon: {
        fontSize: '1.5rem'
    },
    subTitle: {
        fontSize: '0.85rem', fontStyle: "italic"
    }
}

function ChildHeader(props) {
    const { title = "", subTitle = "", IconComponent, onCancel } = props;
    return (
        <Box sx={styles.container}>
            <Grid container flexDirection={"row"}>
                <Grid item xs={10} sx={styles.titleContainer}>
                    <Box sx={styles.titleContent}>
                        <Typography sx={styles.title} >{title}</Typography>
                        {IconComponent && <IconComponent sx={styles.infoIcon} color='secondary' />}
                    </Box>
                    {subTitle !== "" && <Typography sx={styles.subTitle}>{subTitle}</Typography>}
                </Grid>
                <Grid item xs={2} onClick={onCancel} sx={styles.closeContainer}>
                    <CancelOutlined sx={styles.closeIcon} color='secondary' />
                </Grid>
            </Grid>
        </Box>
    );
}

ChildHeader.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string
}

export default ChildHeader;