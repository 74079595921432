import React from 'react';
import { Box, InputLabel, TextField } from '@mui/material';
import PropTypes from 'prop-types'
import { colors } from '../colors';
import _ from 'lodash';

const PayaptTextField = (props) => {
    const { containerSx, autoFocus, disabled, placeholder, className, style, variant, required, label, helperText,
        inputProps, onChange, onBlur, onFocus, color, error, fullWidth, multiline, value, hiddenLabel, margin, type,
        size, sx, name, ...restProps } = props;
    const condMb = _.has(containerSx, 'mb') ? containerSx?.mb : 1.5;

    return (
        <Box sx={{ mb: condMb }}>
            {label && <InputLabel sx={{ textAlign: "left" }} required={required}>{label}</InputLabel>}
            <TextField
                id={`TextField-${name}`}
                autoFocus={autoFocus}
                disabled={disabled}
                placeholder={placeholder}
                className={className}
                style={style}
                variant={variant}
                required={required}
                // label={label}
                helperText={helperText}
                InputProps={inputProps}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                color={color}
                error={error}
                fullWidth={fullWidth}
                multiline={multiline}
                value={value}
                hiddenLabel={hiddenLabel}
                margin={margin}
                type={type}
                size={size}
                sx={sx}
                {...restProps}
            />
        </Box>

    )
}

PayaptTextField.defaultProps = {
    autoFocus: false,
    // defaultValue: '',
    disabled: false,
    placeholder: '',
    style: {},
    className: '',
    variant: 'outlined',//outlined,standard,filled
    required: false,
    label: '',
    helperText: '',
    inputProps: {
        readOnly: false
    },
    onChange: () => null,
    onBlur: () => null,
    onFocus: () => null,
    color: 'primary',//'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
    error: false,
    fullWidth: false,
    id: '',
    multiline: false,
    value: '',
    hiddenLabel: false,
    margin: 'none', //'dense' | 'normal' | 'none';
    type: 'text',
    size: 'medium',
    sx: {
        width: '100% !important',
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: colors.primary.main,
            }
        },
    }
}
PayaptTextField.propTypes = {
    autoFocus: PropTypes.bool,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    style: PropTypes.instanceOf(Object),
    className: PropTypes.string,
    variant: PropTypes.string,
    required: PropTypes.bool,
    label: PropTypes.string,
    helperText: PropTypes.string,
    inputProps: PropTypes.instanceOf(Object),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    color: PropTypes.string,
    error: PropTypes.bool,
    fullWidth: PropTypes.bool,
    id: PropTypes.string,
    multiline: PropTypes.bool,
    rows: PropTypes.number,
    minRows: PropTypes.number,
    maxRows: PropTypes.number,
    value: PropTypes.string,
    hiddenLabel: PropTypes.bool,
    margin: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.string,
    sx: PropTypes.instanceOf(Object)
}
export default PayaptTextField;