import React, { useEffect } from 'react';
import _ from 'lodash';
import Dropdown from '../../shared/components/Dropdown';
import { useGetSelectDirectionListQuery } from '../../redux/uploadTestcase/uploadTestcase.api';

const styles = {
    menuProps: {
      sx: {
          '.MuiPaper-root': {
              maxHeight: '40%',
          },
        },
    },
}

const data = [
    {
        directionId:'1',
        direction: 'SEND',
    },
    {
        directionId:'2',
        direction: 'RECIEVE'
    },
    
]
const DirectionDropdown = (props) => {
    const { onChangeDirection, value, isClear,error } = props;
    const {data:directionOptions} = useGetSelectDirectionListQuery();

    useEffect(() => {
        if (!_.isEmpty(directionOptions)) {
            onChangeDirection(directionOptions?.directionId, directionOptions?.direction)
        }
    }, [directionOptions])

    const onChange = (value) => {
        // console.log('value-onChange',value);
        if(value){
          const obj = _.find(directionOptions, m => m?.directionId === value);
          onChangeDirection(value, obj?.direction)
        } else {
            onChangeDirection(value, value)
        }
        
      }
  return (
    <>
     <Dropdown
       placeholder=' Select Direction Type'
       margin="dense"
       className="user-dropdown"
       style={{ width: '100%', height: '42px',marginTop:'-20px'}}
       inputProps={{ 'aria-label': 'Without label' }}
       options={directionOptions}
       valueKey='direction'
       labelKey='direction'
       onChange={(e) =>onChange(e)}
       value={value}
       MenuProps={styles.menuProps}
    //    isClear={isClear}
     />
    </>
  )
}

export default DirectionDropdown;
