import React, { useState, useEffect } from 'react';
import '../components/styles/customTestCaseScrollbar.css'
import { Box, Grid, IconButton, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import _, { map, isEmpty, keyBy } from 'lodash';
import TestCaseHeader from '../components/testCaseHeader';
import '../components/styles/message.css'
import TextArea from 'antd/es/input/TextArea';
import { AuditLogs, LogFileKeys } from '../constants/constants';
import { useDispatch } from 'react-redux';
import { useGetHopLogsMutation, useGetTestRunLogKeysDataQuery, useLazyGetTestRunXMLDataByLogKeyIdQuery } from '../redux/testRuns/testRuns.api';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Menu from '../shared/components/Menu/menu';
import { ExpandIcon } from '../constants/menuIcons';
import AuditLog from './AuditLog';
import MenuIcon from '@mui/icons-material/Menu';
import PayaptTooltip from '../shared/components/Tooltip';
import { layer } from '@fortawesome/fontawesome-svg-core';
import { useGetMessageIdQuery } from "../redux/testRuns/testRuns.api";


export const ViewLogs = (props) => {
    const { testcaseName, item: xSelRec } = props;
    const openIndex = xSelRec?.openIndex || 0
    const dispatch = useDispatch();
    const [ messageLog , setMessageLog] =useState();
    console.log("messageLog",messageLog);
    const { data : messageId, isFetching} = useGetMessageIdQuery({testRunId: xSelRec?.testrunId})
    const [selectedLog, setSelectedLog] = useState({
        key: 'tcTestRunLog',
        name: 'Test Client run log',
        type: 'Test Client run log'
    })
    const [rightSideValue, setRightSideValue] = useState(null);
    const [selectedSubItem, setSelectedSubItem ] = useState(null);
    const [collapseMenu, setCollapseMenu] = useState(false)
    const [apiLoading, setApiLoading] = useState(false);
    // console.log('messageId',messageId);
    const messageLogDetails = messageLog
    console.log('messageLogDetails',messageLogDetails);
    const { data: leftSideData, loading } = useGetTestRunLogKeysDataQuery({
        testRunId: xSelRec?.testrunId
    }, {
        skip: isEmpty(xSelRec)
    });
    // const jsonData = JSON.parse(leftSideData);
    // console.log('leftSideData',leftSideData);
    const [getTestRunXMLDataByLogKeyId, { isLoading: logsLoading }] = useLazyGetTestRunXMLDataByLogKeyIdQuery()
    const [getHopLogs] = useGetHopLogsMutation();

    const xEPayload = {
        customerTestRunId: xSelRec?.testrunId
    }

    useEffect(() => {
        // Define an async function inside the useEffect hook
        const fetchData = async () => {
          try {
            const res = await getHopLogs(xEPayload).unwrap();
            console.log("🚀 ~ file: viewLogs.js:51 ~ fetchData ~ res:", { res });
            setMessageLog(res)
          } catch (error) {
            // Handle or log error
            console.error("Failed to fetch hop logs:", error);
          }
        };
    
        // Call the async function
        fetchData();
      }, [xSelRec]);


    useEffect(() => {
        if (leftSideData && leftSideData?.length > 0) {
            setSelectedLog(leftSideData[openIndex])
        } else if(leftSideData?.length <= 0){
            setSelectedLog()
        }
        if (leftSideData && leftSideData?.tcTestRunLog !== "") {
            if (leftSideData?.length > 0) {
                handleFetchApi(leftSideData[openIndex]);
            }
        }
    }, [leftSideData])

    // const getMessageDetails =async()=>{
    //     setApiLoading(true);
    // // const response = await fetch(`${window.REACT_APP_MESSAGEDETAILS_LOG_URL}${e}`,
    // const response = await fetch(`${window.REACT_APP_MESSAGEDETAILS_LOG_URL}${messageId?.message}`,
    //   {
    //     method: 'GET',
    //     headers: {
    //       'content-type': 'application/json',
    //       'Accept': 'application/json'
    //     },
    //     // mode: 'no-cors'

    //   })
    //   const jsonData = await response.json();
    //   setApiLoading(false)
    // // const jsonData = logData;
    // setMessageLog(jsonData)
    // // const messageList = jsonData;
    // // console.log('messageList-getMessageDetails',messageList);
    // }

    // useEffect(()=>{
    //     getMessageDetails()
    // },[messageId])

    // console.log('useGetTestRunLogKeysDataQuery',selectedSubItem);
    const handleFetchApi = async (yyItem, parent) => {
        console.log('yyItem',yyItem.key ,'yyItem.key',yyItem.type);
        // await getData(yyItem.key);
        // if(yyItem.type !== 'logs'){
        //     console.log('if');
        //     await getData(yyItem.key);
        // }
        if(yyItem.key == 'tcTestRunLog' || yyItem.key == 'tcMessageLog'){
            await getData(yyItem.key);
        }
           else if(yyItem.messageId && yyItem.type !== 'logs'){
            console.log('messageId');
            getMessageLog(yyItem.key)
           }
        // else{
        //     console.log('else');
        //     getMessageLog(yyItem.key)
        // }
        setSelectedLog(yyItem);
        if(!parent){
            setSelectedSubItem(null)
        } else {
            setSelectedSubItem(parent)
        }
    }
   
    
    const getMessageLog=(key)=>{
        // console.log('getMessageLog',key);

    // const filteredMessages = messageLog?.data1?.listOfMessages
    // .filter((message) => message?.messageStatus === key)
    // .map((message) => message?.message)
    // setRightSideValue(filteredMessages)
    // console.log('filteredMessages',filteredMessages);

    let filteredMessages = [];

    if (messageLog && messageLog?.data1 && messageLog?.data1?.listOfMessages) {
        filteredMessages = filteredMessages.concat(messageLog?.data1?.listOfMessages);
    }

    if (messageLog && messageLog?.data2 && messageLog?.data2?.listOfMessages) {
        filteredMessages = filteredMessages.concat(messageLog?.data2?.listOfMessages);
    }

    filteredMessages = filteredMessages
        .filter((message) => message?.objId === key)
        .map((message) => message?.message);

    setRightSideValue(filteredMessages);
    // console.log('filteredMessages', filteredMessages);

    }
    // console.log('rightSideValue',rightSideValue);
    useEffect(()=>{
        if(leftSideData){
        setRightSideValue(leftSideData[selectedLog.key])
        }
    },[leftSideData])
    const getData = async (zzItem) => {
        console.log("🚀 ~ file: viewLogs.js:51 ~ getData ~ res:")
        // const xPageOptions = {
        //     customerTestRunId: xSelRec?.testrunId
        //     // testRunId: xSelRec?.testrunId,
        //     // pathKey: zzItem?.key,
        //     // filename: zzItem?.fileName,
        // }
        try {
            // const res = await getTestRunXMLDataByLogKeyId(xPageOptions).unwrap();
            // const res = await getHopLogs(xPageOptions).unwrap();
            // console.log("🚀 ~ file: viewLogs.js:51 ~ getData ~ res:", { res })
            if (zzItem) {
                setRightSideValue(leftSideData[zzItem]);
            } else {
                setRightSideValue("NA");
            }
        } catch (err) {
            setRightSideValue(null);
            // console.log("🚀 ~ file: viewLogs.js:52 ~ getData ~ err:", err)
        }
    };

    const downloadFile = async (selectedLog) => {
        // const handleDownload = () => {
            const element = document.createElement("a");
            const file = new Blob([rightSideValue], { type: 'text/plain' });
            element.href = URL.createObjectURL(file);
            element.download = `${messageId.message}_${selectedLog.name}`
            document.body.appendChild(element);
            element.click();
        // const arr = selectedLog?.pathURL?.split('.');
        // let extension = 'txt';
        // const token = await localStorage.getItem('jwttoken');
        // if (!_.isEmpty(arr)) {
        //     extension = arr[_.size(arr) - 1];
        // }
        // fetch(`${window.REACT_APP_BASE_URL}Payapt/api/v1/customerTestRun/download2?pathKey=${selectedLog?.key}&filename=${selectedLog?.fileName}`,
        //     {
        //         method: 'GET',
        //         headers: {
        //             Authorization: `Bearer ${token}`
        //         }
        //     })
        //     .then((response) => response.blob())
        //     .then((blob) => {
        //         // Create blob link to download    
        //         const url = window.URL.createObjectURL(new Blob([blob]))
        //         const link = document.createElement('a');
        //         link.href = url;
        //         link.setAttribute('download', `${selectedLog?.fileDownloadName}.${extension}`);
        //         // Append to html link element page    
        //         document.body.appendChild(link); // Start download    
        //         link.click();// Clean up and remove the link    
        //         link.parentNode.removeChild(link);
        //     });
    }

    // const getMenus = () => {
    //     const menus = [];
    //     _.forEach(leftSideData?.allLogs, l => {
    //         if (_.has(l, 'simOutMessageLog')){
    //             const canHaveMultipleItems = _.size(l?.simOutMessageLog) > 1;
    //             const obj = {
    //                 fileName: canHaveMultipleItems ? "" : l?.simOutMessageLog !== null?  _.get(l?.simOutMessageLog[0], 'fileName') : '',
    //                 pathUrl: canHaveMultipleItems ? "" :l?.simOutMessageLog !== null?  _.get(l?.simOutMessageLog[0], 'pathURL') : '',
    //                 key: canHaveMultipleItems ? "sim_out_message_log" : l?.simOutMessageLog !== null? _.get(l?.simOutMessageLog[0], 'key') : '',
    //                 name: LogFileKeys['sim_out_message_log'].name,
    //                 fileDownloadName: `${LogFileKeys['sim_out_message_log'].name}`
    //             }
    //             if(canHaveMultipleItems){
    //                 obj.icon = (isActive) => <ExpandIcon isActive={isActive} />
    //                 const children = _.map(l?.simOutMessageLog, (o, index) => ({
    //                     fileName: o?.fileName,
    //                     pathURL: o?.pathURL,
    //                     key: o?.key,
    //                     name: `Log ${index+1}`,
    //                     uniqueKey: `${o?.key}-${index}`,
    //                     fileDownloadName: `${LogFileKeys[o?.key].name}-Log ${index+1}`
    //                 }))
    //                 obj.children = children;
    //             }
    //             menus.push(obj)
    //         }
    //         // } if(l?.key === 'sim_console_log'){
    //         //     return {
    //         //         fileName: l?.fileName,
    //         //         pathURL: l?.pathURL,
    //         //         key: l?.key,
    //         //         name: 'Audit Logs',
    //         //         fileDownloadName: `${LogFileKeys[l?.key].name}`,
    //         //         type: 'logs',
    //         //     }
    //         // }
    //         if(!!LogFileKeys[l?.key]){
    //             menus.push({
    //                 fileName: l?.fileName,
    //                 pathURL: l?.pathURL,
    //                 key: l?.key,
    //                 name: LogFileKeys[l?.key].name,
    //                 fileDownloadName: `${LogFileKeys[l?.key].name}`
    //             })
    //         }
    //     })
    //     menus.push({
    //         key: 'logs',
    //         name: 'Audit Logs',
    //         type: 'logs',
    //     })
    //     return menus;
    // }
    // console.log('leftSideData',leftSideData);
    var keyData=[];
    const generateElement = (key,value) => {
        keyData.push(key)
        return (
          <div key={key} className="row">
            <div className="col-xs-6 ins-label">{key}</div>
            {/* <div className="col-xs-6">{value}</div> */}
          </div>
        );
      }
    function generateData(data) {
        if(data){
        const newData = Object.keys(data).reduce((result, currentKey) => {
            if (typeof data[currentKey] === 'string' || data[currentKey] instanceof String) {
              const elementToPush = generateElement(currentKey, data[currentKey]);
              result.push(elementToPush);
            } 
            return result;
          }, []);
        }
    } 
    var keys;
    const getMenus = () => {
        var result;
        const menus = [];
        if(leftSideData){
            keys = Object.keys(leftSideData);
            }
                keys?.map((key)=>{
                const canHaveMultipleItems = _.size(key) > 1;
                var obj = {}
                
                 obj = {
                    key: canHaveMultipleItems ? key: key !== null? _.get(key[0], 'key') : '',
                    name: LogFileKeys[key].name,
                    fileDownloadName: `${messageId?.message}_${LogFileKeys[key].name}`
                }
                if(key != 'testRunId' && key != 'logId'){
                menus.push(obj)
                }
        })
        if (messageLog && messageLog?.data1 && messageLog?.data1?.listOfMessages) {
            messageLog?.data1?.listOfMessages.forEach((message) => {
                const canHaveMultipleItems = _.size(message) > 1;
                const obj = {
                    key: message?.objId,
                    name: message.messageStatus,
                    fileDownloadName: `${messageId?.message}_${message?.messageStatusDescription}`,
                    messageId: messageId,
                    message:message?.message
                };
    
                if (message !== 'testRunId' && message !== 'logId') {
                    menus.push(obj);
                }
            });
        }
    
        if (messageLog && messageLog?.data2 && messageLog?.data2?.listOfMessages) {
            messageLog?.data2?.listOfMessages.forEach((message) => {
                const canHaveMultipleItems = _.size(message) > 1;
                const obj = {
                    key: message?.objId,
                    name: message.messageStatus,
                    fileDownloadName: `${messageId?.message}_${message.messageStatusDescription}`,
                    messageId: messageId
                };
    
                if (message !== 'testRunId' && message !== 'logId') {
                    menus.push(obj);
                }
            });
        }
        // menus.push({
        //     key: 'logs',
        //     name: 'Audit Logs',
        //     type: 'logs'
        // })
        return menus;
        // console.log('menus',menus);
    }

    console.log('selectedLog',selectedLog);
    
    return (
        <Box>
            <TestCaseHeader showInfoIcon={false} title={`View Logs - Logs of the Testcase: ${testcaseName}`}
                showFileUpload={{
                    show: !_.isEmpty(selectedLog) && selectedLog.type !== 'logs',
                    customIcon: <FileDownloadIcon />,
                    onClick: () => downloadFile(selectedLog)
                }}
            />
            <Box sx={{
                display: "flex",
                // minHeight: '50vh',
                minHeight: "65vh",
                flexGrow: 1,
                overflowY: 'auto',
                marginTop: selectedLog.type === 'logs' ? '3%' :0
            }}>
                <Grid container>
                    {!collapseMenu && leftSideData &&
                    <Grid item sx={{ display: "flex", flexDirection: "column", border: '1px solid #f5f5f5', width: '22%' }}>
                        {loading ? (<CircularProgress className='data-loader' />) : (
                            <div id='scrollable-menu-target' style = {{ height: '50%'}}>
                            <Menu
                                items={getMenus()}
                                onClick={(e, item, parent) => handleFetchApi(item, parent)}
                                selectedKeys={_.isEmpty(selectedSubItem) ? [selectedLog?.key]: [selectedLog?.uniqueKey]}
                                collapse={false}
                                openSubmenu={true}
                                // openSubmenu={handleOpenSubMenu}
                                showExpandIcon={false}
                                selectionKey='key'
                                menuTitleKey='name'
                                submenuTitleKey='name'
                                childrenKey='children'
                                width='100%'
                                submenuSelectionKey='uniqueKey'
                                style={{ paddingRight: '10px'  }}
                                selectedSubmenuKeys={[selectedSubItem?.key]}
                                onSubmenuSelected={(e, item) => {
                                    setSelectedSubItem(item);
                                    setSelectedLog({})
                                    
                                }}
                            />
                            
                            {/* {
                                _.map(data, t => (
                                    <div className={selectedTestCase && selectedTestCase.testCaseId === t.testCaseId ? 'test-case-menu-item-active' : 'test-case-menu-item'} onClick={() => setSelectedTestCase(t)}>{t.testCaseName}</div>
                                ))
                            } */}
                            </div>
                        )}
                    </Grid>
                    }
                    <PayaptTooltip title = {collapseMenu ? 'Open Logs' : 'Collpase Logs'}>
                        <MenuIcon onClick = {() => setCollapseMenu(!collapseMenu)} sx = {{color: '#676878'}}/>
                    </PayaptTooltip>
                    <Grid item sx={{ display: "flex", alignItems: selectedLog&&selectedLog.type === 'logs' ? 'flex-start' : 'center', justifyContent: "center", width: collapseMenu ? '95%' : '75%' }}>
                        {displayLogData(logsLoading, rightSideValue, selectedLog, xSelRec?.testrunId, xSelRec?.messageType)}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}


const displayLogData = (xLoading, xValue, selectedLog,testRunId, messageType) => {
    return (
        <React.Fragment>
            {xLoading ?
                <div className="xml-txt-area" style={{ minHeight: '60vh', justifyContent: 'center', display: 'flex' }}> <CircularProgress className='data-loader' /> </div>
                : selectedLog?.key === 'logs' ? <AuditLog testRunId = {testRunId} messageType = {messageType}/>
                
                :!xValue ? <Typography>No Data</Typography>
                    : <TextArea
                        rows={15}
                        className="xml-txt-area"
                        style={{ height: '100%' }}
                        value={xValue}
                        readOnly
                    />
            }
            
        </React.Fragment>
    );
}

export default ViewLogs;