import { LeftCircleFilled, LogoutOutlined } from '@ant-design/icons';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, Grid , Typography} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import React,{useState} from 'react';
import { useNavigate } from 'react-router-dom';
import MUIButton from '../../shared/components/MUIButton';
import TestCaseHeader from '../testCaseHeader';

const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      textAlign:"left",
      margin:'0px 20px'
    },
    card: {
        marginBottom:'35px',
        marginLeft:'25px',
        marginRight:'25px',
    },
    cardHeader:{
            backgroundColor:'#F0F2F5',
            margin:0,
            paddingLeft:0,
            borderBottom:'2px solid green'
    },
    cardContent1:{
        fontWeight: 'bold'
    },
    muibackBotton:{
        backgroundColor:'#F0F2F5',
        color:'#808080',
        marginLeft:"25px",
        marginRight:"25px"
    },
    iconButton:{
        backgroundColor:'white',
        padding:'6px',
        border:"1px solid #C6CBD1"
    }
  };


const OutGoingXmlList = () => {
    const navigate = useNavigate();
    const [pacsName, setPacsName] = useState("PACS.009.ADV");
    const [xmlFileName, setXmlFileName] = useState("Text Xml File Name Goese Here_00022.xml");
    const [referenceID, setReferenceID] = useState("PAY123");
    const [sender, setSender] = useState("BANK897");
    const [receiver, setReceiver] = useState("BANK123");
    const [result, setResult] = useState('OK');

    const onBack = () =>{
        navigate(-1);
      }

      const onNext = () =>{
        navigate('/message/xmlValidateReport');
      }

      const optionDatas = [{
        id:'1',
        label:'SWIFT,CBRR+ 2020,PACS.005 Customer Credit Transfer'
       }]
    

  return (
    <Box sx={styles.container}>
        <TestCaseHeader
            title={'Messages - XML Message List'}
            subtitle={'Some details related to XML Message List goes here'}
            showFileUpload={{
                show: true,
                // onClick: openFileUpload
            }}
            showFileAdded={{
                show: true,
                // onClick: openFileAdded
            }}
            showFilter={{
            show: true,
            //   onClick: openModal
            }}
            showSearch={{
            show: true,
            //   value: searchedText,
            //   onChange: onSearchChange
            }}
      >
      </TestCaseHeader>
      <Box sx={styles.card}>
        <Card>
        <CardHeader sx={styles.cardHeader}
            title={<><strong className='cardTitle1'>{pacsName}</strong>&nbsp; &nbsp;<strong  className='cardTitle2'>{xmlFileName}</strong></>}
            action={
                <>
                    <div onClick={onNext} className='headerAction'>
                        <span style={{cursor: 'pointer'}}>
                        <IconButton aria-label="settings" sx={{transform: 'rotate(310deg)'}} >
                            <LogoutOutlined  sx={{fontSize:"15px"}}/>
                        </IconButton>
                        <strong sx={{fontSize:"15px"}}>Out Going</strong>
                        </span>
                        &nbsp; &nbsp;
                        <span >
                        <IconButton aria-label="settings" sx={styles.iconButton}>
                            <FileDownloadIcon sx={{fontSize:"15px"}}/>
                        </IconButton>
                        </span>
                        &nbsp; &nbsp;
                        <span >
                        <IconButton aria-label="settings" sx={styles.iconButton}>
                            <DeleteForeverRoundedIcon sx={{fontSize:"15px"}}/>
                        </IconButton>
                        </span>
                        &nbsp; &nbsp;
                        <span >
                        <IconButton aria-label="settings" sx={styles.iconButton}>
                            <MoreVertIcon sx={{fontSize:"15px"}}/>
                        </IconButton>
                        </span>
                    </div>
                </>
            }
      />
       <CardContent>
       <Box sx={{ flexGrow: 1 }} mb={2}>
       <Grid  container alignItems="center" spacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6} md={6}>
            <Typography sx={styles.cardContent1}>
                Reference ID
            </Typography>
            <Typography>
                {referenceID}
            </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
            <Typography sx={styles.cardContent1}>
                Sender
            </Typography>
            <Typography>
                {sender}
            </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
            <Typography sx={styles.cardContent1}>
                Receiver
            </Typography>
            <Typography >
                {receiver}
            </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
            <Typography sx={styles.cardContent1}>
                Validation Result
            </Typography>
            <Typography>
                {result}
            </Typography>
            </Grid>
       </Grid>
       </Box>
       </CardContent>
        </Card>
      </Box>
      <Box>
       <MUIButton 
            startIcon={<LeftCircleFilled style={{ fontSize: 18}}/>}
            title="Back"
            sx={styles.muibackBotton}
            color="inherit"
            onClick={onBack}
          />
       </Box>
    </Box>
  )
}

export default OutGoingXmlList;
