export const audit_column = [
    // {
    //     title: 'Server',
    //     dataIndex: 'applicationName',
    //     // fixed: 'left',
    //     width: '7%'
    // },
    // ,
    // {
    //     title: 'Timestamp',
    //     dataIndex: 'timestamp',
    //     width: '15%',
    // },
    // {
    //     title: 'Message Id / Type',
    //     dataIndex: 'messageId',
    //     width: '20%',
    // },
    // {
    //     title: 'Level',
    //     dataIndex: 'level',
    //     width: '8%'
    // },
    {
        title: 'Message',
        dataIndex: 'message',
        width: '40%'
    },
];

export const audit_tabledata = [
    {
        key: 'PGW',
        server: 'Payment Gateway',
        TimeStamp: 'Customer Credit Transfer',
        msgId: 'PACS.008',
        msgType: 'SEND',
        level : 'Level',
    },
    {
        key: 'FNS',
        server: 'FedNow Service',
        TimeStamp: 'Customer Credit Transfer',
        msgId: 'PACS.008',
        msgType: 'SEND',
        level : 'Level'
    },
    {
        key: 'PGW',
        server: 'Payment Gateway',
        TimeStamp: 'Customer Credit Transfer',
        msgId: 'PACS.002',
        msgType: 'SEND',
        level : 'Level'
    },
    {
        key: 'FIS',
        server: 'Financial Institute Service',
        TimeStamp: 'Customer Credit Transfer',
        msgId: 'PACS.004',
        msgType: 'SEND',
        level : 'Level'
    },
    {
        key: 'FNS',
        server: 'FedNow Service',
        TimeStamp: 'Customer Credit Transfer',
        msgId: 'PACS.008',
        msgType: 'SEND',
        level : 'Level'
    },
    {
        key: 'FIS',
        server: 'Financial Institute Service',
        TimeStamp: 'Customer Credit Transfer',
        msgId: 'PACS.008',
        msgType: 'SEND',
        level : 'Level'
    },
    {
        key: 'FIS',
        server: 'Financial Institute Service',
        TimeStamp: 'Customer Credit Transfer',
        msgId: 'PACS.002',
        msgType: 'SEND',
        level : 'Level'
    },
    {
        key: 'PGW',
        server: 'Payment Gateway',
        TimeStamp: 'Customer Credit Transfer',
        msgId: 'PACS.004',
        msgType: 'SEND',
        level : 'Level'
    },
];

export const logData = {
    "took": 1,
    "timed_out": false,
    "_shards": {
        "total": 5,
        "successful": 5,
        "skipped": 0,
        "failed": 0
    },
    "hits": {
        "total": 154,
        "max_score": 1.0,
        "hits": [
            {
                "_index": "logstash-2023.04.24",
                "_type": "doc",
                "_id": "dU6ysocB_TL__RQubYYn",
                "_score": 1.0,
                "_source": {
                    "@timestamp": "2023-04-24T09:57:30.418Z",
                    "@version": "1",
                    "message": "{\"@timestamp\":\"2023-04-24T15:27:30.511+05:30\",\"@version\":\"1\",\"message\":\"[PGW] [2023-04-24-09-57-30-42][20230424021150706FyuBLPABl06379842][pacs008] Input Message Persisted to Mongo DB for the Creditor Account: null \",\"logger_name\":\"com.primesoftinc.paymentgw.controller.PaymentGatewaySimulatorController\",\"thread_name\":\"http-nio-9881-exec-3\",\"level\":\"INFO\",\"level_value\":20000,\"applicationName\":\"PGW\"}\r",
                    "host": "14.192.3.85",
                    "port": 62230
                }
            },
            {
                "_index": "logstash-2023.04.24",
                "_type": "doc",
                "_id": "ek6ysocB_TL__RQucIYL",
                "_score": 1.0,
                "_source": {
                    "@timestamp": "2023-04-24T09:57:31.170Z",
                    "@version": "1",
                    "message": "{\"@timestamp\":\"2023-04-24T15:27:31.273+05:30\",\"@version\":\"1\",\"message\":\"PGW: [2023-04-24-09-57-31-27][pacs008] Message pushed and Sent Pacs-008 to Simualtor Queue: PGW.QM1.Q.OUT \",\"logger_name\":\"com.primesoftinc.paymentgw.service.GatewaySimulatorServiceImpl\",\"thread_name\":\"http-nio-9881-exec-3\",\"level\":\"INFO\",\"level_value\":20000,\"applicationName\":\"PGW\"}\r",
                    "host": "14.192.3.85",
                    "port": 62230
                }
            },
            {
                "_index": "logstash-2023.04.24",
                "_type": "doc",
                "_id": "a06ysocB_TL__RQuXoYM",
                "_score": 1.0,
                "_source": {
                    "@timestamp": "2023-04-24T09:57:26.562Z",
                    "@version": "1",
                    "message": "{\"@timestamp\":\"2023-04-24T15:27:26.164+05:30\",\"@version\":\"1\",\"message\":\"[PGW] [2023-04-24-09-57-26-16][20230424021150706FyuBLPABl06379842][pacs008] Customer Id: tertyuih56789 and Test Run Id 34567890765 : \",\"logger_name\":\"com.primesoftinc.paymentgw.controller.PaymentGatewaySimulatorController\",\"thread_name\":\"http-nio-9881-exec-2\",\"level\":\"INFO\",\"level_value\":20000,\"applicationName\":\"PGW\"}\r",
                    "host": "14.192.3.85",
                    "port": 62230
                }
            },
            {
                "_index": "logstash-2023.04.24",
                "_type": "doc",
                "_id": "cU6ysocB_TL__RQuZIZR",
                "_score": 1.0,
                "_source": {
                    "@timestamp": "2023-04-24T09:57:28.167Z",
                    "@version": "1",
                    "message": "{\"@timestamp\":\"2023-04-24T15:27:28.269+05:30\",\"@version\":\"1\",\"message\":\"PGW: [2023-04-24-09-57-28-26][pacs008] Message pushed and Sent Pacs-008 to Simualtor Queue: PGW.QM1.Q.OUT \",\"logger_name\":\"com.primesoftinc.paymentgw.service.GatewaySimulatorServiceImpl\",\"thread_name\":\"http-nio-9881-exec-2\",\"level\":\"INFO\",\"level_value\":20000,\"applicationName\":\"PGW\"}\r",
                    "host": "14.192.3.85",
                    "port": 62230
                }
            },
            {
                "_index": "logstash-2023.04.24",
                "_type": "doc",
                "_id": "mE7CsocB_TL__RQu94b0",
                "_score": 1.0,
                "_source": {
                    "@timestamp": "2023-04-24T10:15:34.534Z",
                    "@version": "1",
                    "message": "{\"@timestamp\":\"2023-04-24T15:45:32.050+05:30\",\"@version\":\"1\",\"message\":\"[PGW] [2023-04-24-10-15-31-98][20230424021150706FyuBLPABl06379842][pacs008] Input Message Persisted to Mongo DB for the Creditor Account: null \",\"logger_name\":\"com.primesoftinc.paymentgw.controller.PaymentGatewaySimulatorController\",\"thread_name\":\"http-nio-9881-exec-9\",\"level\":\"INFO\",\"level_value\":20000,\"applicationName\":\"PGW\"}\r",
                    "host": "14.192.3.85",
                    "port": 62548
                }
            },
            {
                "_index": "logstash-2023.04.24",
                "_type": "doc",
                "_id": "mU7CsocB_TL__RQu94b0",
                "_score": 1.0,
                "_source": {
                    "@timestamp": "2023-04-24T10:15:34.534Z",
                    "@version": "1",
                    "message": "{\"@timestamp\":\"2023-04-24T15:45:32.260+05:30\",\"@version\":\"1\",\"message\":\"[PGW] [2023-04-24-10-15-32-05][20230424021150706FyuBLPABl06379842][pacs008] Input Message Persisted to Mongo DB after Tech and Business validation \",\"logger_name\":\"com.primesoftinc.paymentgw.controller.PaymentGatewaySimulatorController\",\"thread_name\":\"http-nio-9881-exec-9\",\"level\":\"INFO\",\"level_value\":20000,\"applicationName\":\"PGW\"}\r",
                    "host": "14.192.3.85",
                    "port": 62548
                }
            },
            {
                "_index": "logstash-2023.04.24",
                "_type": "doc",
                "_id": "nE4Os4cB_TL__RQulIb1",
                "_score": 1.0,
                "_source": {
                    "@timestamp": "2023-04-24T11:38:09.932Z",
                    "@version": "1",
                    "message": "\\u0000\\xA5\\u0000\\xA3\\u0000\\xA1\\u0000\\x9F\\u0000k\\u0000j\\u0000i\\u0000h\\u00008\\u00007\\u00006\\u0000\\x88\\u0000\\x87\\u0000\\x86\\u0000\\x85\\xC0\\u0019\\u0000\\xA7\\u0000m\\u0000\\x89\\xC02\\xC0.\\xC0*\\xC0&\\xC0\\u000F\\xC0\\u0005\\u0000\\x9D\\u0000=\\u0000\\x84\\xC0/\\xC0+\\xC0'\\xC0#\\xC0\\u0013\\xC0\\t\\u0000\\xA4\\u0000\\xA2\\u0000\\xA0\\u0000\\x9E\\u0000g\\u0000@\\u0000?\\u0000>\\u00002\\u00001\\u00000\\u0000\\x9A\\u0000\\x99\\u0000\\x98\\u0000\\x97\\u0000E\\u0000D\\u0000C\\u0000B\\xC0\\u0018\\u0000\\xA6\\u0000l\\u0000\\x9B\\u0000F\\xC01\\xC0-\\xC0)\\xC0%\\xC0\\u000E\\xC0\\u0004\\u0000\\x9C\\u0000<\\u0000\\x96\\u0000A\\u0000\\a\\xC0\\u0011\\xC0\\a\\xC0\\u0016\\xC0\\f\\xC0\\u0002\\xC0\\u0012\\xC0\\b\\u0000\\u0013\\u0000\\u0010\\u0000\\r\\xC0\\u0017\\xC0\\r\\xC0\\u0003\\u0000\\xFF\\u0001\\u0000\\u0000\\xF9\\u0000\\v\\u0000\\u0004\\u0003\\u0000\\u0001\\u0002\\u0000",
                    "host": "183.136.225.42",
                    "port": 16322
                }
            },
            {
                "_index": "logstash-2023.04.24",
                "_type": "doc",
                "_id": "dk6ysocB_TL__RQubYYn",
                "_score": 1.0,
                "_source": {
                    "@timestamp": "2023-04-24T09:57:30.418Z",
                    "@version": "1",
                    "message": "{\"@timestamp\":\"2023-04-24T15:27:30.424+05:30\",\"@version\":\"1\",\"message\":\"[PGW] [2023-04-24-09-57-30-42][20230424021150706FyuBLPABl06379842][pacs008] Customer Id: tertyuih56789 and Test Run Id 34567890765 : \",\"logger_name\":\"com.primesoftinc.paymentgw.controller.PaymentGatewaySimulatorController\",\"thread_name\":\"http-nio-9881-exec-3\",\"level\":\"INFO\",\"level_value\":20000,\"applicationName\":\"PGW\"}\r",
                    "host": "14.192.3.85",
                    "port": 62230
                }
            },
            {
                "_index": "logstash-2023.04.24",
                "_type": "doc",
                "_id": "ak6ysocB_TL__RQuXoYK",
                "_score": 1.0,
                "_source": {
                    "@timestamp": "2023-04-24T09:57:26.561Z",
                    "@version": "1",
                    "message": "{\"@timestamp\":\"2023-04-24T15:27:26.164+05:30\",\"@version\":\"1\",\"message\":\"[PGW] [2023-04-24-09-57-26-16][20230424021150706FyuBLPABl06379842][pacs008] Received XML Data from Test Client\",\"logger_name\":\"com.primesoftinc.paymentgw.controller.PaymentGatewaySimulatorController\",\"thread_name\":\"http-nio-9881-exec-2\",\"level\":\"INFO\",\"level_value\":20000,\"applicationName\":\"PGW\"}\r",
                    "host": "14.192.3.85",
                    "port": 62230
                }
            },
            {
                "_index": "logstash-2023.04.24",
                "_type": "doc",
                "_id": "fU62socB_TL__RQuWIZB",
                "_score": 1.0,
                "_source": {
                    "@timestamp": "2023-04-24T10:01:47.222Z",
                    "@version": "1",
                    "message": "{\"@timestamp\":\"2023-04-24T15:31:47.319+05:30\",\"@version\":\"1\",\"message\":\"Started PaymentGatewayApplication in 4.668 seconds (JVM running for 5.927)\",\"logger_name\":\"com.primesoftinc.paymentgw.PaymentGatewayApplication\",\"thread_name\":\"main\",\"level\":\"INFO\",\"level_value\":20000,\"applicationName\":\"PGW\"}\r",
                    "host": "14.192.3.85",
                    "port": 62316
                }
            }
        ]
    }
}