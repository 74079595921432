import { backendApi } from '../base.config';

export const userActivityLogApi = backendApi.injectEndpoints({
    endpoints: (builder) => ({
        getUserActivityLogData: builder.mutation({
            query: (xargs) => {
                const { searchBoxInput, ...args } = xargs;
                    return {
                        url: `dashboard/auditLogList`,
                        method: 'POST',
                        body: { ...args },
                        refetchOnMountOrArgChange: true
                    }
            },

        }),

        getActivityType: builder.query({
            query: () => ({
                method: "GET",
                url: `customers/getActivityTypeDropDown`,
            }),
            providesTags: [],
        }),
    }),
});

export const { 
    useGetUserActivityLogDataMutation, 
    useGetActivityTypeQuery
} = 
userActivityLogApi;