import React from 'react';
import Switch from '@mui/material/Switch';
import { FormControlLabel } from '@mui/material';

function XSwitch(props) {
    const { label, labelPlacement = "start", containerStyles, ...restProps } = props;
    if (label) {
        return (
            <FormControlLabel
                value={restProps?.checked}
                control={<Switch sx={containerStyles} disableRipple {...restProps} />}
                label={label}
                labelPlacement={labelPlacement}
            />
        )
    }
    return (
        <Switch sx={containerStyles} disableRipple {...restProps} />
    );
}

export default XSwitch;
