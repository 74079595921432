import React from 'react'
import CircularProgressPercentage from './CircularProgressPercentage'
import ProgressCircle from './ProgressCircle';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import './progressCircle.css';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material';
import TestCaseHeader from '../testCaseHeader';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { ArrowNarrowDownIcon } from '../../assets/base64/images';
import StandaloneMessage from './Tabs/StandaloneMessage';
import MessageFlows from './Tabs/MessageFlows';
import NfrTestcases from './Tabs/NfrTestcases';

const ProgressCircleContainer = () => {
  return (
    <Box>
      <Grid my={2} mx={1} display='flex' container spacing={2}>
        <Grid item>
      <StandaloneMessage/>
      </Grid>
      <Grid item>
      <MessageFlows/>
      </Grid>
      <Grid item>
      <NfrTestcases/>
      </Grid>
      </Grid>
    </Box>
  )
}

export default ProgressCircleContainer;
