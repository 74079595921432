import api from '../utils/api';

export const generatePasc008 = async () => {
    try {
        const res = await api.get('http://65.1.164.15:9881/generatePacs008');
        return res.data;
    } catch (error) {
        console.log(error)
    }
}

export const validateXML = async (data) => {
    try {
        const res = await api.post('http://65.1.164.15:9882/validateXML', data, {headers: {'Content-Type': 'application/xml'}});
        return res.data;
    } catch (error) {
        console.log(error)
    }
}

export const validateBusiness = async (data) => {
    try {
        const res = await api.post('http://65.1.164.15:9882/businessValidateXML', data, {headers: {'Content-Type': 'application/xml'}});
        return res.data;
    } catch (error) {
        console.log(error)
    }
}

export const sendToMock1 = async (data) => {
    try {
        await api.post('http://65.1.164.15:9881/sendpacs008tosimulator', data, {headers: {'Content-Type': 'application/xml'}});
    } catch (error) {
        console.log(error)
    }
}

export const getFiles = async (data) => {
    try {
        const res = await api.get('http://65.1.164.15:9881/fileNameReader');
        return res.data;
    } catch (error) {
        console.log(error)
    }
}