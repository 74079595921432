import React from 'react';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
    useRadioGroup,
} from "@mui/material";
import { green, grey } from "@mui/material/colors";
import { styled } from "@mui/system";
import PropTypes from "prop-types";
import TestCaseHeader from './testCaseHeader';
import MUIButton from '../shared/components/MUIButton';
import { INPUT_HEIGHT } from '../shared/dims';


const StyledFormControlLabel = styled((props) => (
    <FormControlLabel {...props} />
))(({ checked }) => ({
    // '.MuiFormControlLabel-label': checked && {
    //     color: '#00A76F',
    // },
}));
function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
        checked = radioGroup.value === props.value;
    }
    return <StyledFormControlLabel checked={checked} {...props} />;
}
MyFormControlLabel.propTypes = {
    value: PropTypes.any,
};
const TestcaseDetailsHeader = (props) => {
    const { showReviewBtn = false, onReviewButtonClick, showAddRowBtn, selectedTestCase,
        disableReviewButton, disableAddRowButton, onSelectionChange, value, selectedTestScenario } = props;

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                <TestCaseHeader
                    showInfoIcon={false}
                    title={`View Test Data - ${selectedTestScenario?.customerTestcaseDescription ? selectedTestScenario?.customerTestcaseDescription : selectedTestScenario?.customerTestDescription} (${selectedTestScenario?.customerTestScenarioName})`}
                    // subtitle={<b>{`${selectedTestScenario?.customerTestcaseDescription} (${selectedTestScenario?.customerTestScenarioName})`}</b>}
                    showFilter
                />
                {
                    showReviewBtn &&
                    <MUIButton
                        startIcon={<CheckCircleIcon />}
                        onClick={onReviewButtonClick}
                        title="Review and Approve"
                        size="small"
                        sx={{ mt: 2, width: 300, height: INPUT_HEIGHT }}
                    />
                    // <Button 
                    //     variant="contained" 
                    //     size="small" 
                    //     startIcon={<CheckCircleIcon />} 
                    //     onClick={onReviewButtonClick} 
                    //     sx = {{textTransform: 'none', width: 300, height: 40}}
                    // >
                    //     Review and Approve
                    // </Button>
                    // <Button type="primary" onClick={onReviewButtonClick} className='btn-primary' disabled={disableReviewButton}>
                    //     <CheckCircleFilled twoToneColor="#ffffff" style={{ fontSize: 16 }} />
                    //         Reviewed
                    // </Button>

                }
            </div>
            <div style = {{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <RadioGroup
                    onChange={(e) => onSelectionChange(e.target.value)}
                    name="use-radio-group"
                    value={value}
                    row
                    sx={{ fontFamily: "Inter", paddingLeft: 1.5 }}
                >
                    <MyFormControlLabel
                        value="testData"
                        label={<Typography sx={{ fontSize: 18 }}>Test Data</Typography>}
                        control={
                            <Radio
                                size="10px"
                                sx={{
                                    color: grey[700],
                                    "&.Mui-checked": {
                                        color: "#00A76F",
                                    },
                                }}
                            />
                        }
                    />
                    <MyFormControlLabel
                    value="masterXml"
                    label={<Typography sx={{ fontSize: 18 }}>Master Data</Typography>}
                    control={
                        <Radio
                        size="10px"
                        sx={{
                            color: grey[700],
                            "&.Mui-checked": {
                            color: "#00A76F",
                            },
                        }}
                        />
                    }
                    />
                </RadioGroup>
                {
                    showAddRowBtn?.show &&
                    <Button
                        size="small"
                        startIcon={<AddCircleOutlineIcon size="small" />}
                        onClick={() => showAddRowBtn?.onClick(selectedTestCase)}
                        disabled={disableAddRowButton}
                        sx={{ textTransform: 'none', color: '#676878' }}
                    >
                        Add Row
                    </Button>
                }

            </div>
            
        </div>
    )
}

export default TestcaseDetailsHeader;
