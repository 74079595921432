import React, { useEffect, useState } from "react";
import _, { delay } from "lodash";

const OutputFiles = (props) => {
  const { files, filesLoading } = props;


  return (
    <div className="schema-validation accordion" style={{ padding: 10 }} id="output-files-parent">
      <div class="accordion-item">
        <h2 class="accordion-header" id="output-files-header">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#output-files" aria-controls="output-files" aria-expanded="true" >
            Output Files
          </button>
        </h2>
        <div id="output-files" class="accordion-collapse collapse show" aria-labelledby="output-files-header" data-bs-parent="#output-files-parent">
          <div class="accordion-body">
            {filesLoading && <div class="loader"></div>}
            {
              _.map(files, f => (
                <>
                  <br />
                  <a href= {f.fileName} target ="_blank" className="file-name">{f.fileName}</a>
                  <br />
                </>
              ))
            }
          </div>
        </div>
      </div>

    </div>

  );
};

export default OutputFiles;
