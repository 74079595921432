import { LeftCircleFilled } from '@ant-design/icons';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, FormControl, Grid, MenuItem, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Select from '@mui/material/Select';
import React, { useState , useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { messageTemplateList } from '../../components/messages/constants';
import MUIButton from '../../shared/components/MUIButton';
import TestCaseHeader from '../testCaseHeader';
import Dropdown from '../../shared/components/Dropdown';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    textAlign:"left",
    margin:'0px 20px'
  },
  accordionBox: {
    marginBottom:'35px',
    marginLeft:'25px',
    marginRight:'25px',
  },
  accordionSummary:{
    backgroundColor:'#F0F2F5',
    borderBottom:'2px solid green'
  },
  title:{
    fontSize:"20px",
    fontWeight:'bold'
  },
  label:{
    fontWeight:'bold'
  }
};



const CreateIncomingTemplates = () => {
  const [template, setTemplate] = useState('');
  const templateData = messageTemplateList;
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState('panel_0');
  const [start, setStart] = React.useState(true);

  const handleChange = (panel) => (event, isExpanded) => {
    setStart(false);
    setExpanded(isExpanded ? panel : false);
};

  const onBack = () =>{
    navigate(-1)
  }

  const onSave = () =>{
    navigate('/message/xmlValidateReport')
  }

  const onDropdownValue = (e) => {
    setTemplate(e);
  }
// useEffect(() => {
//   setExpanded(true)
// }, []);

  const optionDatas = [{
    id:'1',
    label:'SWIFT,CBRR+ 2020,PACS.005 Customer Credit Transfer'
   },{
     id:'2',
     label:'SWIFT,CBRR+ 2021,PACS.006 Customer Credit Transfer'
   },{
     id:'3',
     label:'SWIFT,CBRR+ 2022,PACS.007 Customer Credit Transfer'
   },{
     id:'4',
     label:'SWIFT,CBRR+ 2023,PACS.008 Customer Credit Transfer'
   }]

  return (
    <Box sx={styles.container}>
      <TestCaseHeader
                  title={'Create Incoming PACS.009.STP'}
                  subtitle={'Some details related to XML Message List goes here'}
            >
        </TestCaseHeader>
        {
            templateData?.map((value,index)=>(
              <>
              <Box sx={styles.accordionBox}>
          <Accordion id={value.id} key={value.id} expanded={expanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={styles.accordionSummary}
            
          >
            <Typography sx={styles.title}>Basic Routing Data</Typography>
          </AccordionSummary>
          <AccordionDetails>
          {
             optionDatas?.map((value,index)=>{
              return (
                <>
                  <Box sx={{ flexGrow: 1 }} mb={2}  key={index}>
                      <Grid  container alignItems="center" spacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={6} md={4}>
                          <Typography sx={styles.label}>
                            Business Application Header (BAH)
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={8} >
                        <Dropdown
                              id="select-search-by-type"
                              label=""
                              value={template}
                              onChange={onDropdownValue}
                              options={optionDatas}
                              placeholder="Select the value"
                              inputProps={{ 'aria-label': 'Without label' }}
                              sx={{
                                pl: 0,
                                fontSize:17,
                                borderRight: '1px solid #ACACAC',
                                backgroundColor: 'transparent',
                                '& .MuiInputBase-input': { p: 1 },
                                borderWidth: '1px'
                              }}
                          />
                        </Grid>
                      </Grid>
                  </Box>
                </>
               )
             }
             )
          }
          
        </AccordionDetails>
        </Accordion>
        </Box>
              </>
            ))
        }
        

        <Box sx={styles.accordionBox}>
          <MUIButton 
            startIcon={<LeftCircleFilled style={{ fontSize: 18}}/>}
            title="Back"
            sx={{backgroundColor:'#F0F2F5',color:'#808080',marginLeft:"10px",marginRight:"10px"}}
            color="inherit"
            onClick={onBack}
          >
          </MUIButton>
          <MUIButton
           startIcon={<CheckCircleIcon style={{ fontSize: 18,}}/>}
           title="Save"
            sx={{margin:"0px 10px"}}
            onClick={onSave}
          />
      </Box>
    </Box>
  )
}

export default CreateIncomingTemplates;
