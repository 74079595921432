import React, { useContext } from 'react';
import Snackbar from '@mui/material/Snackbar';

import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';

import { ALERT_SEVERITY } from '../constants/constants';
import { SnackbarContext } from '../context/SnackbarContext.Context';

function SnackbarMui() {
    const snackbarCtx = useContext(SnackbarContext);
    const { msg = '', isDisplayed, severity = ALERT_SEVERITY.SUCCESS, onClose } = snackbarCtx;

    const handleClose = () => {
        onClose();
    };

    return (
        <Snackbar
            open={isDisplayed}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            action={
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            }
        >
            <Alert variant="filled" onClose={handleClose} severity={severity}>
                {msg}
            </Alert>
        </Snackbar>
    );
}

export default SnackbarMui;
