import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

const PayaptAccordion = (props) => {
    const { expandIcon, title, details, defaultExpanded, showExpandIcon=true, expanded, accordionSx, titleSx, detailsSx, onClick } = props;
    return (
        <Accordion 
        sx = {accordionSx} defaultExpanded = {defaultExpanded} 
        onChange={onClick}
        aria-controls="panel1a-content"
          id="panel1a-header"
        >
            <AccordionSummary
            expandIcon={showExpandIcon ? (expandIcon ? expandIcon : <ExpandMoreIcon />) : null}
            sx = {titleSx}
            >
          {title}
        </AccordionSummary>
        <AccordionDetails sx = {detailsSx}>
          {details}
        </AccordionDetails>
      </Accordion>
    )
}

export default PayaptAccordion;
