import React, { useEffect } from 'react';
import { useTestDataReviewMutation } from '../../redux/dashboard/dashboard.api';
import { Box, Typography } from '@mui/material';
import ProgressLine from './progressLine';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const TestDataResponseMsg = (props) => {
    const { customer } = props;
    const [testData, { data }] = useTestDataReviewMutation();
    const saData = data?.data;
    //  const mfData = data?.messageFlow;
    // console.log("saData", data);
    useEffect(() => {
        testData({ customerId: customer });
    }, [customer])
    return (
        <Box>
            <Box display={'flex'}
                sx={{ justifyContent: 'space-between', alignItems: 'center' }}
            >
                <Typography className="test-data-review-dashboard" sx={{ textAlign: 'left', fontWeight: '600' }}>Test Data Response Message</Typography>
                {/* <ChevronRightIcon sx={{ marginRight: '15px' }} /> */}
            </Box>
            {/* <Typography className="test-data-review-dashboard" sx={{textAlign:'left',fontWeight:'600'}}>Test Data Review</Typography> */}
            <Box sx={{ flexDirection: 'column', paddingBottom: '15px' }}>
                <ProgressLine data={saData} />
            </Box>

            {/* <Box>
          <TestDataReviewMF data={mfData}/>
          </Box> */}
        </Box>
    )
}

export default TestDataResponseMsg
