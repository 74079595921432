import { Box, Typography } from "@mui/material";
import React from "react";
import './dashBoard.css'
import ProgressLine from "./progressLine";

export const DATA =
    {
        header : 'Messages Flows',
        directionIn : 'Inbound',
        directionOut : 'Outbound',
        total : 1700 ,
        received : 500,
        inbound : 80,
        outbound : 60
    }


const TestDataReviewMF =(props)=>{
    const {  data} = props;
    return(
        <Box>
            <Box sx={{flexDirection:'column',paddingBottom:'15px'}}>
          <ProgressLine data={data}/>
          </Box>
        </Box>
    )
}
export default TestDataReviewMF