import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { DownloadOutlined, DeleteOutlined } from '@ant-design/icons';
import '../styles/onboardingStyles.css'

import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import UploadIcon from '@mui/icons-material/Upload';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography, Checkbox, } from "@mui/material";
import { App, ConfigProvider, Form, Input, InputNumber, Modal, Table, message } from 'antd';
import AntModal from '../../shared/components/Modal/AntModal';

import { LeftCircleFilled } from '@ant-design/icons';
import CancelIcon from '@mui/icons-material/Cancel';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import SearchIcon from '../../assets/images/onboard-search.png';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '../../assets/images/onboard-delete.png';
import { simulatorTestData } from '../../constants/constants';
import { TextField } from "@mui/material";

import { updateCustomerOnboarding } from '../../redux/common/common.slice';
import { updateCurrentStep } from '../../redux/customerOnBoarding/customerOnBoarding.slice';
import { colors } from '../../shared/colors';
import { CustomIconButton } from '../../shared/components/CustomIconButton';
import DragDropFile from '../../shared/components/DragDropFile';
import MUIButton from '../../shared/components/MUIButton';
import PayaptTextField from '../../shared/components/TextField';
import { ALERT_SEVERITY, APP_ROUTE_KEYS, greyBorderColor, primaryColor, redColor } from '../../shared/constants/constants';
import TestCaseHeader from '../testCaseHeader';
import AddDataResults from './UploadandAdd testData/AddDataResults';
import { padding } from '@mui/system';
import PayaptTable from '../../shared/components/Table';
import { useApproveCustomerDataMutation, useDownloadSimulatorTestDataDetailsMutation, useGetSimulatorTestDataDetailsMutation, useUpdateSimulatorTestDataMutation, useUploadSimulatorTestDataDetailsMutation, useCloneCustomerSimTestDataDetailMutation } from '../../redux/settings/settings.api';
import _ from 'lodash';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { generateCurrentEpochTimeValue } from '../../shared/utils';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
import { SimulatorData, UpdateSimulator } from '../../shared/constants/AlertMessages';
import { Button, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

const styles = {
    uploadButtonContainer: {
        textTransform: 'none',
        fontSize: "16px",
        fontFamily: "Inter",
        fontWeight: 600,
        backgroundColor: 'transparent',
        color: '#009966',
        borderColor: "#009966",
        border: ' 1px solid #009966',
        height: 40
    },
    draggerContainer: {
        minHeight: '35vh',
        maxHeight: '50vh',
        minWidth: "60%",
        borderRadius: '20px'
    },
    cloneContainer: {
        minHeight: '35vh',
        maxHeight: '50vh',
        minWidth: "77%",
        borderRadius: '20px'
    },
    floatingActionFlexFiveContainer: {
        flex: 0.5, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'
    }
}


const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    onChange,
    ...restProps
}) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
    return (
        // <td {...restProps}>
        //     {editing ? <PayaptTextField type={inputType} size='small' value={record[dataIndex]} onChange={(e) => console.log(index,dataIndex, e?.target?.value)}/> : (
        //         children
        //     )}
        // </td>
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input!`,
                        },
                    ]}
                >
                    <PayaptTextField type={inputType} size='small' value={record[dataIndex]} onChange={(e) => onChange(index, dataIndex, e?.target?.value)} />
                    {/* {inputNode} */}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const SimulatorTestData = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { userRole } = useSelector((state) => state?.common?.userDetails);
    const { currentStep } = useSelector((state) => state.customerOnBoarding);
    const customerId = useSelector((state) => state?.common?.customerId);
    const [isChecked, setIsChecked] = useState(true);
    const [isModalVisible2, setIsModalVisible2] = useState(false);
    const [data, setData] = React.useState(simulatorTestData);
    const [form] = Form.useForm();
    const [columnName, setColumnName] = useState();
    const [isFilterModalVisible, setFilterModalVisible] = useState(false);
    const [isReplace, setIsReplace] = useState(true);
    const [editingKey, setEditingKey] = React.useState('');
    const [find, setFind] = useState();
    const [replace, setReplace] = useState();
    const [searchTerm, setSearchTerm] = useState(null);
    const [pageNumber, setPageNumber] = useState(0);
    const [creditorAgentRTN, setCreditorAgentRTN] = useState()
    const [accId, setAccId] = useState()

    const [creditorAgentRTNClone, setCreditorAgentRTNClone] = useState("")
    const [accIdClone, setAccIdClone] = useState("")
    const [statusDesiredClone, setStatusDesiredClone] = useState("")
    const [actionFromSimulatorClone, setActionFromSimulatorClone] = useState("")
    const [customerSimTestDataDetailIdClone, setCustomerSimTestDataDetailIdClone] = useState()
    const [messageFlow , setMessageFlow ] = useState(location?.state?.messageFlow)
 

    const formData = new FormData();
    // const [pageSize,setPageSize] = useState(10);
    const pageSize = useSelector((state) => state?.common?.pageSize);
    const isEditing = (record) => record.customerSimTestDataDetailId === editingKey;
    const [simulatorTestDataDetails, { data: simulatorData }] = useGetSimulatorTestDataDetailsMutation();
    const [updateSimulatorTestData, { data: UpdateSimulatorData }] = useUpdateSimulatorTestDataMutation()
    const [approveCustomerData, { data: approveData }] = useApproveCustomerDataMutation();
    const [UploadSimulatorTestDataDetails, { data: uploadData }] = useUploadSimulatorTestDataDetailsMutation()
    const { customerSimTestDataId } = useSelector((state) => state?.common);
    const [downloadSimulatorTestData] = useDownloadSimulatorTestDataDetailsMutation();
    const [cloneCustomerSimTestDataDetail] = useCloneCustomerSimTestDataDetailMutation();
    const [isCloneModalVisible, setIsCloneModalVisible] = useState(false);
    const [errorTextCreditorAgent, setErrorTextCreditorAgent] = useState('');
    const [errorTextAccountId, setErrorTextAccountId] = useState('');


    const Snackbar = useContext(SnackbarContext);

    var initialPageOptions = {
        page: pageNumber,
        size: pageSize,
        search: searchTerm,
        customerSimTestDataId: customerSimTestDataId

    }
    var payload = {
        customerSimTestDataId: customerSimTestDataId
    }



    var updateSimDataPayload = {
        data: [
            {
                customerSimDetailsId: editingKey,
                routingNumber: creditorAgentRTN,
                accountId: accId
            }
        ],
        customerId: customerId
    }


    useEffect(() => {
        setPageOptions(initialPageOptions)
        simulatorTestDataDetails(initialPageOptions)
    }, [searchTerm])

    const [pageOptions, setPageOptions] = useState(initialPageOptions);

    const updatePageAndGetData = (options) => {

        const xPayload = {
            ...pageOptions,
            ...options
        }
        // console.log("cPage", xPayload);

        simulatorTestDataDetails(xPayload)
    }
    const edit = (record) => {
        setAccId(record?.accountId)
        setCreditorAgentRTN(record?.creditorAgentRTN)
        form.setFieldsValue({
            creditorAgentRTN: '',
            accountId: '',
            statusDesired: '',
            actionFromSimulator: '',
            ...record,
        });
        setEditingKey(record?.customerSimTestDataDetailId);
    };
    const cancel = () => {
        setEditingKey('');

    };

    const cloneRecord = (record) => {

        setIsCloneModalVisible(true)


        setCreditorAgentRTNClone(record?.creditorAgentRTN)
        setAccIdClone(record?.accountId)
        setStatusDesiredClone(record?.statusDesired)
        setActionFromSimulatorClone(record?.actionFromSimulator)
        setCustomerSimTestDataDetailIdClone(record?.customerSimTestDataDetailId)

    }


    const handleFinalReview = async() => {
        
        try {
           const row = await from.validateFields();
            // console.log("ROW",row);
            await approveCustomerData(payload).then(res => {
                if (res?.error?.data === "Customer Test Data Approved Successfully") {
                    // message.success('Customer Test Data Approved Successfully')
                    Snackbar.displayMsg(SimulatorData.ADD_SIMDATA_SUCCESS, ALERT_SEVERITY.SUCCESS);
                }
                else {
                    Snackbar.displayMsg(SimulatorData.ADD_SIMDATA_FAIL, ALERT_SEVERITY.ERROR);
                }
            })
        } catch (errInfo) {
            console.log("Validate Failed",errInfo);
        }
         
        // dispatch(updateCustomerOnboarding(true));
        // dispatch(updateCurrentStep(1));
        // onboardprocessdone
        // navigate("/", {
        //     replace: true,
        // });
    }
    const handleOnBack = () => {
        // dispatch(updateCurrentStep(currentStep - 1));
        userRole?.name == 'Super Admin' ?
        navigate(`${APP_ROUTE_KEYS.CUSTOMERS.STIMULATOR_TEST_DATA_NEW_TABLE_SETTINGS}`)
        :
        navigate(`${APP_ROUTE_KEYS.SETTINGS.STIMULATOR_TEST_DATA_NEW_TABLE}`);
    }

    const onInputChange = (index, key, val) => {
        if (key === 'accountId') {
            setAccId(val)
        }
        else {
            setCreditorAgentRTN(val)
        }
        const updatedData = [...data];
        updatedData[index] = {
            ...updatedData[index],
            [key]: val
        }
        setData(updatedData)
    }

    const save = async (key) => {

        
        try {
            const row = await form.validateFields();

            // const newData = [...simulatorData];
            // const index = newData.findIndex((item) => key === item.customerSimTestDataDetailId);
            // if (index > -1) {
            //     const item = newData[index];
            //     newData.splice(index, 1, {
            //         ...item,
            //         ...row,
            //     });
            //     setData(newData);
            //     setEditingKey('');
            // } else {
            //     newData.push(row);
            //     setData(newData);
            //     setEditingKey('');
            // }
            await updateSimulatorTestData(updateSimDataPayload).then(res => {
            // if(res?.error?.data === 'updated Customer sim test data details'){
            // message.success(res?.error?.data)
            Snackbar.displayMsg(UpdateSimulator.ADD_UPDATE_SUCCESS, ALERT_SEVERITY.SUCCESS);
            // }
            setEditingKey('');
            simulatorTestDataDetails(initialPageOptions)
        })
        } catch (errInfo) {
            console.log('Validate Failed:', { errInfo });
        }
        


    };
    const columns = [
        {
            title: 'Creditor Agent RTN',
            dataIndex: 'creditorAgentRTN',
            key: 'creditorAgentRTN',
            width: '20%',
            editable: true
        },
        {
            title: 'Account Id',
            dataIndex: 'accountId',
            key: 'accountId',
            width: '20%',
            editable: true,
        },
        {
            title: 'Status Desired',
            dataIndex: 'statusDesired',
            key: 'statusDesired',
            width: '30%',
            // editable: true
        },
        {
            title: 'Action from Simulator',
            dataIndex: 'actionFromSimulator',
            key: 'actionFromSimulator',
            width: '20%',
            // editable: true
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: '10%',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <Grid container flexDirection={"row"} alignItems={'flex-end'}>
                        <Grid item xs={6}>
                            <CustomIconButton size="small" color={primaryColor} onClick={() => save(record.customerSimTestDataDetailId)}>
                                <CheckIcon fontSize='1rem' />
                            </CustomIconButton>
                        </Grid>
                        <Grid item xs={6}>
                            <CustomIconButton size="small" color={redColor} onClick={cancel}>
                                <CloseIcon fontSize='1rem' />
                            </CustomIconButton>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container flexDirection={"row"} alignItems={'flex-end'}>
                        <Grid item xs={6}>
                            <CustomIconButton size="small" color={primaryColor} onClick={() => edit(record)}>
                                <CreateIcon fontSize='1rem' />
                            </CustomIconButton>
                        </Grid>
                        <Grid item xs={6}>
                            <CustomIconButton size="small" color={primaryColor} onClick={() => cloneRecord(record)}>
                                <ContentCopyIcon fontSize='1rem' />
                            </CustomIconButton>
                        </Grid>
                    </Grid>
                );
            },
        },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'accountId' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                onChange: onInputChange
            }),
        };
    });
    //
    const navigate = useNavigate()
    const [isDragdropModalVisible, setIsDragdropModalVisible] = React.useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const onFinish = () => {
        navigate('/onboardprocessdone')
    }
    const handleSearchOnChange = (xValue) => {
        setSearchTerm(xValue);
    };
    const handleOnChangeDragdrop = (xFilesInfo) => {
        const { fileList, file } = xFilesInfo;
        formData.append('file', file);
        // console.log('xFilesInfo', xFilesInfo);
    }
    const handleSaveFilter = () => {
        setFilterModalVisible(false)
        // console.log(`handleSaveFilter ~ [filters]: `, { filters });
        // closeModal();
        // getData({ pageNo: 0, pageSize: 10,  filters: {
        //   testType: filters.testType.messageFlow ? 'MF': 'SA',
        //   result: ""
        // filters.testType.success ? 'success':  filters.testType.fail ? 'fail': 'inprogress'
        // }});
    }
    const handleCancelFilter = () => {
        setFilterModalVisible(false)
    }
    const handleUploadClick = () => {
        setIsDragdropModalVisible(true);
    }
    const handleAddTestDataClick = () => {
        setIsModalVisible2(true);
    }
    const handleAgree = () => {
        setIsChecked(!isChecked);
    }

    const handleDownloadClick = async () => {
        const token = await localStorage.getItem('jwttoken');
        await fetch(`${window.REACT_APP_BASE_URL}Payapt/api/v1/Simulator/downloadSimulatorTestDataDetails?customerSimTestDataId=${payload?.customerSimTestDataId}`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download    
                const url = window.URL.createObjectURL(new Blob([blob]))
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${generateCurrentEpochTimeValue()}.xlsx`);
                // Append to html link element page    
                document.body.appendChild(link); // Start download    
                link.click();// Clean up and remove the link    
                link.parentNode.removeChild(link);
            })
    }






    const token = localStorage.getItem('jwttoken');


    const [selectedFile, setSelectedFile] = useState(null);
    const [fileList, setFileList] = useState([]);


    const handleFileChange = (file) => {
        setSelectedFile(file);
        setFileList([
            {
                uid: file.uid,
                name: file.name,
                status: 'done',
                url: URL.createObjectURL(file),
            },
        ]);
    };

    const handleUpload = () => {
        if (selectedFile) {
            //   const formData = new FormData();
            formData.append('file', selectedFile);

            // Make the API request with the form data
            fetch(`${window.REACT_APP_BASE_URL}Payapt/api/v1/Simulator/UploadSimulatorTestDataDetails`, {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(response => {
                    if (response.ok) {
                        // message.success(`${selectedFile.name} file uploaded successfully.`);
                        Snackbar.displayMsg(`${selectedFile.name} file uploaded successfully.`, ALERT_SEVERITY.SUCCESS);

                    } else {
                        // message.error(`${selectedFile.name} file upload failed.`);

                        Snackbar.displayMsg(`${selectedFile.name} file upload failed.`, ALERT_SEVERITY.ERROR);

                    }
                })
                .catch(error => {
                    // Handle any network errors
                    console.error('Upload error:', error);
                    Snackbar.displayMsg(`${selectedFile.name} file upload failed.`, ALERT_SEVERITY.ERROR);
                });
        }
    };
    const handleDelete = () => {
        setSelectedFile(null);
        setFileList([]);
    };
    const handleCancel = () => {
        setIsDragdropModalVisible(false);
        setSelectedFile(null);
        setFileList([]);
    };


    const handleCreditorAgentRTNChange = (event) => {
        const value = event.target.value;
        setCreditorAgentRTNClone(value);
        if (value.trim() === '') {
            setErrorTextCreditorAgent('Creditor Agent RTN is Required.');
        } else {
            setErrorTextCreditorAgent('');
        }
    };

    const handleAccountIdChange = (event) => {
        const value = event.target.value;
        setAccIdClone(value)
        if (value.trim() === '') {
            setErrorTextAccountId('Account ID is Required.');
        } else {
            setErrorTextAccountId('');
        }
    };

    const handleCloneSave = async () => {

        if (creditorAgentRTNClone.trim() != '' && accIdClone.trim() != '') {
            console.log("change crtn", creditorAgentRTNClone, "change acciD", accIdClone);
            var clonePayload = {

                customerSimTestDataDetailId: customerSimTestDataDetailIdClone,
                creditorRoutingNumber: creditorAgentRTNClone,
                accountId: accIdClone

            }
            console.log("clone payload", clonePayload);
            await cloneCustomerSimTestDataDetail(clonePayload).then(res => {
                if (res?.data?.status === "success") {
                    Snackbar.displayMsg(res?.data?.message, ALERT_SEVERITY.SUCCESS);
                    simulatorTestDataDetails(initialPageOptions)
                    setIsCloneModalVisible(false)
                }
                else {
                    Snackbar.displayMsg(res?.data?.message, ALERT_SEVERITY.ERROR);
                }
            })
        }
        else {
        }
    }

    const handleCloneCancel = () => {
        setIsCloneModalVisible(false)
        setErrorTextCreditorAgent('');
        setErrorTextAccountId('');
    }

    var subtitle = "The following data will be used by Receiver agent to respond to a request sent by the Sender agent. Please review the data,make modifications if required, and upload."
    return (
        <>
            {/* <div className='onboarding-step-container' > */}
            {/* <div className='onboarding-step-items-container'> */}
            <Box sx={{ overflowY: 'auto', height: `calc(100vh - 100px)`, paddingBottom: "30px" }} >
                <TestCaseHeader
                    title={`Test Data for Response Messages - ${messageFlow}`}
                    subtitle={subtitle}
                    showSimTestSearch={{
                        show: true,
                        value: searchTerm,
                        onChange: handleSearchOnChange
                    }}
                    showFileUpload={{
                        show: true,
                        onClick: handleUploadClick,
                        customIcon: <UploadIcon />
                    }}
                    // showFileAdded={{
                    //     show: true,
                    //     onClick: handleAddTestDataClick,
                    //     customIcon: <PlaylistAddIcon />
                    // }}
                    showFileDownload={{
                        show: true,
                        onClick: handleDownloadClick,
                        customIcon: <FileDownloadIcon />
                    }}
                />
                <Box width={"100%"} px={"0%"}>
                    {/* <div className='onboarding-step-items'> */}
                    <Box sx={{ position: 'relative' }}>
                        <Form form={form} component={false}>
                            <ConfigProvider
                                theme={{
                                    token: {
                                        colorPrimary: colors.primary.main,
                                    },
                                }}
                            >
                                {/* <Table */}
                                <PayaptTable
                                    columns={mergedColumns}
                                    rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
                                    data={simulatorData?.list}
                                    bordered={false}
                                    className="standalone_table"
                                    rowSelection={rowSelection}
                                    rowKey={(rec) => rec?.customerSimTestDataDetailId}
                                    // style={{ width: '1100px', maxHeight: '52vh', overflowY: 'auto' }}
                                    components={{
                                        body: {
                                            cell: EditableCell,
                                        },
                                    }}
                                    onChange={(pagination) =>
                                        updatePageAndGetData({
                                            page: pagination.current - 1,
                                        })
                                    }
                                    pagination={{
                                        pageSize,
                                        current: !_.isEmpty(simulatorData) ? simulatorData.page + 1 : 1,
                                        total: !_.isEmpty(simulatorData) ? simulatorData.count : 0,
                                        showPrevNextJumpers: true,
                                        showTitle: true,
                                        hideOnSinglePage: true,
                                        // sisze: "small",

                                        // onChange: cancel,
                                    }}
                                />
                            </ConfigProvider>
                        </Form>
                        {selectedRowKeys?.length > 0 && <Box sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            bottom: 0,
                            backgroundColor: 'transparent',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Box sx={{
                                height: 100,
                                width: 50,
                                backgroundColor: '#FFF',
                                borderRadius: 2,
                                border: '3px solid #FAFAFA',
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                <Box sx={styles.floatingActionFlexFiveContainer}>
                                    <Box
                                        onClick={() => {
                                            setFilterModalVisible(true)
                                            setIsReplace(false)
                                        }}
                                        component="img"
                                        sx={{
                                            height: 20,
                                            width: 20
                                        }}
                                        alt="The house from the offer."
                                        src={DeleteIcon}
                                    />
                                </Box>
                                <Box sx={styles.floatingActionFlexFiveContainer}>
                                    <Box
                                        onClick={() => {
                                            setFilterModalVisible(true)
                                            setIsReplace(true)
                                        }}
                                        component="img"
                                        sx={{
                                            height: 20,
                                            width: 20
                                        }}
                                        alt="The house from the offer."
                                        src={SearchIcon}
                                    />
                                </Box>
                            </Box>
                        </Box>}
                    </Box>
                    {/* </div> */}
                    <Box m={1} px={"2%"} sx={{ display: 'flex', justifyContent: 'flex-start', }} mt={3} >
                        <MUIButton
                            title="Back"
                            startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
                            sx={{ backgroundColor: '#F0F2F5', color: '#808080' }}
                            color="inherit"
                            onClick={handleOnBack}
                        />
                        <MUIButton
                            startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
                            title="Approve"
                            sx={{ marginLeft: "20px" }}
                            onClick={handleFinalReview}
                        />
                    </Box>
                </Box>
            </Box>
            {/* </div> */}
            {/* </div> */}
            <Modal
                onCancel={handleCancel}
                open={isDragdropModalVisible}
                footer={[]}
                centered
                closeIcon={<CancelOutlined sx={{ color: "#001A11", backgroundColor: "white" }} />}
                style={styles.draggerContainer}>

                <Box>
                    <TestCaseHeader title="Upload Test Data For Simulator For Desired Results"
                        subtitle="About uploading test data instruction goes here"
                        showInfoIcon={false}>

                    </TestCaseHeader>

                    <Box m={3}>

                        <Box mb={3}>
                            <Dragger style={{ padding: '30px', cursor: 'pointer' }}
                                name="file"
                                multiple={false}
                                beforeUpload={file => {
                                    // handleFileChange(file);
                                    // return false;
                                    const allowedTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'];
                                    const fileType = file.type;

                                    if (allowedTypes.includes(fileType)) {
                                        handleFileChange(file);
                                    } else {
                                        // Display an error message or perform any desired action for unsupported file types.
                                        console.log('Unsupported file type ');
                                        Snackbar.displayMsg(`Unsupported File Type. Upload with .csv,.xls,.xlsx`, ALERT_SEVERITY.ERROR);
                                    }

                                    return false;
                                }}
                                showUploadList={{

                                    showRemoveIcon: true,
                                    removeIcon: <DeleteOutlined onClick={handleDelete} />,

                                }}
                                maxCount={1}
                                fileList={fileList}

                            >
                                <CloudUploadIcon style={{ fontSize: 38, color: '#808080' }} />
                                <p>Drag & Drop your file here</p>
                            </Dragger>



                        </Box>
                        <Box>
                            <MUIButton
                                startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
                                title="Cancel"
                                sx={{ backgroundColor: '#F0F2F5', color: '#808080', marginLeft: "10px", marginRight: "10px" }}
                                color="inherit"
                                onClick={() => handleCancel()}
                            >
                            </MUIButton>
                            <MUIButton
                                startIcon={<CheckCircleIcon style={{ fontSize: 18, }} />}
                                title="Upload File"
                                sx={{ margin: "0px 10px" }}
                                disabled={!selectedFile}
                                onClick={handleUpload}
                            />
                        </Box>
                    </Box>

                </Box>


            </Modal>
            <Modal
                onCancel={() => setIsModalVisible2(false)}
                open={isModalVisible2}
                footer={[]}
                width='762px'
                closeIcon={<CancelOutlined sx={{ color: "#001A11", backgroundColor: "white" }} />}
                style={{ height: '710px', borderRadius: '20px', }}
            >
                <AddDataResults />
            </Modal>
            <AntModal
                closable={false}
                title="Find and Replace"
                open={isFilterModalVisible}

                width={'350px'}
                style={{ top: '30%', left: '29%' }}
                mask={true}
                maskClosable={false}
                okText={isReplace === true ? "Replace" : 'Yes'}
                cancelText={isReplace === true ? "Cancel" : 'No'}
                onOk={handleSaveFilter}
                okButtonProps={{
                    style: {
                        display: 'inline-flex',
                        backgroundColor: primaryColor,
                        marginTop: '10px',
                        marginBottom: '20px',
                        marginRight: '105px',
                    },
                    disabled: isChecked,
                    icon: <CheckCircleIcon sx={{ fontSize: '18px', marginTop: '2px' }} />
                }}
                cancelButtonProps={{
                    style: {
                        display: 'inline-flex',
                        backgroundColor: '#F0F2F5',
                        color: greyBorderColor,
                        border: '1px solid transparent',
                        marginTop: '10px',
                        marginBottom: '20px',
                    },
                    icon: <CancelIcon sx={{ fontSize: '18px', marginTop: '2px' }} />
                }}
                onCancel={handleCancelFilter}
                closeIcon={<CancelOutlined sx={{ color: "#001A11", backgroundColor: "white" }} />}
            >
                {isReplace === true ? <>
                    <Typography>About uploading test data instruction goes here</Typography>
                    <Typography>Colomn Name</Typography>
                    <FormControl className='input-simulator-testData' sx={{ marginTop: '10px' }} fullWidth>
                        <InputLabel id="demo-simple-select-label"></InputLabel>
                        <Select
                            value={columnName}
                            placeholder="Colomn Name"
                            onChange={(e) => setColumnName(e.target.value)}
                        >
                            <MenuItem value='creditorAgentRTN'>Creditor Agent RTN</MenuItem>
                            <MenuItem value='accountId'>Account Id</MenuItem>
                        </Select>
                    </FormControl>
                    <Typography>Find</Typography>
                    <PayaptTextField value={find} className='input-simulator-testData' onChange={(e) => { setFind(e.target.value) }} placeholder='Enter Text To Find' margin="dense" fullWidth />
                    <Typography>Replace</Typography>
                    <PayaptTextField value={replace} className='input-simulator-testData' onChange={(e) => { setReplace(e.target.value) }} placeholder='Enter Text To Replace' margin="dense" fullWidth />
                    <Grid sx={{ display: 'flex', flexDirection: 'row', alignItems: 'left' }}>
                        <Checkbox sx={{ padding: 0, marginRight: 1 }} size='small' onClick={handleAgree} />
                        <Typography sx={{ fontSize: '15px' }}><i>I agree to replace</i></Typography>
                    </Grid>
                </> :
                    <>
                        <Typography>Are you sure to delete selected {selectedRowKeys.length} Test Data?</Typography>
                    </>
                }
            </AntModal>





            <Modal
                onCancel={handleCloneCancel}
                open={isCloneModalVisible}
                footer={[]}
                centered
                closeIcon={<CancelOutlined sx={{ color: "#001A11", backgroundColor: "white" }} />}
                style={styles.cloneContainer}>

                <Box>
                    <TestCaseHeader title="Clone The Record"
                        subtitle=""
                        showInfoIcon={false}>

                    </TestCaseHeader>

                    <Box m={3}>

                        <Box mb={3}>

                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={3}>
                                    <InputLabel sx={{ color: "black" }}>Creditor Agent RTN</InputLabel>

                                    <PayaptTextField fullWidth
                                        placeholder='Enter Creditor Agent RTN'
                                        margin="dense"
                                        value={creditorAgentRTNClone}
                                        // onChange={(event) => setCreditorAgentRTNClone(event.target.value)}
                                        onChange={handleCreditorAgentRTNChange}

                                        inputProps={{
                                            readOnly: false,
                                        }} />
                                    {errorTextCreditorAgent && <span style={{ color: 'red' }}>{errorTextCreditorAgent}</span>}

                                </Grid>
                                <Grid item xs={3}>
                                    <InputLabel sx={{ color: "black" }}>Account Id</InputLabel>

                                    <PayaptTextField fullWidth
                                        placeholder='Enter Account Id'
                                        margin="dense"
                                        value={accIdClone}
                                        // onChange={(event) => setAccIdClone(event.target.value)}
                                        onChange={handleAccountIdChange}
                                        inputProps={{
                                            readOnly: false,
                                        }} />
                                    {errorTextAccountId && <span style={{ color: 'red' }}>{errorTextAccountId}</span>}

                                </Grid>
                                <Grid item xs={3}>
                                    <InputLabel sx={{ color: "black" }} >Status Desired</InputLabel>

                                    <PayaptTextField fullWidth
                                        placeholder='Enter Status Desired'
                                        margin="dense"
                                        value={statusDesiredClone}
                                        inputProps={{
                                            readOnly: true,
                                        }} />
                                </Grid>
                                <Grid item xs={3}>
                                    <InputLabel sx={{ color: "black" }} >Action from Simulator</InputLabel>

                                    <PayaptTextField fullWidth
                                        placeholder='Enter Action from Simulator'
                                        margin="dense"
                                        value={actionFromSimulatorClone}
                                        inputProps={{
                                            readOnly: true,
                                        }} />
                                </Grid>
                            </Grid>


                        </Box>
                        <Box>
                            <MUIButton
                                startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
                                title="Cancel"
                                sx={{ backgroundColor: '#F0F2F5', color: '#808080', marginLeft: "10px", marginRight: "10px" }}
                                color="inherit"
                                onClick={() => handleCloneCancel()}
                            >
                            </MUIButton>
                            <MUIButton
                                startIcon={<CheckCircleIcon style={{ fontSize: 18, }} />}
                                title="Clone"
                                sx={{ margin: "0px 10px" }}

                                onClick={handleCloneSave}
                            />
                        </Box>
                    </Box>

                </Box>


            </Modal>


        </>
    )
}

SimulatorTestData.defaultProps = {
    primaryColor: colors.primary.main,
}

SimulatorTestData.propTypes = {
    primaryColor: PropTypes.string,
}

export default SimulatorTestData;


{/* <Box mb={2}>
<Grid container flexDirection="row" justifyContent="space-between">
    <Grid item xs={4}><SearchBar onChange={handleSearchOnChange} /></Grid>
    <Grid item xs={8} display="flex" justifyContent="flex-end">
        <Button variant="outlined" style={styles.uploadButtonContainer} onClick={() => setIsDragdropModalVisible(true)}>
            <CloudUploadIcon />
            &nbsp;&nbsp;Upload Test Data
        </Button>&nbsp;&nbsp;
        <Button
            onClick={() => setIsModalVisible2(true)}
            variant="outlined"
            style={{
                textTransform: 'none',
                color: '#009966',
                fontSize: "16px",
                fontFamily: "Inter",
                fontWeight: 600,
                backgroundColor: 'transparent',
                borderColor: "#009966",
                border: ' 1px solid #009966',
            }}><AddCircleIcon />
            &nbsp;&nbsp;Add New</Button>
    </Grid>
</Grid>
</Box> */}