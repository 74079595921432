import React from 'react';
import Tooltip from '@mui/material/Tooltip';

const PayaptTooltip = (props) => {
    const { children, title } = props;
    return (
        <Tooltip title={title}>
            { children}
        </Tooltip>
    )
}

export default PayaptTooltip;
