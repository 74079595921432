import { Button } from '@mui/material';
import React from 'react';
import Welcome from '../../assets/images/welcome.png'
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import { useNavigate } from 'react-router-dom';
import MUIButton from '../../shared/components/MUIButton';
const CompleteYourProfile = (props) => {
    const { isUserAuthenticated, onlogout } = props;
    const navigate = useNavigate();
const onComplete=()=>{
    navigate('/updateCompleteProfile')
}
    return(
        
            <div>
                <h1 style={{fontSize:'30px',}}>Welcome to PAYAPT</h1>
                <p>Thanks for signup, Complete your profile & get verified</p><br/>
                <img style={{resize:'inline',height:'300px',width:'400px'}} src={Welcome} className='onboarding-img'/><br/><br/><br/>
                <MUIButton
                 title="Complete your Profile"
                 endIcon={<ArrowCircleRightRoundedIcon style={{fontSize: 18}}/>}
                 onClick={onComplete}
                />
            </div>
            )
    }

export default CompleteYourProfile;