import { Box, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { audit_column, audit_tabledata, logData } from "./constant";
import PayaptTable from "../shared/components/Table";
import AuditlogHeader from "./AuditlogHeader";
import { useEffect } from "react";
import { useGetMessageIdQuery } from "../redux/testRuns/testRuns.api";
import PayaptLoader from "../shared/components/Spinner";

const AuditLog =(props)=>{

   const { messageType, testRunId } = props;
   const columns = audit_column;
   const [dataSource, setDataSource] = useState([]);
   const [applicationNames, setApplicationNames] = useState(null)
   const [loading, setLoading] = useState(false);
    // const data = {
    //   message: '20230424021150706FyuBLPABl06379842'
    // }
    // const isFetching =
   const { data, isFetching} = useGetMessageIdQuery({testRunId}, { skip: !testRunId})
   useEffect(() => {
    if(data?.message){
      getData();
    }
   }, [applicationNames, data?.message])
   const getData = async () => {
    setLoading(true);
    const response = await fetch(`${window.REACT_APP_AUDIT_LOG_URL}`,
      {
        method: 'POST',
        // credentials: 'include',
        headers: {
          'content-type': 'application/json',
          'Accept': 'application/json'
        },
        body: (applicationNames || data?.message) ? JSON.stringify({
          "query": {
            "query_string": {
              "query": applicationNames ? _.join([applicationNames, data?.message], ' AND ') : data?.message,
              "default_field": "message"
            }
            // "match": {
            //   "message": '(20230428021040078Cbnycesng84259298) AND (FIS)'
            //   applicationNames ? _.join([applicationNames, data?.message], ' AND ') : data?.message
            // }
          }
        }) : null
        // mode: 'no-cors'

      })
      const jsonData = await response.json();
      setLoading(false)
    // const jsonData = logData;
    const messageList = jsonData?.hits?.hits;
    getDataSource(messageList);
   }

   const getParsedData = (msg) =>{
      try {
        return JSON.parse(msg);
      } catch (error) {
        // console.log(error);
        return {}
      }
   }
   const getDataSource = (data) => {
    const loData = []
    _.forEach(data, d => {
      const source = d?._source;
      // console.log(source?.message)
      const message = source?.message ? getParsedData(source?.message) : {};

      const obj = {
        applicationName: message?.applicationName,
        timestamp: source['@timestamp'],
        messageId: data?.messageId ? `${data?.messageId} / ${messageType}` : messageType,
        messageType: messageType,
        level: message?.level,
        message: message?.message
      }
      loData.push(obj)
    })
    setDataSource(loData)
   };
    return(
        <Box sx = {{width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start'}} >
            <AuditlogHeader applicationNames = {applicationNames} setApplicationNames = {setApplicationNames}/>
            { (isFetching || loading) && <PayaptLoader /> }
            { !(isFetching || loading) &&<PayaptTable
              size='small'
              columns={columns}
              data={dataSource}
              className='audit-log-table'
              // pagination={{
              //   pageSize: 5
              // }}
            //   rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
            //   rowKey={(rec) => rec?.testrunId}
            //   onChange={(pagination, filters, sorter) => updatePageAndGetData({
            //     pageNo: pagination.current - 1,
            //     sortBy: sorter ? getSortColumn(sorter.field) : null,
            //     sortDir: getSortDirection(sorter.order),
            //     search: pageOptions.search,
            //   })}
              // scroll={{ x: '80vw', y: '50vh' }} 
            //   pagination={{
            //     pageSize,
            //     current: !_.isEmpty(data) ? data.pageNo + 1 : 1,
            //     total: !_.isEmpty(data) ? data.totalElements : 0,
            //     hideOnSinglePage: true,
            //     size: 'small'
            //   }}
            //   rowSelection={{
            //     ...rowSelection,
            //     selectedRowKeys: testRunsToKill?.testRunIds || [],
            //     preserveSelectedRowKeys: true,
            //   }}
            />
          }
        </Box>
    );
}
export default AuditLog