import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// initialize an empty api service that we'll inject endpoints into later as needed
const dynamicBaseQuery = async (args, WebApi, extraOptions) => {
  const rawBaseQuery = fetchBaseQuery({
    baseUrl: `${window.REACT_APP_BASE_URL}Payapt/api/v1`,
    prepareHeaders: async (headers, { getState }) => {
      headers.set('Content-Type', "application/json");
      const token = await localStorage.getItem('jwttoken');
      if (token && token?.length > 0) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  });
  return rawBaseQuery(args, WebApi, extraOptions);
};

const baseQueryWithReauth = async (args, api, extraOptions) => {
  const result = await dynamicBaseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // try to get a new token
  } else if (result.error && result.error.status === 400) {
    // try to get a new token
    // global.toast.show(result.error.data?.message || 'Something went wrong.', { type: 'danger' });
    // show toast here
  }
  return result;
};

export const backendApi = createApi({
  reducerPath: 'backend',
  overrideExisting: true,
  baseQuery: baseQueryWithReauth,
  // refetchOnMountOrArgChange: 30,
  tagTypes: [],
  endpoints: () => ({}),
});
