import { createContext, useEffect, useState } from "react";
import { useFetchCustomerNameListMutation } from "../../redux/users/users.api";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useGetPaymentByCustomerQuery } from "../../redux/customers/customer.api";

export const DropdownValueContext = createContext();

export const DropdownProvider = ({ children }) => {
  const [fetchCustomerNameList, { data: customersList, isSuccess }] = useFetchCustomerNameListMutation();
  const { userRole } = useSelector((state) => state?.common?.userDetails);
  const { customerId, isUserAuthenticated, isBack } = useSelector((state) => state?.common);


  const [value, setValue] = useState(null); // Initialize as null to avoid undefined value
  const [customer, setCustomer] = useState(null); // Same for customer
  const [clickedCard, setClickedCard] = useState(false);
  const [dropValue, setDropValue] = useState("");
  // const location = useLocation(); 

  const { data: paymentOptions, isLoading, isError } = useGetPaymentByCustomerQuery({
    customerId: (userRole?.name === 'Admin' || userRole?.name === 'Tester') ? customerId : customer,
  }, { skip: !customer }); // Skip query until customer is defined
  const {data: paymentOptionsDrop} = useGetPaymentByCustomerQuery({customerId: customerId});
  
  useEffect(() => {
    fetchCustomerNameList(); // Fetch customer list on mount
  }, []); // Runs only once when component mounts
 
  useEffect(() => {
    if (isUserAuthenticated || isBack) {
      fetchCustomerNameList();
    }
  }, [isUserAuthenticated, isBack]);
  

  useEffect(() => {
    if (isSuccess && !_.isEmpty(customersList)) {
      setCustomer(customersList[0]?.customerId); // Automatically set first customer from the list
    }
  }, [customersList, isSuccess]);

  useEffect(() => {
    if (paymentOptions && paymentOptions.length > 0) {
      // Set the initial payment service value if payment options are available
      const initialValue = paymentOptions[0]?.paymentServiceId;
      setValue(initialValue);
      // setDropValue(initialValue)
    }
  }, [paymentOptions]);

  useEffect(() => {
    if (paymentOptionsDrop && paymentOptionsDrop.length > 0) {
      // Set the initial payment service value if payment options are available
      const initialValue = paymentOptionsDrop[0]?.paymentServiceId;
      // setValue(initialValue);
      setDropValue(initialValue)
    }
  }, [paymentOptionsDrop]);

  
  // Helper functions to manage state changes
  const changeValue = (v) => setValue(v);
  const changeDropValue = (v) => setDropValue(v);
  const changeCustomer = (v) => setCustomer(v);
  const updateClickedCard = (bool) => setClickedCard(bool);

  return (
    <DropdownValueContext.Provider
      value={{
        value,
        customer,
        changeValue,
        changeCustomer,
        customersList,
        clickedCard,
        updateClickedCard,
        changeDropValue,
        dropValue,
      }}
    >
      {children}
    </DropdownValueContext.Provider>
  );
};
