import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ProgressCircle from '../ProgressCircle';
import '../../Dashboard/dashBoard.css';
import { useGetDistinctTcCountFromBatchMutation } from '../../../redux/dashboard/dashboard.api';
import CircularProgress from '../../../shared/components/CircularProgress';
import moment from 'moment';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import TestCaseHeader from '../../testCaseHeader';
import { useSelector } from 'react-redux';


const NfrTestcases = (props) => {
  const { dateForDashboard, customer, messageType } = props;
  const { customerId } = useSelector((state) => state?.common);
  const { userRole } = useSelector((state) => state?.common?.userDetails);
  // const [getNFRDetails, { data }] = useGetNFRDetailsMutation(); getDistinctTcCountFromBatch
  const [getDistinctTcCountFromBatch, { data }] = useGetDistinctTcCountFromBatchMutation();
  const [batchName, setBatchName] = useState("");
  const safeDateForDashboard = dateForDashboard || [];

  var payload = {
    date: safeDateForDashboard.length > 0 ? moment(new Date(safeDateForDashboard[0])).format() : null,
    toDate: safeDateForDashboard.length > 1 ? moment(new Date(safeDateForDashboard[1])).format() : null,
    customerId: (userRole?.name === 'Admin' || userRole?.name === 'Tester') ? customerId : customer,
    messageTypeId: messageType ? messageType : null,
    batchId: batchName ? batchName : null
  };

  useEffect(() => {
    if(customer){
    getDistinctTcCountFromBatch(payload)
    }
  }, [dateForDashboard, customer, batchName]);

  
  const resultData = [{
    inbound: [
      {
        resultTitle: 'Executed',
        count: data?.inboundExecuted
      },
      {
        resultTitle: 'Passed',
        count: data?.inboundPassed
      },
      {
        resultTitle: 'Failed',
        count: data?.inboundFailed
      }
    ],
    outbound: [
      {
        resultTitle: 'Executed',
        count: data?.outboundExecuted
      },
      {
        resultTitle: 'Passed',
        count: data?.outboundPassed
      },
      {
        resultTitle: 'Failed',
        count: data?.outboundFailed
      }
    ]
  }
  ]
  const onBatchNameChange = (event) => {
    setBatchName(event);
    // console.log('onBatchNameChange', event);
  }

  return (
    <div style={{ width: "100%" }}>

      <Box sx={{ marginTop: '10px' }}
      //  sx={{borderBottom: '2px solid #009966',backgroundColor:'#F4F8F9',width:'330px',justifyContent:'center'}}
      >
        <Box display={'flex'}
          sx={{ justifyContent: 'space-between' }}
        >
          <Typography className='test-data-review' sx={{ fontWeight: 600, textAlign: 'left', paddingLeft: '15px' }}>Batch Summary</Typography>
          {/* <ChevronRightIcon sx={{ marginRight: '15px' }} /> */}
          <TestCaseHeader
            showBatchName={{ show: true, onChange: onBatchNameChange, value: batchName, customer: customer }}
          />
        </Box>

        {/* <Box sx={{ justifyContent: 'center', alignItems: 'center', padding: '10px' }}> */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between !important', alignItems: 'center', width: '100%' }}>
          <ProgressCircle
            iconType={<SouthIcon sx={{ color: "#0596FF", marginTop: '10px' }} />}
            circleIcon={
              <CircularProgress
                value={data?.inboundPercentage}
                size={45}
                strokeColor='#0596FF'
                strokeWidth={8}
                trailColor='#D7E2EA'
                trailWidth={6}
              />
            }
            sx={{ backgroundColor: "#EDF4FA", borderRadius: "5px" }}
            total={data?.inboundTestcases}
            directionTitle="Inbound"
            testCases
            title="Test Cases"
            // resultTitle1="Executed"
            // count1={xEl.standalone.inbound.executed}
            // resultTitle2="Passed"
            // count2={xEl.standalone.inbound.passed}
            // resultTitle3="Failed"
            // count3={xEl.standalone.inbound.failed}
            resultData={resultData[0]?.inbound}
          />


          {/* <br/> */}
          {/* <Box sx={{ marginTop: '10px' }}> */}
          <ProgressCircle
            iconType={<NorthIcon sx={{ color: "#0596FF", marginTop: '10px' }} />}
            circleIcon={
              <CircularProgress
                value={data?.outboundPercentage}
                size={45}
                strokeColor='#0596FF'
                strokeWidth={8}
                trailColor='#D7E2EA'
                trailWidth={6}
              />
            }
            sx={{ backgroundColor: "#EDF4FA", borderRadius: "5px" }}
            total={data?.outboundTestcases}
            directionTitle="Outbound"
            testCases
            title="Test Cases"
            // resultTitle1="Executed"
            // count1={xEl.standalone.inbound.executed}
            // resultTitle2="Passed"
            // count2={xEl.standalone.inbound.passed}
            // resultTitle3="Failed"
            // count3={xEl.standalone.inbound.failed}
            resultData={resultData[0]?.outbound}
          />
          {/* </Box> */}

        </Box>

      </Box>


    </div>
  )
}

export default NfrTestcases;