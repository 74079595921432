import React, { useContext } from 'react';
import { Grid, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PayaptTextField from '../../shared/components/TextField';
import { newRegistrationValidationSchema } from './utils';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import MUIButton from '../../shared/components/MUIButton';
import TestCaseHeader from '../testCaseHeader';
import PhoneInput from 'react-phone-input-2';
import { useCustomerLoginBaseUpdateMutation, useGetCustomerLoginBaseInfoMutation } from '../../redux/users/users.api';
import { UserMsg } from '../../shared/constants/AlertMessages';
import { ALERT_SEVERITY } from '../../shared/constants/constants';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
import { updateAuthCheckParams } from '../../redux/common/common.slice';

import { useSelector, useDispatch } from 'react-redux';

const UserProfile = () => {
    const resolver = yupResolver(newRegistrationValidationSchema);
    const Snackbar = useContext(SnackbarContext);
    const dispatch = useDispatch();
    const { userRole } = useSelector((state) => state?.common?.userDetails);
    const [headerDetails, setHeaderDetails] = React.useState({
        title: "",
        subTitle: ""
    });
    const [getCustomerLoginBaseInfo, { data: userInfo }] = useGetCustomerLoginBaseInfoMutation();
    const [customerLoginBaseUpdate] = useCustomerLoginBaseUpdateMutation();

    React.useEffect(() => {
        if (userRole?.email) {
            getCustomerLoginBaseInfo({ email: userRole?.email });
        }
    }, []);

    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver,
        defaultValues: {
            firstName: "",
            lastName: "",
            email: "",
            contactNumber: "",
        }
    });
    const defaultValues = {
        firstName: userInfo?.firstName,
        lastName: userInfo?.lastName,
        email: userInfo?.email,
        contactNumber: userInfo?.contactNumer
    }

    React.useEffect(() => {
        setHeaderDetails({
            title: "My Profile Info",
            subTitle: "You can change Profile here"
        })
        if (userInfo && userInfo?.customerId) {
            reset({
                ...defaultValues,
            })
            return;
        }

    }, [userInfo]);

    const handleValidate = (value) => {
        const isValid = isValidPhoneNumber(value);
        return isValid;
    }

    const handleEditNavigation = async (xRes, data) => {

        console.log("xRes", xRes);


        if (xRes?.data == null) {

            const xPayload = {
                isUserAuthenticated: true,
                userName: `${data?.firstName} ${data?.lastName}`,
                  
            }

         await dispatch(updateAuthCheckParams(xPayload));

            Snackbar.displayMsg(UserMsg.UPDATE_USER_SUCCESS, ALERT_SEVERITY.SUCCESS);
        } else {
            Snackbar.displayMsg(`${xRes?.error?.data?.message}`, ALERT_SEVERITY.ERROR);
        }
    }

    const onUpdateProfile = async (data) => {

        const updateResult = await customerLoginBaseUpdate(data);
        handleEditNavigation(updateResult, data);
    }

    return (
        <>
            <Box >
                <TestCaseHeader
                    title={headerDetails.title}
                    subtitle={headerDetails.subTitle}

                />
                <Grid spacing={3} container >
                    <Grid item xs={0} > </Grid>
                    <Grid item xs={6} sx={{ textAlign: 'left', marginTop: 3 }}>
                        <Box >
                            <Grid>
                                <span style={{ textAlign: 'left', lineHeight: 2 }}>First Name <font color='red'>*</font></span>
                                <br />
                                <Controller
                                    control={control}
                                    name={"firstName"}
                                    render={({ field }) => (
                                        <PayaptTextField
                                            {...register("firstName")}
                                            {...field}
                                            placeholder='Enter your First Name'
                                            id="firstName"
                                            className='signin-text-box'
                                            type='text'
                                            error={!!errors['firstName']}
                                            helperText={errors['firstName']?.message}
                                            inputProps={{ className: 'signin-input-box' }}
                                        />
                                    )}
                                />
                            </Grid>
                            <br />
                            <Grid>
                                <span style={{ textAlign: 'left', lineHeight: 2 }}>Last Name <font color='red'>*</font></span>
                                <br />
                                <Controller
                                    control={control}
                                    name={"lastName"}
                                    render={({ field }) => (
                                        <PayaptTextField
                                            {...register("lastName")}
                                            {...field}
                                            placeholder='Enter your Last Name'
                                            id="lastName"
                                            className='signin-text-box'
                                            type='text'
                                            error={!!errors['lastName']}
                                            helperText={errors['lastName']?.message}
                                            inputProps={{ className: 'signin-input-box' }}
                                        />
                                    )}
                                />
                            </Grid>
                            <br />
                            <Grid>
                                <span style={{ textAlign: 'left', lineHeight: 2 }}>Email Address <font color='red'>*</font></span>
                                <br />
                                <Controller
                                    control={control}
                                    name={"email"}
                                    render={({ field }) => (
                                        <PayaptTextField
                                            {...register("email")}
                                            {...field}
                                            placeholder='Enter your Email Address'
                                            id="email"
                                            className='signin-text-box'
                                            type='text'
                                            // error={!!errors['email']}
                                            // helperText={errors['email']?.message}
                                            inputProps={{ className: 'signin-input-box', readOnly: true }}
                                        />
                                    )}
                                />
                            </Grid>
                            <br />
                            <Grid>
                                <span style={{ textAlign: 'left' }}>Contact Number <font color='red'>*</font> </span>
                                <Controller
                                    name="contactNumber"
                                    control={control}
                                    rules={{
                                        validate: (value) => handleValidate(value)
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <PhoneInput
                                            value={value}
                                            onChange={onChange}
                                            id="contactNumber"
                                            name="contactNumber"
                                            placeholder='Enter Mobile Number'
                                            margin="dense"
                                            addInternationalOption={true}
                                            international={true}
                                            style={errors.contactNumber && {
                                                border: '1px solid red'
                                            }}
                                            inputProps={{
                                                inputRef: register('contactNumber'),
                                            }}
                                        />
                                    )}
                                />
                                {errors["contactNumber"] && (
                                    <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem' }}>Mobile Number is required</div>
                                )}
                            </Grid>
                            {/* <br/> */}
                            <Box my={2} sx={{ display: 'flex', justifyContent: 'flex-start', }} >
                                <MUIButton
                                    startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
                                    title="Update"
                                    onClick={handleSubmit(onUpdateProfile)}
                                    type="submit"
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={2}></Grid>
                </Grid>

            </Box>



        </>
    )
}

export default UserProfile;