// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::-webkit-scrollbar {
    width: 5px;
    border: 3px solid #ACACAC;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #EEEEEE;
  }
  ::-webkit-scrollbar-thumb {
    background: #ACACAC;
    border-radius: 5px;
  }`, "",{"version":3,"sources":["webpack://./src/components/styles/customTestCaseScrollbar.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,yBAAyB;EAC3B;;EAEA,UAAU;EACV;IACE,mBAAmB;EACrB;EACA;IACE,mBAAmB;IACnB,kBAAkB;EACpB","sourcesContent":["::-webkit-scrollbar {\n    width: 5px;\n    border: 3px solid #ACACAC;\n  }\n  \n  /* Track */\n  ::-webkit-scrollbar-track {\n    background: #EEEEEE;\n  }\n  ::-webkit-scrollbar-thumb {\n    background: #ACACAC;\n    border-radius: 5px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
