import { Radio, Space, message } from 'antd';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { onboardingScreen, participationTypeOptions } from '../../constants/constants';
import { InfoCircleFilled, LeftCircleFilled } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux';
import MUIButton from '../../shared/components/MUIButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { updateCurrentStep, updateParticipationType } from '../../redux/customerOnBoarding/customerOnBoarding.slice';
import { useGetParticipationTypeMutation, useGetParticipationTypeQuery, useSaveParticipationTypeMutation } from '../../redux/customerOnBoarding/customerOnBoarding.api';
import { updateOnboardingCurrentStatus } from '../../redux/common/common.slice';
import { useNavigate } from 'react-router-dom';
import { ALERT_SEVERITY, APP_ROUTE_KEYS } from '../../shared/constants/constants';
import { Box } from '@mui/material';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
import PayaptLoader from "../../shared/components/Spinner";
import { DropdownValueContext } from '../../shared/context/DropdownProvider';
import { useGetCustomerDataByIdMutation } from '../../redux/customers/customer.api';


const ChooseParticipationType = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { value, updateClickedCard, dropValue } = useContext(DropdownValueContext);
    const Snackbar = useContext(SnackbarContext);
    // const data = useGetParticipationTypeMutation({paymentServiceId:value});
    const [getParticipation] = useGetParticipationTypeMutation();
    const [data, setData] = useState();
    const [payloadArr, setPayloadArr] = useState([]);

    // console.log("DATAA", data);
    const customerId = useSelector((state) => state?.common?.customerId);
    const { participationType } = useSelector((state) => state?.common?.customerOnBoarding);
    const { userRole } = useSelector((state) => state?.common?.userDetails)
    const [selectedParticipationType, setSelectedParticipationType] = useState({});
    const [selectedParticipationType1, setSelectedParticipationType1] = useState({});
    const { isDashboard } = useSelector((state) => state.common);
    const [getCustomerDataById, { data: customerAccountList }] = useGetCustomerDataByIdMutation();
    // console.log("customerAccountList", customerAccountList);

    const payloadCustomer = {
        customerId: customerId,
        paymentServiceId: (userRole?.name === 'Super Admin' ? dropValue : value)
    }

    React.useEffect(() => {
        if (customerId) {
            getCustomerDataById(payloadCustomer);
        }
    }, [customerId, dropValue, value])

    const payloadValue = {
        paymentServiceId: (userRole?.name === 'Super Admin' ? dropValue : value)
    };

    useEffect(() => {
        if (dropValue && userRole?.name === 'Super Admin') {
            getParticipation(payloadValue)
                .unwrap().then(res => {
                    //   console.log("Res",res);
                    setData(res)
                })
        }

    }, [dropValue])

    useEffect(() => {
        if (value && userRole?.name !== 'Super Admin') {
            getParticipation(payloadValue)
                .unwrap().then(res => {
                    //   console.log("Res",res);
                    setData(res)
                })
        }
    }, [value])
    // useEffect(() => {
    //     if (!_.isEmpty(participationType)) {
    //         setSelectedParticipationType({
    //             participationTypeId: participationType?.participationTypeId,
    //             participation_type_name: participationType?.participationTypeName
    //         })
    //     }
    // }, [participationType]);
    useEffect(() => {
        if (!_.isEmpty(customerAccountList)) {
            // Check if participationTypeDetails exists
            // if (customerAccountList?.participationTypeDetails?.[0]) {
            //     setSelectedParticipationType1({...customerAccountList.participationTypeDetails[0]?.data[0], ...payloadCustomer});
            // }
            // if (customerAccountList?.participationTypeDetails?.[1]) {
            //     setSelectedParticipationType({...customerAccountList.participationTypeDetails[1]?.data[0], ...payloadCustomer});
            // }
            const participationDetails = customerAccountList?.participationTypeDetails;

            if (participationDetails?.[0]) {
                const updatedData0 = { ...participationDetails[0]?.data?.[0], ...payloadCustomer };
                setSelectedParticipationType1(updatedData0);

                // Conditionally setSelectedParticipationType to use [0] data
                if (participationDetails?.[0]) {
                    setSelectedParticipationType(updatedData0);
                }
            }

            if (participationDetails?.[1]) {
                const updatedData1 = { ...participationDetails[1]?.data?.[0], ...payloadCustomer };
                // Default to using [1] data for setSelectedParticipationType if no condition overrides
                setSelectedParticipationType(updatedData1);
            }

            // Dispatch update for onboarding status
            dispatch(updateOnboardingCurrentStatus({
                participationType: {
                    participationTypeId: customerAccountList?.participationType,
                    participationTypeName: customerAccountList?.participationTypeName,
                    isConfigured: false
                }
            }));
        }
    }, [customerAccountList]);

    const [updateParticipationType, { isLoading }] = useSaveParticipationTypeMutation();
    const payload = {
        "data": payloadArr
    }

    // console.log("payload", payload);

    const saveParticipationType = async () => {
        await updateParticipationType(payload).unwrap().then((res) => {
            if (res.status) {
                message.success(res?.status)
            }
        })
        dispatch(updateOnboardingCurrentStatus({
            participationType: {
                participationTypeId: customerAccountList?.participationType,
                participationTypeName: customerAccountList?.participationTypeName,
                isConfigured: false
            }
        }))
        navigate(`/customer/onboarding?screen=${onboardingScreen.CONFIGURE_PARTICIPATION_TYPE}`)
    }

    const saveSettings = async () => {
        try {
            await updateParticipationType(payload).unwrap().then((res) => {
                // console.log("RESSSS", res);
                if (res.message) {
                    Snackbar.displayMsg(res?.message, ALERT_SEVERITY.SUCCESS);
                }
                else {
                    Snackbar.displayMsg("Participation Type Failed", ALERT_SEVERITY.ERROR)
                }
            })
            dispatch(updateOnboardingCurrentStatus({
                participationType: {
                    participationTypeId: customerAccountList?.participationType,
                    participationTypeName: customerAccountList?.participationTypeName,
                    isConfigured: false
                }
            }))
            // navigate(`${APP_ROUTE_KEYS.CUSTOMERS.MASTER_SETTINGS}`)
        }
        catch (e) {
            console.log("e", e);
        }
    }
    // console.log('selectedParticipationType', selectedParticipationType,selectedParticipationType1);

    useEffect(() => {
        const payloadArr = [selectedParticipationType1, selectedParticipationType]
        const filteredArray = payloadArr.filter(obj => Object.keys(obj).length !== 0);
        const updatedArray = filteredArray.map(({ participationTypeName, ...rest }) => rest);
        setPayloadArr(updatedArray)

        //  const payload = data{}
    }, [selectedParticipationType, selectedParticipationType1])
    return (

        <div className='onboarding-step-container'>
            <div className='onboarding-step-items-container'>
                <Box mx={3}>
                    {isDashboard ?
                        <span className='onboarding-step-title header1'>Select Participation Type&nbsp;&nbsp;<InfoCircleFilled twoToneColor='#ACACAC' className='step-info-icon' />
                        </span>
                        :
                        <span className='onboarding-step-title header1'>Participation Type&nbsp;&nbsp;
                            {/* <InfoCircleFilled twoToneColor='#ACACAC' className='step-info-icon' /> */}
                        </span>
                    }
                    {
                        data && data?.length === 2 ? (
                            <>
                                <span className='onboarding-step-title header1'>{data && data[1]?.type}&nbsp;&nbsp;</span>
                                <div >
                                    <Radio.Group>
                                        <Space direction='vertical' className='participation-value-container'>
                                            {
                                                _.map(data && data[1]?.data, sp => (
                                                    <div key={sp.participationTypeId} onClick={() => {
                                                        // handleRadioChange('CCT',sp)
                                                        setSelectedParticipationType({ ...sp, ...payloadCustomer })
                                                    }}>
                                                        <input
                                                            type='radio'
                                                            value={sp.participationTypeId}
                                                            className='participation-radio-item'
                                                            id={sp.participationTypeId}
                                                            checked={selectedParticipationType?.participationTypeId === sp.participationTypeId}
                                                            disabled={userRole?.name !== 'Super Admin'}
                                                        />
                                                        <label className={selectedParticipationType?.participationTypeId === sp.participationTypeId ? 'input-radio-label-checked' : 'input-radio-label'} htmlFor={sp.participationTypeId}>
                                                            {sp.participationTypeName}
                                                        </label>
                                                    </div>
                                                ))
                                            }
                                        </Space>
                                    </Radio.Group>
                                </div>

                                <span className='onboarding-step-title header1'>{data && data[0]?.type}&nbsp;&nbsp;</span>
                                <div >
                                    <Radio.Group>
                                        <Space direction='vertical' className='participation-value-container'>
                                            {
                                                _.map(data && data[0]?.data, sp => (
                                                    <div key={sp.participationTypeId} onClick={() => {
                                                        // handleRadioChange('FIT',sp)
                                                        setSelectedParticipationType1({ ...sp, ...payloadCustomer })
                                                    }}>
                                                        <input
                                                            type='radio'
                                                            value={sp.participationTypeId}
                                                            className='participation-radio-item'
                                                            id={sp.participationTypeId}
                                                            checked={selectedParticipationType1?.participationTypeId === sp.participationTypeId}
                                                            disabled={userRole?.name !== 'Super Admin'}
                                                        />
                                                        <label className={selectedParticipationType1?.participationTypeId === sp.participationTypeId ? 'input-radio-label-checked' : 'input-radio-label'} htmlFor={sp.participationTypeId}>
                                                            {sp.participationTypeName}
                                                        </label>
                                                    </div>
                                                ))
                                            }
                                        </Space>
                                    </Radio.Group>
                                </div>
                            </>
                        ) : (<>
                            <span className='onboarding-step-title header1'>{data && data[0]?.type}&nbsp;&nbsp;</span>
                            <div>
                                <Radio.Group>
                                    <Space direction='vertical' className='participation-value-container'>
                                        {
                                            _.map(data && data[0]?.data, sp => (
                                                <div key={sp.participationTypeId} onClick={() => setSelectedParticipationType1({ ...sp, ...payloadCustomer })}>
                                                    <input
                                                        type='radio'
                                                        value={sp.participationTypeId}
                                                        className='participation-radio-item'
                                                        id={sp.participationTypeId}
                                                        checked={selectedParticipationType1?.participationTypeId === sp.participationTypeId}
                                                        disabled={userRole?.name !== 'Super Admin'}
                                                    />
                                                    <label className={selectedParticipationType1?.participationTypeId === sp.participationTypeId ? 'input-radio-label-checked' : 'input-radio-label'} htmlFor={sp.participationTypeId}>
                                                        {sp.participationTypeName}
                                                    </label>
                                                </div>
                                            ))
                                        }
                                    </Space>
                                </Radio.Group>
                            </div>
                        </>)
                    }


                    {/* <div className='footer-buttons'> */}
                    {isDashboard ?
                        <Box sx={{ marginTop: '20%' }}>
                            <MUIButton
                                startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
                                sx={{ backgroundColor: '#F0F2F5', color: '#808080' }}
                                color="inherit"
                                title="Back"
                                onClick={() => navigate(`/customer/onboarding?screen=${onboardingScreen.CHOOSE_SUBSCRIPTION}`)}
                            />
                            <MUIButton
                                startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
                                title="Confirm"
                                onClick={() => saveParticipationType()}
                            />
                        </Box>
                        :

                        (userRole?.name === 'Admin' || userRole?.name === 'Tester') ?
                            <Box sx={{ marginTop: '20%' }}>
                                <MUIButton
                                    startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
                                    sx={{ backgroundColor: '#F0F2F5', color: '#808080', }}
                                    color="inherit"
                                    title="Back"
                                    onClick={() => navigate(`${APP_ROUTE_KEYS.SETTINGS.MASTER}`)}
                                />
                            </Box>

                            :
                            <Box sx={{ marginTop: '20%' }}>
                                <MUIButton
                                    startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
                                    sx={{ backgroundColor: '#F0F2F5', color: '#808080', }}
                                    color="inherit"
                                    title="Back"
                                    onClick={() => {
                                        updateClickedCard(false);
                                        navigate(`${APP_ROUTE_KEYS.CUSTOMERS.MASTER_SETTINGS}`)
                                    }
                                    }
                                />
                                <MUIButton
                                    startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
                                    title="Save"
                                    onClick={() => saveSettings()}
                                    sx={{ mx: 2 }}
                                />
                            </Box>
                    }

                    {/* </div> */}
                </Box>
            </div>
            {isLoading && <PayaptLoader />}
        </div>
    )
}

export default ChooseParticipationType;


