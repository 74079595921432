//  import * as yup from 'yup';

// export const addTestDataSchema = yup.object().shape({
//     category:yup.string().required("Category is required"),
//     selectType: yup.string().required("Type is required"),
//     name: yup.string().required("Name is required"),
//     address: yup.string().required("Address is required"),
//     // accountId: yup.string().required("Account Id is required"),
//     accountId: yup
//     .string()
//     .required("Account Id is required")
//     .matches(/^\d+$/, "Account Id should contain numbers only"),
//     // rtn: yup.string().required("RTN is required"),
//     rtn: yup
//         .string()
//         .required("RTN is required")
//         .matches(/^\d+(-\d+)*$/, "RTN should contain numbers with optional hyphens"),
//     usedFor: yup.string().required("Used For is required"),
//     connectionPartyIndentifier: yup.string().required("Connection Party Indentifier is required"),
// });

import * as yup from 'yup';

 export const addTestDataSchema = yup.object().shape({
    category: yup.string().required("Category is required"),
    selectType: yup.string().required("Type is required"),
    name: yup.string().required("Name is required"),
    address: yup.string().required("Address is required"),
    accountId: yup
      .string()
      .test('isAccountIdRequired', 'Account Id is required', function (value) {
        const category = this.resolve(yup.ref('category'));
        return category === 'Customer' ? yup.string().required().isValidSync(value) : true;
      })
      .test('isAccountIdNumber', 'Account Id should contain numbers only', function (value) {
        const category = this.resolve(yup.ref('category'));
        return category === 'Customer' ? /^\d+$/.test(value) : true;
      }),
    rtn: yup
      .string()
      .test('isRtnRequired', 'RTN is required', function (value) {
        const category = this.resolve(yup.ref('category'));
        return category === 'Bank' ? yup.string().required().isValidSync(value) : true;
      })
      .test('isRtnValid', 'RTN should contain numbers with optional hyphens', function (value) {
        const category = this.resolve(yup.ref('category'));
        return category === 'Bank' ? /^\d+(-\d+)*$/.test(value) : true;
      }),
    usedFor: yup
      .string()
      .test('isUsedForRequired', 'Used For is required', function (value) {
        const category = this.resolve(yup.ref('category'));
        return category === 'Bank' ? yup.string().required().isValidSync(value) : true;
      }),
    connectionPartyIndentifier: yup
      .string()
      .test('isConnectionPartyRequired', 'Connection Party Identifier is required', function (value) {
        const category = this.resolve(yup.ref('category'));
        return category === 'Bank' ? yup.string().required().isValidSync(value) : true;
      }),
  });
  