import React, { useEffect, useState } from 'react'
import Dropdown from '../../shared/components/Dropdown'
import { useGetMessageTypeQuery } from '../../redux/testRuns/testRuns.api';
import _ from 'lodash';

const styles = {
  menuProps: {
    sx: {
        '.MuiPaper-root': {
            maxHeight: '40%',
        },
    },
},
}

const MessageTypeDropdown = (props) => {
    const {value, onChangeMessage ,isClear} = props;
    const {data : messageOptions} = useGetMessageTypeQuery();
    useEffect(() => {
        if(!_.isEmpty(messageOptions)){
          onChangeMessage(messageOptions?.messageTypeId, messageOptions?.messageTypeName)
        }
    }, [messageOptions])

    const onChange = (value) => {
      // console.log('value-onChange',value);
      if(value){
        const obj = _.find(messageOptions, m => m?.messageTypeId === value);
        onChangeMessage(value, obj?.messageTypeName)
      } else {
        onChangeMessage(value, value)
      }
      
    }
  return (
    <>
     <Dropdown
       placeholder=' Select Message Type'
       margin="dense"
       className="user-dropdown"
       style={{ width: '100%', height: '42px',marginTop:'-20px'}}
       inputProps={{ 'aria-label': 'Without label' }}
       options={messageOptions}
       valueKey='messageTypeId'
       labelKey='messageTypeName'
       onChange={(e) =>onChange(e)}
       value={value}
       MenuProps={styles.menuProps}
       isClear={isClear}
     />
    </>
  )
}

export default MessageTypeDropdown;
