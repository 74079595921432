import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetOnboardingDetailsQuery } from '../redux/customerOnBoarding/customerOnBoarding.api';
import { Roles, menuItems, onboardingScreen } from '../constants/constants';
import { useNavigate } from 'react-router-dom';
import {  updateOnboardingCurrentStatus, updateOnboardingDetails } from '../redux/common/common.slice';
import WrapperComponent from '../shared/components/wrapperComponent';
import { Box } from '@mui/system';

const AppLoader = () => {
    const { userDetails } = useSelector(state => state?.common);
    const { userRole:role } = userDetails;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data: onboardingDetails, isFetching, isSuccess, isError } = useGetOnboardingDetailsQuery({}, 
        {skip: _.isEqual(_.toUpper(Roles.SUPER_ADMIN.name), _.toUpper(role?.name)) 
            ||  _.isEqual(_.toUpper(Roles.TESER.name), _.toUpper(role?.name))
            || Number(window.REACT_APP_ONBOARDING_REQUIRED) == 0
        })

    const getDefaultMenu = () => {
        const prvlgs = userDetails?.userRole?.privileges;
        let menu = !_.isEmpty(prvlgs) ? _.find(menuItems, m => m.privilege === prvlgs[0]) : {};
        if(!_.isEmpty(menu?.children)){
            menu = menu.children[0]
        }
        return menu;
    }

    const getOnboardingScreen = (onboardingConfig) => {
        if(!onboardingConfig?.subscriptionPlan?.subscriptionPlanId){
            return onboardingScreen.CHOOSE_SUBSCRIPTION;
        } if(!onboardingConfig?.participationType?.participationTypeId){
            return onboardingScreen.SELECT_PARTICIPATION_TYPE;
        } if(!onboardingConfig?.participationType?.isConfigured){
            return onboardingScreen.CONFIGURE_PARTICIPATION_TYPE
        }if(onboardingConfig?.participationType?.isConfigured){
            return `${onboardingScreen.CONFIGURE_PARTICIPATION_TYPE}&type=review`
        }
    }
    const updateOnboarding = async (payload, menu, onboardingConfig) => {
        await dispatch(updateOnboardingDetails(payload));
        if(!_.isEmpty(onboardingConfig)){
            await dispatch(updateOnboardingCurrentStatus(onboardingConfig));
            if(!onboardingConfig?.isOnboardingCompleted){
                navigate(`/customer/onboarding?screen=${getOnboardingScreen(onboardingConfig)}`)
            } else {
                navigate(menu?.url);
            }
        } else {
            navigate(menu?.url);
        }
        
    }
    const onboarding = useMemo(() => {
        
        if(!_.isEmpty(onboardingDetails)) {
            const menu = getDefaultMenu();
            const xPayload = {
                isUserAuthenticated: true,
                isUserProfileCompleted: true, // temporary true flag for testing the onBoarding routes
                enableCustomerOnboarding: !onboardingDetails?.isOnboardingCompleted,
                isUserOnBoardingCompleted: onboardingDetails?.isOnboardingCompleted,
                selectedMenuKey: menu?.key,
                selectedSubMenuKey: menu?.parentKey,
            }
            const onboardingPayload = {
                subscriptionPlan: {
                    subscriptionPlanId: onboardingDetails?.subscriptionPlanId,
                    subscriptionPlanName: onboardingDetails?.subscriptionPlanName
                },
                participationType: {
                    participationTypeId: onboardingDetails?.participationTypeId,
                    participationTypeName: onboardingDetails?.participationTypeName,
                    isConfigured: onboardingDetails?.isParticipationTypeConfigured
                },
                isOnboardingCompleted: onboardingDetails?.isOnboardingCompleted
            }
            updateOnboarding(xPayload, menu, onboardingPayload);
        }
    }, [onboardingDetails])
    // isUserProfileCompleted: Number(window.REACT_APP_ACCOUNT_ACTIVATION_REQUIRED) == 0, // temporary true flag for testing the onBoarding routes
                // enableCustomerOnboarding: Number(window.REACT_APP_ONBOARDING_REQUIRED) == 1,
                // isUserOnBoardingCompleted: Number(window.REACT_APP_ONBOARDING_REQUIRED) == 0,

    if(_.isEqual(_.toUpper(Roles.SUPER_ADMIN.name), _.toUpper(role?.name)) 
    ||  _.isEqual(_.toUpper(Roles.TESER.name), _.toUpper(role?.name))
    || Number(window.REACT_APP_ONBOARDING_REQUIRED) == 0){
        const menu = getDefaultMenu();
        const xPayload = {
            isUserAuthenticated: true,
            isUserProfileCompleted: Number(window.REACT_APP_ACCOUNT_ACTIVATION_REQUIRED) == 0, // temporary true flag for testing the onBoarding routes
            enableCustomerOnboarding: false,
            isUserOnBoardingCompleted: true,
            selectedMenuKey: menu?.key,
            selectedSubMenuKey: menu?.parentKey,
        }
        updateOnboarding(xPayload, menu);
    } else if(!_.isEmpty(onboardingDetails)){
        return onboarding;
    } else {
        return (
            <WrapperComponent isFetching={isFetching} isError={isError} isSuccess={isSuccess}>
                <Box>
                    Loading...
                </Box>
            </WrapperComponent>
        )
    }
}

export default AppLoader;
