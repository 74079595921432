import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';
import SouthIcon from '@mui/icons-material/South';
import './progressCircle.css';
import CircularProgressPercentage from './CircularProgressPercentage';
const ProgressCircle = (props) => {
    const {iconType,circleIcon,sx,testCases,title="",directionTitle="",resultTitle1="",count1,resultTitle2="",count2,resultTitle3="",count3,total,resultData} = props;
  return (
    <Box sx={{width:'100%',paddingLeft:'0px',paddingRight:'0px',marginTop:'10px'}}>
      <Card className='progress-circle'>
        <Grid  display="flex" sx={{justifyContent:"space-between",padding:'5px'}}>
            <Box sx={{display:"flex"}}>
        <Box sx={[{width:'40px',height:'46px',display:"flex",justifyContent:"center"},{...sx}]} >
          {/* <SouthIcon sx={{color:"#1EA472",marginTop:'10px'}}/> */}
          {iconType}
        </Box>&nbsp;
        <Box>
        <Typography sx={{fontWeight:700,fontSize:16,display:"flex",fontFamily:"Inter",fontStyle:"normal"}}>{directionTitle}</Typography>
        <Typography sx={{display:"flex",fontWeight:400}}>{total}&nbsp;{testCases && <span className='content-heading-executed'>{title}</span> }
        </Typography>
        </Box>
        </Box>
        <Grid ml={0}>
        <span style={{alignSelf:"flex-end",display:"flex"}}>{circleIcon}</span>
        </Grid>
        </Grid>
        <CardContent style={{display:"flex",justifyContent:'space-between'}}>
            {resultData?.map((pEl, index) => (
                <Box key={index}>
            <Typography className='content-heading-executed'>{pEl.resultTitle}</Typography>
            <Typography style={{textAlign:"center",fontWeight:400}}>{pEl.count}</Typography>
            </Box>
            ))}
            
            {/* <Box>
            <Typography className='content-heading-executed'>{resultTitle2}</Typography>
            <Typography style={{textAlign:"center",fontWeight:400}}>{count2}</Typography>
            </Box>
            <Box>
            <Typography className='content-heading-executed'>{resultTitle3}</Typography>
            <Typography style={{textAlign:"center",fontWeight:400}}>{count3}</Typography>
            </Box> */}
        </CardContent>
      </Card>
    </Box>
  )
}

export default ProgressCircle;