import { Grid } from '@mui/material';
import React from 'react';
import BatchFileSelection from './BatchFileSelection.first';
import TestCasesSelection from './TestCasesSelection.second';
import TestCasesAddition from './TestCasesAddition.third';
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
// import TestCaseList from './testcaseList';

const styles = {
    container: {
        p: 2,
        border: '1px solid #FAFAFA',
        borderRadius: '4px',
        minHeight: '60vh',
        width: "100%",
    }
}

function BatchFiles(props) {
    const { selectedBatch, setSelectedBatch, onPreview, openBatchTestcaseDetails, type, onBatchSelectionChange,
         batchContextToRun, onBatchTestDataSelectionChange, displayAllBatches,customer } = props;
    return (
        <DndProvider backend={HTML5Backend}>
            <Grid container sx={styles.container}>
                <Grid item xs={4}>
                    <BatchFileSelection
                        setSelectedBatch={setSelectedBatch}
                        selectedBatch={selectedBatch}
                        onPreview={onPreview}
                        type={type}
                        onBatchSelectionChange = { onBatchSelectionChange }
                        batchContextToRun = { batchContextToRun }
                        displayAllBatches = {displayAllBatches}
                        customer={customer}
                    /></Grid>
                <Grid item xs={4}>
                    <TestCasesSelection 
                        selectedBatch={selectedBatch} 
                        openBatchTestcaseDetails={openBatchTestcaseDetails} 
                        onBatchTestDataSelectionChange = {onBatchTestDataSelectionChange}
                        selectedTestcases = {_.find(batchContextToRun, b => b.batchId === selectedBatch?.customerTestRunBatchId)}
                    /></Grid>
                <Grid item xs={4} pl={2}><TestCasesAddition selectedBatch={selectedBatch} openBatchTestcaseDetails={openBatchTestcaseDetails} /></Grid>
            </Grid>
        </DndProvider>
    );
}

export default BatchFiles;