import { createSlice } from '@reduxjs/toolkit';

export const testCasesSlice = createSlice({
    name: 'testCases',
    initialState: { pageSize: 10 },
    reducers: {
         // actionsGoesHere: (state, actions) => {
        //     state.customState = actions.payload;
        // },
    },
});

// export const { actionsGoesHere } = testCasesSlice.actions;
