import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PropTypes from 'prop-types';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import '../../components/styles/datepickerStyles.css'
import dayjs from 'dayjs';
const PayaptDatePicker = (props) => {
    const { placeholder , onChangeDate, maxDate} = props;

    const handleDateChange = (date) => {
        // Perform the date validation here to allow only past and present dates
        const currentDate = dayjs(); // Get current date with dayjs
        if (date?.isBefore(currentDate, 'day')) {
            onChangeDate(date.toDate()); // Convert dayjs object to Date object
        }
    };
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        {/* <DemoContainer components={['DatePicker']}> */}
          <DatePicker inputFormat="DD/MM/YYYY" onChange={(date) => handleDateChange(date)} hintText={placeholder}
    container="inline" format='DD/MM/YYYY' maxDate={maxDate}
           sx={{ width: '100%'}} size = 'small' className='payapt-date-picker' inputStyle={{ textAlign: 'center' }}/>
        {/* </DemoContainer> */}
      </LocalizationProvider>
    )
}

PayaptDatePicker.defaultProps = {
    onChange: () => {},
    selectedDate: '',
    placeholder: 'Select date',
    maxDate: dayjs(),
}
PayaptDatePicker.PropTypes = {
    onChange: PropTypes.func,
    selectedDate: PropTypes.instanceOf(Date),
    placeholder: PropTypes.string,
    maxDate: PropTypes.instanceOf(Date),
}

export default PayaptDatePicker;