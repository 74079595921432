import { Box, Button } from "@mui/material";
import React, { useEffect } from "react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import "../styles/message.css";
import { Input } from "antd";
import { useGetXmlDataLatestMutation, useGetXmlDataQuery } from "../../redux/testScenarios/testScenarios.api";
import WrapperComponent from "../../shared/components/wrapperComponent";
import TestcaseDetailsHeader from "../testcaseDetailsHeader";
import { useSelector } from "react-redux";

const MasterXml = (props) => {
  const { selectedTestScenario, reviewTheXmlData: retrieveXmlData, onSelectionChange,messageTypePayload, } = props;
  const masterXMLPath = selectedTestScenario?.masterXMLPath;
  const { TextArea } = Input;
  const { customerTestScenarioName } = useSelector((state) => state?.common)

  // console.log("customerTestScenarioName",customerTestScenarioName);

  const [value, setValue] = React.useState({});

  const payload ={
    "direction":customerTestScenarioName,
    "messageType":messageTypePayload,
  }
  // console.log("payload",payload);
  const [gtXmlMasterdata] = useGetXmlDataLatestMutation(); 

  useEffect(() => {
    if(selectedTestScenario && !selectedTestScenario?.isNew){
      gtXmlMasterdata(payload).then(res=>{
        // console.log('res', res?.data?.data);
        if (res?.data?.data?.inboundXml) {
          setValue(res?.data?.data?.inboundXml)
        } else {
          setValue(res?.data?.data?.outboundXml)
        }
        
        // let data=JSON.parse(atob(res.data?.getXmlDataLatest[0].xmlContent));
        //console.log('data', data);
        // setValue(data);
        })
        }

  },[selectedTestScenario])
  return (
    <div style = {{paddingTop: 10}}>
      <TestcaseDetailsHeader 
          onSelectionChange = {onSelectionChange}
          showReviewBtn={false}
          showAddRowBtn={false}
          value = 'masterXml'
          selectedTestScenario = {selectedTestScenario}
      />
      <div>
        <label className="xml-txt-label">XML Data</label>
            {/* <WrapperComponent > */}
            <TextArea
              rows={15}
              className="xml-txt-area"
              style={{ height: '100%' }}
              value={value}
              readOnly
            />
            {/* </WrapperComponent> */}
        {/* <Box my={3}>
          <Button
            variant="contained"
            style={{
              textTransform: "none",
              fontFamily: "Inter",
              fontSize: "16px",
              color: "#808080",
              backgroundColor: "#F0F2F5",
            }}
          >
            <CancelIcon
              style={{
                height: "16.67px",
                width: "16.67px",
                color: "#808080",
                margin: "5px",
              }}
            />
            Close
          </Button>{" "}
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            variant="contained"
            style={{
              textTransform: "none",
              background: "#009966",
              fontFamily: "Inter",
              fontSize: "16px",
              borderRadius: "5px",
              marginRight: "30px",
            }}
          >
            <CheckCircleIcon
              style={{
                height: "16.67px",
                width: "16.67px",
                color: "#f6f6f6",
                margin: "5px",
              }}
            />
            Reviewed
          </Button>
        </Box> */}
      </div>
    </div>
  );
};

export default MasterXml;