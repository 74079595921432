import { Table } from 'antd';

export const messageXmllist_columns = [
    Table.SELECTION_COLUMN,
    Table.EXPAND_COLUMN,
    {
        title: 'Created Date',
        dataIndex: 'createdDate',
        key: 'createdDate',
        sorter: true,
        width: '19%',
    },
    {
        title: 'XML File Name',
        dataIndex: 'fileName',
        key: 'fileName',
        width: '26.5%',
    },
    {
        title: 'Message Type',
        dataIndex: 'messageType',
        width: '17.3%',
        key: 'messageType',
    },
    {
        title: 'Direction',
        dataIndex: 'direction',
        // width: '30%',
        key: 'direction',
    },
    {
        title: 'Status',
        dataIndex: 'xmlStatus',
        // width: '30%',
        key: 'xmlStatus',
    },

    {
        title: 'Action',
        dataIndex: 'action',
        // width: '30%',
        key: 'action',
    },
];

export const messageXmlData = [
    {
        createdDate: "15/03/2023",
        fileName: "xyz.xml",
        messageType: "PACS.008",
        direction: "SEND",
        xmlStatus: "Invalid",
    },
    {
        createdDate: "16/03/2023",
        fileName: "xyz.xml",
        messageType: "PACS.008",
        direction: "SEND",
        xmlStatus: "Valid",
    },
    {
        createdDate: "17/03/2023",
        fileName: "xyz.xml",
        messageType: "PACS.008",
        direction: "SEND",
        xmlStatus: "Invalid",
    },
    {
        createdDate: "18/03/2023",
        fileName: "xyz.xml",
        messageType: "PACS.008",
        direction: "SEND",
        xmlStatus: "Valid",
    },
  ];

export const messageTemplateList=[
    {
      id:'1',
      title:'PACS.009.ADV',
      create:'Incoming PACS.009',
      templates:[{
        id:"1",
        template:"Swift, CBPR+ 2022"
      },
       //{
      //   id:"2",
      //   template:"CBPR+ 2022"
      // }
    ]
    },{
      id:'2',
      title:'PACS.008.ADV',
      create:'Incoming PACS.008',
      templates:[{
        id:"1",
        template:"Swift, CBPR+ 2023"
      },
      // {
      //     id:"2",
      //     template:"CBPR+ 2023"
      //   }
      ]
    }
  ]
