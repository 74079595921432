import { Button, InputLabel, OutlinedInput, TextField, Typography } from "@mui/material";
import React, {useContext} from "react";
// import './welcome.css'
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import { useNavigate } from "react-router-dom";
// import '../styles/profileupdateStyles.css';
import { Select } from "antd";
import PayaptTextField from "../../shared/components/TextField";
import TestCaseHeader from "../testCaseHeader";
import MUIButton from "../../shared/components/MUIButton";
import { Box, Grid } from "@mui/material";
import '../styles/accountInfoStyles.css';
import { Controller,useForm } from "react-hook-form";
import Dropdown from "../../shared/components/Dropdown";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useGetCustomerAccountListQuery,useUpdateCustomerAccountDetailsMutation,useGetCustomerTypeListQuery } from '../../redux/accountInfo/accountInfo.api';
import { customerInfoValidationSchema } from '../onboarding/accountInfoValidation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { useGetCustomerSignupInfoByIdMutation , useUpdateCustomerSignupInformationMutation} from '../../redux/registration/registration.api';
import { CustomerMsg } from '../../shared/constants/AlertMessages';
import { ALERT_SEVERITY } from '../../shared/constants/constants';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
import { InfoCircleFilled } from "@ant-design/icons";

const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    title: {
        fontWeight: '600', fontSize: '1.2rem', pr: 1
      },
      subTitle: {
        fontSize: '0.85rem', fontStyle: 'italic'
      }
  }


const ProfileUpdate =()=>{
    const navigate = useNavigate();
    const [customerTypes, setCustomerTypes] = React.useState();
    const resolver = yupResolver(customerInfoValidationSchema);
    const Snackbar = useContext(SnackbarContext);
    const { userName } = useSelector((state) => state?.common?.userDetails);
    const customerId = useSelector((state) => state?.common?.customerId);
    const {data: customerTypeList} = useGetCustomerTypeListQuery();
    const {data: customerAccountList} = useGetCustomerAccountListQuery();
    const [getCustomerSignupInfoById,{data:CustomerData}] = useGetCustomerSignupInfoByIdMutation();
    const [updateCustomerSignupInformation] = useUpdateCustomerSignupInformationMutation();

const handleDropDownValue = () => {
  const customerTypeData = _.map(customerTypeList, (m,index) => ({
      id: m.customerTypeId,
      value: m.customerTypeName,
      label: m.customerTypeName
    }));
    setCustomerTypes(customerTypeData);
}

React.useEffect(() => {
    getCustomerSignupInfoById({customerId:customerId});
}, [customerId]);

React.useEffect(() => {
  handleDropDownValue();
}, [customerTypeList]);

    const {
      control,
      register,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm({
        resolver,
        defaultValues: {
            organisationType:0,
            organisationName:'',
            address1: '',
            address2: '',
            city:'',
            country: '',
            postalCode:'',
            email: '',
            mobile: '',
            firstName: '',
            lastName: ''
        }
    });

    const defaultValues = {
        organisationType:CustomerData?.organisationType ? CustomerData?.organisationType : null,
        organisationName:CustomerData?.organizationName,
        address1: CustomerData?.address1,
        address2: CustomerData?.address2,
        city:CustomerData?.city,
        country: CustomerData?.country,
        postalCode:CustomerData?.postalCode,
        email: CustomerData?.email,
        mobile: CustomerData?.contactNumber,
        firstName: CustomerData?.firstName,
        lastName: CustomerData?.lastName
    }

    React.useEffect(() => {
            reset({
                ...defaultValues,
            })
            return;
    }, [CustomerData]);

    const handleValidate = (value) => {
      const isValid = isValidPhoneNumber(value);
      return isValid;
    }

    const handleEditNavigation = async (xRes) => {
        if (xRes?.data?.customerId) {
            Snackbar.displayMsg(CustomerMsg.UPDATE_CUSTOMER_SUCCESS, ALERT_SEVERITY.SUCCESS);
             navigate('/thankscompleteprofile');
        } else {
            Snackbar.displayMsg(`${xRes?.error?.data?.error}`, ALERT_SEVERITY.ERROR);
        }
    }

    const onComplete= async (data)=>{
        data.customerId = customerId;
        const updateResult = await updateCustomerSignupInformation(data);
        handleEditNavigation(updateResult);
    };
    return(
       <>
        <Box mt={2}>
          <Box>
            <Grid spacing={3} container >
              <Grid item xs={3} ></Grid>
              <Grid item xs={6}>
                      <Box sx={styles.container}>
                        <Box display={"flex"} flexDirection="row">
                        <Typography sx={styles.title}>{"Finish Setting Up Your Organization Profile"}</Typography>
                        {/* <InfoCircleFilled className='step-info-icon' /> */}
                        </Box>
                        <Typography sx={styles.subTitle}>{"Some Information about profile completion goes here"}</Typography>
                    </Box>
              </Grid>
              <Grid item xs={3} ></Grid>
            </Grid>
          </Box>
          <Box sx={{overflowY: 'auto', maxHeight: '68vh',marginTop: 3,marginRight: '2%', width: '100%'}}>
          <Grid spacing={3} container >
            <Grid item xs={3} ></Grid>
            <Grid item xs={6} sx={{ textAlign: 'left'}} >
              <Box sx = {{marginTop: 2}}>
                <Grid container rowSpacing={2} columnSpacing={4}>
                  <Grid item xs={6}>
                        <span style={{ textAlign: 'left' }}>Organization Name <font color='red'>*</font></span>
                        <br />
                        <Controller
                            control={control}
                            name={"organisationName"}
                            render={({ field }) => (
                                <PayaptTextField
                                    {...register("organisationName")}
                                    {...field}
                                    name="organisationName"
                                    placeholder='Enter Organization Name'
                                    margin="dense"
                                    // className="user-text-box"
                                    error={(!!errors['organisationName'])}
                                    helperText={errors['organisationName']?.message}

                                />
                            )}
                        />
                  </Grid>
                  <Grid item xs={6}>
                        <span style={{ textAlign: 'left' }}>Organization Type <font color='red'>*</font></span>
                        <br />
                        <Controller
                            control={control}
                            name={"organisationType"}
                            render={({ field }) => (
                                <Dropdown
                                    inputRef={register('organisationType', { required: true })}
                                    {...field}
                                    placeholder='Select Organization Type'
                                    margin="dense"
                                    sx={{marginTop:'7px'}}
                                    className="user-dropdown"
                                    style={{ width: '100%', height: '56px' }}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    options={customerTypes}
                                    error={!!errors['organisationType']}
                                    helperText={errors['organisationType']?.message}
                                />
                            )}
                        />
                        {errors.organisationType && (
                            <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem' }}>Organization Type is required</div>
                        )}
                  </Grid>
                  <Grid item xs={6}>
                        <span style={{ textAlign: 'left' }}>First Name <font color='red'>*</font></span>
                        <br />
                        <Controller
                            control={control}
                            name={"firstName"}
                            render={({ field }) => (
                              <PayaptTextField
                                {...register("firstName")}
                                {...field}
                                name="firstName"
                                placeholder='Enter First Name'
                                margin="dense"
                                // className="user-text-box"
                                error={(!!errors['firstName'])}
                                helperText={errors['firstName']?.message}

                              />
                            )}
                        />
                  </Grid>
                  <Grid item xs={6}>
                        <span style={{ textAlign: 'left' }}>Last Name <font color='red'>*</font></span>
                        <br />
                        <Controller
                            control={control}
                            name={"lastName"}
                            render={({ field }) => (
                              <PayaptTextField
                                {...register("lastName")}
                                {...field}
                                name="lastName"
                                placeholder='Enter Last Name'
                                margin="dense"
                                // className="user-text-box"
                                error={(!!errors['lastName'])}
                                helperText={errors['lastName']?.message}

                              />
                            )}
                        />
                  </Grid>
                  <Grid item xs={6}>
                        <span style={{ textAlign: 'left' }}>Contact Number <font color='red'>*</font></span>
                        <br />
                        <Controller
                          name="mobile"
                          control={control}
                          rules={{
                           validate: (value) => handleValidate(value)
                          }}
                          render={({ field: { onChange, value } }) => (
                            <PhoneInput
                              value={value}
                              onChange={onChange}
                              id="mobile"
                              name="mobile"
                              placeholder='Enter Contact Number'
                              margin="dense"
                              addInternationalOption={true}
                              international={true}
                              style={errors.contactNumber && {
                              border: '1px solid red'
                              }}
                              inputProps={{
                              inputRef: register('mobile'),
                              }}
                            />
                          )}
                        />
                        {errors["mobile"] && (
                            <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem' }}>Contact Number is required</div>
                        )}
                  </Grid>
                  <Grid item xs={6}>
                        <span style={{ textAlign: 'left' }}>Email <font color='red'>*</font></span>
                        <br />
                        <Controller
                            control={control}
                            name={"email"}
                            render={({ field }) => (
                                <PayaptTextField
                                    {...register("email")}
                                    {...field}
                                    name="email"
                                    placeholder='Enter Email address'
                                    margin="dense"
                                    // className="user-text-box"
                                    error={!!errors['email']}
                                    helperText={errors['email']?.message}
                                />
                            )}
                        />
                  </Grid>
                  <Grid item xs={6}>
                        <span style={{ textAlign: 'left' }}>Address1 <font color='red'>*</font></span>
                        <br />
                        <Controller
                            control={control}
                            name={"address1"}
                            render={({ field }) => (
                                <PayaptTextField
                                    {...register("address1")}
                                    {...field}
                                    name="address1"
                                    placeholder='Enter Address1'
                                    margin="dense"
                                    // className="user-text-box"
                                    error={!!errors['address1']}
                                    helperText={errors['address1']?.message}

                                />
                            )}
                        />
                  </Grid>
                  <Grid item xs={6}>
                        <span style={{ textAlign: 'left' }}>Address2 <font color='red'>*</font></span>
                        <br />
                        <Controller
                            control={control}
                            name={"address2"}
                            render={({ field }) => (
                                <PayaptTextField
                                    {...register("address2")}
                                    {...field}
                                    name="address2"
                                    placeholder='Enter Address2'
                                    margin="dense"
                                    // className="user-text-box"
                                    error={!!errors['address2']}
                                    helperText={errors['address2']?.message}

                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <span style={{ textAlign: 'left' }}>City <font color='red'>*</font></span>
                        <br />
                        <Controller
                            control={control}
                            name={"city"}
                            render={({ field }) => (
                                <PayaptTextField
                                    {...register("city")}
                                    {...field}
                                    name="city"
                                    placeholder='Enter City'
                                    margin="dense"
                                    // className="user-text-box"
                                    error={!!errors['city']}
                                    helperText={errors['city']?.message}

                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <span style={{ textAlign: 'left' }}>Country <font color='red'>*</font></span>
                        <br />
                        <Controller
                            control={control}
                            name={"country"}
                            render={({ field }) => (
                                <PayaptTextField
                                    {...register("country")}
                                    {...field}
                                    name="country"
                                    placeholder='Enter Country'
                                    // className="user-text-box"
                                    error={!!errors['country']}
                                    helperText={errors['country']?.message}

                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <span style={{ textAlign: 'left' }}>Postal Code <font color='red'>*</font></span>
                        <br />
                        <Controller
                            control={control}
                            name={"postalCode"}
                            render={({ field }) => (
                                <PayaptTextField
                                    {...register("postalCode")}
                                    {...field}
                                    name="postalCode"
                                    placeholder='Enter Postal Code'
                                    // className="user-text-box"
                                    error={!!errors['postalCode']}
                                    helperText={errors['postalCode']?.message}

                                />
                            )}
                        />
                    </Grid>
                </Grid>
              </Box>
              <Box my={2} sx={{ display: 'flex', justifyContent: 'flex-start', }} >
                      <MUIButton
                        startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
                        title="Finish"
                        onClick={handleSubmit(onComplete)}
                        type="submit" />
                    </Box>
            </Grid>
            <Grid item xs={3} ></Grid>
          </Grid>
          </Box>
        </Box>
       </>
    );
}

export default ProfileUpdate;
