import * as React from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

// const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
//   height: 5,
//   borderRadius: 5,
//   [`&.${linearProgressClasses.colorPrimary}`]: {
//     backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
//   },
//   [`& .${linearProgressClasses.bar}`]: {
//     borderRadius: 5,
//     backgroundColor: ,
//   },
// }));

const BorderLinearProgress = (props) => {
    const { variant, value, sx } = props;
    return (
        <LinearProgress value = {value} variant={variant} sx = {{ borderRadius: 5, height: 5, ...sx}}/>
    )
}
export default BorderLinearProgress;

