import React from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';

import CustomerOnboardingTitle from '../components/customerOnboardingTitle';
import Signin from '../components/login/signin';
import Signup from '../components/login/signup';

import { updateUserAuth } from '../redux/auth/auth.slice';

import '../components/styles/loginStyles.css';
import '../components/styles/buttonStyles.css';

const LoginContainer = (props) => {
    const { isLogin } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const onButtonClick = (xSignIn) => {
        if (!xSignIn) {
            navigate('/signup');
        } else {
            navigate('/signin');
        }
    }
    return (
        <div className='container'>
            <CustomerOnboardingTitle />
            <div className='signin-signup-container'>
                <div className='login-content'>
                    <div className='login-container'>
                        <button type="button" className={isLogin ? "btn btn-success login-container-btn" : "btn btn-link login-container-btn"} onClick={() => onButtonClick(true)}>Sign In</button>
                        {/* <button type="button" className={!isLogin ? "btn btn-success login-container-btn" : "btn btn-link login-container-btn"} onClick={() => onButtonClick(false)}>Sign Up</button> */}
                    </div>
                    <div>
                        {
                            isLogin ? <Signin setUserAuthenticated={() => dispatch(updateUserAuth)} /> : <Signup />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginContainer;