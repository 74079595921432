import CancelIcon from '@mui/icons-material/Cancel';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { Box, IconButton } from '@mui/material';
import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EditIcon } from '../../assets/base64/images';
import { getMessageFilterOptions, testMessageInitialFilters } from '../../components/homeScreens/TestRun/constants';
import { messageXmlData } from '../../components/messages/constants';
import { messageXmllist_columns } from '../../constants/constants';
import DragDropFile from '../../shared/components/DragDropFile';
import Filter from '../../shared/components/Filter.popup';
import AntModal from '../../shared/components/Modal/AntModal';
import PayaptTable from '../../shared/components/Table';
import { greyBorderColor, primaryColor } from '../../shared/constants/constants';
import useModal from '../../shared/hooks/useModal.hook';
import TestCaseHeader from '../testCaseHeader';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  draggerContainer: {
    minHeight: '35vh',
    maxHeight: '50vh',
    minWidth: "60%",
    borderRadius: '20px'
  },
  iconButton: {
    backgroundColor: 'white',
    padding: '6px',
    border: "1px solid #C6CBD1"
  }
};

const MessageXmlList = () => {
  const columns = messageXmllist_columns;
  const xmlData = messageXmlData;
  const navigate = useNavigate();

  const { open, openModal, closeModal } = useModal();
  const [searchedText, setSearchedText] = useState("");
  const [uploadFile, setUploadFile] = useState();
  const [filters, setFilters] = React.useState(testMessageInitialFilters || {});
  const [filterOptions, setFilterOptions] = useState(getMessageFilterOptions([]))
  const [isDragdropModalVisible, setIsDragdropModalVisible] = React.useState(false);

  const onEdit = (value) => {
  }

  const onDelete = (value) => {
  }

  const onDownload = (value) => {
  }
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // const loData = { ...selectedTestScenarios };
      const selectedKeys = Object.keys(selectedTestScenarios);
      const removedKeys = _.difference(selectedKeys, selectedRowKeys);
      _.map(removedKeys, (k) => delete loData[k]);
      _.map(selectedRowKeys, (k) => {
        loData[k] = {
          isAllTestcasesSelected: true,
          selectedKeys: [],
        };
      });
      setSelectedScenarios(loData);
    },
    getCheckboxProps: (record) => ({
      className: "checkbox-red",
      disabled: !record.reviewed,
    }),
  };

  columns[6].render = (data, rec) => (
    <>
      {data === "Invalid" ? (
        <>
          <span><CancelRoundedIcon className='cancelicon' /></span>
          <span className='testruns-results-failed'>{data}</span>
        </>
      ) : (
        <>
          <span><CheckCircleRoundedIcon className='checkicon' /></span>
          <span className='testruns-results-success'>{data}</span>
        </>
      )}
    </>
  );

  columns[7].render = (data, rec) => (

    <>
      <div >
        <span onClick={(e) => onEdit(rec)}>
          <EditIcon sx={{ fontSize: "15px" }} />
        </span>
        &nbsp; &nbsp;
        <span onClick={(e) => onDelete(rec)}>
          <IconButton aria-label="settings" sx={styles.iconButton}>
            <DeleteForeverRoundedIcon sx={{ fontSize: "15px", color: '#C6CBD1' }} />
          </IconButton>
        </span>
        &nbsp; &nbsp;
        <span onClick={(e) => onDownload(rec)}>
          <IconButton aria-label="settings" sx={styles.iconButton}>
            <FileDownloadIcon sx={{ fontSize: "15px", color: '#C6CBD1' }} />
          </IconButton>
        </span>
      </div>
    </>
  );

  const onSearchChange = (value) => {
    setSearchedText(value);
  }

  const handleSaveFilter = async () => {
    const options = {
      ...pageOptions,
      filters: {
        ...pageOptions.filters,
        testType: [testType],
        result: getSelectedFilter(filters.result),
        direction: getSelectedFilter(filters.direction),
        triggerMessage: !_.isEmpty(filters.triggerMessage) && !_.isEmpty(filters.triggerMessage.triggerMessage) ? filters.triggerMessage.triggerMessage : null
      }
    };
    setPageOptions(options)
    await getTestRuns({
      ...options
    });
    closeModal();
  }
  const handleCancelFilter = () => {
    // setFilters({ ...testRunInitialFilters });
    closeModal();
  }

  useEffect(() => {

  }, []);

  const openFileUpload = () => {
    setIsDragdropModalVisible(true)
  }

  const openFileAdded = () => {
    navigate('/message/template')
  }
  const handleOnChangeDragdrop = (xFilesInfo) => {
    const { fileList, file } = xFilesInfo;
    setUploadFile(fileList);
  }
  const onCancel = () => {
    setIsDragdropModalVisible(false)
  }

  const onUploadFile = () => {
    if (uploadFile?.length > 0) {
      navigate('/message/outGoingXmlList');
    }
  }

  return (
    <Box sx={styles.container}>
      <TestCaseHeader
        title={'Messages - XML Message List'}
        subtitle={'Some details related to XML Message List goes here'}
        showFileUpload={{
          show: true,
          onClick: openFileUpload
        }}
        showFileAdded={{
          show: true,
          onClick: openFileAdded
        }}
        showFilter={{
          show: true,
          onClick: openModal
        }}
        showSearch={{
          show: true,
          value: searchedText,
          onChange: onSearchChange
        }}
      />

      <Box my={2}>
        {/* <WrapperComponent > */}
        <PayaptTable
          size="small"
          rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
          className="standalone_table"
          columns={columns}
          data={xmlData}
        >
        </PayaptTable>
        {/* </WrapperComponent> */}
      </Box>
      <Modal
        onCancel={() => setIsDragdropModalVisible(false)}
        open={isDragdropModalVisible}
        footer={[]}
        centered
        closeIcon={<CancelOutlinedIcon sx={{ color: "#001A11", backgroundColor: "white" }} />}
        style={styles.draggerContainer}
      >
        <DragDropFile
          title={"Upload XML File to Create Message"}
          subTitle={"About uploading XML file instruction goes here"}
          sampleFileUrl={""}
          onChange={handleOnChangeDragdrop}
          onClick={onCancel}
          onUploadFile={onUploadFile}
        />
      </Modal>

      {open && <AntModal
        title="Filter Results for XML Message List"
        open={open}
        width={'750px'}
        style={{ top: '15%', left: '22%' }}
        mask={false}
        okText="Apply Filter"
        cancelText="Clear"
        onOk={handleSaveFilter}
        okButtonProps={{
          style: {
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: primaryColor
          },
          icon: <CheckCircleIcon sx={{ fontSize: '18px' }} />
        }}
        cancelButtonProps={{
          style: {
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#F0F2F5',
            color: greyBorderColor
          },
          icon: <CancelIcon sx={{ fontSize: '18px' }} />
        }}
        onCancel={handleCancelFilter}
        closeIcon={<CancelOutlinedIcon sx={{ color: "#001A11", backgroundColor: "white" }} />}
      >
        <Filter filters={filters} setFilters={setFilters} options={filterOptions} totalElements={10} />
      </AntModal>}




    </Box>
  )
}

export default MessageXmlList;
