import React from 'react';
import MenuItem from './menuItem';
import SubMenu from './subMenu';
import './menuStyles.css'
import PropTypes from 'prop-types'

const Menu = (props) => {
    const { items, selectedKeys, selectedSubmenuKeys,
    openSubmenu, expandIcon, showExpandIcon, collapse, 
    onClick, selectionKey, menuTitleKey, submenuTitleKey, 
    childrenKey, onSubmenuExtraClick, onMenuExtraClick, width, submenuSelectionKey, submenuContainerStyle,
    onSubmenuSelected } = props;

    const getMenuItem = (item,index) => {
        if(!_.isEmpty(item[childrenKey])){
            return <SubMenu 
                    key={index}
                    item = {item} 
                    children = {item[childrenKey]} 
                    openSubmenu = {openSubmenu}
                    showExpandIcon = {showExpandIcon}
                    expandIcon = {expandIcon}
                    selectedChildKeys = {selectedKeys}
                    isSelected = {_.includes(selectedSubmenuKeys, item[selectionKey])}
                    collapse = {collapse}
                    onClick = {(e, child) => onClick(e, child, item)}
                    selectionKey = {selectionKey}
                    menuTitleKey = {menuTitleKey}
                    submenuTitleKey = {submenuTitleKey}
                    childrenKey = {childrenKey}
                    onMenuExtraClick={onMenuExtraClick}
                    onSubmenuExtraClick = {onSubmenuExtraClick}
                    submenuSelectionKey = {submenuSelectionKey}
                    onSubmenuSelected = {onSubmenuSelected}
                    submenuContainerStyle={submenuContainerStyle}
                    />
        }
        return <MenuItem 
                item = {item} 
                key={index}
                isSelected = {_.includes(selectedKeys, item[selectionKey])} 
                collapse = {collapse} 
                onClick = {onClick}
                menuTitleKey = {menuTitleKey}
                onExtraClick = {onMenuExtraClick}
                />
    }
    return (
        <div style = {{width: collapse ? '50px' : width}} >
            {
                _.map(items, (item,index) => getMenuItem(item,index))
            }
        </div>
    )
}

Menu.defaultProps = {
    items: [],
    selectedKeys: [],
    selectedSubmenuKeys: [],
    openSubmenu: false,
    expandIcon: null,
    showExpandIcon: true,
    collapse: false,
    onClick: () => null,
    selectionKey: 'key',
    menuTitleKey: 'title',
    submenuTitleKey: 'title',
    childrenKey: 'children',
    width: '250px',
    submenuSelectionKey: 'key',
    submenuContainerStyle: {},
    onSubmenuSelected: () => null
}

Menu.PropTypes = {
    items: PropTypes.arrayOf(Object),
    selectedKeys: PropTypes.arrayOf(String),
    selectedSubmenuKeys: PropTypes.arrayOf(String),
    openSubmenu: PropTypes.bool,
    expandIcon: PropTypes.element,
    showExpandIcon: PropTypes.bool,
    collapse: PropTypes.bool,
    onClick: PropTypes.func,
    selectionKey: PropTypes.string,
    menuTitleKey: PropTypes.string,
    submenuTitleKey: PropTypes.string,
    childrenKey: PropTypes.string,
    width: PropTypes.string,
    submenuSelectionKey: PropTypes.string,
    submenuContainerStyle: PropTypes.instanceOf(Object),
    onSubmenuSelected: PropTypes.func
}

export default Menu;
