import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useSelector } from 'react-redux';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import { colors } from '../colors';
import { NoAccessPage } from '../../constants/menuIcons';

const AccessPage = (props) => {
    const { children, menu } = props;
    const { privileges } = useSelector((state) => state?.common?.userDetails?.userRole)
    if(_.includes(privileges, menu)){
        return children;
    } return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%'}}>
            <NoAccessPage />
            <Typography>You are not authorized to access this page</Typography>
        </Box>
    )
}

export default AccessPage;