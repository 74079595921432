import { Button, Descriptions } from 'antd';
import _ from 'lodash';
import React from 'react';
import { messageTypes } from '../../constants/constants';
import TextAreaWithLineNumber from 'text-area-with-line-number';
import { useLocation, useNavigate } from 'react-router-dom';
import '../styles/message.css'
import { ArrowLeftOutlined } from '@ant-design/icons'
import PageHeader from '../pageHeader';


const MessageDetail = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search)
    const messageId = searchParams.get('messageId');
    
    const getMessageDetail =() => {
        const reqMsg = _.find(messageTypes, msg => msg.messageId === messageId);
        if(!_.isEmpty(reqMsg)){
            return reqMsg;
        }
        return {
            messageDetails: null,
        };
    }

    return (
        <div>
            <PageHeader pageTitle = 'Message Details'/>
            <div className='message-details-info'>
                <div>
                    <span className='message-details-info-header'>Message ID:</span>&nbsp;&nbsp;
                    <span>{getMessageDetail().messageId}</span>
                </div>
                <div>
                    <span className='message-details-info-header'>Message Name:</span>&nbsp;&nbsp;
                    <span>{getMessageDetail().messageName}</span>
                </div>
                <div>
                    <span>
                        <Button icon={<ArrowLeftOutlined />} type="default" className='back-btn' onClick={() => navigate(-1)}>
                            Back
                        </Button>
                    </span>
                </div>
            </div>
            
            <div className='message-detail'>
                <TextAreaWithLineNumber 
                    // onChange={(e) => onTextChange(e.target.value)} 
                    border = '0px'
                    height = '60vh'
                    value = {getMessageDetail().messageDetails}>

                </TextAreaWithLineNumber>
            </div>
        </div>
    )
}

export default MessageDetail;