import { LeftCircleFilled } from "@ant-design/icons";
import { Box, Button, Grid, InputLabel, MenuItem, TextField } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import React, { useContext, useState } from "react";
import "./styles.css";
import PayaptTextField from "../../../shared/components/TextField";
import MUIButton from "../../../shared/components/MUIButton";
import { Controller, useController, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addTestDataSchema } from "./utils";
import { SnackbarContext } from "../../../shared/context/SnackbarContext.Context";
import { AddTestDataMsg } from "../../../shared/constants/AlertMessages";
import { ALERT_SEVERITY } from "../../../shared/constants/constants";
import Dropdown from "../../../shared/components/Dropdown";
import { useGetSimulatorTestDataWithTypeMutation, useListofsimtestdatatypeQuery, useSaveSimulatortestdataMutation, useSimulatorTypeDropdownMutation } from "../../../redux/settings/settings.api";
import { useEffect } from "react";
import { useSelector } from "react-redux";


const AddData = (props) => {

  const { setModalVisibility = () => { }, setIsSimulatorList = () => { }, } = props;
  const Snackbar = useContext(SnackbarContext);
  const { data: listofsimtestdatatype } = useListofsimtestdatatypeQuery();
  const [simulatorTestDataWithTypeList, { data: customerBankDropDown }] = useGetSimulatorTestDataWithTypeMutation();
  const customerId = useSelector((state) => state?.common?.customerId);

  const [typeDropDown, { data: typeDropDownList }] = useSimulatorTypeDropdownMutation();

  const [saveSimulatortestdata] = useSaveSimulatortestdataMutation();

  const resolver = yupResolver(addTestDataSchema);
  const {
    control,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver,
    defaultValues: {
    },

  });


  var initialpayload = {
    "page": 0,
    "size": 10,
    "filter": null,
    "customerId": customerId
  }
  useEffect(() => {
    simulatorTestDataWithTypeList(initialpayload);


  }, [])


  const handelCategoryChange = async (event) => {
    // console.log('categoryValue', event);
    setValue('selectType', '')
    typeDropDown({ type: event })

  }


  const handleAddTestData = async (data) => {
    // console.log("savee data",data);
    const dropDownOptions = typeDropDownList?.customerDropdown || typeDropDownList?.bankDropDown;
    const selectTypeNumber = parseInt(data?.selectType);
    const selectedType = dropDownOptions.find(option => option.simulatorTestDataTypeId === selectTypeNumber);
    const selectedTypeName = selectedType ? selectedType.type : null;

    try {
      var savepayload = {
        "category": data?.category,
        "name": data?.name,
        "type": selectedTypeName,
        "simulatorTestDataTypeId": +data?.selectType,
        "address": data?.address,
        "connectionPartyIdentifier": data?.connectionPartyIndentifier ? data?.connectionPartyIndentifier : "",
        "routingNo": data?.rtn ? data?.rtn : "",
        "usedFor": data?.usedFor ? data?.usedFor : "",
        "customerId": customerId,
        "accountNo": data?.accountId ? data?.accountId : "",
      }
      // console.log("payload", savepayload);
      saveSimulatortestdata(savepayload)
      Snackbar.displayMsg("Simulator test data saved succesfully", ALERT_SEVERITY.SUCCESS);
      setModalVisibility(false)
      setIsSimulatorList(true)
      reset();
    } catch (error) {
      // console.log(error);
      Snackbar.displayMsg("Failed to save", ALERT_SEVERITY.SUCCESS)
    }

  };

  const handleReset = () => {
    reset();
  };

  return (
    <Box>
      <h4 className="add-title">Add Test Data</h4>
      <span className="add-subtitle">
        About uploading test data instruction goes here
      </span>
      <Box sx={{ marginTop: "10px" }} >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <span className="add-header-content">Category</span>
            <br />
            <Controller
              control={control}
              name={"category"}
              render={({ field }) => (
                <Dropdown
                  inputRef={register('category', { required: true })}
                  {...field}
                  placeholder='Select Category'
                  margin="dense"
                  className="user-dropdown"
                  style={{ width: '100%', height: '56px', marginTop: "8px" }}
                  inputProps={{ 'aria-label': 'Without label' }}
                  options={customerBankDropDown}
                  error={!!errors['category']}
                  helperText={errors['category']?.message}
                  valueKey='type'
                  labelKey='type'
                  onChange={(selectedValue) => {
                    field.onChange(selectedValue);
                    handelCategoryChange(selectedValue);
                  }} />

              )}
            />
            {errors.category && (
              <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem' }}>Category is required</div>
            )}

          </Grid>
          <Grid item xs={6}>
            <span className="add-header-content">Type</span>
            <br />
            <Controller
              control={control}
              name="selectType"
              render={({ field }) => (
                <Dropdown
                  inputRef={register('selectType', { required: true })}
                  {...field}
                  placeholder='Select Type'
                  margin="dense"
                  className="user-dropdown"
                  style={{ width: '95%', height: '56px', marginTop: "8px" }}
                  inputProps={{ 'aria-label': 'Without label' }}
                  options={typeDropDownList?.customerDropdown ? typeDropDownList?.customerDropdown : typeDropDownList?.bankDropDown ? typeDropDownList?.bankDropDown : []}
                  error={!!errors['selectType']}
                  helperText={errors['selectType']?.message}
                  valueKey='simulatorTestDataTypeId'
                  labelKey='type'
                />
              )}
            />
            {errors.selectType && (
              <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem' }}>Type is required</div>
            )}
          </Grid>

          <>
            <Grid item xs={6}>
              <span className="add-header-content">Name</span>
              <br />
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <PayaptTextField
                    {...register("name")}
                    {...field}
                    name="name"
                    style={{ width: "330px", height: '50px', }}
                    placeholder="Enter Name"
                    type="text"
                    margin="dense"
                    error={!!errors['name']}
                    helperText={errors['name']?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <span className="add-header-content">Address</span>
              <br />
              <Controller
                control={control}
                name="address"
                render={({ field }) => (
                  <PayaptTextField
                    {...register("address")}
                    {...field}
                    sx={{ width: "330px", height: "50px", border: " #C6CBD1" }}
                    placeholder="Enter Address"
                    margin="dense"
                    type
                    // multiline
                    // rows={4}
                    error={!!errors['address']}
                    helperText={errors['address']?.message}
                  />
                )}
              />
            </Grid>

            {getValues("category") === "Customer" && (
              <Grid item xs={6}>
                <span className="add-header-content">Account Id</span>
                <br />
                <Controller
                  control={control}
                  name="accountId"
                  render={({ field }) => (
                    <PayaptTextField
                      {...register("accountId")}
                      {...field}
                      style={{ width: "330px", height: '50px' }}
                      placeholder="Enter Account Id"
                      margin="dense"
                      error={!!errors['accountId']}
                      helperText={errors['accountId']?.message}
                    />
                  )}
                />
              </Grid>
            )}

            {getValues("category") === "Bank" && (
              <Grid item xs={6}>
                <span className="add-header-content">RTN</span>
                <br />
                <Controller
                  control={control}
                  name="rtn"
                  render={({ field }) => (
                    <PayaptTextField
                      {...register("rtn")}
                      {...field}
                      sx={{ width: "330px", height: "50px", border: " #C6CBD1" }}
                      placeholder="Enter Column RTN"
                      margin="dense"
                      error={!!errors['rtn']}
                      helperText={errors['rtn']?.message}
                    />
                  )}
                />
              </Grid>
            )}

            {getValues("category") === "Bank" && (
              <Grid item xs={6}>
                <span className="add-header-content">Used For</span>
                <br />
                <Controller
                  control={control}
                  name="usedFor"
                  render={({ field }) => (
                    <PayaptTextField
                      {...register("usedFor")}
                      {...field}
                      style={{ width: "330px", height: '50px' }}
                      placeholder="Enter Used For"
                      margin="dense"
                      error={!!errors['usedFor']}
                      helperText={errors['usedFor']?.message}
                    />
                  )}
                />
              </Grid>
            )}

            {getValues("category") === "Bank" && (
              <Grid item xs={6}>
                <span className="add-header-content">Connection Party Identifier</span>
                <br />
                <Controller
                  control={control}
                  name="connectionPartyIndentifier"
                  render={({ field }) => (
                    <PayaptTextField
                      {...register("connectionPartyIndentifier")}
                      {...field}
                      sx={{ width: "330px", height: "50px" }}
                      placeholder="Enter Connection Party Identifier"
                      margin="dense"
                      error={!!errors['connectionPartyIndentifier']}
                      helperText={errors['connectionPartyIndentifier']?.message}
                    />
                  )}
                />
              </Grid>
            )}
          </>


        </Grid>
      </Box>

      <Box mt={4}>
        <Grid container justifyContent="flex-start"  >
          <MUIButton
            startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
            title="Reset"
            sx={{ backgroundColor: '#F0F2F5', color: '#808080' }}
            color="inherit"
            onClick={handleReset}
          />&nbsp;&nbsp;&nbsp;&nbsp;
          <MUIButton
            startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
            title="Confrim"
            onClick={handleSubmit(handleAddTestData)}
          />
        </Grid>
      </Box>
    </Box>
  );
};

export default AddData;