import { Radio, Space, message } from 'antd';
import _ from 'lodash';
import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CheckOutlined, InfoCircleFilled, LeftCircleFilled } from '@ant-design/icons'

import MUIButton from '../../shared/components/MUIButton';
// import { subscriptionPlans } from '../../constants/constants';
import { updateCurrentStep, createSubscriptionPlan, updateSubscriptionPlan } from '../../redux/customerOnBoarding/customerOnBoarding.slice';
import { useGetSubscriptionPlanListQuery, useGetSubscriptionPlanDetailsByIdMutation, useCusInfoSubscriptionPlanByIdMutation } from '../../redux/customerOnBoarding/customerOnBoarding.api';
import { updateOnboardingCurrentStatus } from '../../redux/common/common.slice';
import { useNavigate } from 'react-router-dom';
import { onboardingScreen } from '../../constants/constants';
import { ALERT_SEVERITY, APP_ROUTE_KEYS } from '../../shared/constants/constants';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
import PayaptLoader from '../../shared/components/Spinner';


const ChooseSubscriptionPlan = () => {

    const Snackbar = useContext(SnackbarContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { subscriptionPlan } = useSelector((state) => state?.common?.customerOnBoarding);
    const customerId = useSelector((state) => state?.common?.customerId);
    const { isDashboard } = useSelector((state) => state.common)
    const subscriptionPlanList = useGetSubscriptionPlanListQuery();
    const subscriptionPlans = subscriptionPlanList.data;

    const { userRole } = useSelector((state) => state?.common?.userDetails)

    const [getSubscriptionPlanDetailsById, { data, isSuccess }] = useGetSubscriptionPlanDetailsByIdMutation();
    const [cusInfoSubscriptionPlanById,{isLoading}] = useCusInfoSubscriptionPlanByIdMutation();

    const [ selectedSubscriptionPlan, setSelectedSubscriptionPlan ] = useState({});
    
    
    useEffect(() => {
        if(!_.isEmpty(subscriptionPlan)){
            setSelectedSubscriptionPlan({
                subscriptionPlanId: subscriptionPlan?.subscriptionPlanId,
                subscriptionPlanName: subscriptionPlan?.subscriptionPlanName,
                planDescription: subscriptionPlan?.planDescription,
            })
        }
    }, [subscriptionPlan]);

    const onUpdateSubscriptionPlan = (item) => {
        setSelectedSubscriptionPlan(item)
        // dispatch(updateSubscriptionPlan(item));
        // dispatch(updateOnboardingCurrentStatus({
        //     subscriptionPlan: {
        //         subscriptionPlanId: item?.subscriptionPlanId,
        //         subscriptionPlanName: item?.subscriptionPlanName,
        //         planDescription: item?.planDescription,
        //         isConfigured: false
        //     }
        // }))
    }
    

    const fetchSubscriptionPlanDetails = async (item) => {
        const SubscriptionPlanDetail = await getSubscriptionPlanDetailsById({ subscriptionId: item });
    }

    useEffect(() => {
        fetchSubscriptionPlanDetails(selectedSubscriptionPlan?.subscriptionPlanId);
    }, [selectedSubscriptionPlan?.subscriptionPlanId]);

    const onSubmit = async () => {
        const subscriptionPlanValue = selectedSubscriptionPlan?.subscriptionPlanId;
        if (subscriptionPlanValue) {
            const res = await cusInfoSubscriptionPlanById(subscriptionPlanValue).unwrap()
                .then((res) => {
                    if (res.status) {
                        // message.success(res?.status)
                        Snackbar.displayMsg(res?.status, ALERT_SEVERITY.SUCCESS)
                        dispatch(updateOnboardingCurrentStatus({ selectedSubscriptionPlan }));
                        navigate(`/customer/onboarding?screen=${onboardingScreen.SELECT_PARTICIPATION_TYPE}`)
                    }

                })
                .catch((err) => {
                })
        }
        // dispatch(updateCurrentStep(currentStep + 1));
    }

    const saveSettings = async () => {
        const subscriptionPlanValue = selectedSubscriptionPlan?.subscriptionPlanId;
        // console.log('subscriptionPlanValue',subscriptionPlanValue);
        var payload ={
            subscriptionPlanId : subscriptionPlanValue,
            customerId : customerId
        }
        if (subscriptionPlanValue) {
            const res = await cusInfoSubscriptionPlanById(payload).unwrap()
                .then((res) => {
                    if (res?.status) {
                        // message.success(res?.status)
                        Snackbar.displayMsg(res?.status, ALERT_SEVERITY.SUCCESS)
                        // dispatch(updateOnboardingCurrentStatus({ selectedSubscriptionPlan }));
                        dispatch(updateOnboardingCurrentStatus({subscriptionPlan: {
                            subscriptionPlanId: selectedSubscriptionPlan?.subscriptionPlanId,
                            subscriptionPlanName: selectedSubscriptionPlan?.subscriptionPlanName,
                            planDescription: selectedSubscriptionPlan?.planDescription,
                            isConfigured: false
                        }}))
                        navigate(`${APP_ROUTE_KEYS.CUSTOMERS.MASTER_SETTINGS}`)
                    }

                })
                .catch((err) => {
                })
        }
        // dispatch(updateCurrentStep(currentStep + 1));
    }

    const getRadioItem = (item) => {
        return (
            <div key={item.subscriptionPlanId} className={item.subscriptionPlanId === selectedSubscriptionPlan?.subscriptionPlanId ? 'subscription-plan-item active-plan' : 'subscription-plan-item'} onClick={(e) => onUpdateSubscriptionPlan(item)}>
                <div className='subscription-plan-title-radio'>
                    <input readOnly type="radio" className='subscription-radio' value={item.subscriptionPlanId} checked={item.subscriptionPlanId === selectedSubscriptionPlan?.subscriptionPlanId} />
                    <div className='subscription-plan-title-desc'>
                        <div className='subscription-plan-title'>{item.subscriptionPlanName}</div>
                        <div className='onboarding-step-detail-description'>{item.planDescription}</div>
                    </div>
                </div>
            </div>
        )
    }
    const getTooltipTitle = (selectedPlan) => {
        const sub_plan = data;
        if (sub_plan) {
            const planDetails = sub_plan?.subscriptionPlanName;
            const info = sub_plan?.info;
            const keypoints = sub_plan?.subscriptionPlanDetails;
            return (
                <div className='plan-details-tooltip'>
                    <span className='subscription-plan-tooltip-title'>{planDetails}</span>
                    <span className='subscription-plan-detail-info'>{info}</span>
                    <div>
                        {
                            _.map(keypoints, key => (
                                <div className='subscription-keypoints' key={keypoints[key]}>
                                    <CheckOutlined style={{ color: '#009966', fontWeight: 400 }} />
                                    <span className='keypoint'>{key}</span>
                                </div>
                            ))
                        }
                    </div>
                </div>
            )
        }

    }

    return (
        <div className='onboarding-step-container'>
            <div className='onboarding-step-items-container'>
                <div className='onboarding-step-header-container'>
                    <span className='onboarding-step-title header1'>Choose Your Plan&nbsp;&nbsp;<InfoCircleFilled className='step-info-icon' /></span>
                    <span className='onboarding-step-description header2-italic'>About available subscription plan details goes here</span>
                </div>
                <div className='onboarding-step-items'>
                    <Radio.Group>
                        <Space direction='vertical'>
                            {
                                _.map(subscriptionPlans, sp => (
                                    getRadioItem(sp)
                                ))
                            }
                        </Space>
                    </Radio.Group>
                    <div>
                        {!_.isEmpty(selectedSubscriptionPlan) && getTooltipTitle(selectedSubscriptionPlan?.subscriptionPlanId)}
                    </div>
                </div>
                <div className='footer-buttons'>
                    {isDashboard ?
                        <MUIButton
                            startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
                            title="Confirm"
                            onClick={() => onSubmit()}
                        />
                        :
                        (userRole?.name === 'Admin' || userRole?.name === 'Tester') ?
                            <MUIButton
                                startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
                                sx={{ backgroundColor: '#F0F2F5', color: '#808080' }}
                                color="inherit"
                                title="Back"
                                onClick={() => navigate(`${APP_ROUTE_KEYS.SETTINGS.MASTER}`)}
                            />
                            :
                            <>
                                <MUIButton
                                    startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
                                    sx={{ backgroundColor: '#F0F2F5', color: '#808080' }}
                                    color="inherit"
                                    title="Back"
                                    onClick={() =>  navigate(`${APP_ROUTE_KEYS.CUSTOMERS.MASTER_SETTINGS}`)}
                                />
                                <MUIButton
                                    startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
                                    title="Save"
                                    onClick={() => saveSettings()}
                                />
                            </>
                    }
                </div>
            </div>
            { isLoading && <PayaptLoader/>}

        </div>
    )
}

export default ChooseSubscriptionPlan;
