import React, { useContext, useState } from 'react';
import TestCaseHeader from '../testCaseHeader';
import { Box, Button, Typography } from '@mui/material';
import { Col, Input, Modal, Row } from 'antd';
import XSwitch from '../../shared/components/XSwitch';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MUIButton from '../../shared/components/MUIButton';
import { LeftCircleFilled } from '@ant-design/icons';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
import { useActiveOrInactiveRTNMutation, useAddingRtnNumberMutation, useDeleteRtnNumberMutation, useGetRtnNumberListMutation } from '../../redux/customers/customer.api';
import { ALERT_SEVERITY, APP_ROUTE_KEYS, greyBorderColor, primaryColor } from '../../shared/constants/constants';
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Controller, useForm } from 'react-hook-form';
import { routingNumberValidation } from '../Customers/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import useModal from '../../shared/hooks/useModal.hook';
import AntModal from '../../shared/components/Modal/AntModal';
import PayaptTextField from '../../shared/components/TextField';

const RespondentRoutingNo = () => {

    const { customerTestScenarioName } = useSelector((state) => state?.common);
    const pageSize = useSelector((state) => state?.common?.pageSize);
    const navigate = useNavigate();
    const Snackbar = useContext(SnackbarContext);
    const { userRole } = useSelector((state) => state?.common?.userDetails);

    const [viewRtnNumberList, { data }] = useGetRtnNumberListMutation();
    const [activeOrInactiveRTN] = useActiveOrInactiveRTNMutation();
    const [deleteRtnNumber] = useDeleteRtnNumberMutation();
    const [addingRtnNumber] = useAddingRtnNumberMutation();
    const [rtnNumberList, setRtnNumberList] = useState();
    const [isModalVisible, setModalVisibility] = useState(false);
    const [isDelete, setIsDelete] = useState();
    const [rountingNumber, setRountingNumber] = useState();
    const { open, openModal, closeModal } = useModal();
    const resolver = yupResolver(routingNumberValidation);
    const {control,register,formState:{errors},handleSubmit,setValue} = useForm({resolver, });
    const initialPageOptions = {
        customerId: customerTestScenarioName,
        pageNo: 0,
        pageSize
    }

    React.useEffect(() => {
        viewRtnNumberList(initialPageOptions)
    }, []);
    React.useEffect(() => {
        if (data) {
            // console.log("Second",data?.data);
            setRtnNumberList(data?.data)

        }
    }, [data]);

    const onAddRowButtonClick = (xSelTC) => {
        openModal()
        // const newObj = {
        //     // customerRoutingNumberId: null,
        //     routingNumber: null,

        //     isActive: 0,
        //     isNew: true,
        //     // isUpdated: false
        // }
        // // const newRowWithOldValues = [...xpathAndValueList, newObj];
        // const newRowWithOldValues = [...rtnNumberList, newObj];
        // setRtnNumberList(newRowWithOldValues);
        // console.log("newRowWithOldValues", newRowWithOldValues);
        // if (newRowRef.current) {
        //     newRowRef.current.scrollIntoView({ behavior: 'smooth' });
        //   }
    }

    const updateValue = (key, val, index) => {
        // console.log("udateValue", val);
        const updatedXpathAndValues = [...rtnNumberList];
        updatedXpathAndValues[index] = {
            ...updatedXpathAndValues[index],
            // isUpdated: true,
            [key]: val
        }
        // console.log("updatedXpathAndValues", val);
        setRtnNumberList(updatedXpathAndValues);
    }

    const handleActiveCheck = async (isActive, rountingNumber) => {
        // console.log("active", isActive);
        // setIsActive(event.target.checked === true ? 1 : 0)
        try {

            const payload = {
                customerId: customerTestScenarioName,
                routingNumber: rountingNumber,
                isActive: isActive
            }
            // console.log("payload", payload);

            await activeOrInactiveRTN(payload).unwrap().then((res) => {
                // console.log('ACTIVERES', res);
                if (res?.successMessage === "Customer RTN Status Updated Successfully") {
                    Snackbar.displayMsg("Customer RTN Status Updated Successfully", ALERT_SEVERITY.SUCCESS);
                    viewRtnNumberList(initialPageOptions);
                } else {
                    Snackbar.displayMsg("Customer RTN Failed to Update", ALERT_SEVERITY.ERROR);
                }
            })

        } catch (error) {
            console.error('Error updating active status:', error);
        }
    }

    const handleDeleteModal = (isDelete, rountingNumber, isVisible) => {
        // console.log("isVisible", isVisible);
        setModalVisibility(isVisible)
        setIsDelete(isDelete)
        setRountingNumber(rountingNumber)
    }

    const handleDeleteRtnNumber = async () => {
        try {
            const payloadDelete = {
                customerId: customerTestScenarioName,
                routingNumber: rountingNumber,
                isDelete: isDelete,
            }
            // console.log("payloadDelete", payloadDelete);

            await deleteRtnNumber(payloadDelete).unwrap().then((res) => {
                if (res?.successMessage === "Customer RTN Deleted Successfully") {
                    Snackbar.displayMsg("Customer RTN Deleted Successfully", ALERT_SEVERITY.SUCCESS);
                    handleDeleteModal(false);
                    viewRtnNumberList(initialPageOptions);
                } else {
                    Snackbar.displayMsg("Customer RTN Failed to Delete", ALERT_SEVERITY.ERROR);
                }
            })

        } catch (error) {
            console.error('Error deleting the number:', error);
        }
    }

    const handleRtnNumberSubmit = async (data) => {
        // const addValues = _.filter(rtnNumberList, d => d.isNew).map(newRow => newRow.routingNumber);
        // console.log("addValues", addValues);
        const xElpayload = {
            customerId: customerTestScenarioName,
            routingNumber: [data?.routingNumber],
        }
        // console.log("xElpayload", xElpayload);
        await addingRtnNumber(xElpayload).unwrap().then((res) => {
            if (res?.status === "success") {
                Snackbar.displayMsg("Routing Number added or updated succesfully", ALERT_SEVERITY.SUCCESS);
                closeModal();
                setValue('routingNumber',"")
                viewRtnNumberList(initialPageOptions);
            } else {
                Snackbar.displayMsg("Routing Number is Failed to added or updated", ALERT_SEVERITY.ERROR);
            }

        })
    }

    const handleCancelRtn = () => {
        closeModal();
    }

    return (
        <Box sx={{ overflowY: 'auto', height: `calc(100vh - 100px)`, paddingBottom: "30px" }}>
            <TestCaseHeader title="Routing Numbers" />
            <Box px={2}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                    <Button
                        size="small"
                        startIcon={<AddCircleOutlineIcon size="small" />}
                        onClick={() => onAddRowButtonClick()}
                        // disabled={disableAddRowButton}
                        sx={{ textTransform: 'none', color: '#001A11' }}
                    >
                        Add RTN
                    </Button>
                </div>

                <div style={{ marginTop: 5, width: "98%", }} >
                    <Row className='xpath-value-table-header'>
                        <Col span={18} className='table-col-header'><span>Routing Number</span></Col>
                        {/* <Col span={4} className='table-col-header'><span>Is Primary</span></Col> */}
                        {/* <Col span={5} className='table-col-header'><span>Sending</span></Col> */}
                        {/* <Col span={5} className='table-col-header'><span>Receiving</span></Col> */}
                        <Col span={4} className='table-col-header'><span>Is Active</span></Col>
                        <Col span={1} className='table-col-header'><span>Action</span></Col>
                    </Row>
                </div>
                <div style={{ marginTop: 3 }}>
                    {rtnNumberList?.map((rtn1, index) => (
                        // console.log("rtn1",rtn1),
                        <Row className='test-data-value-row'>
                            <Col span={18} className='table-col-header' key={index}>
                                {/* <Input className='test-data-xpath-value' value={rtn1?.routingNumber}
                                disabled={rtn1?.isPrimaryRtn === 1}

                                onChange={(e) => updateValue('routingNumber', e.target.value, index)}
                            /> */}
                                <Typography style={{ color: rtn1?.isPrimaryRtn === 1 ? "#ACACAC" : '#ACACAC' }}>{rtn1?.routingNumber}</Typography>
                            </Col>

                            <Col span={3} className='table-col-header' key={index}>
                                <XSwitch
                                    checked={rtn1?.isActive}
                                    disabled={rtn1?.isPrimaryRtn === 1}
                                    onChange={rtn1?.isPrimaryRtn === 1 ? null : () => handleActiveCheck(!rtn1?.isActive === false ? 0 : 1, rtn1?.routingNumber)}
                                    sx={{
                                        // Define your styles here
                                        '& .MuiSwitch-track': {
                                            // backgroundColor: rtn1.isActive ? '#009966 !important' : '#ACACAC !important',
                                            backgroundColor: rtn1?.isPrimaryRtn === 1 ? '#ACACAC !important' : (rtn1.isActive ? '#009966 !important' : '#ACACAC !important'),
                                            opacity: 1,
                                        },
                                    }}
                                />
                            </Col>
                            <Col span={2} className='table-col-header'>
                                <DeleteOutlineIcon
                                    onClick={rtn1?.isPrimaryRtn === 1 ? null : () => handleDeleteModal(!rtn1?.isDelete === true ? 1 : 0, rtn1?.routingNumber, true)}
                                    disabled={rtn1?.isPrimaryRtn === 1}
                                    // sx={{ color: '#676878' }}
                                    sx={{ color: rtn1?.isPrimaryRtn === 1 ? "#ACACAC" : '#676878' }}
                                />
                            </Col>
                        </Row>
                    ))}
                </div>
            </Box>

            <Box my={2} mt={7} mx={2} sx={{ display: 'flex', justifyContent: 'flex-start', }} >
                <MUIButton
                    startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
                    sx={{ backgroundColor: '#F0F2F5', color: '#808080' }}
                    color="inherit"
                    title="Back"
                    onClick={() => userRole?.name == 'Super Admin' ?
                        navigate(`${APP_ROUTE_KEYS.CUSTOMERS.RESPONDENT_BANK}`) : navigate(`${APP_ROUTE_KEYS.SETTINGS.RESPONDENT_BANK}`)}
                />&nbsp;&nbsp;&nbsp;
                {/* <MUIButton
                    startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
                    title="Save"
                    onClick={() => handleRtnNumberSubmit()}
            /> */}

            </Box>
            <Modal
                onCancel={() => handleDeleteModal(false)}
                open={isModalVisible} footer={[]}
                closeIcon={<CancelOutlinedIcon style={{ color: "#001A11", backgroundColor: "white" }} />}
                destroyOnClose
                width={"30%"}
            >
                <Box>
                    <TestCaseHeader
                        title="Are you sure to delete ?"
                    />

                    <Box mt={4} mx={1}>
                        <MUIButton
                            startIcon={<CancelIcon style={{ fontSize: 18 }} />}
                            title="No"
                            color="inherit"
                            sx={{ backgroundColor: '#F0F2F5', color: '#808080', marginRight: "10px", }}
                            onClick={() => handleDeleteModal(false)}
                        />
                        <MUIButton
                            startIcon={<CheckCircleIcon style={{ fontSize: 20, }} />}
                            title="Delete"
                            onClick={handleDeleteRtnNumber}
                        />
                    </Box>
                </Box>
            </Modal>
            {open && <AntModal
                title="Add Routing Number"
                open={open}
                mask={false}
                width={"40%"}
                style={{ top: '15%', left: '22%' }}
                okText="Add"
                cancelText="Back"
                onCancel={handleCancelRtn}
                onOk={handleSubmit(handleRtnNumberSubmit)}
                okButtonProps={{
                    style: {
                        display: 'inline-flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: primaryColor
                    },
                    icon: <CheckCircleIcon sx={{ fontSize: '18px' }} />
                }}
                cancelButtonProps={{
                    style: {
                        display: 'inline-flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#F0F2F5',
                        color: greyBorderColor
                    },
                    icon: <CancelIcon sx={{ fontSize: '18px' }} />
                }}
                closeIcon={<CancelOutlinedIcon sx={{ color: "#001A11", backgroundColor: "white" }} />}
            >
                <Box mt={2}>
                    {/* <span style={{ textAlign: 'left' }}>Primary Routing Number <font color='red'>*</font></span>
                <br /> */}
                    <Controller
                        control={control}
                        name={"routingNumber"}
                        render={({ field }) => (
                            <PayaptTextField
                                {...register("routingNumber")}
                                size={"small"}
                                {...field}
                                name="routingNumber"
                                placeholder='Enter Routing Number'
                                margin="dense"
                                // className="user-text-box"
                                error={!!errors['routingNumber']}
                                helperText={errors['routingNumber']?.message}

                            />
                        )}
                    />
                </Box>
            </AntModal>}
        </Box>
    )
}

export default RespondentRoutingNo;
