import { Box, InputBase } from '@mui/material';
import React from 'react';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { LeftCircleFilled } from '@ant-design/icons';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Dragger from 'antd/es/upload/Dragger';

import './styles.css';
import MUIButton from './MUIButton';
import Dropdown from './Dropdown';
import TestCaseHeader from '../../components/testCaseHeader';

import testcase1  from '../../assets/testcases/testcase-1.xlsx';

const dropdownOptions = [
  { id: 'fedNow', label: 'FEDNOW' },
];
const DragDropFile = (props) => {
  const { title = "", subTitle = "", sampleFileUrl = "", onChange, onClick, hasDropdown = false, onUploadFile,onUploadxlsx,isSimData,beforeUpload,fileList,showUploadList } = props;
  const [dropdownSelectedValue, setDropdownSelectedValue] = React.useState("");

  const handleDropDownOnChange = (xItem) => {
    setDropdownSelectedValue(xItem)
  }
  return (
    <Box>
      <TestCaseHeader title={title} subtitle={subTitle} showInfoIcon={false}>
        {hasDropdown && <Box sx={{ width: 200, mt: 2 }}>
          {/* <Dropdown
            id="select-type-by"
            label="Select"
            value={dropdownSelectedValue}
            onChange={handleDropDownOnChange}
            options={dropdownOptions}
            placeholder="Select Option"
          /> */}
        </Box>}
      </TestCaseHeader>

      <Box >
        {/* <Box sx={{ justifyContent: 'flex-end' }} className='upload-file-content'>
        <p style={{ textAlign: 'end' }}>Need to check sample file? <a href= {testcase1} rel="noopener noreferrer">Download Now</a> </p>
        </Box> */}
        <Box container mb={3}>
          <Dragger style={{ padding: '30px', cursor: 'pointer' }} onChange={onChange} beforeUpload={beforeUpload} fileList={fileList} showUploadList={showUploadList}>
            <CloudUploadIcon style={{ fontSize: 38 ,color: '#808080'}} />
            <p>Drag & Drop your file here</p>
          </Dragger>
        </Box>
        <Box>
          <MUIButton
            startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
            title="Cancel"
            sx={{ backgroundColor: '#F0F2F5', color: '#808080', marginLeft: "10px", marginRight: "10px" }}
            color="inherit"
            onClick={onClick}
          >
          </MUIButton>
          <MUIButton
            startIcon={<CheckCircleIcon style={{ fontSize: 18, }} />}
            title="Upload File"
            sx={{ margin: "0px 10px" }}
            onClick={!isSimData? onUploadFile : onUploadxlsx}
          />
        </Box>
      </Box>

    </Box>
  )
}

export default DragDropFile