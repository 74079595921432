import React from 'react';
import Welcome from '../../assets/images/welcome.png'
import { useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateCurrentStep, updateSubscriptionPlan } from '../../actions/customerOnboarding'

import { updateUserProfile } from '../../redux/userProfile/userProfile.slice';
import { updateUserAuth } from '../../redux/auth/auth.slice';

const ThanksForCompleteProfile = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onLogin = async () => {
    await dispatch(updateUserProfile(true));
    await dispatch(updateUserAuth(true));
    navigate('/signin');
  }
  return (
    <div>
      <h1>Thanks For Completing Your Profile</h1>
      <p>You will receive an email once your account got activated</p><br />
      <br />
      <img style={{ resize: 'inline', height: '300px', width: '400px' }} src={Welcome} className='onboarding-img' /><br /><br /><br />
      <p>Click here to <font onClick={onLogin} style={{ color: "#009966",cursor: "pointer" }}>Login</font></p>
    </div>
  )
}
const mapStateToProps = (state) => ({
  // currentStep: state.customerOnboarding.currentStep,
  // subscriptionPlan: state.customerOnboarding.subscriptionPlan,
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    updateCurrentStep,
    updateSubscriptionPlan
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ThanksForCompleteProfile);