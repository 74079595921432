import { Box, Checkbox, Menu, MenuItem, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import InfoSharpIcon from '@mui/icons-material/InfoSharp';
import { useSelector } from 'react-redux';
import { useGetParticipationTypeByIdMutation, useSaveParticipationByIdMutation, useUpdateParticipationByIdMutation, useFetchUpdatedParticipationReviewListByIdMutation, useStatusCheckParticipationTypeMutation } from '../../redux/customerOnBoarding/customerOnBoarding.api';
import ConditionalIcon from '../../assets/images/conditional.png';
import prohibitedIcon from '../../assets/images/prohibited.png';
import mandatoryIcon from '../../assets/images/mandatory.png';
import checkedBlue from '../../assets/images/checkedBlue.png';
import optionalIcon from '../../assets/images/optionalIcon.png';
import { MandatoryIcon, OptionalIcon, ProhibitedIcon, ConditionalIconSvg, ConditionalCheckIcon } from '../../assets/base64/images';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import MUIButton from '../../shared/components/MUIButton';
import { LeftCircleFilled } from '@ant-design/icons';
import { ALERT_SEVERITY, APP_ROUTE_KEYS } from '../../shared/constants/constants';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';
import _, { map } from 'lodash';
import { message } from 'antd';
import '../Settings/settingStyles.css';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
import PayaptLoader from "../../shared/components/Spinner";
import { useGetCustomerDataByIdMutation } from '../../redux/customers/customer.api';


const ConfigureParticipationSettings = () => {

    const Snackbar = useContext(SnackbarContext);
    const { participationType } = useSelector(state => state?.common?.customerOnBoarding);
    const [getParticipationTypeById, { data }] = useGetParticipationTypeByIdMutation();
    const [saveParticipationById, { data: saveList }] = useSaveParticipationByIdMutation();
    const [updateParticipationById, { data: updateList, isLoading }] = useUpdateParticipationByIdMutation();
    const [fetchUpdatedParticipationReviewListById, { data: reviewList }] = useFetchUpdatedParticipationReviewListByIdMutation();
    const [statusCheckParticipationType, { data: statusList }] = useStatusCheckParticipationTypeMutation();
    const { userRole } = useSelector((state) => state?.common?.userDetails);
    const customerId = useSelector((state) => state?.common?.customerId);
    const navigate = useNavigate();
    const [messageData, setMessageData] = useState();
    let newArray = [];

    const [getCustomerDataById, { data: customerAccountList }] = useGetCustomerDataByIdMutation();
    const payloadCustomer = {
        customerId: customerId
    }
    React.useEffect(() => {
        if (customerId) {
            getCustomerDataById(payloadCustomer);
        }
    }, [customerId])
    var payload = customerAccountList?.participationType;

    // console.log("DATAAAAA", data);

    var updatePayload = {
        customerId: customerId,
        participationTypeId: customerAccountList?.participationType,
    }
    // console.log('participationTypeCheck', participationType,payload);
    const [myArray, setMyArray] = useState([]);
    const [checked, setChecked] = React.useState(false);
    const [checkId, setCheckId] = React.useState();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [userId, setUserId] = React.useState();
    const [tableData, setTableData] = useState()
    const [sendStatus, setSendStatus] = useState(false);
    const [receiveStatus, setReceiveStatus] = useState(false);
    const [checkData, setCheckData] = useState([]);
    const [jsonData, setJsonData] = useState([]);
    // var jsonData = []

    // React.useEffect(() => {
    //     statusCheckParticipationType(updatePayload)
    // }, [])

    const saveParicipationCofigSend = (item, event, status) => {
        // console.log("item===>", item);
        let newCheck = checkData;
        if (item.isOptional === false || item.isCondition === false || item.isReceive === false) {
            // console.log("item===>", item);
            if (newCheck.some((cEl) => (cEl.participationConfigId === item.participationTypeConfigId))) {
                if (newCheck.some((cEl) => cEl.receiveStatus && (cEl.participationConfigId === item.participationTypeConfigId))) {
                    const upd_obj = newCheck.map(obj => {
                        if (obj.participationConfigId === item.participationTypeConfigId) {
                            obj.send = obj.sendStatus ? item.send : "YesO"
                            obj.sendStatus = status
                        }
                        return obj;
                    })
                    newCheck = upd_obj;
                } else {
                    newCheck = newCheck.filter((nEl) => nEl.participationConfigId !== item.participationTypeConfigId);
                    setUserId(item.participationTypeConfigId)
                }
            } else {
                if (item.send === 'O' || item.send === 'YesO') {
                    var newObj = {
                        participationId: payload,
                        participationConfigId: item.participationTypeConfigId,
                        send: item.send === 'YesO' ? 'O' : 'YesO',
                        receive: item.receive,
                        sendStatus: status,
                        //receiveStatus: false
                    }
                    setCheckId(event.target.value)
                    newCheck.push(newObj);
                } else if (item.send === 'C' || item.send === 'YesC') {
                    var newObj = {
                        participationId: payload,
                        participationConfigId: item.participationTypeConfigId,
                        send: item.send === 'C' ? 'YesC' : 'C',
                        receive: item.receive,
                        sendStatus: status,
                        //receiveStatus: false
                    }
                    setCheckId(event.target.value)
                    newCheck.push(newObj);
                }
            }
            setCheckData(newCheck);


            setJsonData(jsonData.map((messageGroup) => {
                const updatedGroup = { ...messageGroup };

                for (const key in updatedGroup) {
                    if (Array.isArray(updatedGroup[key])) {
                        updatedGroup[key] = updatedGroup[key].map((message) => {
                            if (message.participationTypeConfigId === item.participationTypeConfigId) {
                                return {
                                    ...message, send: item.send === 'O' ? 'YesO' : item.send === 'C' ? 'YesC' : item.send === 'YesO' ? 'O' : item.send === 'YesC' ? 'C' : item.send,
                                };
                            }
                            return message;
                        });
                    }
                }

                return updatedGroup;
            }))
        }
        else if (item.isOptional === true || item.isCondition === true) {
            let newCheck = checkData;
            //   alert(item.conditionCusPartTypeId)
            //   Snackbar.displayMsg(`Participation Type ${item.typeName} Failed to Update`,ALERT_SEVERITY.ERROR)
            // console.log('else if');

            try {
                _.map(jsonData, (valuee, key) =>
                    _.map(valuee, (value, key, i) => (
                        _.map(value, (zRow, index) => {
                            value?.map(valueOfValue => {
                                if (item.isOptional === true && item.send === 'YesO' && valueOfValue.receive === 'YesC') {

                                    // Snackbar.displayMsg(`Participation Type ${valueOfValue.typeName} needs Send of ${item.typeName} should be selected`, ALERT_SEVERITY.ERROR)
                                    Snackbar.displayMsg(`${valueOfValue.typeName} (send) can be selected only if the ${item.typeName}(Receive) is chosen`, ALERT_SEVERITY.ERROR)
                                }
                                else {

                                    if (item?.conditionCusPartTypeId === valueOfValue?.participationTypeConfigId) {
                                        // console.log('valueOfValue', valueOfValue);
                                        //  if(valueOfValue?.receive=== 'C' && item?.send=== 'O'){
                                        //     Snackbar.displayMsg(`Participation Type ${item.typeName} needs Receive of ${valueOfValue.typeName} should be selected`,ALERT_SEVERITY.ERROR)
                                        //  }
                                        if (item?.send) {
                                            // if(item?.send=== 'O'){
                                            // Snackbar.displayMsg(`Participation Type ${item.typeName} needs Receive of ${valueOfValue.typeName} should be selected`,ALERT_SEVERITY.ERROR)
                                            // }
                                            let newCheck = checkData;
                                            if (newCheck.some((cEl) => (cEl.participationConfigId === item.participationTypeConfigId))) {
                                                if (newCheck.some((cEl) => cEl.receiveStatus && (cEl.participationConfigId === item.participationTypeConfigId))) {
                                                    const upd_obj = newCheck.map(obj => {
                                                        if (obj.participationConfigId === item.participationTypeConfigId) {
                                                            obj.send = obj.sendStatus ? item.send : "YesO"
                                                            obj.sendStatus = status
                                                        }
                                                        return obj;
                                                    })
                                                    newCheck = upd_obj;
                                                } else {
                                                    newCheck = newCheck.filter((nEl) => nEl.participationConfigId !== item.participationTypeConfigId);
                                                    setUserId(item.participationTypeConfigId)
                                                }
                                            } else {
                                                if (item.send === 'O' || item.send === 'YesO') {
                                                    var newObj = {
                                                        participationId: payload,
                                                        participationConfigId: item.participationTypeConfigId,
                                                        send: item.send === 'YesO' ? 'O' : 'YesO',
                                                        receive: item.receive,
                                                        sendStatus: status,
                                                        //receiveStatus: false
                                                    }
                                                    setCheckId(event.target.value)
                                                    // console.log('newCheck1', newCheck);
                                                    newCheck.push(newObj);
                                                    // console.log('newCheck2', newCheck);
                                                    // console.log('newObj', newObj);
                                                    setCheckData(newCheck);
                                                } else if (item.send === 'C' || item.send === 'YesC') {
                                                    var newObj = {
                                                        participationId: payload,
                                                        participationConfigId: item.participationTypeConfigId,
                                                        send: item.send === 'C' ? 'YesC' : 'C',
                                                        receive: item.receive,
                                                        sendStatus: status,
                                                        //receiveStatus: false
                                                    }
                                                    setCheckId(event.target.value)
                                                    newCheck.push(newObj);
                                                    setCheckData(newCheck);
                                                }
                                            }
                                            // setCheckData(newCheck);


                                            setJsonData(jsonData.map((messageGroup) => {
                                                const updatedGroup = { ...messageGroup };

                                                for (const key in updatedGroup) {
                                                    if (Array.isArray(updatedGroup[key])) {
                                                        updatedGroup[key] = updatedGroup[key].map((message) => {
                                                            if (message.participationTypeConfigId === item.participationTypeConfigId) {
                                                                return {
                                                                    ...message, send: item.send === 'O' ? 'YesO' : item.send === 'C' ? 'YesC' : item.send === 'YesO' ? 'O' : item.send === 'YesC' ? 'C' : item.send,
                                                                };
                                                            }
                                                            return message;
                                                        });
                                                    }
                                                }

                                                return updatedGroup;
                                            }))
                                        }
                                    }
                                }
                            })
                        }
                        ))))
            }
            catch (e) {
                console.log('e', e)
            }

        }

    };
    // console.log('checkdataa', checkData);
    const MessageType = (item) => {
        // console.log('MessageType');
        // Find the message type based on participationTypeConfigId
        const getTypeName = (item) => {
            // console.log('getTypeName');
            for (const messageTypeKey in jsonData) {
                const types = jsonData[messageTypeKey];
                const foundType = types.find(
                    (type) => type.participationTypeConfigId === item.participationTypeConfigId
                );
                // console.log('foundType',foundType);
                if (foundType) {
                    // console.log('foundType');
                    return foundType.typeName;
                }
            }
            return 'Message type not found.';
        };
        // console.log('getTypeName', getTypeName);
        // Render the message type name if found, or a default message if not found
        return <div>Type Name: {getTypeName(jsonData)}</div>;
    };


    // console.log("jsonData====obob=======>", jsonData);
    const saveParicipationCofigReceive = (item, event, status) => {
        // console.log("receive  ===>", item);
        let newCheck = checkData;
        if (item.isOptional === false || item.isCondition === false) {

            if (newCheck.some((cEl) => cEl.participationConfigId === item.participationTypeConfigId)) {
                if (newCheck.some((cEl) => (cEl.participationConfigId === item.participationTypeConfigId))) {
                    const upd_obj = newCheck.map(obj => {
                        if (obj.participationConfigId === item.participationTypeConfigId) {
                            obj.receive = item.receive === 'YesO' ? 'O' : item.receive === 'C' ? 'YesC' : item.receive === 'YesC' ? 'C' : item.receive;
                            obj.receiveStatus = status
                        }
                        return obj;
                    })
                    newCheck = upd_obj;
                } else {
                    newCheck = newCheck.filter((nEl) => nEl.participationConfigId !== item.participationTypeConfigId);
                    setUserId(item.participationTypeConfigId)
                }

            } else {
                if (item.receive === 'O' || item.receive === 'YesO') {
                    var newObj = {
                        participationId: payload,
                        participationConfigId: item.participationTypeConfigId,
                        send: item.send,
                        receive: item.receive === 'YesO' ? 'O' : 'YesO',
                        receiveStatus: status
                    }
                    setCheckId(event.target.value)
                    newCheck.push(newObj);
                } else if (item.receive === 'C' || item.receive === 'YesC') {
                    var newObj = {
                        participationId: payload,
                        participationConfigId: item.participationTypeConfigId,
                        send: item.send,
                        receive: item.receive === 'C' ? 'YesC' : 'C',
                        receiveStatus: status
                    }
                    setCheckId(event.target.value)
                    newCheck.push(newObj);
                }


            }
            setJsonData(jsonData.map((messageGroup) => {
                const updatedGroup = { ...messageGroup };

                for (const key in updatedGroup) {
                    if (Array.isArray(updatedGroup[key])) {
                        updatedGroup[key] = updatedGroup[key].map((message) => {
                            if (message.participationTypeConfigId === item.participationTypeConfigId) {
                                return {
                                    ...message, receive: item.receive === 'C' ? 'YesC' : item.receive === 'O' ? 'YesO' : item.receive === 'YesO' ? 'O' : item.receive === 'YesC' ? 'C' : item.receive
                                };
                            }
                            return message;
                        });
                    }
                }

                return updatedGroup;
            }))
        }
        else if (item.isOptional === true || item.isCondition === true) {
            //   alert(item.conditionCusPartTypeId)
            //   Snackbar.displayMsg(`Participation Type ${item.typeName} Failed to Update`,ALERT_SEVERITY.ERROR)
            // console.log('else if');
            try {
                _.map(jsonData, (valuee, key) =>
                    _.map(valuee, (value, key, i) => (
                        _.map(value, (zRow, index) => {
                            value?.map(valueOfValue => {
                                if (item?.OptionalCusPartTypeId === valueOfValue?.participationTypeConfigId) {
                                    // console.log('valueOfValue', valueOfValue);
                                    if (valueOfValue?.receive === 'YesC' && item?.send === 'O') {
                                        // Snackbar.displayMsg(`Participation Type ${item.typeName} needs Receive of ${valueOfValue.typeName} should be selected`, ALERT_SEVERITY.ERROR)
                                        Snackbar.displayMsg(`${item.typeName} (Receive) can be selected only if the ${valueOfValue.typeName} (Send) is chosen`, ALERT_SEVERITY.ERROR)
                                    }
                                    else if (valueOfValue?.send === 'O') {
                                        // Snackbar.displayMsg(`Participation Type ${item.typeName} needs Send of ${valueOfValue.typeName} should be selected`, ALERT_SEVERITY.ERROR)
                                        Snackbar.displayMsg(`${item.typeName} (Receive) can be selected only if the ${valueOfValue.typeName} (Send) is chosen`, ALERT_SEVERITY.ERROR)
                                    }
                                    else if (valueOfValue?.send === 'YesO') {
                                        if (valueOfValue?.send === 'O') {
                                            // Snackbar.displayMsg(`Participation Type ${item.typeName} needs Send of ${valueOfValue.typeName} should be selected`, ALERT_SEVERITY.ERROR)
                                            Snackbar.displayMsg(`${item.typeName} (Receive) can be selected only if the ${valueOfValue.typeName} (Send) is chosen`, ALERT_SEVERITY.ERROR)
                                        }
                                        let newCheck = checkData;

                                        if (newCheck.some((cEl) => cEl.participationConfigId === item.participationTypeConfigId)) {
                                            if (newCheck.some((cEl) => (cEl.participationConfigId === item.participationTypeConfigId))) {
                                                const upd_obj = newCheck.map(obj => {
                                                    if (obj.participationConfigId === item.participationTypeConfigId) {
                                                        obj.receive = item.receive === 'YesO' ? 'O' : item.receive === 'C' ? 'YesC' : item.receive === 'YesC' ? 'C' : item.receive;
                                                        obj.receiveStatus = status
                                                    }
                                                    return obj;
                                                })
                                                newCheck = upd_obj;
                                            } else {
                                                newCheck = newCheck.filter((nEl) => nEl.participationConfigId !== item.participationTypeConfigId);
                                                setUserId(item.participationTypeConfigId)
                                            }

                                        } else {
                                            if (item.receive === 'O' || item.receive === 'YesO') {
                                                var newObj = {
                                                    participationId: payload,
                                                    participationConfigId: item.participationTypeConfigId,
                                                    send: item.send,
                                                    receive: item.receive === 'YesO' ? 'O' : 'YesO',
                                                    receiveStatus: status
                                                }
                                                setCheckId(event.target.value)
                                                newCheck.push(newObj);
                                            } else if (item.receive === 'C' || item.receive === 'YesC') {
                                                var newObj = {
                                                    participationId: payload,
                                                    participationConfigId: item.participationTypeConfigId,
                                                    send: item.send,
                                                    receive: item.receive === 'C' ? 'YesC' : 'C',
                                                    receiveStatus: status
                                                }
                                                setCheckId(event.target.value)
                                                newCheck.push(newObj);
                                            }


                                        }
                                        setJsonData(jsonData.map((messageGroup) => {
                                            const updatedGroup = { ...messageGroup };

                                            for (const key in updatedGroup) {
                                                if (Array.isArray(updatedGroup[key])) {
                                                    updatedGroup[key] = updatedGroup[key].map((message) => {
                                                        if (message.participationTypeConfigId === item.participationTypeConfigId) {
                                                            return {
                                                                ...message, receive: item.receive === 'C' ? 'YesC' : item.receive === 'O' ? 'YesO' : item.receive === 'YesO' ? 'O' : item.receive === 'YesC' ? 'C' : item.receive
                                                            };
                                                        }
                                                        return message;
                                                    });
                                                }
                                            }

                                            return updatedGroup;
                                        }))



                                    }
                                }
                            })
                        }
                        ))))
            }
            catch (e) {

            }
        }
    };


    React.useEffect(() => {
        if (updatePayload?.participationTypeId) {
          fetchUpdatedParticipationReviewListById(updatePayload)  
        }
        
    }, [statusList,customerAccountList]);





    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            color: theme.palette.common.black,
            border: 0,
        },
        [`&.${tableCellClasses.body}`]: {
            borderBottom: "none",
            fontSize: 14,
            border: 0,
        },
    }));
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
            border: 0,
        },
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const [modifiedDataArray, setModifiedDataArray] = React.useState([]);

    const handleRowSelect = (id) => {
        setModifiedDataArray([...modifiedDataArray, id]);
    }

    const menuItems = [
        {
            // src : MandatoryIcon, 
            svg: <MandatoryIcon />,
            label: "mandatory"
        },
        {
            svg: <ProhibitedIcon />,
            label: "Prohibited",
        },
        {
            svg: <OptionalIcon />,
            label: "Optional"
        },
        {
            svg: <ConditionalIconSvg />,
            label: "Conditional"
        },
        {
            Desc: '',
            label: "N/A Not Applicable"
        },
    ];


    const configMenu = [
        {
            key: 'customerCreditTransfer',
            value: 'Customer Credit Transfer'
        },
        {
            key: 'paymentReturns',
            value: 'Payment Returns'
        },
        {
            key: 'requestForPayment',
            value: 'Request For Payment'
        },
        {
            key: 'informationRequestMessage',
            value: 'Information Request Message'
        },
        {
            key: 'accountReportingMessage',
            value: 'Account Reporting Message'
        },
        {
            key: 'systemMessages',
            value: 'System Messages'
        }
    ];

    const selectRowHeader = (keyy) => {
        const filteredValues = configMenu.filter((menuItem) => {
            return menuItem.key === keyy;
        }).map((menuItem) => {
            return menuItem.value;
        }).join(', ');
        return filteredValues

    }
    const onSaveHandler = () => {

        const options = {
            data: checkData,
            customerId: customerId
        };
        // if (data?.isFlag === "Save") {
        //     saveParticipationById(options).then(res => {
        //         if (res?.error.data === "customerTypeUpdated Successfully") {
        //             message.success("Customer Type Saved Successfully")
        //         }
        //         else {
        //             message.error("Customer Type Failed to Saved ")
        //         }
        //     }

        //     )
        // }
        // else {
        updateParticipationById(options).then(res => {
            // console.log("RESSSSSSSSSSSSS", res)
            if (res?.data?.message === "Participation Updated Successfully") {
                // message.success("Customer Type Updated Successfully")
                Snackbar.displayMsg("Participation Updated Successfully", ALERT_SEVERITY.SUCCESS)
            }
            else {
                Snackbar.displayMsg("Participation Type Failed to Update ", ALERT_SEVERITY.ERROR)
            }
            // if (res?.data?.message === "Participation List Updated Successfully") {
            //     message.success("Customer Type Updated Successfully")
            // }
            // else {
            //     message.error("Customer Type Failed to Update ")
            // }
        }

        )
        // if (updateList?.message === "Participation Updated Successfully") {
        //     // message.success("Customer Type Updated Successfully")
        //     Snackbar.displayMsg("Participation Updated Successfully", ALERT_SEVERITY.SUCCESS)
        // }
        // else {
        //     Snackbar.displayMsg("Participation Type Failed to Update ", ALERT_SEVERITY.ERROR)
        // }
        // }

        // saveParticipationById(options);
        // updateParticipationById(options);
    }
    // console.log("updateList", updateList);
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    useEffect(() => {
        if (reviewList) {
            setTableData(reviewList)
        }
        else {
            setTableData(data)
        }
    }, [reviewList, data])
    const modifiedData = _.map(tableData, (value, key) => {

        if (key != "isFlag" && jsonData.length <= 0) {
            // setJsonData({value})
            jsonData.push(value)
            setMessageData(value)
        }
    })
    // console.log("receivejsonData===========>",jsonData);
    // console.log("messageData===========>",messageData);
    return (
        <div style={{ textAlign: 'left' }}>
            {isLoading && <PayaptLoader sx={{ position: "absolute", left: "55%" }} />}
            <Box my={1} mx={2}>
                <h4 className="title">Selected Participation Type &nbsp;
                    <InfoSharpIcon
                        className='step-info-icon'
                        id="menuButton1"
                        aria-owns={open ? "simple-menu" : null}
                        aria-haspopup="true"
                        onMouseEnter={handleClick}
                    ></InfoSharpIcon>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {menuItems.map(item => (
                            <Tooltip title={item.label} key={item.label}>
                                <div style={{ flexDirection: 'row' }}>

                                    <MenuItem disabled={item.disabled}>{
                                        item.svg ? <span style={{ marginRight: '5px' }}>{item.svg}</span> : <></>}{item.label}</MenuItem>
                                </div>
                            </Tooltip>
                        ))}
                    </Menu>
                </h4>
                <p className='title-content'>You have selected <span style={{ fontWeight: 'bold' }}>{customerAccountList?.participationTypeName}</span>, now you can configure it</p>
            </Box>
            {(userRole?.name === 'Admin' || userRole?.name === 'Tester') ?

                <TableContainer style={{ marginTop: '20px', maxHeight: '60vh', overflowY: 'auto', width: "95%" }} sx={{ py: 2, mx: 2 }} component={Paper}>
                    {
                        jsonData &&
                        _.map(jsonData, (valuee, key) =>
                            _.map(valuee, (value, key, index) => (
                                (
                                    <Table
                                        // key={xEl.participationTypeConfigId} 
                                        size='small'
                                        sx={{ minWidth: 700 }}
                                        aria-label="customized table">
                                        <TableHead>
                                            <TableRow >
                                                {/* {xEl.headCells?.map((yEl) => (
                                    <StyledTableCell style={{ fontWeight: 'bold' }}>{yEl}</StyledTableCell>
                                    ))} */}

                                                <StyledTableCell style={{ fontWeight: 'bold' }}>{

                                                    configMenu &&
                                                    selectRowHeader(key)

                                                }
                                                </StyledTableCell>

                                                <StyledTableCell style={{ fontWeight: 'bold' }}></StyledTableCell>

                                                <StyledTableCell style={{ fontWeight: 'bold', width: '15%' }}>
                                                    {key === 'customerCreditTransfer' ? <p>Send</p> :
                                                        key === 'systemMessages' ? "    " :
                                                            key === 'requestForPayment' ? "" :
                                                                key === 'accountReportingMessage' ? "" :
                                                                    key === 'paymentReturns' ? "" :
                                                                        key === 'informationRequestMessage' ? "" : ''}
                                                </StyledTableCell>

                                                <StyledTableCell style={{ fontWeight: 'bold', width: '15%' }}>
                                                    {key === 'customerCreditTransfer' ? <p>Receive</p> :
                                                        key === 'systemMessages' ? " " :
                                                            key === 'requestForPayment' ? "" :
                                                                key === 'accountReportingMessage' ? "" :
                                                                    key === 'paymentReturns' ? "" :
                                                                        key === 'informationRequestMessage' ? "" : ''}
                                                </StyledTableCell>

                                            </TableRow>

                                            <TableRow >

                                            </TableRow>

                                        </TableHead>
                                        <TableBody>

                                            {
                                                _.map(value, (zRow, key) =>
                                                (
                                                    <StyledTableRow onClick={handleRowSelect} key={zRow.participationTypeConfigId}>

                                                        <StyledTableCell sx={{ width: '50%' }} component="th" scope="row">{zRow.typeName}</StyledTableCell>

                                                        <StyledTableCell>{zRow.message}</StyledTableCell>

                                                        <StyledTableCell  >
                                                            {
                                                                zRow.send === "M" ?
                                                                    <>
                                                                        <Box component="img" sx={{ height: 20, width: 20, }} src={mandatoryIcon} />
                                                                    </>
                                                                    :
                                                                    zRow.send === "O" ?
                                                                        <>
                                                                            {/* <Checkbox sx={{ height: 20, width: 20, }}
                                                                                // onClick={()=>handleUpdateCustomerSend(zRow)}
                                                                                // checked={zRow.send}
                                                                                inputProps={{ 'aria-label': 'controlled' }} /> */}
                                                                            <Box component="img" sx={{ height: 20, width: 20, }} src={optionalIcon} />
                                                                        </>
                                                                        :
                                                                        zRow.send === 'P' ?
                                                                            <>
                                                                                <Box component="img" sx={{ height: 20, width: 20, }} src={prohibitedIcon} />
                                                                            </>
                                                                            :
                                                                            zRow.send === 'C' ?
                                                                                <>
                                                                                    <Box component="img" sx={{ height: 20, width: 20, }} src={ConditionalIcon} />
                                                                                </>
                                                                                :
                                                                                zRow.send === "Yes" ? <>
                                                                                    <Box component="img" sx={{ height: 20, width: 20, }} src={checkedBlue} />
                                                                                    {/* <Checkbox sx={{ height: 20, width: 20, }}
                                                                                        // onClick={()=>handleUpdateCustomerSend(zRow)}
                                                                                        checked={zRow.send === 'Yes' ? true : false}
                                                                                        inputProps={{ 'aria-label': 'controlled' }} /> */}
                                                                                </>
                                                                                    : zRow.send === "YesO" ?
                                                                                        <>
                                                                                            <Box component="img" sx={{ height: 20, width: 20, }} src={checkedBlue}
                                                                                            // onClick={(e) => saveParicipationCofigSend(zRow, e, false)} 
                                                                                            />
                                                                                        </>
                                                                                        :
                                                                                        <><p>N/A</p></>
                                                            }</StyledTableCell>

                                                        <StyledTableCell>
                                                            {zRow.receive === "M" ? <>
                                                                {/* <Checkbox sx={{height: 20,width: 20,}}
                                    // onClick={(e) => receiveCheckboxClick(zRow)}
                                    checked={zRow.receive}
                                    inputProps={{ 'aria-label': 'controlled' }}/> */}
                                                                <Box component="img" sx={{ height: 20, width: 20, }} src={mandatoryIcon} />
                                                            </>
                                                                :
                                                                zRow.receive === 'O' ?
                                                                    <>
                                                                        {/* <Checkbox sx={{ height: 20, width: 20, }}
                                                                            // onClick={()=>handleUpdateCustomer(zRow)}
                                                                            // checked={zRow.receive}
                                                                            inputProps={{ 'aria-label': 'controlled' }} />
                                                                        {checked ? (
                                                                            <img src="checkedblue.png" alt="Checked" />
                                                                        ) : (
                                                                            <img src="unchecked.png" alt="Unchecked" />
                                                                        )} */}
                                                                        <Box component="img" sx={{ height: 20, width: 20, }} src={optionalIcon}
                                                                        // onClick={(e) => saveParicipationCofigReceive(zRow, e, true)} 
                                                                        />
                                                                    </>
                                                                    :
                                                                    zRow.receive === 'P' ?
                                                                        <>
                                                                            <Box component="img" sx={{ height: 20, width: 20, }} src={prohibitedIcon} />
                                                                        </>
                                                                        :
                                                                        zRow.receive === 'C' ?
                                                                            <>
                                                                                <Box component="img" sx={{ height: 20, width: 20, }} src={ConditionalIcon} />
                                                                            </>
                                                                            :
                                                                            zRow.receive === 'YesO' ?
                                                                                <>
                                                                                    <Box component="img" sx={{ height: 20, width: 20, }} src={checkedBlue}
                                                                                    // onClick={(e) => saveParicipationCofigSend(zRow, e, false)} 
                                                                                    />
                                                                                </>
                                                                                :
                                                                                zRow.receive === 'YesC' ?
                                                                                    <>
                                                                                        <Box component="span" sx={{ height: 20, width: 20, }}
                                                                                        //  onClick={(e) => saveParicipationCofigReceive(zRow, e, false)} 
                                                                                        >
                                                                                            <ConditionalCheckIcon />
                                                                                        </Box>
                                                                                    </>
                                                                                    :
                                                                                    <><p>N/A</p></>
                                                            }
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                ))
                            ))
                    }
                </TableContainer>
                :

                <TableContainer style={{ marginTop: '20px', maxHeight: '60vh', overflowY: 'auto', width: "95%" }} sx={{ py: 2, mx: 2 }} component={Paper}>
                    {
                        jsonData &&
                        _.map(jsonData, (valuee, key) =>
                            _.map(valuee, (value, key, i) => (
                                (
                                    <Table
                                        size='small'
                                        sx={{ minWidth: 700 }}
                                        aria-label="customized table">
                                        <TableHead>
                                            <TableRow >
                                                <StyledTableCell style={{ fontWeight: 'bold' }}>
                                                    {
                                                        configMenu &&
                                                        selectRowHeader(key)
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell style={{ fontWeight: 'bold' }}></StyledTableCell>
                                                <StyledTableCell style={{ fontWeight: 'bold', width: '15%' }}>
                                                    {key === 'customerCreditTransfer' ? <p>Send</p> :
                                                        key === 'systemMessages' ? "    " :
                                                            key === 'requestForPayment' ? "" :
                                                                key === 'accountReportingMessage' ? "" :
                                                                    key === 'paymentReturns' ? "" :
                                                                        key === 'informationRequestMessage' ? "" : ''}
                                                </StyledTableCell>
                                                <StyledTableCell style={{ fontWeight: 'bold', width: '15%' }}>
                                                    {key === 'customerCreditTransfer' ? <p>Receive</p> :
                                                        key === 'systemMessages' ? " " :
                                                            key === 'requestForPayment' ? "" :
                                                                key === 'accountReportingMessage' ? "" :
                                                                    key === 'paymentReturns' ? "" :
                                                                        key === 'informationRequestMessage' ? "" : ''}
                                                </StyledTableCell>
                                            </TableRow>
                                            <TableRow >
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                _.map(value, (zRow, index) =>
                                                (
                                                    <StyledTableRow onClick={handleRowSelect} key={zRow.participationTypeConfigId}>
                                                        <StyledTableCell sx={{ width: '50%' }} component="th" scope="row">{zRow.typeName}</StyledTableCell>
                                                        <StyledTableCell>{zRow.message}</StyledTableCell>
                                                        <StyledTableCell  >
                                                            {
                                                                zRow.send === "M" ?
                                                                    <>
                                                                        <Box component="img" sx={{ height: 20, width: 20, }} src={mandatoryIcon} />
                                                                    </>
                                                                    :
                                                                    zRow.send === "O" ?
                                                                        // <>
                                                                        //     {/* <Box component="img" sx={{ height: 20, width: 20, }} src={optionalIcon} /> */}
                                                                        //         <Checkbox   sx={{ height: 20, width: 20,transform: "scale(1.2)" ,color:"#808080",
                                                                        //              strokeWidth:1,stroke: "#ffffff",
                                                                        //          }}
                                                                        //             checkedIcon={<CheckBoxOutlinedIcon sx={{color:"#051DFF"}}/>}
                                                                        //             onClick={(e) =>  saveParicipationCofigSend(zRow,e)}
                                                                        //             checked={checkData?.find((x)=> x.participationConfigId === zRow.participationTypeConfigId)? checkId : zRow.participationTypeConfigId === userId ? false : null}
                                                                        //             inputProps={{ 'aria-label': 'controlled' }} />
                                                                        //             {/* <input type='checkbox' id="usa" checked/>
                                                                        //             <label for="usa"></label> */}
                                                                        // </>
                                                                        (
                                                                            //     checkData?.find((x)=> ((x.participationConfigId === zRow.participationTypeConfigId) && x.sendStatus)) ?
                                                                            // <>
                                                                            //     <Box component="img" sx={{ height: 20, width: 20, }} src={checkedBlue}
                                                                            //     onClick={(e) => saveParicipationCofigSend(zRow,e, false)}
                                                                            //      />
                                                                            // </>
                                                                            // :zRow.participationTypeConfigId === userId ?
                                                                            <>
                                                                                <Box component="img" sx={{ height: 20, width: 20, }} src={optionalIcon} onClick={(e) => saveParicipationCofigSend(zRow, e, true)} />
                                                                            </>
                                                                            // :
                                                                            // <>
                                                                            //     <Box component="img" sx={{ height: 20, width: 20, }} src={optionalIcon} onClick={(e) => saveParicipationCofigSend(zRow,e,true)}/>
                                                                            // </>
                                                                        )
                                                                        :
                                                                        zRow.send === 'P' ?
                                                                            <>
                                                                                <Box component="img" sx={{ height: 20, width: 20, }} src={prohibitedIcon} />
                                                                            </>
                                                                            :
                                                                            zRow.send === 'C' ?
                                                                                <>
                                                                                    <Box component="img" sx={{ height: 20, width: 20, backgroundColor: 'red' }} src={ConditionalIcon}
                                                                                        onClick={(e) => saveParicipationCofigSend(zRow, e, true)} />
                                                                                </>
                                                                                :
                                                                                zRow.send === "YesO" ?

                                                                                    (
                                                                                        // checkData?.find((x)=> x.participationConfigId === zRow.participationTypeConfigId && x.send !== zRow.send && x.sendStatus) ?

                                                                                        // <>
                                                                                        //  <Box component="img" sx={{ height: 20, width: 20, }} src={checkedBlue} onClick={(e) => saveParicipationCofigSend(zRow,e, true)}/>
                                                                                        // </> : checkData?.find((x)=> ((x.participationConfigId === zRow.participationTypeConfigId) && !(x.sendStatus)))?
                                                                                        // <>
                                                                                        //  <Box component="img" sx={{ height: 20, width: 20, }} src={optionalIcon} onClick={(e) => saveParicipationCofigSend(zRow,e,false)}/>
                                                                                        // </>:
                                                                                        <>
                                                                                            <Box component="img" sx={{ height: 20, width: 20, }} src={checkedBlue} onClick={(e) => saveParicipationCofigSend(zRow, e, false)} />
                                                                                        </>)
                                                                                    :
                                                                                    <><p>N/A</p></>
                                                            }
                                                        </StyledTableCell>

                                                        <StyledTableCell>
                                                            {zRow.receive === "M" ? <>

                                                                <Box component="img" sx={{ height: 20, width: 20, }} src={mandatoryIcon} />
                                                            </>
                                                                :
                                                                zRow.receive === 'O' ?
                                                                    // <>
                                                                    //     <Checkbox sx={{ height: 20, width: 20, transform: "scale(1.2)" ,color:"#808080",
                                                                    //         strokeWidth:1,stroke: "#ffffff",
                                                                    //     }}
                                                                    //         checkedIcon={<CheckBoxOutlinedIcon sx={{color:"#051DFF"}}/>}
                                                                    //         onClick={(e) => saveParicipationCofigReceive(zRow,e)}
                                                                    //         checked={checkData?.find((x)=> x.participationConfigId === zRow.participationTypeConfigId)? checkId : zRow.participationTypeConfigId === userId ? false : null}
                                                                    //         // checked={zRow.receive}
                                                                    //         inputProps={{ 'aria-label': 'controlled' }} />
                                                                    // </>
                                                                    (
                                                                        // checkData?.find((x)=> ((x.participationConfigId === zRow.participationTypeConfigId) && x.receiveStatus))?
                                                                        // <>
                                                                        //     <Box component="img" sx={{ height: 20, width: 20, }} src={checkedBlue} onClick={(e) => saveParicipationCofigReceive(zRow,e,false)}/>
                                                                        // </>
                                                                        // :
                                                                        // zRow.participationTypeConfigId === userId ?
                                                                        <>
                                                                            <Box component="img" sx={{ height: 20, width: 20, }} src={optionalIcon} onClick={(e) => saveParicipationCofigReceive(zRow, e, true)} />
                                                                        </>
                                                                        // :
                                                                        // <>
                                                                        //     <Box component="img" sx={{ height: 20, width: 20, }} src={optionalIcon} onClick={(e) => saveParicipationCofigReceive(zRow,e,true)}/>
                                                                        // </>

                                                                    )
                                                                    :
                                                                    zRow.receive === 'P' ?
                                                                        <>
                                                                            <Box component="img" sx={{ height: 20, width: 20, }} src={prohibitedIcon} />
                                                                        </>
                                                                        : zRow.receive === 'C' ?
                                                                            (
                                                                                // checkData?.find((x)=> ((x.participationConfigId === zRow.participationTypeConfigId) && x.receiveStatus))?
                                                                                // <>
                                                                                //     <Box component="span" sx={{ height: 20, width: 20, }} 
                                                                                //     onClick={(e) => saveParicipationCofigReceive(zRow,e,false)}
                                                                                //      >
                                                                                //         <ConditionalCheckIcon/>
                                                                                //      </Box>
                                                                                // </>
                                                                                // :
                                                                                // zRow.participationTypeConfigId === userId ?
                                                                                <>
                                                                                    <Box component="img" sx={{ height: 20, width: 20, }} src={ConditionalIcon} onClick={(e) => saveParicipationCofigReceive(zRow, e, true)} />
                                                                                </>
                                                                                // :
                                                                                // <>
                                                                                //     <Box component="img" sx={{ height: 20, width: 20, }} src={ConditionalIcon} onClick={(e) => saveParicipationCofigReceive(zRow,e,true)}/>
                                                                                // </>

                                                                            )
                                                                            :
                                                                            zRow.receive === 'YesO' ?
                                                                                (
                                                                                    //     checkData?.find((x)=> ((x.participationConfigId === zRow.participationTypeConfigId) && x.receiveStatus)) ?
                                                                                    // <>
                                                                                    //     <Box component="img" sx={{ height: 20, width: 20, }} src={optionalIcon}
                                                                                    //     onClick={(e) => saveParicipationCofigReceive(zRow,e,true)}
                                                                                    //      />
                                                                                    // </>
                                                                                    // :zRow.participationTypeConfigId === userId ?
                                                                                    <>
                                                                                        <Box component="img" sx={{ height: 20, width: 20, }} src={checkedBlue} onClick={(e) => saveParicipationCofigReceive(zRow, e, false)} />
                                                                                    </>
                                                                                    // :
                                                                                    // <>
                                                                                    //     <Box component="img" sx={{ height: 20, width: 20, }} src={checkedBlue} onClick={(e) => saveParicipationCofigReceive(zRow,e,false)}/>
                                                                                    // </>
                                                                                )
                                                                                :
                                                                                zRow.receive === 'YesC' ?

                                                                                    (
                                                                                        //     checkData?.find((x)=> ((x.participationConfigId === zRow.participationTypeConfigId) && x.receiveStatus)) ?
                                                                                        // <>
                                                                                        //     <Box component="img" sx={{ height: 20, width: 20, }} src={ConditionalIcon}
                                                                                        //     onClick={(e) => saveParicipationCofigReceive(zRow,e,true)}
                                                                                        //      />
                                                                                        // </>
                                                                                        // :zRow.participationTypeConfigId === userId ?
                                                                                        <>
                                                                                            <Box component="span" sx={{ height: 20, width: 20, }} onClick={(e) => saveParicipationCofigReceive(zRow, e, false)} >
                                                                                                <ConditionalCheckIcon />
                                                                                            </Box>
                                                                                        </>
                                                                                        // :
                                                                                        // <>
                                                                                        //     <Box component="span" sx={{ height: 20, width: 20, }} onClick={(e) => saveParicipationCofigReceive(zRow,e,false)} >
                                                                                        //     <ConditionalCheckIcon/>
                                                                                        //  </Box>
                                                                                        // </>
                                                                                    )
                                                                                    :
                                                                                    <><p>N/A</p></>
                                                            }
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                )

                            )

                            ))
                    }
                </TableContainer>
            }
            <Box mt={2} mx={2}>
                {(userRole?.name === 'Admin' || userRole?.name === 'Tester') ?

                    <MUIButton
                        startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
                        sx={{ backgroundColor: '#F0F2F5', color: '#808080', }}
                        color="inherit"
                        title="Back"
                        onClick={() => navigate(`${APP_ROUTE_KEYS.SETTINGS.MASTER}`)}
                    />
                    :
                    <>

                        <Box>
                            <MUIButton
                                startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
                                sx={{ backgroundColor: '#F0F2F5', color: '#808080', }}
                                color="inherit"
                                title="Back"
                                onClick={() => navigate(`${APP_ROUTE_KEYS.CUSTOMERS.MASTER_SETTINGS}`)}
                            />
                            <MUIButton
                                startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
                                title="Save"
                                sx={{ mx: 2 }}
                                onClick={onSaveHandler}
                            />
                        </Box>
                    </>
                }
            </Box>
        </div>
    )
}

export default ConfigureParticipationSettings
