import _ from 'lodash';
import React from 'react';

const MessageListItem = (props) => {
    const { item, keys, isTitle } = props;

    return (
        <div className={isTitle ? 'payapt-list-item payapt-list-title-item' : 'payapt-list-item'}>
            {
                _.map(keys, k => (
                    <span style = {{width: `${(100/_.size(keys))}%`, textAlign: 'left'}}>{item[k]}</span>
                ))
            }
        </div>
    )
}

export default MessageListItem;