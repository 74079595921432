import React, { useContext, useEffect, useState } from "react";
import { useFetchUserNameListMutation } from "../../redux/users/users.api";
import Dropdown from "../../shared/components/Dropdown";
import _ from "lodash";
import { DropdownValueContext } from "../../shared/context/DropdownProvider";
import { useSelector } from "react-redux";

const styles = {
    menuProps: {
        sx: {
            '.MuiPaper-root': {
                maxHeight: '40%',
            },
        },
    },
}
const UserDropdown = (props) => {
    const [UsersList, setUsersList] = useState();
    const { user, onChange } = props;
    const { customer } = useContext(DropdownValueContext);
    const { customerId } = useSelector((state) => state?.common);
    const { userRole } = useSelector((state) => state?.common?.userDetails);
    const payloadMsg = {
        customerId: (userRole?.name === 'Admin' || userRole?.name === 'Tester') ? customerId : customer
    }
    const [fetchUserNameList] = useFetchUserNameListMutation()

    useEffect(() => {
        if (customer) {
            fetchUserNameList(payloadMsg).then(res => {
                setUsersList(res?.data);
            });
        }
    }, [customer]);

    const handleDropdownChange = (selectedValues) => {
        onChange(selectedValues);
    };

    return (
        <>
            <Dropdown
                placeholder='Select User'
                margin="dense"
                className="user-dropdown"
                style={{ width: '100%', height: '42px', marginTop: '-20px' }}
                inputProps={{ 'aria-label': 'Without label' }}
                options={UsersList}
                valueKey='userId'
                labelKey='firstName'
                onChange={(selectedLabel) => handleDropdownChange(selectedLabel)}
                value={user}
                MenuProps={styles.menuProps}
            // isClear={isClear}
            // disabled = {!!selectedRecord?.userId}
            />
        </>
    )
}
export default UserDropdown