import { backendApi } from "../base.config";

export const customerOnBoardingApi = backendApi.injectEndpoints({
    endpoints: (builder) => ({
        getParticipationType: builder.query({
            query: () => ({
                method: "GET",
                url:`ParticipationType/fetchParticipationTypeList`,
            })
            
        }),
        getSubscriptionPlanList: builder.query({
            query: () => ({
                method: "GET",
                url: `Subscription/subscriptionPlanList`,
                // body: { ...args },
            }),
            providesTags: [],
        }),
        getSubscriptionPlanDetailsById: builder.mutation({
            query: (args) => ({
                method: "POST",
                url: `Subscription/subscriptionPlanDetailsList`,
                body: { ...args },
            }),
            invalidatesTags : [],
        }),
        cusInfoSubscriptionPlanById: builder.mutation({
            query: (args) => ({
                method: "POST",
                url: `customers/cusInfoSubscriptionPlan`,
                params: {...args},
            }),
            invalidatesTags : [],
        }),
        getParticipationTypeById: builder.mutation({
            query:(payload) => ({
                method:"POST",
                url:`User/getParticipationType?participationTypeId=${payload}`,
                // body: payload,
            }),
        }),
        getUpdateParticipationType: builder.mutation({
            query: (payload) => ({
                method: "POST",
                url: `ParticipationType/updateParticipation`,
                body: payload,
            }),
        }),
        getUpdatedParticipationReviewListById: builder.mutation({
            query:(payload) => ({
                method:"POST",
                url:`User/getUpdatedParticipationReviewList?participationTypeId=${payload}`
            }),
        }),
        getOnboardingDetails: builder.query({
            query: (payload) => ({
                method: "GET",
                url: `onboarding/details`,
            }),
        }),
        saveParticipationType: builder.mutation({
            query: (payload) => ({
                method: "POST",
                url: `ParticipationType/saveParticipationByTypeId`,
                 body: payload,
            }),
        }),
        saveParticipationById: builder.mutation({
            query: (payload) => (
                console.log("PAYLOAD",payload),
                {
                method: "POST",
                url:`ParticipationType/saveParticipation`,
                body: payload,
            })
        }),
        updateParticipationById: builder.mutation({
            query: (payload) => (
                console.log("PAYLOAD",payload),
                {
                method: "POST",
                url:`ParticipationType/UpdateParticipation`,
                body: payload,
            })
        }),
        fetchUpdatedParticipationReviewListById: builder.mutation({
            query:(updatePayload) => ({
                method:"POST",
                url:`User/getUpdatedParticipationReviewList?customerId=${updatePayload?.customerId}&participationTypeId=${updatePayload?.participationTypeId}`
            }),
        }),
        statusCheckParticipationType: builder.mutation({
            query: (updatePayload) => ({
                method: "POST",
                url: `ParticipationType/statusCheckParticipation?participationTypeId=${updatePayload?.participationTypeId}&customerId=${updatePayload?.customerId}`,
            }),
        }),
    }),
    
});

export const {
    useGetParticipationTypeQuery,
    useGetSubscriptionPlanListQuery,
    useGetSubscriptionPlanDetailsByIdMutation,
    useCusInfoSubscriptionPlanByIdMutation,
    useGetParticipationTypeByIdMutation,
    useGetUpdateParticipationTypeMutation,
    useGetUpdatedParticipationReviewListByIdMutation,
    useGetOnboardingDetailsQuery,
    useSaveParticipationTypeMutation,
    useSaveParticipationByIdMutation,
    useUpdateParticipationByIdMutation,
    useFetchUpdatedParticipationReviewListByIdMutation,
    useStatusCheckParticipationTypeMutation,
} = customerOnBoardingApi;
