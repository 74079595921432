// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --primary-color: #009966;
    --text-color: #001A11;
    --disabled-text-color: #ACACAC;
    --line-color: #D4D4D4;
    --black-text: #000000;
    --background-white: #FFFFFF;
    --radio-button-color: #C6CBD1;
    --menu-item-background: #F0F2F5;
}
.header1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    text-transform: capitalize;
    color: var(--text-color);
}
.header2-italic {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--black-text)
}
`, "",{"version":3,"sources":["webpack://./src/components/styles/variables.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,qBAAqB;IACrB,8BAA8B;IAC9B,qBAAqB;IACrB,qBAAqB;IACrB,2BAA2B;IAC3B,6BAA6B;IAC7B,+BAA+B;AACnC;AACA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,0BAA0B;IAC1B,wBAAwB;AAC5B;AACA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB;AACJ","sourcesContent":[":root {\n    --primary-color: #009966;\n    --text-color: #001A11;\n    --disabled-text-color: #ACACAC;\n    --line-color: #D4D4D4;\n    --black-text: #000000;\n    --background-white: #FFFFFF;\n    --radio-button-color: #C6CBD1;\n    --menu-item-background: #F0F2F5;\n}\n.header1 {\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 20px;\n    text-transform: capitalize;\n    color: var(--text-color);\n}\n.header2-italic {\n    font-family: 'Inter';\n    font-style: italic;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 17px;\n    color: var(--black-text)\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
