
export const colors = {
    type: 'light',
    primary: {
        main: '#009966',
    },
    secondary: {
        main: '#777777',
        dark: '#D9D9D9',
    },
    info: {
        main: '#009966',
    },
    warning: {
        main: '#FBBC05',
    },
    error: {
        main: '#DD2025',
    },
    success: {
        main: '#009966',
    },
};

export const borderColor = '#ACACAC'
