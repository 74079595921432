import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { backendApi } from '../base.config';

export const authApi = backendApi.injectEndpoints({
  overrideExisting: true,
  baseQuery: fetchBaseQuery({ baseUrl: `${window.REACT_APP_BASE_URL}Payapt/api/v1` }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: 'jwt/authenticate',
        method: 'POST',
        body: credentials,
      }),
    }),
  }),
});


export const { useLoginMutation  } = authApi;
